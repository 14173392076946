import React, { Fragment } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@mrnkr/redux-saga-toolbox';

import {
  Creators as AuthActions,
  AuthState,
  authFormValidator,
  changePasswordFormValidator,
} from '../modules/auth.module';
import { MyState } from '../store';

import MMDForm from '../components/forms/MMDForm';
import MMDTextInput from '../components/forms/MMDTextInput';
import MMDButton from '../components/MMDButton';
import MMDModal from '../components/MMDModal';
type PropTypes = {
  auth: AuthState;
} & typeof AuthActions;

const formConfig = (auth: AuthState) => ({
  formName: 'change-password-form',
  fields: ['oldPassword', 'newPassword', 'confirmPassword'],
  validator: changePasswordFormValidator,
  onSubmit: (values) => {
    if (!auth.authenticated) return;
    return AuthActions.requestChangePassword({
      oldPassword: values['oldPassword'],
      newPassword: values['newPassword'],
      confirmPassword: values['confirmPassword'],
      tokenType: auth.token_type,
    });
  },
});

const ChangePasswordForm: React.FC<PropTypes> = ({ auth, errorAuth }) => {
  const renderFormControls = React.useCallback(
    (form: Form, onChange: (e: React.FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          type="password"
          name={form.fields['oldPassword'].name}
          label="Old Password"
          valid={form.fields['oldPassword'].valid}
          dirty={form.fields['oldPassword'].dirty}
          placeholder="********"
          value={form.fields['oldPassword'].value}
          onChange={onChange}
        />
        <MMDTextInput
          type="password"
          name={form.fields['newPassword'].name}
          label=" New password"
          valid={form.fields['newPassword'].valid}
          dirty={form.fields['newPassword'].dirty}
          placeholder="********"
          value={form.fields['newPassword'].value}
          onChange={onChange}
        />
        <MMDTextInput
          type="password"
          name={form.fields['confirmPassword'].name}
          label="Confirm password"
          valid={form.fields['confirmPassword'].valid}
          dirty={form.fields['confirmPassword'].dirty}
          placeholder="********"
          value={form.fields['confirmPassword'].value}
          onChange={onChange}
        />
        <MMDButton isSubmit isPrimary text="Change" />
      </div>
    ),
    [],
  );

  return (
    <Fragment>
      <MMDForm
        {...({ renderFormControls, formConfig: formConfig(auth) } as any)}
      />

      <MMDModal visible={!!auth.error} onRequestClose={() => errorAuth(null)}>
        <p>Error!</p>
      </MMDModal>
    </Fragment>
  );
};

const mapStateToProps = (state: MyState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...AuthActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
