import { createActions, createReducer } from 'reduxsauce';
import { Action } from 'redux';
import { SagaIterator, SplitPercentage } from '../typings';
import { API_URL } from '../config';
import {
  ErrorAction,
  MyAction,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { ArgsWithHeaders, CreationResult } from '../utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import moment from 'moment';

interface ActionTypes {
  REQUEST_CREATE_SPLIT_PERCENTAGE: string;
  LOADING_SPLIT_PERCENTAGE: string;
  ERROR_SPLIT_PERCENTAGE: string;
  COMMIT_SPLIT_PERCENTAGE: string;
}

const initialState = {
  loading: false,
};

export interface SplitPercentageState<TError extends Error = Error> {
  loading: boolean;
  error?: TError;
  currentSplitPercentage: SplitPercentage;
}

interface SplitPercentagePayload {
  applicationFeePercentage: number;
}

interface ActionCreators {
  requestCreateSplitPercentage: (
    payload: SplitPercentagePayload,
  ) => MyAction<SplitPercentage>;
  loadingSplitPercentage: () => Action;
  errorSplitPercentage: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
  commitSplitPercentage: (
    payload: SplitPercentage,
  ) => MyAction<SplitPercentage>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateSplitPercentage: ['payload'],
  loadingSplitPercentage: [],
  errorSplitPercentage: ['error'],
  commitSplitPercentage: ['payload'],
});

function setLoading(state: SplitPercentageState): SplitPercentageState {
  return {
    ...state,
    loading: true,
  };
}

function setError<TError extends Error = Error>(
  state: SplitPercentageState,
  { error }: ErrorAction<TError>,
): SplitPercentageState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function commitSplitPercentage(
  state: SplitPercentageState,
  action: MyAction<SplitPercentage>,
): SplitPercentageState {
  return {
    ...state,
    currentSplitPercentage: action.payload,
    loading: false,
  };
}

export const splitPercentageReducer = createReducer(initialState, {
  [Types.LOADING_SPLIT_PERCENTAGE]: setLoading,
  [Types.ERROR_SPLIT_PERCENTAGE]: setError,
  [Types.COMMIT_SPLIT_PERCENTAGE]: commitSplitPercentage,
});

async function createSplitPercentage({
  headers,
  ...payload
}: ArgsWithHeaders<SplitPercentagePayload>): Promise<
  CreationResult<SplitPercentage>
> {
  const now = moment().toISOString();
  const newSplitPercentage = {
    ...payload,
    createdAt: now,
    updatedAt: now,
  };

  if (
    newSplitPercentage.applicationFeePercentage < 0 ||
    newSplitPercentage.applicationFeePercentage > 100
  ) {
    throw Error('Percentage should be a value between 0 and 100');
  }

  const result = await fetch(`${API_URL}/split-percentages`, {
    headers,
    method: 'POST',
    body: JSON.stringify(newSplitPercentage),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadSplitPercentage({
  headers,
}: ArgsWithHeaders<SplitPercentagePayload>): Promise<SplitPercentage> {
  const result = await fetch(
    `${API_URL}/split-percentages?__sort=-createdAt&__limit=1`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  const topPercent = await result.json();

  return topPercent[0];
}

const requestCreateSplitPercentageWatcher = createSingleEventSaga<
  SplitPercentage,
  SplitPercentage,
  MyAction<SplitPercentage>
>({
  takeEvery: Types.REQUEST_CREATE_SPLIT_PERCENTAGE,
  loadingAction: Creators.loadingSplitPercentage,
  beforeAction: putAuthInfoInArgs,
  action: createSplitPercentage,
  *afterAction(
    res: CreationResult<SplitPercentage>,
    { headers, ...payload }: ArgsWithHeaders<SplitPercentage>,
  ): SagaIterator {
    return {
      ...payload,
      id: res.createdId.toString(),
    };
  },
  commitAction: Creators.commitSplitPercentage,
  successAction: noOpAction,
  errorAction: Creators.errorSplitPercentage,
});

const requestSplitPercentageWatcher = createSingleEventSaga<
  SplitPercentage,
  SplitPercentage,
  MyAction<SplitPercentage>
>({
  takeEvery: onRoute('/payment-config'),
  loadingAction: Creators.loadingSplitPercentage,
  beforeAction: putAuthInfoInArgs,
  action: downloadSplitPercentage,
  commitAction: Creators.commitSplitPercentage,
  successAction: noOpAction,
  errorAction: Creators.errorSplitPercentage,
});

export const splitPercentageSagas = [
  requestCreateSplitPercentageWatcher,
  requestSplitPercentageWatcher,
];
