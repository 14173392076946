import keys from 'lodash/keys';
import FileSaver from 'file-saver';
import MMDLoader from 'components/MMDLoader';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';

import { MyState } from 'store';
import MMDList from 'components/MMDList';
import { Rating, Pharmacy } from 'typings';
import MMDModal from 'components/MMDModal';
import MMDAvatar from 'components/MMDAvatar';
import MMDButton from 'components/MMDButton';
import PetForm from 'components/patient/petForm';
import MMDDropdown from 'components/MMDDropdown';
import { useFirstMount } from 'hooks/useFirstMount';
import { APPROVED, REJECTED } from 'utils/constants';
import ChildForm from 'components/patient/childForm';
import OrganizationSelect from './OrganizationSelect';
import PatientForm from 'components/patient/patientForm';
import { Files as FilesFC } from 'components/patient/files';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { getCoordinatesString } from 'utils/getCoordinatesString';
import { Creators as PetsActions } from 'modules/patients/pets.module';
import { deleteProviderImage, getFirebaseImage } from 'utils/Firebase';
import { Creators as ChildrenActions } from 'modules/patients/children.module';
import {
  selectPatientById,
  selectPatientsState,
  Creators as PatientsActions,
} from 'modules/patients.module';

const Patient = () => {
  const [patientImg, setPatientImg] = useState<string>();
  const [loadingFileFront, setLoadingFileFront] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const params = useParams<{ id: string }>();

  const isFirstMount = useFirstMount();

  const patient = useSelector((state) => selectPatientById(state, params.id));

  const { loading, loadingReport, report, files, loadingFiles } =
    useSelector(selectPatientsState);

  const {
    requestPatient,
    requestUpdatePet,
    requestUpdateChild,
    requestPatientFiles,
    commitPatientReport,
    requestUpdatePatient,
    requestPatientReport,
  } = useBoundedActions({
    ...PatientsActions,
    ...ChildrenActions,
    ...PetsActions,
  });

  useEffect(() => {
    if (!isFirstMount) {
      return;
    }

    requestPatient({ id: params.id });

    requestPatientFiles({ id: params.id });

    getFirebaseImage(`profile/${params.id}.jpeg`).then(setPatientImg);
  }, [params, patient, requestPatient, requestPatientFiles, isFirstMount]);

  useEffect(() => {
    if (!downloading) return;
    if (!report) {
      requestPatientReport({ id: params.id });
    } else {
      FileSaver.saveAs(report.data, `report-${patient.id}.pdf`);
      commitPatientReport(null); // This is just to clear the report entry in the state and avoid re-download
      setDownloading(false);
    }
  }, [params, patient, report, downloading]);

  const patientSave = useMemo(() => {
    if (!patient) return undefined;
    return {
      id: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      address: patient.address,
      birthday: patient.birthday,
      city: patient.city,
      state: patient.state,
      zipCode: patient.zipCode,
      phone: patient.phone,
      gender: patient.gender,
      avatarApproved: patient.avatarApproved,
      ssn: patient.ssn,
      coordinates: getCoordinatesString(patient.coordinates),
    };
  }, [patient]);

  const updateImageHandler = (e, relativeType, patient, diff) => {
    if (!relativeType) {
      e.preventDefault();
      requestUpdatePatient({ ...patient, ...diff });
      return;
    }
    if ((patient as any) && relativeType === 'children') {
      requestUpdateChild({
        ...diff,
        parentId: patient.parentId,
        id: patient.id,
      } as any);
      return;
    }
    if ((patient as any) && relativeType === 'pets') {
      requestUpdatePet({
        ...diff,
        parentId: patient.parentId,
        id: patient.id,
      } as any);
    }
  };

  const calculateBmi = () => {
    if (!patient) return '0.00';
    if (!patient.myLifestyle) return '0.00';

    const weight = patient.myLifestyle['Weight'];
    const height = patient.myLifestyle['Height'];
    const inches = patient.myLifestyle['inches'];
    if (!(weight && height && inches)) return '0.00';
    const feets = Number(height) + Number(inches) * 0.0833;
    const meters = feets * 0.3048;
    const kg = Number(weight) * 0.453592;
    const bmi = Number(kg) / Math.pow(meters, 2);
    const totalBmi = Math.round(bmi * 100) / 100;
    if (totalBmi > 0.0 && totalBmi !== Infinity) {
      return totalBmi;
    }
    return '0.00';
  };

  const LifeStyle = function () {
    if (patient.relativeType === 'pets') {
      return (
        <MMDList title="My lifestyle" items={patient.myLifestyle} isObject />
      );
    }

    return (
      <>
        <h6 className="has-text-weight-bold">My Lifestyle:</h6>
        <ul>
          {renderMlsLi('Weight(lbs)')}
          {renderMlsLi('Height(feet)')}
          {renderMlsLi('inches')}
          {renderMlsLi('BMI')}
          {renderMlsLi('Type 1 Diabetes and values(mg/dl)')}
          {renderMlsLi('Type 2 Diabetes and values(mg/dl)')}
          {renderMlsLi('Cancer (Type)')}
          {renderMlsLi('Physical Activity')}
          {renderMlsLi('Get 6≥ hours of sleep')}
          {renderMlsLi('Eat a healthy diet')}
          {!patient.relativeType ? (
            <>
              {renderMlsLi('Tobacco use')}
              {renderMlsLi('Alcohol')}
              {renderMlsLi('Recreational drug use')}
            </>
          ) : null}
          {patient.relativeType === 'children' ? (
            <>
              {renderMlsLi('Snore')}
              {renderMlsLi('Ever stopped breathing while asleep')}
            </>
          ) : null}
        </ul>
      </>
    );
  };

  const addBMIandUnits = (BMI, lifestyleObj) => {
    const newObj = {};
    keys(lifestyleObj).map((attr) => {
      switch (true) {
        case attr === 'Weight':
          newObj['Weight(lbs)'] = lifestyleObj[attr];
          break;

        case attr === 'Height':
          newObj['Height(feet)'] = lifestyleObj[attr];
          break;

        case attr.indexOf('Diabetes') >= 0:
          newObj[`${attr}(mg/dl)`] = lifestyleObj[attr];
          break;

        default:
          newObj[attr] = lifestyleObj[attr];
      }
      if (attr === 'inches') {
        const newKey = keys(BMI)[0];
        newObj[newKey] = BMI[newKey];
      }
    });
    return newObj;
  };

  let mls = {};
  if (patient) {
    mls = addBMIandUnits({ BMI: calculateBmi() }, patient.myLifestyle);
  }

  const renderMlsLi = (key) => {
    if (typeof mls[key] !== 'boolean') {
      return mls[key] ? (
        <li>
          {' '}
          - {key}: {mls[key]}{' '}
        </li>
      ) : null;
    }

    return mls[key] ? <li> - {key}</li> : null;
  };

  return patient ? (
    <div className="container">
      <MMDModal
        visible={showReport}
        onRequestClose={() => setShowReport(false)}
      >
        <div className="w100">
          <iframe
            src={report ? report.url : ''}
            style={{ height: '100vh', width: '100vw' }}
            className="mx-auto"
          ></iframe>
        </div>
      </MMDModal>
      <MMDLoader
        loading={loading || loadingReport || loadingFiles || loadingFileFront}
      />
      <div className="columns is-vstart">
        <div className="column is-one-fifth">
          <MMDAvatar image={patientImg} label="Image" />
        </div>
        {patientImg && (
          <div className="column is-one-fifth">
            {!patient.avatarApproved ? (
              <MMDButton
                className="mt-2"
                text="Approve Image"
                isSuccess
                onClick={(e) =>
                  updateImageHandler(
                    e,
                    (patient as any).relativeType,
                    patient as any,
                    { avatarApproved: APPROVED },
                  )
                }
              />
            ) : (
              <MMDButton
                className="mt-2"
                text="Reject Image"
                isDanger
                onClick={(e) =>
                  updateImageHandler(
                    e,
                    (patient as any).relativeType,
                    patient as any,
                    { avatarApproved: REJECTED },
                  )
                }
              />
            )}
            <MMDButton
              text="Delete Image"
              className="mt-2"
              isDanger
              onClick={(e) => {
                e.preventDefault();
                deleteProviderImage(`profile/${patient.id}.jpeg`);
                setPatientImg(null);
              }}
            />
          </div>
        )}
        <div className="column">
          {patient.organization !== undefined && (
            <OrganizationSelect
              patientId={patient.id}
              organization={patient.organization}
            />
          )}

          <Link to={`/patients/${patient.id}/immunizations`}>
            <MMDButton className="mt-2" text="Immunizations" isSuccess />
          </Link>

          <Link to={`/patients/${patient.id}/transactions`}>
            <MMDButton className="mt-2" text="Transactions" isSuccess />
          </Link>

          <MMDDropdown
            className="mb-2 mt-2"
            title="Bookings"
            items={[
              {
                text: 'Common',
                href: `/patients/${patient.id}/bookings`,
              },
              {
                text: 'Public',
                href: `/patients/${patient.id}/bookings-public`,
              },
            ]}
          />

          {!patient.relativeType && (
            <Link to={`/patients/${patient.email}/sessions`}>
              <MMDButton className="mt-2" text="Sessions" isSuccess />
            </Link>
          )}

          <MMDButton
            className="mt-2"
            text="View Report"
            isSuccess
            onClick={(e) => {
              requestPatientReport({ id: params.id });
              setShowReport(true);
            }}
          />
          <MMDButton
            className="mt-2"
            text="Download Report"
            isSuccess
            onClick={(e) => {
              setDownloading(true);
            }}
          />
          <MMDButton
            className="mt-2"
            text={patient.enable ? 'Disable patient' : 'Enable patient'}
            isSuccess={!patient.enable}
            onClick={(e) => {
              requestUpdatePatient({
                ...patientSave,
                enable: !patient.enable,
                coordinates: getCoordinatesString(patient.coordinates),
              });
            }}
          />
        </div>
        <div className="column flex">
          <div className="column is-two-fifth">
            <MMDList title="Conditions" items={patient.conditions} />
          </div>
          <div className="column is-two-fifth">
            <MMDList title="Allergies" items={patient.allergies} />
          </div>
          <div className="column is-two-fifth">
            <MMDList title="Procedures" items={patient.procedures} />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <MMDList title="Medications" items={patient.medications} />
        </div>
        <div className="column">
          <LifeStyle />
        </div>
        {(!patient.relativeType || patient.relativeType !== 'pets') && (
          <div className="column">
            <MMDList
              title="Family History"
              items={patient.familyHistory}
              isObject
            />
          </div>
        )}
        {!patient.relativeType && (
          <div className="column">
            {patient.children && patient.children.length ? (
              <>
                <h6 className="has-text-weight-bold">Children</h6>
                {patient.children.map((child) => (
                  <p key={child.id}>
                    <Link to={`/patients/${child.id}`}>
                      {child.firstName} {child.lastName}
                    </Link>
                  </p>
                ))}
              </>
            ) : (
              <p>No children</p>
            )}
          </div>
        )}
        {!patient.relativeType && (
          <div className="column">
            {patient.pets && patient.pets.length ? (
              <>
                <h6 className="has-text-weight-bold">Pets</h6>
                {patient.pets.map((pet) => (
                  <p key={pet.id}>
                    <Link to={`/patients/${pet.id}`}>{pet.name}</Link>
                  </p>
                ))}
              </>
            ) : (
              <p>No pets</p>
            )}
          </div>
        )}

        <div className="column">
          <h6 className="has-text-weight-bold">Timezone</h6>
          <span>{patient.timeZone ?? 'No info'}</span>
        </div>
      </div>
      {patient.pharmacies !== undefined && patient.pharmacies.length > 0 && (
        <div className="columns">
          <div className="column is-two-fifth">
            <h6 className="has-text-weight-bold">Pharmacies:</h6>
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Address</td>
                  <td>Phone number</td>
                  <td>Fax number</td>
                </tr>
              </thead>
              <tbody>
                {patient.pharmacies.map((item: Pharmacy) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.address}</td>
                    <td>{item.phone}</td>
                    <td>{item.fax}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {patient.ratings !== undefined && patient.ratings.length > 0 && (
        <div className="columns">
          <div className="column is-two-fifth">
            <h6 className="has-text-weight-bold">Feedback:</h6>
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <td>Provider name</td>
                  <td>Date</td>
                  <td>Rating</td>
                  <td>Comments</td>
                </tr>
              </thead>
              <tbody>
                {patient.ratings.map((item: Rating) => (
                  <tr key={item.startDate}>
                    <td>{item.raterName}</td>
                    <td>{item.startDate}</td>
                    <td>{item.rating || 'Not rating'}</td>
                    <td>{item.comment || 'Not comment'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <FilesFC
        files={files}
        onclick={getFirebaseImage}
        setLoadingFileFront={setLoadingFileFront}
      />

      {!(patient as any).relativeType && <PatientForm patient={patient} />}

      {(patient as any).relativeType === 'children' && (
        <ChildForm patient={patient as any} />
      )}

      {(patient as any).relativeType === 'pets' && (
        <PetForm patient={patient as any} />
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state: MyState, { match }) => ({
  loading: state.patients.loading,
  loadingReport: state.patients.loadingReport,
  loadingFiles: state.patients.loadingFiles,
  report: state.patients.report,
  files: state.patients.files,
  versionReport: state.patients.versionReport,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PatientsActions,
      ...ChildrenActions,
      ...PetsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
