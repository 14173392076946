import MMDButton from 'components/MMDButton';
import { Box, Columns, Heading, Media } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { Patient, Provider, User } from '../typings';
import { FC } from 'react';
interface Props {
  user: Provider | User | Patient;
  targetPath: string;
  onClickResetPassword: (data: { email: string }) => void;
  onClickEdit?: (event?: any) => void;
}

export const UserRow: FC<Props> = ({
  user,
  targetPath,
  onClickResetPassword,
  onClickEdit,
}) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>{`${user.firstName} ${user.lastName}`}</strong>{' '}
        <small>{user.email}</small>
        {(user as any).teacherId && (
          <div className="tag-information">IS STUDENT</div>
        )}
        {user.address && user.city && user.state ? (
          <Heading
            subtitle
            size={6}
            renderAs="h6"
          >{`${user.address}, ${user.city}, ${user.state}`}</Heading>
        ) : null}
        <Heading
          subtitle
          size={6}
          renderAs="h6"
        >{`Phone: ${user.phone}`}</Heading>
      </Media.Item>
    </Media>
    <Media.Item></Media.Item>
    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${user.id}`}>
          <MMDButton isSubmit isPrimary text="Edit" onClick={onClickEdit} />
        </Link>
      </Columns.Column>
      <Columns.Column>
        <MMDButton
          isSubmit
          isPrimary
          text="Reset password email"
          onClick={() => {
            onClickResetPassword({ email: user.email });
          }}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default UserRow;
