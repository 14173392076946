import { FC, FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDForm from '../components/forms/MMDForm';
import MMDTextInput from '../components/forms/MMDTextInput';
import MMDButton from '../components/MMDButton';
import MMDCheckbox from '../components/forms/MMDCheckbox';
import {
  shiftFaxNumber,
  selectPharmacyById,
  pharmacyFormValidator,
  Creators as PharmacyActions,
} from '../modules/pharmacies.module';
import { Pharmacy } from 'typings';
import { putDefaultValueSafe, getBooleanFieldValue } from 'utils/formUtils';
import { useParams } from 'react-router-dom';

type PropTypes = {
  pharmacy: Pharmacy;
};

const PromoCodeForm: FC<PropTypes> = () => {
  const params = useParams<{ id: string }>();

  const pharmacy = useSelector((state) => selectPharmacyById(state, params.id));

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="Name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter the pharmacy's name here"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['address'].name}
          label="Address"
          valid={form.fields['address'].valid}
          dirty={form.fields['address'].dirty}
          placeholder="Enter the pharmacy's address here"
          value={form.fields['address'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['coordinates'].name}
          label="Coordinates"
          valid={form.fields['coordinates'].valid}
          dirty={form.fields['coordinates'].dirty}
          placeholder="Enter the pharmacy's coordinates here with the format: (lat,lng)"
          value={form.fields['coordinates'].value}
          onChange={onChange}
        />
        <MMDTextInput
          name={form.fields['phone'].name}
          label="Phone Number"
          valid={form.fields['phone'].valid}
          dirty={form.fields['phone'].dirty}
          placeholder="(999) 999-9999"
          value={form.fields['phone'].value}
          onChange={onChange}
          required={true}
        />

        <div className="mb-2">Original phone – {pharmacy?.phone}</div>

        <MMDTextInput
          name={form.fields['fax'].name}
          label="Fax Number"
          valid={form.fields['fax'].valid}
          dirty={form.fields['fax'].dirty}
          placeholder="(999)-999-9999"
          value={form.fields['fax'].value}
          onChange={onChange}
          required={true}
        />

        <div className="mb-2">Original fax – {pharmacy?.fax}</div>

        <MMDCheckbox
          name={form.fields['approved'].name}
          label="Approved"
          dirty={form.fields['approved'].dirty}
          value={form.fields['approved'].value as 'true' | 'false'}
          onChange={onChange}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={pharmacy ? 'Update' : 'Create'}
        />
      </div>
    ),
    [pharmacy],
  );

  if (!pharmacy && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-pharmacy-form-${params.id}`
      : 'create-pharmacy-form',
    fields: {
      name: putDefaultValueSafe(pharmacy, 'name'),
      address: putDefaultValueSafe(pharmacy, 'address'),
      coordinates: `(${putDefaultValueSafe(
        pharmacy,
        'lat',
      )},${putDefaultValueSafe(pharmacy, 'lng')})`,
      phone: putDefaultValueSafe(pharmacy, 'phone'),
      fax: shiftFaxNumber(putDefaultValueSafe(pharmacy, 'fax')),
      approved: putDefaultValueSafe(pharmacy, 'approved'),
    },
    validator: pharmacyFormValidator,
    onSubmit: (values: Dictionary<string>) =>
      pharmacy && pharmacy.id
        ? PharmacyActions.requestUpdatePharmacy({
            id: pharmacy.id,
            name: values['name'],
            address: values['address'],
            lat: parseFloat(
              values['coordinates'].split(',')[0].replace('(', ''),
            ),
            lng: parseFloat(
              values['coordinates'].split(',')[1].replace(')', ''),
            ),
            phone: values['phone'],
            fax: values['fax'],
            approved: getBooleanFieldValue(values, 'approved'),
          })
        : PharmacyActions.requestCreatePharmacy({
            name: values['name'],
            address: values['address'],
            lat: parseFloat(
              values['coordinates'].split(',')[0].replace('(', ''),
            ),
            lng: parseFloat(
              values['coordinates'].split(',')[1].replace(')', ''),
            ),
            phone: values['phone'],
            fax: values['fax'],
            approved: getBooleanFieldValue(values, 'approved'),
          } as Pharmacy),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PromoCodeForm;
