import { FC } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import Routes from './routes';
import store, { history } from 'store';
import MMDHeader from './app/MMDHeader';
import { routes } from './routes/routes';
import MfaVerification from './login/MfaVerification';

const App: FC = () => (
  <Provider store={store}>
    <Router history={history}>
      <div>
        <MMDHeader routes={routes} />

        <Routes routes={routes} />

        <ToastContainer />

        <MfaVerification />
      </div>
    </Router>
  </Provider>
);

export default App;
