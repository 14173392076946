import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import MMDButton from 'components/MMDButton';
import { TermsAndConditions } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  termsAndConditions: TermsAndConditions;
  targetPath: string;
  onSendLive: (id: number) => void;
}

export const TermsAndConditionsRow: FC<Props> = ({
  termsAndConditions,
  onSendLive,
  targetPath,
}) => {
  const requestSendLive = useCallback(() => {
    onSendLive(termsAndConditions.id);
  }, [termsAndConditions, onSendLive]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>ID: {termsAndConditions.id}</strong>
          <p>Live: {termsAndConditions.sendLive ? 'Yes' : 'No'}</p>
          {termsAndConditions.userType && (
            <p> User type: {termsAndConditions.userType}</p>
          )}
        </Media.Item>
      </Media>
      <Columns>
        {!termsAndConditions.sendLive && (
          <Columns.Column size={1}>
            <MMDButton
              className="mt-2"
              isPrimary
              onClick={requestSendLive}
              text="Send live"
            />
          </Columns.Column>
        )}

        <Columns.Column size={1}>
          <Link
            state={{ html: termsAndConditions.content }}
            to={{
              pathname: '/previewHtml',
            }}
          >
            <MMDButton className="mt-2" isPrimary text="Preview" />
          </Link>
        </Columns.Column>

        {!termsAndConditions.sendLive ? (
          <Columns.Column size={1}>
            <Link to={`/terms-and-conditions/${termsAndConditions.id}`}>
              <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
            </Link>
          </Columns.Column>
        ) : null}
      </Columns>
    </Box>
  );
};

export default TermsAndConditionsRow;
