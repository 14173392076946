import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { PatientConsent } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

type Props = {
  patientConsent: PatientConsent;
  onSendLive: () => void;
};
const PatientConsentRow: FC<Props> = ({ patientConsent, onSendLive }) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>ID: {patientConsent.id}</strong>

        <p>Live: {patientConsent.sendLive ? 'Yes' : 'No'}</p>
        {patientConsent.userType && (
          <p> User type: {patientConsent.userType}</p>
        )}
      </Media.Item>
    </Media>

    <Columns>
      {!patientConsent.sendLive && (
        <Columns.Column size={2}>
          <MMDButton
            isPrimary
            className="mt-2"
            text="Send live"
            onClick={onSendLive}
          />
        </Columns.Column>
      )}

      <Columns.Column size={2}>
        <Link
          state={{ html: patientConsent.content }}
          to={{
            pathname: '/previewHtml',
          }}
        >
          <MMDButton className="mt-2" isPrimary text="Preview" />
        </Link>
      </Columns.Column>

      {!patientConsent.sendLive && (
        <Columns.Column size={1}>
          <Link to={`/patient-consent/${patientConsent.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
      )}
    </Columns>
  </Box>
);

export default PatientConsentRow;
