import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { User } from 'modules/users.module';
import MMDButton from 'components/MMDButton';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

type Props = {
  user: User;
  targetPath: string;
  onDelete: () => void;
};

const UsersRow: FC<Props> = ({ user, targetPath, onDelete }) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>
          {user.firstName} {user.lastName}
        </strong>
        <Heading subtitle size={6} renderAs="h6">
          Email: {user.email}
        </Heading>
        <Heading subtitle size={6} renderAs="h6">
          Job Title: {user.jobTitle ?? '-'}
        </Heading>
      </Media.Item>
    </Media>
    <Media.Item></Media.Item>
    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${user.id}`}>
          <MMDButton className="mt-2" isPrimary text="Edit" />
        </Link>
      </Columns.Column>
      <Columns.Column size={1}>
        <MMDSuperAdminButton
          isPrimary
          text="Delete"
          className="mt-2"
          onClick={onDelete}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default UsersRow;
