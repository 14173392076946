import { Controller, useForm } from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import { EMAIL_REGEX } from 'modules/auth.module';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as UsersInvitesActions } from 'modules/users-invites.module';

const FORM_FIELDS = {
  EMAIL: 'email',
};

const InviteUser = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [FORM_FIELDS.EMAIL]: '',
    },
  });

  const { requestUserInvite } = useBoundedActions(UsersInvitesActions);

  return (
    <div className="container p-2">
      <form onSubmit={handleSubmit(requestUserInvite)}>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
          render={({ field: { ref: _, ...fieldControl } }) => (
            <MMDTextInput
              label="User Email"
              placeholder="juan@doe.com"
              valid={!errors[FORM_FIELDS.EMAIL]}
              {...fieldControl}
            />
          )}
          name="email"
        />

        <MMDButton isSubmit isPrimary text="Send invite" />
      </form>
    </div>
  );
};

export default InviteUser;
