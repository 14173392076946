import { connect } from 'react-redux';
import { Form } from '@mrnkr/redux-saga-toolbox';
import { FC, FormEvent, useCallback } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { push as reduxPush } from 'redux-first-history';

import {
  AuthState,
  authFormValidator,
  Creators as AuthActions,
} from 'modules/auth.module';
import { MyState } from 'store';
import MMDModal from 'components/MMDModal';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';

type PropTypes = {
  auth: AuthState;
} & typeof AuthActions;

const formConfig = {
  formName: 'auth-form',
  fields: ['email', 'password'],
  validator: authFormValidator,
  onSubmit: (values) =>
    AuthActions.requestAuth({
      email: values['email'].trim().toLowerCase(),
      password: values['password'].trim(),
    }),
};

const LoginForm: FC<PropTypes> = ({ auth, errorAuth }) => {
  const { push } = useBoundedActions({
    push: reduxPush,
  });
  const onResetPassword = () => push('/reset-password');

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          type="email"
          name={form.fields['email'].name}
          value={form.fields['email'].value}
          label="Email"
          valid={form.fields['email'].valid}
          dirty={form.fields['email'].dirty}
          placeholder="juan@doe.com"
          onChange={onChange}
        />
        <MMDTextInput
          type="password"
          name={form.fields['password'].name}
          label="Password"
          valid={form.fields['password'].valid}
          dirty={form.fields['password'].dirty}
          placeholder="********"
          value={form.fields['password'].value}
          onChange={onChange}
        />

        <div className="flex">
          <MMDButton isSubmit isPrimary text="Login" />

          <MMDButton
            className="ml-6"
            isPrimary={false}
            text="Reset Password"
            onClick={onResetPassword}
          />
        </div>
      </div>
    ),
    [],
  );

  return (
    <>
      <MMDForm {...({ renderFormControls, formConfig } as any)} />

      <MMDModal visible={!!auth.error} onRequestClose={() => errorAuth(null)}>
        <p>
          Error!&nbsp;
          <span className="has-text-weight-bold">{auth.error?.message}</span>
        </p>
      </MMDModal>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...AuthActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
