import { Moment } from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { FC, Dispatch, useState, useEffect, SetStateAction } from 'react';

import { TIME_FORMAT } from './config';

type Props = {
  startTime: Moment;
  bookingDuration: number;
  endTime: Moment;
  setEndTime: Dispatch<SetStateAction<Moment>>;
};

const EndTimePicker: FC<Props> = ({
  startTime,
  endTime,
  setEndTime,
  bookingDuration,
}) => {
  const [disabledMinutes, setDisabledMinutes] = useState([]);

  const generateDisabledMinutes = (availableMinutes: number[]) =>
    setDisabledMinutes(
      Array.from({ length: 61 }, (_, index) => index).filter(
        (minute) => !availableMinutes.includes(minute),
      ),
    );

  const onChangeEnd = (value?: Moment) => {
    const newEndTime = value ?? endTime;

    setEndTime(newEndTime);

    const timestampExactEndHour = parseInt(
      newEndTime.clone().endOf('hour').format('X'),
    );

    const totalAvailableMinutes = {};
    const timeToAdd = startTime.clone();
    let hourCounter = 0;

    while (hourCounter < timestampExactEndHour && bookingDuration > 0) {
      timeToAdd.add(bookingDuration, 'minutes');

      if (totalAvailableMinutes[timeToAdd.hours()]) {
        totalAvailableMinutes[timeToAdd.hours()].push(timeToAdd.minutes());
      } else {
        totalAvailableMinutes[timeToAdd.hours()] = [timeToAdd.minutes()];
      }
      hourCounter = parseInt(timeToAdd.format('X'));
    }

    if (
      Object.keys(totalAvailableMinutes).length > 0 &&
      totalAvailableMinutes[newEndTime.hours()]
    ) {
      generateDisabledMinutes(totalAvailableMinutes[newEndTime.hours()]);
    }
  };

  useEffect(() => {
    onChangeEnd(startTime.clone().add(bookingDuration, 'minutes'));
  }, [startTime]);

  const getDisabledHours = () =>
    Array.from({ length: 24 }, (_, index) => index).filter(
      (hour) => hour < startTime.hours(),
    );

  return (
    <div>
      <div className="align-self-center">End:</div>

      <TimePicker
        use12Hours
        inputReadOnly
        value={endTime}
        showSecond={false}
        allowEmpty={false}
        hideDisabledOptions
        format={TIME_FORMAT}
        onChange={onChangeEnd}
        disabledHours={getDisabledHours}
        disabledMinutes={() => disabledMinutes}
      />
    </div>
  );
};

export default EndTimePicker;
