import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { API_URL } from '../config';
import { SagaIterator, Speciality } from '../typings';
import { putAuthInfoInArgs } from './auth.module';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import { isNumber } from 'utils/isNumber';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface ActionTypes {
  REQUEST_SPECIALITIES_FOR_CREDENTIAL: string;
  REQUEST_CREATE_SPECIALITY: string;
  REQUEST_UPDATE_SPECIALITY: string;
  REQUEST_REMOVE_SPECIALITY: string;
  LOADING_SPECIALITIES: string;
  COMMIT_SPECIALITY: string;
  COMMIT_SPECIALITIES: string;
  ERROR_SPECIALITIES: string;
  REMOVE_SPECIALITY: string;
}

interface ActionCreators {
  requestSpecialitiesForCredential: (payload: {
    credential: string;
  }) => MyAction<{ credential: string }>;
  requestCreateSpeciality: (payload: Speciality) => MyAction<Speciality>;
  requestUpdateSpeciality: (
    payload: Partial<Speciality>,
  ) => MyAction<Partial<Speciality>>;
  requestRemoveSpeciality: (
    payload: Partial<{ id: number }>,
  ) => MyAction<Partial<{ id: number }>>;
  loadingSpecialities: () => Action;
  commitSpecialities: (
    payload: Paginated<Speciality>,
  ) => MyAction<Paginated<Speciality>>;
  commitSpeciality: (payload: Speciality) => MyAction<Speciality>;
  removeSpeciality: (payload: { id: number }) => MyAction<{ id: number }>;
  errorSpecialities: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface SpecialitiesState<TError extends Error = Error>
  extends EntityState<Speciality> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestSpecialitiesForCredential: ['payload'],
  requestCreateSpeciality: ['payload'],
  requestUpdateSpeciality: ['payload'],
  requestRemoveSpeciality: ['payload'],
  requestSpecialities: [],
  loadingSpecialities: [],
  commitSpecialities: ['payload'],
  commitSpeciality: ['payload'],
  errorSpecialities: ['error'],
  removeSpeciality: ['payload'],
});

const entityAdapter = createEntityAdapter<Speciality>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const specialitiesSelector = entityAdapter.getSelectors();

function setLoading(state: SpecialitiesState): SpecialitiesState {
  return {
    ...state,
    loading: true,
  };
}

function commitSpecialities(
  state: SpecialitiesState,
  action: MyAction<Paginated<Speciality>>,
): SpecialitiesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitSpeciality(
  state: SpecialitiesState,
  action: MyAction<Speciality>,
): SpecialitiesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: SpecialitiesState,
  { error }: ErrorAction<TError>,
): SpecialitiesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const specialitiesReducer = createReducer(initialState, {
  [Types.LOADING_SPECIALITIES]: setLoading,
  [Types.COMMIT_SPECIALITIES]: commitSpecialities,
  [Types.ERROR_SPECIALITIES]: setError,
  [Types.COMMIT_SPECIALITY]: commitSpeciality,
  [Types.REMOVE_SPECIALITY]: removeSpeciality,
});

async function requestSpeciality({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<Speciality> {
  const result = await fetch(`${API_URL}/specialities?id=${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

function removeSpeciality(
  state: SpecialitiesState,
  action: MyAction<{ id: number }>,
): SpecialitiesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

async function downloadSpecialitiesForCredential({
  headers,
  ...payload
}: ArgsWithHeaders<{ credential: string }>): Promise<Paginated<Speciality>> {
  const result = await fetch(
    `${API_URL}/specialities/for-provider?search=${payload.credential}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createSpeciality({
  headers,
  ...payload
}: ArgsWithHeaders<Speciality>): Promise<CreationResult<Speciality>> {
  const result = await fetch(`${API_URL}/specialities`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  const res = await result.json();

  const categoriesIds = payload.categories.map(({ id }) => ({ id }));

  const resultCat = await fetch(
    `${API_URL}/specialities/categories/${res.createdId}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify({ categories: categoriesIds }),
    },
  );

  if (!result.ok || !resultCat.ok) {
    if (result.status === UNAUTHORIZED || resultCat.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return res;
}

async function updateSpeciality({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Speciality>>): Promise<void> {
  const result = await fetch(`${API_URL}/specialities/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  const categoriesIds = payload.categories.map(({ id }) => ({ id }));

  const resultCat = await fetch(
    `${API_URL}/specialities/categories/${payload.id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify({ categories: categoriesIds }),
    },
  );

  if (!result.ok || !resultCat.ok) {
    if (result.status === UNAUTHORIZED || resultCat.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteSpeciality({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<void> {
  const result = await fetch(`${API_URL}/specialities/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestSpecialityWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Speciality,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/specialities/:id'),
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.commitSpeciality,
  successAction: noOpAction,
  errorAction: Creators.errorSpecialities,
  action: requestSpeciality,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: number },
    ArgsWithHeaders<{ id: number }>
  >(extractRouteParams('/specialities/:id'), putAuthInfoInArgs),
  *afterAction(res?: Paginated<Speciality>): SagaIterator {
    return res ? res.data.pop() : undefined;
  },
});

const goBack = goBackFactory('/specialities');

const requestCreateSpecialityWatcher = createSingleEventSaga<
  Speciality,
  Speciality,
  MyAction<Speciality>
>({
  takeEvery: Types.REQUEST_CREATE_SPECIALITY,
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.commitSpeciality,
  successAction: goBack.action,
  errorAction: Creators.errorSpecialities,
  action: createSpeciality,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Speciality>,
    { headers, ...args }: ArgsWithHeaders<Speciality>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateSpecialityWatcher = createSingleEventSaga<
  Partial<Speciality>,
  Partial<Speciality>,
  MyAction<Partial<Speciality>>
>({
  takeEvery: Types.REQUEST_UPDATE_SPECIALITY,
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.commitSpeciality,
  successAction: goBack.action,
  errorAction: Creators.errorSpecialities,
  action: updateSpeciality,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Speciality>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteSpecialityWatcher = createSingleEventSaga<
  { id: number },
  void,
  MyAction<{ id: number }>
>({
  takeEvery: Types.REQUEST_REMOVE_SPECIALITY,
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.removeSpeciality,
  successAction: noOpAction,
  errorAction: Creators.errorSpecialities,
  action: deleteSpeciality,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<{ id: number }>,
  ): SagaIterator {
    return args;
  },
});

const requestSpecialitiesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Speciality>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/specialities'),
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.commitSpecialities,
  successAction: noOpAction,
  errorAction: Creators.errorSpecialities,
  action: downloadUsingLocationQuery<Speciality>('specialities'),
  beforeAction: putAuthInfoInArgs,
});

const requestAllSpecialitiesForCredentialWatcher = createSingleEventSaga<
  { credential: string },
  Paginated<Speciality>,
  MyAction<{ credential: string }>
>({
  takeEvery: Types.REQUEST_SPECIALITIES_FOR_CREDENTIAL,
  loadingAction: Creators.loadingSpecialities,
  commitAction: Creators.commitSpecialities,
  successAction: noOpAction,
  errorAction: Creators.errorSpecialities,
  action: downloadSpecialitiesForCredential,
  beforeAction: putAuthInfoInArgs,
});

export const specialitiesSagas = [
  requestSpecialitiesWatcher,
  requestSpecialityWatcher,
  requestCreateSpecialityWatcher,
  requestUpdateSpecialityWatcher,
  requestDeleteSpecialityWatcher,
  requestAllSpecialitiesForCredentialWatcher,
  goBack.watcher,
];

export function specialityFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
    doctorType: true,
    credential: true,
    approved: true,
    firstTimePrice: true,
    firstTimeLength: true,
    monthlyPrice: true,
    oneTimePrice: true,
    timeSlotLength: true,
  };

  if (values['name'] === '' || !values['name']) {
    result.name = false;
  }

  if (!isNumber(values['monthlyPrice'])) {
    result.monthlyPrice = false;
  }

  if (values['isPsychiatry'] === 'true') {
    if (!isNumber(values['firstTimePrice'])) {
      result.firstTimePrice = false;
    }

    if (!isNumber(values['firstTimeLength'])) {
      result.firstTimeLength = false;
    }

    if (values['doctorType'] === '' || !values['doctorType']) {
      result.doctorType = false;
    }
  }

  if (!isNumber(values['oneTimePrice'])) {
    result.oneTimePrice = false;
  }

  if (!isNumber(values['timeSlotLength'])) {
    result.timeSlotLength = false;
  }

  return Promise.resolve(result);
}

export const selectSpecialitiesState = (state: MyState) => state.specialities;

export const selectSpecialityById = createSelector(
  [selectSpecialitiesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectSpecialities = createSelector(
  selectSpecialitiesState,
  (state) => specialitiesSelector.selectAll(state),
);
