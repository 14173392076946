import { FC, FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, Dictionary } from '@mrnkr/redux-saga-toolbox';
import moment from 'moment';

import MMDForm from '../components/forms/MMDForm';
import MMDTextArea from '../components/forms/MMDTextArea';
import MMDButton from '../components/MMDButton';
import {
  Creators as PrivacyPoliciesActions,
  privacyPolicySelectors,
  privacyPolicyFormValidator,
  selectPrivacyPolicyState,
} from '../modules/privacy-policy.module';
import { PrivacyPolicy } from 'typings';
import { useParams } from 'react-router-dom';

type Props = {
  privacyPolicies: PrivacyPolicy;
};

const PrivacyPolicyForm: FC<Props> = () => {
  const params = useParams<{ id?: string; userType?: string }>();

  const privacyPolicyState = useSelector(selectPrivacyPolicyState);

  const privacyPolicies = params.id
    ? privacyPolicyState.entities[params.id]
    : privacyPolicySelectors
        .selectAll(privacyPolicyState)
        .filter((t) => t.userType === params.userType && t.sendLive)
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt, 'milliseconds'))
        .pop();

  const renderFormControls = useCallback(
    (
      form: Form,
      onChange: (e: FormEvent<HTMLTextAreaElement | HTMLDivElement>) => void,
    ) => {
      const editing = !!params.id;

      return (
        <div className="container p-2">
          <MMDTextArea
            name={form.fields['content'].name}
            value={form.fields['content'].value}
            label="Privacy policy"
            valid={form.fields['content'].valid}
            placeholder="Enter the privacy policy's here"
            onChange={onChange}
            rows={40}
            cols={40}
            required={true}
          />
          <MMDButton
            className="mt-2"
            isSubmit
            isPrimary
            text={editing ? 'Save changes' : 'Create'}
          />
        </div>
      );
    },
    [],
  );

  if (!privacyPolicies) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-privacy-policies-form-${params.id}`
      : 'create-privacy-policies-form',
    fields: {
      content: privacyPolicies ? privacyPolicies.content : '',
      userType: privacyPolicies ? privacyPolicies.userType : '',
    },
    validator: privacyPolicyFormValidator,
    onSubmit: (values: Dictionary<string>) =>
      params.id
        ? PrivacyPoliciesActions.requestUpdatePrivacyPolicy({
            id: privacyPolicies.id,
            content: values['content'],
            userType: values['userType'],
          } as PrivacyPolicy)
        : PrivacyPoliciesActions.requestCreatePrivacyPolicy({
            content: values['content'],
            userType: values['userType'],
          } as PrivacyPolicy),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PrivacyPolicyForm;
