import { Action } from 'redux';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import { Immunization, Nullable, Option, SagaIterator } from 'typings';
import {
  MyAction,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_ALL_IMMUNIZATIONS: string;
  REQUEST_CREATE_IMMUNIZATION: string;
  REQUEST_REMOVE_IMMUNIZATION: string;
  REQUEST_UPDATE_IMMUNIZATION: string;
  LOADING_IMMUNIZATIONS: string;
  COMMIT_IMMUNIZATIONS: string;
  COMMIT_IMMUNIZATION: string;
  REMOVE_IMMUNIZATION: string;
  ERROR_IMMUNIZATIONS: string;
}

export type CreateImmunizationPayload = Omit<Immunization, 'id'> & {
  questionnaireId: Nullable<Option>;
};

export type UpdateImmunizationPayload = Partial<Immunization> & {
  questionnaireId: Nullable<Option>;
};

type RemoveImmunizationPayload = Pick<Immunization, 'id'>;

interface ActionCreators {
  requestAllImmunizations: () => MyAction<Paginated<Immunization>>;
  requestCreateImmunization: (
    payload: CreateImmunizationPayload,
  ) => MyAction<Immunization>;
  requestUpdateImmunization: (
    payload: UpdateImmunizationPayload,
  ) => MyAction<UpdateImmunizationPayload>;
  requestRemoveImmunization: (
    payload: RemoveImmunizationPayload,
  ) => MyAction<RemoveImmunizationPayload>;
  loadingImmunizations: () => Action;
  commitImmunizations: (
    payload: Paginated<Immunization>,
  ) => MyAction<Paginated<Immunization>>;
  commitImmunization: (payload: Immunization) => MyAction<Immunization>;
  removeImmunization: (
    payload: RemoveImmunizationPayload,
  ) => MyAction<RemoveImmunizationPayload>;
  errorImmunizations: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface ImmunizationsState<TError extends Error = Error>
  extends EntityState<Immunization> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAllImmunizations: [],
  requestCreateImmunization: ['payload'],
  requestUpdateImmunization: ['payload'],
  requestRemoveImmunization: ['payload'],
  loadingImmunizations: [],
  commitImmunizations: ['payload'],
  commitImmunization: ['payload'],
  removeImmunization: ['payload'],
  errorImmunizations: ['error'],
});

const entityAdapter = createEntityAdapter<Immunization>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

const immunizationsSelectors = entityAdapter.getSelectors();

function setLoading(state: ImmunizationsState): ImmunizationsState {
  return {
    ...state,
    loading: true,
  };
}

function commitImmunization(
  state: ImmunizationsState,
  action: MyAction<Immunization>,
): ImmunizationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitImmunizations(
  state: ImmunizationsState,
  action: MyAction<Paginated<Immunization>>,
): ImmunizationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeImmunization(
  state: ImmunizationsState,
  action: MyAction<RemoveImmunizationPayload>,
): ImmunizationsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: ImmunizationsState,
  { error }: ErrorAction<TError>,
): ImmunizationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const immunizationsReducer = createReducer(initialState, {
  [Types.LOADING_IMMUNIZATIONS]: setLoading,
  [Types.COMMIT_IMMUNIZATIONS]: commitImmunizations,
  [Types.COMMIT_IMMUNIZATION]: commitImmunization,
  [Types.REMOVE_IMMUNIZATION]: removeImmunization,
  [Types.ERROR_IMMUNIZATIONS]: setError,
});

const goBack = goBackFactory('/immunizations');

async function downloadAllImmunizations({ headers }: ArgsWithHeaders) {
  const result = await fetch(`${API_URL}/immunizations`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadImmunization({
  headers,
  id,
}: ArgsWithHeaders<Pick<Immunization, 'id'>>) {
  const result = await fetch(`${API_URL}/immunizations/${id}`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createImmunization({
  headers,
  questionnaireId,
  ...payload
}: ArgsWithHeaders<CreateImmunizationPayload>) {
  const result = await fetch(`${API_URL}/immunizations`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  const { createdId } = await result.json();

  if (questionnaireId) {
    await attachQuestionnaireToImmunization({
      immunizationId: createdId,
      questionnaireId,
      headers,
    });
  }

  return { createdId };
}

async function attachQuestionnaireToImmunization({
  headers,
  immunizationId,
  questionnaireId,
}: ArgsWithHeaders<{
  immunizationId: number;
  questionnaireId: number;
}>) {
  return fetch(
    `${API_URL}/immunizations/attach-questionnaire/${immunizationId}`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({ questionnaireId }),
    },
  );
}

async function removeQuestionnaireToImmunization({
  headers,
  immunizationId,
}: ArgsWithHeaders<{
  immunizationId: number;
}>) {
  return fetch(`${API_URL}/immunizations/${immunizationId}/questionnaire`, {
    headers,
    method: 'DELETE',
  });
}

async function updateImmunization({
  headers,
  id,
  questionnaireId,
  ...payload
}: ArgsWithHeaders<UpdateImmunizationPayload>) {
  const result = await fetch(`${API_URL}/immunizations/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (questionnaireId) {
    await attachQuestionnaireToImmunization({
      immunizationId: id,
      questionnaireId,
      headers,
    });
  } else {
    await removeQuestionnaireToImmunization({
      immunizationId: id,
      headers,
    });
  }

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function removeImmunizationRequest({
  headers,
  id,
}: ArgsWithHeaders<Pick<Immunization, 'id'>>) {
  const result = await fetch(`${API_URL}/immunizations/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestAllImmunizationsWatcher = createSingleEventSaga<
  void,
  Paginated<Immunization>,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_ALL_IMMUNIZATIONS,
  loadingAction: noOpAction,
  commitAction: Creators.commitImmunizations,
  successAction: noOpAction,
  errorAction: Creators.errorImmunizations,
  action: downloadAllImmunizations,
  beforeAction: putAuthInfoInArgs,
});

const requestImmunizationsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Immunization>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/immunizations'),
  loadingAction: Creators.loadingImmunizations,
  commitAction: Creators.commitImmunizations,
  successAction: noOpAction,
  errorAction: Creators.errorImmunizations,
  action: downloadUsingLocationQuery<Immunization>('immunizations'),
  beforeAction: putAuthInfoInArgs,
});

const requestImmunizationWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Immunization,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/immunizations/:id'),
  loadingAction: Creators.loadingImmunizations,
  commitAction: Creators.commitImmunization,
  successAction: noOpAction,
  errorAction: Creators.errorImmunizations,
  action: downloadImmunization,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    Pick<Immunization, 'id'>,
    ArgsWithHeaders<Pick<Immunization, 'id'>>
  >(extractRouteParams('/immunizations/:id'), putAuthInfoInArgs),
});

const requestCreateImmunizationWatcher = createSingleEventSaga<
  Immunization,
  Immunization,
  MyAction<Immunization>
>({
  takeEvery: Types.REQUEST_CREATE_IMMUNIZATION,
  loadingAction: Creators.loadingImmunizations,
  commitAction: Creators.commitImmunization,
  successAction: goBack.action,
  errorAction: Creators.errorImmunizations,
  action: createImmunization,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Immunization>,
    { headers, ...args }: ArgsWithHeaders<Immunization>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateImmunizationWatcher = createSingleEventSaga<
  Partial<Immunization>,
  Partial<Immunization>,
  MyAction<Partial<Immunization>>
>({
  takeEvery: Types.REQUEST_UPDATE_IMMUNIZATION,
  loadingAction: Creators.loadingImmunizations,
  commitAction: Creators.commitImmunization,
  successAction: goBack.action,
  errorAction: Creators.errorImmunizations,
  action: updateImmunization,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Immunization>,
  ): SagaIterator {
    return args;
  },
});

const requestRemoveImmunizationWatcher = createSingleEventSaga<
  Pick<Immunization, 'id'>,
  void,
  MyAction<Pick<Immunization, 'id'>>
>({
  takeEvery: Types.REQUEST_REMOVE_IMMUNIZATION,
  loadingAction: Creators.loadingImmunizations,
  commitAction: Creators.removeImmunization,
  successAction: noOpAction,
  errorAction: Creators.errorImmunizations,
  action: removeImmunizationRequest,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Pick<Immunization, 'id'>>,
  ): SagaIterator {
    return args;
  },
});

export const selectImmunizationsState = (state: MyState) => state.immunizations;

export const selectImmunizations = createSelector(
  selectImmunizationsState,
  (immunizationsState) => immunizationsSelectors.selectAll(immunizationsState),
);

export const selectImmunizationById = createSelector(
  [selectImmunizationsState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const immunizationSagas = [
  requestCreateImmunizationWatcher,
  requestRemoveImmunizationWatcher,
  requestUpdateImmunizationWatcher,
  requestAllImmunizationsWatcher,
  requestImmunizationWatcher,
  requestImmunizationsWatcher,
  goBack.watcher,
];
