import React, { FC } from 'react';

import MMDModal from 'components/MMDModal';
import { API_ERRORS_CODE } from 'utils/constants';
import { Creators as PharmacyActions } from 'modules/pharmacies.module';

type Props = {
  error?: string | API_ERRORS_CODE;
} & Pick<typeof PharmacyActions, 'errorPharmacies'>;

const PharmacyDeleteErrorModal: FC<Props> = ({ error, errorPharmacies }) => {
  const isModalVisible =
    error === API_ERRORS_CODE.FORBID_DELETE_PHARMACY.toString();

  const onClosePharmacyDeleteModal = () => {
    errorPharmacies(undefined);
  };

  return (
    <MMDModal
      visible={isModalVisible}
      onRequestClose={onClosePharmacyDeleteModal}
    >
      <p>Error!</p>
      <span>This pharmacy is linked to the video call, try again later</span>
    </MMDModal>
  );
};

export default PharmacyDeleteErrorModal;
