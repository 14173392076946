import { FC, Fragment, useCallback, MouseEvent } from 'react';

import { MenuItem } from 'utils/typings';
import { SUPER_ADMIN_ROUTES } from 'utils/constants';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';

type PropTypes = MenuItem;

const MMDMenuItem: FC<PropTypes> = (props) => {
  const navigate = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      props.onPress();
    },
    [props],
  );

  const isSuperAdmin = useIsSuperAdminCheck();

  return (
    <a
      className={`navbar-item ${
        props.items ? 'has-dropdown is-hoverable' : ''
      }`}
      {...(props.items ? {} : { onClick: navigate })}
    >
      {props.items ? (
        <Fragment>
          <a className="navbar-link" onClick={navigate}>
            {props.text}
          </a>
          <div className="navbar-dropdown">
            {props.items
              .filter((item) =>
                isSuperAdmin ? true : !SUPER_ADMIN_ROUTES.includes(item?.path),
              )
              .map((item) => (
                <MMDMenuItem key={item.text} {...item} />
              ))}
          </div>
        </Fragment>
      ) : (
        props.text
      )}
    </a>
  );
};

export default MMDMenuItem;
