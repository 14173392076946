import {
  ActionCreator,
  ActionCreatorsMapObject,
  bindActionCreators,
} from 'redux';
import { useDispatch } from 'react-redux';

export const useBoundedActions = <T extends string>(
  actions: Record<T, ActionCreator<any>>,
) => {
  const dispatch = useDispatch();

  return bindActionCreators(actions, dispatch);
};
