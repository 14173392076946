import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DurationForm from './DurationForm';
import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectPublicBookingDurationById,
  selectPublicBookingDurationsState,
  Creators as PublicBookingDurationsActions,
} from 'modules/public-booking-durations.module';

const EditDuration = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, error } = useSelector(selectPublicBookingDurationsState);

  const { requestUpdatePublicBookingDuration, requestPublicBookingDuration } =
    useBoundedActions(PublicBookingDurationsActions);

  const publicBookingDuration = useSelector((state) =>
    selectPublicBookingDurationById(state, id),
  );

  useEffect(() => {
    if (!publicBookingDuration) {
      requestPublicBookingDuration({ id });
    }
  }, [publicBookingDuration]);

  const onUpdatePublicBookingDuration = (values: { duration: number }) =>
    requestUpdatePublicBookingDuration({ id, ...values });

  return (
    <div className="container p-2">
      <MMDLoader loading={loading} />

      {publicBookingDuration && (
        <DurationForm
          durationError={error}
          duration={publicBookingDuration}
          onSubmit={onUpdatePublicBookingDuration}
        />
      )}
    </div>
  );
};

export default EditDuration;
