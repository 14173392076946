import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDLoader from 'components/MMDLoader';
import {
  Creators as NotificationsActions,
  notificationsSelectors,
} from '../modules/notifications.module';
import { MyState } from '../store';
import { Notification } from '../typings';
import NotificationRow from '../components/notifications/NotificationRow';
import MMDPager from 'components/MMDPager';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { push } from 'redux-first-history';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { FC, useCallback } from 'react';

type Props = {
  notifications?: Notification[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof NotificationsActions;

const Notifications: FC<Props> = ({
  notifications,
  count,
  routeParams,
  push,
  loading,
}) => {
  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(
        `push-notifications${prepareQueryWithOffset(nextOffset, routeParams)}`,
      );
    },
    [push, routeParams],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        {notifications.map((item) => (
          <NotificationRow
            key={item.id}
            notification={item}
            targetPath={'push-notifications'}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  notifications: notificationsSelectors.selectAll(state.notifications),
  loading: state.notifications.loading,
  count: state.notifications.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...NotificationsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
