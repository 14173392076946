import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns } from 'react-bulma-components';

import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { PublicBookingDuration } from 'modules/public-booking-durations.module';

type Props = {
  targetPath: string;
  onDelete: () => void;
  duration: PublicBookingDuration;
};

const DurationRow: FC<Props> = ({ duration, targetPath, onDelete }) => (
  <Box>
    <Columns.Column>{duration.duration} min</Columns.Column>

    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${duration.id}`}>
          <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
        </Link>
      </Columns.Column>

      <Columns.Column size={1}>
        <MMDSuperAdminButton
          isPrimary
          text="Delete"
          className="mt-2"
          onClick={onDelete}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default DurationRow;
