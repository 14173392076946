import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { push as reduxPush } from 'redux-first-history/build/es6/actions';

import MMDMenuItem from './header/MMDMenuItem';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Route, routesWithoutAuth } from 'pages/routes/routes';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';
import { Creators as AuthActions, selectUserInfo } from 'modules/auth.module';

type PropTypes = {
  routes: Route[];
};

const MMDHeader: FC<PropTypes> = ({ routes }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const currentUserInfo = useSelector(selectUserInfo);

  const isSuperAdmin = useIsSuperAdminCheck();

  const { push, requestAuthOut } = useBoundedActions({
    push: reduxPush,
    ...AuthActions,
  });

  const currentLocation = useLocation().pathname;

  const currentUserCredentials = currentUserInfo
    ? `${currentUserInfo.firstName} ${currentUserInfo.lastName}`
    : '-';

  useEffect(() => {
    const ROUTES_WITHOUT_MENU = ['/', ...routesWithoutAuth];

    const shouldShowMenu = !ROUTES_WITHOUT_MENU.includes(currentLocation);

    if (shouldShowMenu) {
      const menuItemsArray = routes
        .filter((r) => r.showMenuItem)
        .map((r) =>
          r.menuItem((path: string) => {
            push(path);
            setExpanded(false);
          }),
        );

      const userDropdownItems = [
        {
          text: 'Logout',
          onPress: () => {
            requestAuthOut();
            push('/');
            setExpanded(false);
          },
        },
      ];

      if (isSuperAdmin) {
        userDropdownItems.unshift(
          {
            text: 'Users',
            onPress: () => {
              push('/users');
              setExpanded(false);
            },
          },
          {
            text: 'Invites',
            onPress: () => {
              push('/invites');
              setExpanded(false);
            },
          },
          {
            text: 'Admin Logs',
            onPress: () => {
              push('/admin-logs');
              setExpanded(false);
            },
          },
        );
      }

      menuItemsArray.push({
        text: currentUserCredentials,
        onPress: () => {},
        items: userDropdownItems,
      });
      setMenuItems(menuItemsArray);
    } else {
      setMenuItems([]);
    }
  }, [routes, currentLocation, currentUserInfo, isSuperAdmin]);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item">
          <img
            className="ml-1"
            src="/assets/app_logo.png"
            alt="MomentMD"
            height="28"
          />
        </a>

        <a
          role="button"
          className={`navbar-burger burger ${isExpanded ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setExpanded(!isExpanded)}
        >
          {menuItems.map((_, index) => (
            <span key={index} aria-hidden="true"></span>
          ))}
        </a>
      </div>

      <div className={`navbar-menu ${isExpanded ? 'is-active' : ''}`}>
        <div className="navbar-start">
          {menuItems.map((item) => (
            <MMDMenuItem key={item.text} {...item} />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default MMDHeader;
