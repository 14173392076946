import moment from 'moment';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { API_URL } from 'config';
import { MyState } from 'store';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { PatientConsent, PrivacyPolicy, SagaIterator } from 'typings';
import {
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_CREATE_PATIENT_CONSENT: string;
  REQUEST_UPDATE_PATIENT_CONSENT: string;
  LOADING_PATIENT_CONSENT: string;
  COMMIT_PATIENT_CONSENTS: string;
  COMMIT_PATIENT_CONSENT: string;
  ERROR_PATIENT_CONSENT: string;
}

interface ActionCreators {
  requestCreatePatientConsent: (
    payload: PatientConsent,
  ) => MyAction<PatientConsent>;
  requestUpdatePatientConsent: (
    payload: Partial<PatientConsent>,
  ) => MyAction<Partial<PatientConsent>>;
  loadingPatientConsent: () => Action;
  commitPatientConsents: (
    payload: PatientConsent[],
  ) => MyAction<PatientConsent[]>;
  commitPatientConsent: (payload: PatientConsent) => MyAction<PatientConsent>;
  errorPatientConsent: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface PatientConsentState<TError extends Error = Error>
  extends EntityState<PatientConsent> {
  loading: boolean;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePatientConsent: ['payload'],
  requestUpdatePatientConsent: ['payload'],
  loadingPatientConsent: [],
  commitPatientConsents: ['payload'],
  commitPatientConsent: ['payload'],
  errorPatientConsent: ['error'],
});

const entityAdapter = createEntityAdapter<PatientConsent>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
});

const patientConsentSelectors = entityAdapter.getSelectors();

function setLoading(state: PatientConsentState): PatientConsentState {
  return {
    ...state,
    loading: true,
  };
}

function commitPatientConsent(
  state: PatientConsentState,
  action: MyAction<PatientConsent>,
): PatientConsentState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    ...state,
    loading: false,
  };
}

function commitPatientConsents(
  state: PatientConsentState,
  action: MyAction<PatientConsent[]>,
): PatientConsentState {
  const afterAdded = entityAdapter.addAll(action.payload, state);
  return {
    ...afterAdded,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PatientConsentState,
  { error }: ErrorAction<TError>,
): PatientConsentState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const patientConsentReducer = createReducer(initialState, {
  [Types.LOADING_PATIENT_CONSENT]: setLoading,
  [Types.COMMIT_PATIENT_CONSENT]: commitPatientConsent,
  [Types.COMMIT_PATIENT_CONSENTS]: commitPatientConsents,
  [Types.ERROR_PATIENT_CONSENT]: setError,
});

async function downloadPatientConsents({
  headers,
  ...payload
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<PatientConsent[]> {
  const location = payload.location.search
    ? `${payload.location.search}&__sort=-updatedAt`
    : `${payload.location.search}?__sort=-updatedAt`;

  const result = await fetch(`${API_URL}/patient-consents${location}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadLivePatientConsent({
  headers,
}: ArgsWithHeaders): Promise<PatientConsent[]> {
  const result = await fetch(
    `${API_URL}/patient-consents?sendLive=true&__sort=-updatedAt&__limit=1`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createPatientConsent({
  headers,
  ...payload
}: ArgsWithHeaders<PatientConsent>): Promise<CreationResult<PatientConsent>> {
  const result = await fetch(`${API_URL}/patient-consents`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updatePatientConsent({
  headers,
  id,
  ...diff
}: ArgsWithHeaders<Partial<PrivacyPolicy>>): Promise<void> {
  const result = await fetch(`${API_URL}/patient-consents/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(diff),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const goBack = goBackFactory('/patient-consent');

const requestPatientConsentsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PatientConsent[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/patient-consent'),
  loadingAction: Creators.loadingPatientConsent,
  commitAction: Creators.commitPatientConsents,
  successAction: noOpAction,
  errorAction: Creators.errorPatientConsent,
  action: downloadPatientConsents,
  beforeAction: putAuthInfoInArgs,
});

const requestLivePatientConsentWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PatientConsent,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/create-patient-consent'),
  loadingAction: Creators.loadingPatientConsent,
  commitAction: Creators.commitPatientConsent,
  successAction: noOpAction,
  errorAction: Creators.errorPatientConsent,
  action: downloadLivePatientConsent,
  beforeAction: putAuthInfoInArgs,
  *afterAction(res: PatientConsent[]): SagaIterator {
    if (res && res.length) {
      return res.pop();
    }
  },
});

const requestCreatePatientConsentWatcher = createSingleEventSaga<
  PatientConsent,
  PatientConsent,
  MyAction<PatientConsent>
>({
  takeEvery: Types.REQUEST_CREATE_PATIENT_CONSENT,
  loadingAction: Creators.loadingPatientConsent,
  commitAction: Creators.commitPatientConsent,
  successAction: goBack.action,
  errorAction: Creators.errorPatientConsent,
  action: createPatientConsent,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<PatientConsent>,
    { headers, ...args }: ArgsWithHeaders<PatientConsent>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdatePatientConsentWatcher = createSingleEventSaga<
  Partial<PatientConsent>,
  Partial<PatientConsent>,
  MyAction<Partial<PatientConsent>>
>({
  takeEvery: Types.REQUEST_UPDATE_PATIENT_CONSENT,
  loadingAction: Creators.loadingPatientConsent,
  commitAction: Creators.commitPatientConsent,
  successAction: goBack.action,
  errorAction: Creators.errorPatientConsent,
  action: updatePatientConsent,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Partial<PatientConsent>>,
  ): SagaIterator {
    return args;
  },
});

export const patientConsentSagas = [
  goBack.watcher,
  requestPatientConsentsWatcher,
  requestLivePatientConsentWatcher,
  requestCreatePatientConsentWatcher,
  requestUpdatePatientConsentWatcher,
];

export const selectPatientConsentState = (state: MyState) =>
  state.patientConsent;

export const selectPatientConsents = createSelector(
  selectPatientConsentState,
  (patientConsentState) =>
    patientConsentSelectors.selectAll(patientConsentState),
);

export const selectPatientConsentById = createSelector(
  [selectPatientConsentState, (_, id) => id],
  (patientConsentState, id) => patientConsentState.entities[id],
);

export const selectLivePatientConsent = createSelector(
  selectPatientConsentState,
  (patientConsentState) =>
    patientConsentSelectors
      .selectAll(patientConsentState)
      .filter(({ sendLive }) => sendLive)
      .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt, 'milliseconds'))
      .pop(),
);
