import { Link } from 'react-router-dom';
import { MouseEvent, FC, useCallback } from 'react';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { LicensedState } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  licensedState: LicensedState;
  onEdit: (
    event: MouseEvent<HTMLButtonElement | HTMLInputElement, MouseEvent>,
  ) => void;
  onDelete: (id: string) => void;
  providerId: string;
}

export const LicensedStateRow: FC<Props> = ({
  licensedState,
  onEdit,
  onDelete,
  providerId,
}) => {
  const requestDelete = useCallback(() => {
    onDelete(licensedState.id);
  }, [licensedState, onDelete]);
  const { name, coordinates, id } = licensedState;
  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{name}</strong>{' '}
          <Heading
            subtitle
            size={6}
            renderAs="h6"
          >{`Geo point: ${coordinates}`}</Heading>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/providers/${providerId}/licensed-states/${id}`}>
            <MMDSuperAdminButton isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default LicensedStateRow;
