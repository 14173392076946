import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL, MOMENT_BACKEND } from '../config';
import { Prescription, SagaIterator } from '../typings';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import { auth } from '../utils/Firebase';
import { signInWithCustomToken } from 'firebase/auth';

interface PrescriptionPayload {
  id: string;
}

interface StatusPayload {
  status: string;
}

interface ActionTypes {
  REQUEST_CREATE_PRESCRIPTION: string;
  REQUEST_UPDATE_PRESCRIPTION: string;
  REQUEST_REMOVE_PRESCRIPTION: string;
  LOADING_PRESCRIPTIONS: string;
  COMMIT_PRESCRIPTIONS: string;
  COMMIT_PRESCRIPTION: string;
  REMOVE_PRESCRIPTION: string;
  ERROR_PRESCRIPTIONS: string;
  REQUEST_RESEND_PRESCRIPTION: string;
}

interface ActionCreators {
  requestCreatePrescription: (payload: Prescription) => MyAction<Prescription>;
  requestUpdatePrescription: (
    payload: Partial<Prescription>,
  ) => MyAction<Partial<Prescription>>;
  requestRemovePrescription: (
    payload: PrescriptionPayload,
  ) => MyAction<PrescriptionPayload>;
  requestResendPrescription: (
    payload: Partial<Prescription>,
  ) => MyAction<Partial<Prescription>>;
  loadingPrescriptions: () => Action;
  commitPrescriptions: (
    payload: Paginated<Prescription>,
  ) => MyAction<Paginated<Prescription>>;
  commitPrescription: (payload: Prescription) => MyAction<Prescription>;
  removePrescription: (
    payload: PrescriptionPayload,
  ) => MyAction<PrescriptionPayload>;
  errorPrescriptions: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface PrescriptionsState<TError extends Error = Error>
  extends EntityState<Prescription> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePrescription: ['payload'],
  requestUpdatePrescription: ['payload'],
  requestRemovePrescription: ['payload'],
  requestResendPrescription: ['payload'],
  loadingPrescriptions: [],
  commitPrescriptions: ['payload'],
  commitPrescription: ['payload'],
  removePrescription: ['payload'],
  errorPrescriptions: ['error'],
});

const entityAdapter = createEntityAdapter<Prescription>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const PrescriptionSelectors = entityAdapter.getSelectors();

function setLoading(state: PrescriptionsState): PrescriptionsState {
  return {
    ...state,
    loading: true,
  };
}

function commitPrescription(
  state: PrescriptionsState,
  action: MyAction<Prescription>,
): PrescriptionsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitPrescriptions(
  state: PrescriptionsState,
  action: MyAction<Paginated<Prescription>>,
): PrescriptionsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removePrescription(
  state: PrescriptionsState,
  action: MyAction<PrescriptionPayload>,
): PrescriptionsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PrescriptionsState,
  { error }: ErrorAction<TError>,
): PrescriptionsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const prescriptionReducer = createReducer(initialState, {
  [Types.LOADING_PRESCRIPTIONS]: setLoading,
  [Types.COMMIT_PRESCRIPTION]: commitPrescription,
  [Types.COMMIT_PRESCRIPTIONS]: commitPrescriptions,
  [Types.REMOVE_PRESCRIPTION]: removePrescription,
  [Types.ERROR_PRESCRIPTIONS]: setError,
});

async function downloadPrescription({
  headers,
  ...payload
}: ArgsWithHeaders<StatusPayload>): Promise<Prescription> {
  const result = await fetch(
    `${API_URL}/prescriptions?__sort=-updatedAt&status=${payload.status}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createPrescription({
  headers,
  ...payload
}: ArgsWithHeaders<Prescription>): Promise<CreationResult<Prescription>> {
  const result = await fetch(`${API_URL}/prescriptions`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function requestUpdatePrescription({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Prescription>>): Promise<void> {
  const result = await fetch(`${API_URL}/prescriptions/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function resendPrescription({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Prescription>>): Promise<Partial<Prescription>> {
  const session = JSON.parse(localStorage['moment.session']);
  await signInWithCustomToken(auth, session.firebase_token);
  const token = await auth.currentUser.getIdToken();
  const resendResult = await fetch(
    `${MOMENT_BACKEND}/prescriptions/resend-prescription`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({
        token,
        ...payload,
      }),
    },
  );
  const resendResponse = await resendResult.json();

  const status = !resendResponse.response ? 'send_fax_error' : 'sent';

  if (resendResponse?.response === true) {
    const result = await fetch(`${API_URL}/prescriptions/${payload.id}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify({ status }),
    });

    if (!result.ok) {
      if (result.status === UNAUTHORIZED) {
        throw Error(INVISIBLE_ERROR_MESSAGE);
      }

      throw Error('There has been an error processing your request');
    }
  }
  return { ...payload, status };
}

const requestPrescriptionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Prescription,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/create-prescriptions/'),
  loadingAction: Creators.loadingPrescriptions,
  commitAction: Creators.commitPrescription,
  successAction: noOpAction,
  errorAction: Creators.errorPrescriptions,
  action: downloadPrescription,
  beforeAction: putAuthInfoInArgs,
});

const requestPrescriptionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Prescription>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/prescriptions'),
  loadingAction: Creators.loadingPrescriptions,
  commitAction: Creators.commitPrescriptions,
  successAction: noOpAction,
  errorAction: Creators.errorPrescriptions,
  action: downloadUsingLocationQuery<Prescription>('prescriptions'),
  beforeAction: putAuthInfoInArgs,
});

const requestCreatePrescriptionWatcher = createSingleEventSaga<
  Prescription,
  Prescription,
  MyAction<Prescription>
>({
  takeEvery: Types.REQUEST_CREATE_PRESCRIPTION,
  loadingAction: Creators.loadingPrescriptions,
  commitAction: Creators.commitPrescription,
  successAction: noOpAction,
  errorAction: Creators.errorPrescriptions,
  action: createPrescription,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Prescription>,
    { headers, ...args }: ArgsWithHeaders<Prescription>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdatePrescriptionWatcher = createSingleEventSaga<
  Partial<Prescription>,
  Partial<Prescription>,
  MyAction<Partial<Prescription>>
>({
  takeEvery: Types.REQUEST_UPDATE_PRESCRIPTION,
  loadingAction: Creators.loadingPrescriptions,
  commitAction: Creators.commitPrescription,
  successAction: noOpAction,
  errorAction: Creators.errorPrescriptions,
  action: requestUpdatePrescription,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Prescription>,
  ): SagaIterator {
    return args;
  },
});

const resendPrescriptionWatcher = createSingleEventSaga<
  Partial<Prescription>,
  Partial<Prescription>,
  MyAction<Partial<Prescription>>
>({
  takeEvery: Types.REQUEST_RESEND_PRESCRIPTION,
  loadingAction: Creators.loadingPrescriptions,
  commitAction: Creators.commitPrescription,
  successAction: noOpAction,
  errorAction: Creators.errorPrescriptions,
  action: resendPrescription,
  beforeAction: putAuthInfoInArgs,
});

export const prescriptionSagas = [
  requestPrescriptionWatcher,
  requestPrescriptionsWatcher,
  requestCreatePrescriptionWatcher,
  requestUpdatePrescriptionWatcher,
  resendPrescriptionWatcher,
];

export function prescriptionFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  return Promise.resolve(result);
}
