import { Form } from '@mrnkr/redux-saga-toolbox';
import {
  Creators as LicensedStatesActions,
  licensedStatesFormValidator,
  selectLicensedStatesById,
} from 'modules/licensed-states.module';
import { FC, FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LicensedState } from 'typings';
import { putDefaultValueSafe } from 'utils/formUtils';
import MMDForm from '../../components/forms/MMDForm';
import MMDTextInput from '../../components/forms/MMDTextInput';
import MMDButton from '../../components/MMDButton';
import { useParams } from 'react-router-dom';

const LicensedStateForm: FC = () => {
  const params = useParams<{ idLicensedState: string; id: string }>();

  const licensedState = useSelector((state) =>
    selectLicensedStatesById(state, params.idLicensedState),
  );

  const renderFormControls = useCallback(
    (
      form: Form,
      onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
    ) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter name"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['coordinates'].name}
          value={form.fields['coordinates'].value}
          label="Coordinates"
          valid={form.fields['coordinates'].valid}
          dirty={form.fields['coordinates'].dirty}
          placeholder="[lat,long]"
          onChange={onChange}
          required={true}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={licensedState ? 'Update' : 'Create'}
        />
      </div>
    ),
    [licensedState],
  );

  if (!licensedState && params.idLicensedState) {
    return null;
  }

  const formConfig = {
    formName: licensedState
      ? `update-licensed-states-form-${licensedState.id}`
      : 'create-licensed-states-form',
    fields: {
      name: putDefaultValueSafe(licensedState, 'name'),
      coordinates: putDefaultValueSafe(licensedState, 'coordinates'),
    },
    validator: licensedStatesFormValidator,
    onSubmit: (values) => {
      const licensedStateObj = {
        name: values['name'],
        coordinates: values['coordinates'],
        doctorId: params.id,
        id: licensedState ? licensedState.id : undefined,
      } as LicensedState;

      return licensedState
        ? LicensedStatesActions.requestUpdateLicensedState(licensedStateObj)
        : LicensedStatesActions.requestCreateLicensedState(licensedStateObj);
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default LicensedStateForm;
