import { Link } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import { Booking } from 'typings';
import MMDButton from 'components/MMDButton';
import { getFirebaseImage } from 'utils/Firebase';

const BookingFiles: FC<{ booking: Booking }> = ({ booking }) => {
  const [files, setFiles] = useState(null);

  useEffect(() => {
    (async () => {
      const downloadedFiles = await Promise.all(
        booking.files.map(async (file) => {
          const image = await getFirebaseImage(
            `/bookings/${booking.id}/${booking.doctorId}/${booking.patientId}/${file.fileName}`,
          );

          return {
            image,
            id: file.id,
            name: file.fileName,
          };
        }),
      );

      setFiles(downloadedFiles);
    })();
  }, []);

  if (!booking.files?.length) {
    return <span>-</span>;
  }

  if (!files) {
    return <span>Loading...</span>;
  }

  return (
    <>
      {files.map((file) => (
        <Link
          key={file.id}
          to={file.image}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MMDButton isDanger className="mt-2" text={file.name} />
        </Link>
      ))}
    </>
  );
};

export default BookingFiles;
