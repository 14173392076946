import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import QuestionnaireForm from './QuestionnaireForm';
import { mapQuestionnaireToPreview } from './helpers';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectQuestionnairesState,
  Creators as QuestionnaireActions,
} from 'modules/questionnaires.module';

const PreviewQuestionnaire = () => {
  const params = useParams();

  const { requestQuestionnaire } = useBoundedActions(QuestionnaireActions);

  const { loading, questionnaireToPreview } = useSelector(
    selectQuestionnairesState,
  );

  useEffect(() => {
    requestQuestionnaire({ id: params.id });
  }, []);

  return (
    <div className="container p-2">
      <MMDLoader loading={loading} />

      {questionnaireToPreview && (
        <QuestionnaireForm
          isPreview
          initialValues={mapQuestionnaireToPreview(questionnaireToPreview)}
        />
      )}
    </div>
  );
};

export default PreviewQuestionnaire;
