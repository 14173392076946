import { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  affiliationsSelectors,
  Creators as AffiliationsActions,
} from '../modules/affiliations.module';
import { MyState } from 'store';
import { Affiliation } from 'typings';
import EntitiesTable from 'components/entities-table';
import AffiliationRow from 'pages/affiliations/AffiliationRow';

type Props = {
  affiliations?: Affiliation[];
  loading: boolean;
  count: number;
} & typeof AffiliationsActions;

const Affiliations: FC<Props> = ({
  count,
  loading,
  affiliations,
  requestRemoveAffiliation,
}) => (
  <EntitiesTable
    count={count}
    loading={loading}
    withoutApproveFilter
    rootPath="affiliations"
    newEntityUrl="create-affiliation"
    elements={affiliations.map((item: Affiliation) => (
      <AffiliationRow
        key={item.id}
        affiliation={item}
        targetPath="affiliations"
        onDeleteClick={(id: number) => requestRemoveAffiliation({ id })}
      />
    ))}
  />
);

const mapStateToProps = (state: MyState) => ({
  affiliations: affiliationsSelectors.selectAll(state.affiliations),
  loading: state.affiliations.loading,
  count: state.affiliations.count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...AffiliationsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Affiliations);
