import {
  MyAction,
  Dictionary,
  ErrorAction,
  EntityState,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';

import { API_URL } from 'config';
import { MyState } from 'store';
import { noOpAction } from 'utils/noOpAction';
import { Pharmacy, SagaIterator } from 'typings';
import { putAuthInfoInArgs } from './auth.module';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { API_ERRORS_CODE, FAX_REG_EXP, PHONE_REG_EXP } from 'utils/constants';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';

interface PharmacyPayload {
  id: string;
}

interface ActionTypes {
  REQUEST_CREATE_PHARMACY: string;
  REQUEST_UPDATE_PHARMACY: string;
  REQUEST_REMOVE_PHARMACY: string;
  LOADING_PHARMACIES: string;
  COMMIT_PHARMACIES: string;
  COMMIT_PHARMACY: string;
  REMOVE_PHARMACY: string;
  ERROR_PHARMACIES: string;
}

interface ActionCreators {
  requestCreatePharmacy: (payload: Pharmacy) => MyAction<Pharmacy>;
  requestUpdatePharmacy: (
    payload: Partial<Pharmacy>,
  ) => MyAction<Partial<Pharmacy>>;
  requestRemovePharmacy: (
    payload: PharmacyPayload,
  ) => MyAction<PharmacyPayload>;
  loadingPharmacies: () => Action;
  commitPharmacies: (
    payload: Paginated<Pharmacy>,
  ) => MyAction<Paginated<Pharmacy>>;
  commitPharmacy: (payload: Pharmacy) => MyAction<Pharmacy>;
  removePharmacy: (payload: PharmacyPayload) => MyAction<PharmacyPayload>;
  errorPharmacies: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface PharmaciesState<TError extends Error = Error>
  extends EntityState<Pharmacy> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePharmacy: ['payload'],
  requestUpdatePharmacy: ['payload'],
  requestRemovePharmacy: ['payload'],
  loadingPharmacies: [],
  commitPharmacies: ['payload'],
  commitPharmacy: ['payload'],
  removePharmacy: ['payload'],
  errorPharmacies: ['error'],
});

const entityAdapter = createEntityAdapter<Pharmacy>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
});
export const pharmacySelectors = entityAdapter.getSelectors();

function setLoading(state: PharmaciesState): PharmaciesState {
  return {
    ...state,
    loading: true,
  };
}

function commitPharmacy(
  state: PharmaciesState,
  action: MyAction<Pharmacy>,
): PharmaciesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitPharmacies(
  state: PharmaciesState,
  action: MyAction<Paginated<Pharmacy>>,
): PharmaciesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removePharmacy(
  state: PharmaciesState,
  action: MyAction<PharmacyPayload>,
): PharmaciesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PharmaciesState,
  { error }: ErrorAction<TError>,
): PharmaciesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const pharmacyReducer = createReducer(initialState, {
  [Types.LOADING_PHARMACIES]: setLoading,
  [Types.COMMIT_PHARMACY]: commitPharmacy,
  [Types.COMMIT_PHARMACIES]: commitPharmacies,
  [Types.REMOVE_PHARMACY]: removePharmacy,
  [Types.ERROR_PHARMACIES]: setError,
});

async function downloadPharmacy({
  headers,
  ...payload
}: ArgsWithHeaders<PharmacyPayload>): Promise<Pharmacy> {
  const result = await fetch(`${API_URL}/pharmacies/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createPharmacy({
  headers,
  ...payload
}: ArgsWithHeaders<Pharmacy>): Promise<CreationResult<Pharmacy>> {
  const result = await fetch(`${API_URL}/pharmacies`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}
function* updatePharmacy({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Pharmacy>>) {
  const pharmacy = yield select((state) =>
    selectPharmacyById(state, payload.id),
  );

  const updatedPharmacyData = deepDiffBetweenObjects(payload, pharmacy);

  if (!Object.keys(updatedPharmacyData).length) {
    return;
  }

  const result = yield call(fetch, `${API_URL}/pharmacies/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(updatedPharmacyData),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deletePharmacy({
  headers,
  ...payload
}: ArgsWithHeaders<Pharmacy>): Promise<void> {
  const result = await fetch(`${API_URL}/pharmacies/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const errorBody = await result.json();

    if (errorBody.code === API_ERRORS_CODE.FORBID_DELETE_PHARMACY) {
      throw Error(API_ERRORS_CODE.FORBID_DELETE_PHARMACY.toString());
    }

    throw Error('There has been an error processing your request');
  }
}

const requestPharmacyWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Pharmacy,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/pharmacies/:id'),
  loadingAction: Creators.loadingPharmacies,
  commitAction: Creators.commitPharmacy,
  successAction: noOpAction,
  errorAction: Creators.errorPharmacies,
  action: downloadPharmacy,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    PharmacyPayload,
    ArgsWithHeaders<PharmacyPayload>
  >(extractRouteParams('/pharmacies/:id'), putAuthInfoInArgs),
});

const requestPharmaciesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Pharmacy>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/pharmacies'),
  loadingAction: Creators.loadingPharmacies,
  commitAction: Creators.commitPharmacies,
  successAction: noOpAction,
  errorAction: Creators.errorPharmacies,
  action: downloadUsingLocationQuery<Pharmacy>('pharmacies'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/pharmacies');

const requestCreatePharmacyWatcher = createSingleEventSaga<
  Pharmacy,
  Pharmacy,
  MyAction<Pharmacy>
>({
  takeEvery: Types.REQUEST_CREATE_PHARMACY,
  loadingAction: Creators.loadingPharmacies,
  commitAction: Creators.commitPharmacy,
  successAction: goBack.action,
  errorAction: Creators.errorPharmacies,
  action: createPharmacy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Pharmacy>,
    { headers, ...args }: ArgsWithHeaders<Pharmacy>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdatePharmacyWatcher = createSingleEventSaga<
  Partial<Pharmacy>,
  Partial<Pharmacy>,
  MyAction<Partial<Pharmacy>>
>({
  takeEvery: Types.REQUEST_UPDATE_PHARMACY,
  loadingAction: Creators.loadingPharmacies,
  commitAction: Creators.commitPharmacy,
  successAction: goBack.action,
  errorAction: Creators.errorPharmacies,
  action: updatePharmacy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Pharmacy>,
  ): SagaIterator {
    return args;
  },
});

const requestDeletePharmacyWatcher = createSingleEventSaga<
  PharmacyPayload,
  void,
  MyAction<PharmacyPayload>
>({
  takeEvery: Types.REQUEST_REMOVE_PHARMACY,
  loadingAction: Creators.loadingPharmacies,
  commitAction: Creators.removePharmacy,
  successAction: noOpAction,
  errorAction: Creators.errorPharmacies,
  action: deletePharmacy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<PharmacyPayload>,
  ): SagaIterator {
    return args;
  },
});

export const pharmacySagas = [
  requestPharmacyWatcher,
  requestPharmaciesWatcher,
  requestCreatePharmacyWatcher,
  requestUpdatePharmacyWatcher,
  requestDeletePharmacyWatcher,
  goBack.watcher,
];

export const shiftFaxNumber = (fax: string) =>
  /^\+1\d/.test(fax) ? fax.slice(2) : fax;

export function pharmacyFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const coordinatesRegex =
    /^\(([+-]?([0-9]*[.])?[0-9]+),([+-]?([0-9]*[.])?[0-9]+)\)/g;

  const result = {
    name: true,
    address: true,
    coordinates: true,
    phone: true,
    fax: true,
    approved: true,
  };

  if (!values['coordinates'].match(coordinatesRegex)) {
    result.coordinates = false;
  }

  if (!values.phone.match(PHONE_REG_EXP)) {
    result.phone = false;
  }

  if (!values['fax'].match(FAX_REG_EXP)) {
    result.fax = false;
  }

  return Promise.resolve(result);
}

const selectPharmaciesState = (state: MyState) => state.pharmacies;

export const selectPharmacyById = createSelector(
  [selectPharmaciesState, (_, id) => id],
  (state, id) => state.entities[id],
);
