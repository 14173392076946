import moment from 'moment';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { ProviderLanguage } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  providerLanguage: ProviderLanguage;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const ProviderLanguageRow: FC<Props> = ({
  providerLanguage,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(providerLanguage.id);
  }, [providerLanguage, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{providerLanguage.name}</strong>
          <p>Created at: {moment(providerLanguage.createdAt).format('LLLL')}</p>
          <p>Updated at: {moment(providerLanguage.updatedAt).format('LLLL')}</p>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${providerLanguage.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default ProviderLanguageRow;
