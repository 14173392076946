import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';
import moment from 'moment';
import { createSelector } from 'reselect';
import { call, put, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';

import { MyState } from 'store';
import { API_URL } from 'config';
import { ArgsWithHeaders } from 'utils/typings';
import { SagaIterator, Timeslot } from 'typings';
import { putAuthInfoInArgs } from './auth.module';
import { extractRouteParams } from 'utils/onRoute';

interface TimeslotId {
  id: string;
}

interface TimeslotQuery {
  query?: string;
}

interface TimeslotPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  birthday: string;
  city: string;
  state: string;
  phone: string;
  gender: string;
  avatarApproved: boolean;
}

interface ActionTypes {
  REQUEST_CREATE_TIMESLOT: string;
  REQUEST_UPDATE_TIMESLOT: string;
  REQUEST_DELETE_TIMESLOT: string;
  REQUEST_CLEAR_TIMESLOTS: string;
  REQUEST_DELETE_ALL_TIMESLOTS: string;
  REQUEST_ALL_TIMESLOTS: string;
  REQUEST_TIMESLOTS: string;
  REQUEST_TIMESLOT: string;
  COMMIT_TIMESLOTS: string;
  COMMIT_ALL_TIMESLOTS: string;
  COMMIT_TIMESLOT: string;
  SUCCESS_TIMESLOTS: string;
  REQUEST_TIMESLOT_REPORT: string;
  COMMIT_TIMESLOT_REPORT: string;
  REQUEST_TIMESLOT_FILES: string;
  COMMIT_TIMESLOT_FILES: string;
  SET_CURRENT_DATE: string;
  LOADING_TIMESLOTS: string;
  ERROR_TIMESLOTS: string;
}

interface ActionCreators {
  requestTimeslot: (payload: TimeslotId) => MyAction<Timeslot>;
  requestTimeslots: (payload?: TimeslotQuery) => MyAction<Timeslot[]>;
  requestAllTimeslots: () => MyAction<Timeslot[]>;
  requestTimeslotReport: (payload: TimeslotId) => MyAction<void>;
  requestUpdateTimeslot: (
    payload: TimeslotPayload,
  ) => MyAction<TimeslotPayload>;
  requestCreateTimeslot: (
    payload: TimeslotPayload,
  ) => MyAction<TimeslotPayload>;
  requestDeleteTimeslot: (payload: TimeslotId) => MyAction<TimeslotPayload>;
  requestClearTimeslots: () => MyAction<TimeslotPayload>;
  requestDeleteAllTimeslots: (
    payload: TimeslotId[],
  ) => MyAction<TimeslotPayload>;
  commitTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  commitAllTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  commitTimeslot: (payload: Timeslot) => MyAction<Timeslot>;
  commitTimeslotReport: (payload: string) => MyAction<string>;
  commitTimeslotFiles: (payload: string) => MyAction<File[]>;
  successTimeslots: (payload: Timeslot[]) => MyAction<Timeslot[]>;
  setCurrentDate: (payload: Date) => MyAction<Date>;
  loadingTimeslots: () => MyAction<void>;
  errorTimeslots: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface TimeslotsState<TError extends Error = Error>
  extends EntityState<Timeslot> {
  count: number;
  report?: string;
  loadingReport: boolean;
  files: File[];
  loadingFiles: boolean;
  versionReport: number;
  timeslot: Timeslot | null;
  stateDelete: boolean;
  allTimeSlots: Timeslot[];
  recurrentTimeslotsDSTMap: Map<string, boolean>;
  currentDate: Date;
  loading: boolean;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestTimeslots: ['payload'],
  requestAllTimeslots: ['payload'],
  requestTimeslot: ['payload'],
  requestTimeslotReport: ['payload'],
  commitTimeslots: ['payload'],
  commitAllTimeslots: ['payload'],
  commitTimeslot: ['payload'],
  commitTimeslotReport: ['payload'],
  commitTimeslotFiles: ['payload'],
  successTimeslots: ['payload'],
  requestUpdateTimeslot: ['payload'],
  requestCreateTimeslot: ['payload'],
  requestDeleteTimeslot: ['payload'],
  requestClearTimeslots: ['payload'],
  requestDeleteAllTimeslots: ['payload'],
  setCurrentDate: ['payload'],
  loadingTimeslots: [],
  errorTimeslots: ['payload'],
});

const entityAdapter = createEntityAdapter<Timeslot>();

const initialState = entityAdapter.getInitialState({
  count: 0,
  loadingReport: false,
  loadingFiles: false,
  files: [],
  versionReport: 0,
  timeslot: null,
  stateDelete: false,
  allTimeSlots: [],
  currentDate: moment().toDate(),
});
export const timeslotsSelectors = entityAdapter.getSelectors();

function commitTimeslots(
  state: TimeslotsState,
  action: MyAction<Timeslot[]>,
): TimeslotsState {
  return {
    ...entityAdapter.addAll(action.payload, state),
    loading: false,
  };
}
function commitAllTimeslots(
  state: TimeslotsState,
  action: MyAction<Timeslot[]>,
): TimeslotsState {
  return {
    ...state,
    allTimeSlots: action.payload,
    loading: false,
  };
}

function commitTimeslot(
  state: TimeslotsState,
  action: MyAction<Timeslot>,
): TimeslotsState {
  return {
    ...state,
    timeslot: action.payload.timeslot,
    loading: false,
  };
}

function setCurrentDate(
  state: TimeslotsState,
  action: MyAction<Date>,
): TimeslotsState {
  return {
    ...state,
    currentDate: action.payload,
  };
}

function setLoading(state: TimeslotsState): TimeslotsState {
  return {
    ...state,
    loading: true,
  };
}

function setError<TError extends Error = Error>(
  state: TimeslotsState,
  { error }: ErrorAction<TError>,
): TimeslotsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const timeslotsReducer = createReducer(initialState, {
  [Types.COMMIT_TIMESLOTS]: commitTimeslots,
  [Types.COMMIT_ALL_TIMESLOTS]: commitAllTimeslots,
  [Types.COMMIT_TIMESLOT]: commitTimeslot,
  [Types.SET_CURRENT_DATE]: setCurrentDate,
  [Types.LOADING_TIMESLOTS]: setLoading,
  [Types.ERROR_TIMESLOTS]: setError,
});

function* getProviderId(): SagaIterator {
  const router = yield select((store) => store.router);

  const params = yield call(
    extractRouteParams('/providers/:id/availability'),
    router,
  );

  return params?.id;
}

function* downloadTimeslots({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotQuery>): SagaIterator {
  const { currentDate } = yield select(selectTimeslotsState);

  payload.query =
    payload.query || `?month=${moment(currentDate).format('YYYY-MM-DD')}`;

  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/availability${payload.query}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error);
  }
  const { timeslots } = yield call([result, 'json']);

  return timeslots;
}

function* downloadAllTimeslots({
  headers,
}: ArgsWithHeaders<TimeslotQuery>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/availability?all=0`,
    {
      headers,
      method: 'GET',
    },
  );
  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error);
  }
  const { timeslots } = yield call([result, 'json']);

  return timeslots;
}

function* updateTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/${payload.id}`,
    {
      headers,
      method: 'PATCH',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }
}

function* createTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/range`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }

  yield put(Creators.requestAllTimeslots());
}

function* deleteTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotPayload>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/${payload.id}`,
    {
      headers,
      method: 'DELETE',
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }

  yield put(Creators.requestAllTimeslots());
}

function* clearTimeslot({
  headers,
}: ArgsWithHeaders<TimeslotPayload>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/clear`,
    {
      headers,
      method: 'POST',
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }

  yield put(Creators.requestAllTimeslots());
  yield put(Creators.requestTimeslots());
}

function* deleteAllTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<Record<string, string>>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/all`,
    {
      headers,
      method: 'DELETE',
      body: JSON.stringify({ timeSlotsIds: Object.values(payload) }),
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }

  yield put(Creators.requestAllTimeslots());
}

function* downloadTimeslot({
  headers,
  ...payload
}: ArgsWithHeaders<TimeslotId>): SagaIterator {
  const providerId = yield call(getProviderId);

  const result = yield call(
    fetch,
    `${API_URL}/providers/${providerId}/timeslots/${payload.id}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    const error = yield call([result, 'json']);
    throw Error(error.msg);
  }

  return yield call([result, 'json']);
}

const requestCreateTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_CREATE_TIMESLOT,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: createTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPDATE_TIMESLOT,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: updateTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DELETE_TIMESLOT,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: deleteTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestClearTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_CLEAR_TIMESLOTS,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.commitAllTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: clearTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteAllTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_DELETE_ALL_TIMESLOTS,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.requestTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: deleteAllTimeslot,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotsWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TIMESLOTS,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.commitTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: downloadTimeslots,
  beforeAction: putAuthInfoInArgs,
});
const requestAllTimeslotsWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_ALL_TIMESLOTS,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.commitAllTimeslots,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: downloadAllTimeslots,
  beforeAction: putAuthInfoInArgs,
});

const requestTimeslotWatcher = createSingleEventSaga<
  object,
  Timeslot[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TIMESLOT,
  loadingAction: Creators.loadingTimeslots,
  commitAction: Creators.commitTimeslot,
  successAction: Creators.successTimeslots,
  errorAction: Creators.errorTimeslots,
  action: downloadTimeslot,
  beforeAction: putAuthInfoInArgs,
});

export const timeslotsSagas = [
  requestTimeslotWatcher,
  requestTimeslotsWatcher,
  requestAllTimeslotsWatcher,
  requestClearTimeslotWatcher,
  requestCreateTimeslotWatcher,
  requestUpdateTimeslotWatcher,
  requestDeleteTimeslotWatcher,
  requestDeleteAllTimeslotWatcher,
];

export const selectTimeslotsState = (state: MyState) => state.timeslots;

export const selectTimeslots = createSelector(selectTimeslotsState, (state) =>
  timeslotsSelectors.selectAll(state),
);
