import { FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@mrnkr/redux-saga-toolbox';

import MMDForm from '../components/forms/MMDForm';
import MMDTextInput from '../components/forms/MMDTextInput';
import MMDButton from '../components/MMDButton';
import {
  Creators as AffiliationActions,
  affiliationFormValidator,
  selectAffiliationById,
} from '../modules/affiliations.module';
import { Affiliation } from 'typings';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { putDefaultValueSafe, getBooleanFieldValue } from 'utils/formUtils';
import { useParams } from 'react-router-dom';

const AffiliationForm = () => {
  const params = useParams<{ id: string }>();

  const affiliation = useSelector((state) =>
    selectAffiliationById(state, params.id),
  );

  const renderFormControls = useCallback(
    (
      form: Form,
      onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
    ) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="Name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter affiliation name"
          onChange={onChange}
          required
        />
        <MMDCheckbox
          name={form.fields['approved'].name}
          label="Approved"
          dirty={form.fields['approved'].dirty}
          value={form.fields['approved'].value as 'true' | 'false'}
          onChange={onChange}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={affiliation ? 'Update' : 'Create'}
        />
      </div>
    ),
    [affiliation],
  );

  if (!affiliation && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-affiliation-form-${params.id}`
      : 'create-affiliation-form',
    fields: {
      name: putDefaultValueSafe(affiliation, 'name'),
      approved: putDefaultValueSafe(affiliation, 'approved'),
    },
    validator: affiliationFormValidator,
    onSubmit: (values) =>
      affiliation && affiliation.id
        ? AffiliationActions.requestUpdateAffiliation({
            id: affiliation.id,
            name: values['name'],
            approved: values['approved'] === 'true',
          })
        : AffiliationActions.requestCreateAffiliation({
            name: values['name'],
            approved: getBooleanFieldValue(values, 'approved'),
          } as Affiliation),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default AffiliationForm;
