import { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  pharmacySelectors,
  Creators as PharmacyActions,
} from 'modules/pharmacies.module';
import { MyState } from 'store';
import { Pharmacy } from 'typings';
import EntitiesTable from 'components/entities-table';
import PharmacyRow from 'pages/pharmacies/PharmacyRow';
import PharmacyDeleteErrorModal from './PharmacyDeleteErrorModal';

type Props = {
  error: Error;
  count: number;
  loading: boolean;
  pharmacies?: Pharmacy[];
} & typeof PharmacyActions;

const Pharmacies: FC<Props> = ({
  error,
  count,
  loading,
  pharmacies,
  errorPharmacies,
  requestRemovePharmacy,
}) => (
  <EntitiesTable
    count={count}
    loading={loading}
    rootPath="pharmacies"
    newEntityUrl="create-pharmacy"
    elements={pharmacies.map((item: Pharmacy) => (
      <PharmacyRow
        key={item.id}
        pharmacy={item}
        targetPath={'pharmacies'}
        onDeleteClick={(id: string) => requestRemovePharmacy({ id })}
      />
    ))}
  >
    <PharmacyDeleteErrorModal
      error={error?.message}
      errorPharmacies={errorPharmacies}
    />
  </EntitiesTable>
);

const mapStateToProps = (state: MyState) => ({
  pharmacies: pharmacySelectors.selectAll(state.pharmacies),
  loading: state.pharmacies.loading,
  count: state.pharmacies.count,
  error: state.pharmacies.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PharmacyActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Pharmacies);
