import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import {
  Nullable,
  Questionnaire,
  QuestionnaireInList,
  QuestionnaireAnswer,
  QuestionnaireFormValue,
  QuestionnaireQuestionAnswerType,
} from 'typings';

interface ActionTypes {
  REQUEST_ALL_QUESTIONNAIRES: string;
  REQUEST_QUESTIONNAIRE: string;
  COMMIT_QUESTIONNAIRE: string;
  COMMIT_QUESTIONNAIRES: string;
  REQUEST_DELETE_QUESTIONNAIRE: string;
  REMOVE_QUESTIONNAIRE: string;
  LOADING_QUESTIONNAIRES: string;
  REQUEST_CREATE_QUESTIONNAIRE: string;
  ERROR_QUESTIONNAIRES: string;
}

interface ActionCreators {
  requestAllQuestionnaires: () => MyAction<void>;
  commitQuestionnaires: (
    payload: Paginated<QuestionnaireInList>,
  ) => MyAction<Paginated<QuestionnaireInList>>;
  requestQuestionnaire: (payload: { id: string }) => MyAction<{ id: string }>;
  requestDeleteQuestionnaire: (payload: {
    id: string;
  }) => MyAction<{ id: string }>;
  removeQuestionnaire: (payload: { id: number }) => MyAction<{ id: number }>;
  commitQuestionnaire: (payload: Questionnaire) => MyAction<Questionnaire>;
  loadingQuestionnaires: () => MyAction<void>;
  requestCreateQuestionnaire: (payload) => MyAction<void>;
  errorQuestionnaires: <TError extends Error>(
    error: Nullable<TError>,
  ) => ErrorAction<TError>;
}

export interface QuestionnairesState<TError extends Error = Error>
  extends EntityState<QuestionnaireInList> {
  loading: boolean;
  questionnaireToPreview: Nullable<Questionnaire>;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAllQuestionnaires: [],
  commitQuestionnaires: ['payload'],
  requestQuestionnaire: ['payload'],
  commitQuestionnaire: ['payload'],
  requestDeleteQuestionnaire: ['payload'],
  removeQuestionnaire: ['payload'],
  loadingQuestionnaires: [],
  requestCreateQuestionnaire: ['payload'],
  errorQuestionnaires: ['payload'],
});

const entityAdapter = createEntityAdapter<QuestionnaireInList>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
  questionnaireToPreview: null,
});

const questionnaireSelectors = entityAdapter.getSelectors();

const goBack = goBackFactory('/questionnaire');

function setLoading(state: QuestionnairesState): QuestionnairesState {
  return {
    ...state,
    loading: true,
  };
}

function setError<TError extends Error = Error>(
  state: QuestionnairesState,
  { error }: ErrorAction<TError>,
): QuestionnairesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function commitQuestionnaires(
  state: QuestionnairesState,
  action: MyAction<Paginated<QuestionnaireInList>>,
): QuestionnairesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitQuestionnaire(
  state: QuestionnairesState,
  action: MyAction<Questionnaire>,
): QuestionnairesState {
  return {
    ...state,
    loading: false,
    questionnaireToPreview: action.payload,
  };
}

function requestQuestionnaire(state: QuestionnairesState): QuestionnairesState {
  return {
    ...state,
    questionnaireToPreview: null,
  };
}

function removeQuestionnaire(
  state: QuestionnairesState,
  action: MyAction<{ id: string }>,
): QuestionnairesState {
  return {
    ...entityAdapter.removeOne(action.payload.toString(), state),
    loading: false,
  };
}

export const questionnairesReducer = createReducer(initialState, {
  [Types.LOADING_QUESTIONNAIRES]: setLoading,
  [Types.ERROR_QUESTIONNAIRES]: setError,
  [Types.COMMIT_QUESTIONNAIRES]: commitQuestionnaires,
  [Types.COMMIT_QUESTIONNAIRE]: commitQuestionnaire,
  [Types.REMOVE_QUESTIONNAIRE]: removeQuestionnaire,
  [Types.REQUEST_QUESTIONNAIRE]: requestQuestionnaire,
});

async function createAnswerRequest({
  headers,
  questionId,
  ...body
}: ArgsWithHeaders<{
  type: QuestionnaireQuestionAnswerType;
  text?: string;
  order?: number;
  questionId: string;
}>) {
  return fetch(
    `${API_URL}/questionnaire/questions/${questionId}/answer-options`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    },
  );
}

async function createQuestionAnswer({
  headers,
  questionId,
  ...answer
}: ArgsWithHeaders<QuestionnaireAnswer & { questionId: string }>) {
  const { type, ...restAnswerValues } = answer;
  switch (type) {
    case 'text':
      return createAnswerRequest({
        headers,
        questionId,
        type: 'text',
        order: 1,
      });
    case 'radio':
      return Promise.all(
        Object.values(restAnswerValues).map((answerValue, index) =>
          createAnswerRequest({
            headers,
            questionId,
            type: 'radio',
            order: index + 1,
            text: answerValue,
          }),
        ),
      );
  }
}

async function createQuestionnaireQuestionBlock({
  headers,
  questionnaireId,
  answer,
  ...payload
}: ArgsWithHeaders<{
  text: string;
  order: number;
  isRequired: boolean;
  questionnaireId: string;
  answer: QuestionnaireAnswer;
}>) {
  const questionResponse = await fetch(
    `${API_URL}/questionnaire/${questionnaireId}/questions`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );

  if (!questionResponse.ok) {
    if (questionResponse.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await questionResponse.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  const { id: questionId } = await questionResponse.json();

  // @ts-ignore
  return createQuestionAnswer({
    headers,
    questionId,
    ...answer,
  });
}

function createQuestionnaireTextBlock({
  headers,
  questionnaireId,
  ...payload
}: ArgsWithHeaders<{ text: string; order: number; questionnaireId: string }>) {
  return fetch(`${API_URL}/questionnaire/${questionnaireId}/text-block`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

function createQuestionnaireLinkBlock({
  headers,
  questionnaireId,
  ...payload
}: ArgsWithHeaders<{ url: string; order: number; questionnaireId: string }>) {
  return fetch(`${API_URL}/questionnaire/${questionnaireId}/link-block`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

async function createQuestionnaire({
  headers,
  ...payload
}: ArgsWithHeaders<QuestionnaireFormValue>) {
  const { name, description, blocks } = payload;

  const questionnaireResponse = await fetch(`${API_URL}/questionnaire`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ name, description }),
  });

  if (!questionnaireResponse.ok) {
    if (questionnaireResponse.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await questionnaireResponse.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  const { createdId } = await questionnaireResponse.json();

  const commonQuestionnaireOptions = {
    headers,
    questionnaireId: createdId,
  };

  blocks.map((block, index) => {
    switch (block.type) {
      case 'text':
        return createQuestionnaireTextBlock({
          order: index + 1,
          text: block.text,
          ...commonQuestionnaireOptions,
        });
      case 'link':
        return createQuestionnaireLinkBlock({
          order: index + 1,
          url: block.text,
          ...commonQuestionnaireOptions,
        });
      case 'question':
        return createQuestionnaireQuestionBlock({
          order: index + 1,
          text: block.text,
          answer: block.answer,
          isRequired: block.isRequired,
          ...commonQuestionnaireOptions,
        });
    }
  });
}

async function deletedQuestionnaire({
  headers,
  id,
}: ArgsWithHeaders<{ id: number }>) {
  const questionnaireResponse = await fetch(`${API_URL}/questionnaire/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!questionnaireResponse.ok) {
    if (questionnaireResponse.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await questionnaireResponse.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return id;
}

async function downloadQuestionnaire({
  headers,
  id,
}: ArgsWithHeaders<{ id: string }>) {
  const questionnaireResponse = await fetch(`${API_URL}/questionnaire/${id}`, {
    headers,
  });

  if (!questionnaireResponse.ok) {
    if (questionnaireResponse.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await questionnaireResponse.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return questionnaireResponse.json();
}

async function downloadAllQuestionnaires({ headers }: ArgsWithHeaders) {
  const questionnaireResponse = await fetch(
    `${API_URL}/questionnaire?__limit=1000`,
    {
      headers,
    },
  );

  if (!questionnaireResponse.ok) {
    if (questionnaireResponse.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await questionnaireResponse.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return questionnaireResponse.json();
}

const requestDeleteQuestionnaireWatcher = createSingleEventSaga<
  { id: number },
  Questionnaire,
  MyAction<{ id: number }>
>({
  takeEvery: Types.REQUEST_DELETE_QUESTIONNAIRE,
  loadingAction: Creators.loadingQuestionnaires,
  commitAction: Creators.removeQuestionnaire,
  successAction: goBack.action,
  errorAction: Creators.errorQuestionnaires,
  action: deletedQuestionnaire,
  beforeAction: putAuthInfoInArgs,
});

const requestQuestionnaireWatcher = createSingleEventSaga<
  { id: string },
  Questionnaire,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_QUESTIONNAIRE,
  loadingAction: Creators.loadingQuestionnaires,
  commitAction: Creators.commitQuestionnaire,
  successAction: noOpAction,
  errorAction: Creators.errorQuestionnaires,
  action: downloadQuestionnaire,
  beforeAction: putAuthInfoInArgs,
});

const requestAllQuestionnairesWatcher = createSingleEventSaga<
  void,
  Paginated<QuestionnaireInList>,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_ALL_QUESTIONNAIRES,
  loadingAction: Creators.loadingQuestionnaires,
  commitAction: Creators.commitQuestionnaires,
  successAction: noOpAction,
  errorAction: Creators.errorQuestionnaires,
  action: downloadAllQuestionnaires,
  beforeAction: putAuthInfoInArgs,
});

const requestQuestionnairesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<QuestionnaireInList>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/questionnaire'),
  loadingAction: Creators.loadingQuestionnaires,
  commitAction: Creators.commitQuestionnaires,
  successAction: noOpAction,
  errorAction: Creators.errorQuestionnaires,
  action:
    downloadUsingLocationQuery<Paginated<QuestionnaireInList>>('questionnaire'),
  beforeAction: putAuthInfoInArgs,
});

const requestCreateQuestionnaireWatcher = createSingleEventSaga({
  takeEvery: Types.REQUEST_CREATE_QUESTIONNAIRE,
  loadingAction: Creators.loadingQuestionnaires,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorQuestionnaires,
  action: createQuestionnaire,
  beforeAction: putAuthInfoInArgs,
});

export const questionnairesSaga = [
  goBack.watcher,
  requestQuestionnaireWatcher,
  requestQuestionnairesWatcher,
  requestAllQuestionnairesWatcher,
  requestDeleteQuestionnaireWatcher,
  requestCreateQuestionnaireWatcher,
];

export const selectQuestionnairesState = (state: MyState) =>
  state.questionnaires;

export const selectQuestionnaires = createSelector(
  selectQuestionnairesState,
  (questionnairesState) =>
    questionnaireSelectors.selectAll(questionnairesState),
);
