import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Nullable } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import { getDurationError, MIN_DURATION, validateDuration } from './config';
import { PublicBookingDuration } from 'modules/public-booking-durations.module';

type Duration = {
  durationError: Nullable<Error>;
  duration?: PublicBookingDuration;
  onSubmit: (values: { duration: number }) => void;
};

const DurationForm: FC<Duration> = ({ durationError, duration, onSubmit }) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      duration: duration?.duration ?? MIN_DURATION,
    },
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <MMDTextInput
        required
        type="number"
        valid={!errors.duration}
        label="Duration in minutes"
        error={getDurationError(watch('duration'))}
        {...register('duration', {
          required: true,
          valueAsNumber: true,
          validate: validateDuration,
        })}
      />

      {durationError && (
        <small className="has-text-danger">
          {durationError?.message ?? 'Error'}
        </small>
      )}

      <MMDButton
        isSubmit
        isPrimary
        className="mt-2"
        text={duration ? 'Update' : 'Create'}
      />
    </form>
  );
};

export default DurationForm;
