import { useSelector } from 'react-redux';

import EntitiesTable from 'components/entities-table';
import {
  UserInvite,
  selectUsersInvites,
  selectUsersInvitesState,
} from 'modules/users-invites.module';
import InviteRow from './invites/InviteRow';

const Invites = () => {
  const usersInvites = useSelector(selectUsersInvites);

  const { count, loading } = useSelector(selectUsersInvitesState);

  return (
    <EntitiesTable
      count={count}
      loading={loading}
      rootPath="invites"
      withoutApproveFilter
      elements={usersInvites.map((item: UserInvite) => (
        <InviteRow key={item.id} {...item} />
      ))}
    />
  );
};

export default Invites;
