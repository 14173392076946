import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { MyState } from 'store';
import MMDLoader from 'components/MMDLoader';
import MMDButton from 'components/MMDButton';
import MMDSelect from 'components/forms/MMDSelect';
import { PAYMENT_FREQUENCIES } from 'utils/constants';
import MMDTextInput from 'components/forms/MMDTextInput';
import { Creators as SplitPercentageActions } from 'modules/split-percentage.module';
import { Creators as PaymentFrecuencyActions } from 'modules/payment-frequency.module';

type PropTypes = {
  initialFeePercent: number;
  initialPaymentFrequency: number;
  loading: boolean;
} & typeof SplitPercentageActions &
  typeof PaymentFrecuencyActions;

const PaymentConfig: FC<PropTypes> = ({
  loading,
  requestCreateSplitPercentage,
  requestCreatePaymentFrequency,
  initialFeePercent,
  initialPaymentFrequency,
}) => {
  const [feePercentage, setFeePercentage] = useState<number>();
  const [paymentFrequency, setPaymentFrequency] = useState<number>();

  useEffect(() => {
    setFeePercentage(initialFeePercent);
  }, [initialFeePercent]);

  const navigate = useNavigate();

  if (!initialFeePercent) {
    return <p>There is no initial fee percentage stored in the database</p>;
  }

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <MMDTextInput
          label={'Enter the percentage (%) to split payment with the doctor'}
          type="number"
          value={feePercentage}
          onChange={(evt) => {
            setFeePercentage(Number(evt.currentTarget.value));
          }}
          required={true}
        />

        <MMDSelect
          label="Select the doctor's payment frequecy"
          value={paymentFrequency || initialPaymentFrequency}
          options={PAYMENT_FREQUENCIES}
          onChange={(evt) => {
            setPaymentFrequency(Number(evt.currentTarget.value));
          }}
        />

        <div className="columns">
          <div className="column is-2">
            <MMDButton
              text="Save"
              isSuccess
              onClick={(e) => {
                if (feePercentage) {
                  requestCreateSplitPercentage({
                    applicationFeePercentage: feePercentage,
                  });
                }

                if (paymentFrequency) {
                  requestCreatePaymentFrequency({ paymentFrequency });
                }
              }}
            />
          </div>
          <div className="column ">
            <MMDButton text="Cancel" isDanger onClick={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: MyState) => {
  return {
    loading: state.splitPercentage.loading,
    initialFeePercent: state.splitPercentage.currentSplitPercentage
      ? state.splitPercentage.currentSplitPercentage.applicationFeePercentage
      : 0,
    initialPaymentFrequency: state.paymentFrequency.currentPaymentFrequency
      ? state.paymentFrequency.currentPaymentFrequency.frequencyInWeeks
      : 1,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...SplitPercentageActions,
      ...PaymentFrecuencyActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfig);
