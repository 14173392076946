import { Immunization, Option, Organization, OrganizationType } from 'typings';
import {
  OrganizationFormValues,
  ConvertedOrganizationFormValues,
} from './types';

export const filterImmunizationOptions = (
  immunizations: Immunization[],
  selectedOptions: Option[],
) =>
  immunizations.filter(
    ({ id }) => !selectedOptions.some(({ value }) => value === id.toString()),
  );

export const mapOptionsForSelect = (
  immunizations: (Immunization | OrganizationType)[],
): Option[] =>
  immunizations.map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }));

const mapOptionsForBE = (options?: Option[]): number[] => {
  if (!options?.length) {
    return [];
  }

  return options.map(({ value }) => Number(value));
};

export const convertFormValues =
  (
    onSubmit: (values: ConvertedOrganizationFormValues) => void,
    initialValues?: Organization,
  ): ((values: OrganizationFormValues) => void) =>
  ({
    immunizationRequired,
    requiredImmunizations,
    recommendedImmunizations,
    promoCode,
    organizationTypeId,
    ...formValues
  }) => {
    const convertedValues =
      immunizationRequired === 'false'
        ? {
            ...formValues,
            organizationTypeId: Number(organizationTypeId.value),
            immunizationRequired: false,
          }
        : {
            ...formValues,
            organizationTypeId: Number(organizationTypeId.value),
            immunizationRequired: true,
            requiredImmunizations: mapOptionsForBE(requiredImmunizations),
            recommendedImmunizations: mapOptionsForBE(recommendedImmunizations),
          };

    const isPromoCodeAdded =
      promoCode === 'true' && !initialValues?.promoCodeId;

    const isPromoCodeDeleted =
      promoCode === 'false' && initialValues?.promoCodeId;

    if (isPromoCodeAdded || isPromoCodeDeleted) {
      convertedValues['promoCode'] = promoCode;
    }

    onSubmit(convertedValues);
  };
