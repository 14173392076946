import moment from 'moment';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { Allergy } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  allergy: Allergy;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const AllergyRow: FC<Props> = ({
  allergy,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(allergy.id);
  }, [allergy, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{allergy.name}</strong>
          <p>Created at: {moment(allergy.createdAt).format('LLLL')}</p>
          <p>Updated at: {moment(allergy.updatedAt).format('LLLL')}</p>
        </Media.Item>
      </Media>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${allergy.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default AllergyRow;
