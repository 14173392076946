import React, { Fragment } from 'react';

type PropTypes = {
  items?: any;
  title?: string;
  isObject?: boolean;
  byKey?: string;
};

const MMDList: React.FC<PropTypes> = ({
  title = '',
  items = [],
  isObject = false,
  byKey = '',
}) => {
  const newItems = isObject ? Object.keys(items) : items;

  const renderItemObject = (key) => {
    if (typeof items[key] !== 'boolean') {
      return items[key] ? (
        <li>
          {' '}
          - {key}: {items[key]}{' '}
        </li>
      ) : null;
    }

    return items[key] ? <li> - {key}</li> : null;
  };

  const renderArrayItem = (item) => {
    if (byKey) return <li> - {item[byKey]}</li>;

    return <li> - {item}</li>;
  };

  return (
    <Fragment>
      {title && <h6 className="has-text-weight-bold">{title}:</h6>}
      <ul>
        {newItems.length > 0 ? (
          newItems.map((e) =>
            isObject ? renderItemObject(e) : renderArrayItem(e),
          )
        ) : (
          <li>Empty</li>
        )}
      </ul>
    </Fragment>
  );
};

export default MMDList;
