import moment from 'moment';
import _ from 'lodash';
import MMDButton from 'components/MMDButton';
import { Link } from 'react-router-dom';
import { Dispatch, FC, HTMLProps, SetStateAction } from 'react';
import { Prescription } from '../../typings';

type PropTypes<T> = {
  label: string;
  titles: string[];
  dataTable?: T[];
  isTimeslot?: boolean;
  isCustomPrice?: boolean;
  isPrescriptions?: boolean;
  hasSessions?: boolean;
  hasSigninAttempts?: boolean;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  setFocusedPrescription?: Dispatch<SetStateAction<number>>;
  resendPrescription?: (id: any, payload: Partial<Prescription>) => void;
} & HTMLProps<HTMLInputElement>;

const MMDTable: FC<PropTypes<any>> = ({
  label,
  titles = [],
  dataTable = [],
  isTimeslot = false,
  isCustomPrice = false,
  isPrescriptions = false,
  hasSessions = false,
  hasSigninAttempts = false,
  setShowModal,
  setFocusedPrescription,
  resendPrescription,
}) => {
  const sortedDataTable =
    isTimeslot && _.orderBy(dataTable, (o: any) => o.start.dateTime, ['desc']);

  const renderTimeslot = (row, i) => (
    <tr key={i}>
      <th>{row.description.includes('BOOKED') ? 'BOOKED' : 'FREE'}</th>
      <th>{moment(row.start.dateTime).local().format('LLLL')}</th>
      <td>{moment(row.end.dateTime).local().format('LLLL')} </td>
      <td>{row.isFirstTimeConsultationSlot ? 'YES' : 'NO'}</td>
    </tr>
  );

  const renderCustomPrices = (row, i) => (
    <tr key={i}>
      <th>{row.description}</th>
      <th>{row.planType}</th>
      <th>{moment(row.createdAt).utc().format('LLLL')}</th>
      <td>{moment(row.updatedAt).utc().format('LLLL')} </td>
    </tr>
  );

  const getPharmacy = (row) => {
    if (row.booking) {
      return row.booking.pharmacy;
    }
    if (row.pharmacy) {
      return row.pharmacy;
    }
    if (row.patient && row.patient.pharmacy) {
      const patient = row.patient;
      const defaultPharmacy = patient.patientpharmacies
        ? patient.patientpharmacies.find((f) => f.isDefault)
        : null;
      const pharmacy = defaultPharmacy
        ? patient.pharmacies.find((f) => f.id === defaultPharmacy.pharmacyId)
        : patient.pharmacies[0];
      return pharmacy;
    }
    return null;
  };

  const renderPrescriptions = (row, i) => {
    const doctor = row.booking ? row.booking.doctor : row.doctor;
    const pharmacy = getPharmacy(row);
    return (
      <tr key={i}>
        <th>{row.createdAt}</th>
        <th>
          {doctor && <Link to={`/providers/${doctor.id}`}>{doctor.email}</Link>}
        </th>
        <th style={{ whiteSpace: 'pre-line' }}>
          {`Medication: ${row.name}
          Dosage: ${row.dosage}
          Quantity: ${row.quantity}
          Frequency: ${row.frequency}
          Method refills: ${row.methodRefills}
          Days of supply: ${row.daysOfsupply}
          Auto refills days: ${row.autoRefillsDays}`}
        </th>
        <th>
          {pharmacy && (
            <Link to={`/pharmacies/${pharmacy.id}`}>{pharmacy.name}</Link>
          )}
        </th>
        <th>
          {pharmacy && (
            <Link to={`/pharmacies/${pharmacy.id}`}>{pharmacy.fax}</Link>
          )}
        </th>
        <th>{row.status}</th>
        <th>{getActionButtons(row.status, i, row)}</th>
      </tr>
    );
  };

  const renderSessions = (row, i) => {
    const device = JSON.parse(row.additionalDeviceInfo);
    return (
      <tr key={i}>
        <th>{moment(row.signin).local().format('LLLL')}</th>
        <th>
          {row.signout
            ? moment(row.signout).local().format('LLLL')
            : 'Date not available - User may still be active or user is inactive and his session token will expire in Firestore'}
        </th>
        <th>{row.email}</th>
        <th>{row.ip}</th>
        <th>{row.location}</th>
        <th style={{ whiteSpace: 'pre-line' }}>
          {device.userAgent
            ? `UserAgent: ${device.userAgent}`
            : `System Version: ${device.systemVersion}
          Brand: ${device.brand}
          Build number: ${device.buildNumber}
          Device locale: ${device.deviceLocale}
          Device name: ${device.deviceName}
          Country: ${device.country}
          Carrier: ${device.carrier}
          Manufacturer: ${device.manufacturer}
          Device id: ${device.deviceId}
          System name: ${device.systemName}
          System Version: ${device.systemVersion}
          Timezone: ${device.timezone}
          uniqueId: ${device.uniqueId}`}
        </th>
        <th>{moment(row.createdAt).local().format('LLLL')}</th>
        <td>{moment(row.updatedAt).local().format('LLLL')} </td>
      </tr>
    );
  };

  const renderSigninAttempts = (row, i) => {
    const device = JSON.parse(row.additionalDeviceInfo);

    return (
      <tr key={i}>
        <th>{row.email}</th>
        <th>{row.ip}</th>
        <th>{row.location}</th>
        <th style={{ whiteSpace: 'pre-line' }}>
          {`System Version: ${device.systemVersion}
            Brand: ${device.brand}
            Build number: ${device.buildNumber}
            Device locale: ${device.deviceLocale}
            Device name: ${device.deviceName}
            Country: ${device.country}
            Carrier: ${device.carrier}
            Manufacturer: ${device.manufacturer}
            Device id: ${device.deviceId}
            System name: ${device.systemName}
            System Version: ${device.systemVersion}
            Timezone: ${device.timezone}
            uniqueId: ${device.uniqueId}`}
        </th>
        <th>{moment(row.createdAt).utc().format('LLLL')}</th>
        <td>{moment(row.updatedAt).utc().format('LLLL')} </td>
      </tr>
    );
  };

  const getActionButtons = (status, i, row) => {
    let render = null;
    switch (status) {
      case 'sent':
      case 'deny':
        break;
      case 'send_fax_error':
        render = (
          <div className="field is-grouped">
            <div className="control">
              <MMDButton
                text="Resend"
                isSuccess
                onClick={(e) => {
                  resendPrescription(row.id, row);
                }}
              />
            </div>
          </div>
        );
        break;
      case 'pending':
        render = (
          <div className="field is-grouped">
            <div className="control">
              <MMDButton
                text="Approve"
                isSuccess
                onClick={(e) =>
                  resendPrescription(undefined, { ...row, status: 'sent' })
                }
              />
            </div>
            <div className="control">
              <MMDButton
                text="Deny"
                isDanger
                onClick={(e) => {
                  setFocusedPrescription(i);
                  setShowModal(true);
                }}
              />
            </div>
          </div>
        );
        break;
    }
    return render;
  };

  return (
    <div className="table__wrapper">
      <label className="label">{label}</label>
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            {titles.map((title, i) => (
              <th key={i}>
                <abbr title={title}>{title}</abbr>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isTimeslot && sortedDataTable.map(renderTimeslot)}
          {isCustomPrice && dataTable.map(renderCustomPrices)}
          {isPrescriptions && dataTable.map(renderPrescriptions)}
          {hasSessions && dataTable.map(renderSessions)}
          {hasSigninAttempts && dataTable.map(renderSigninAttempts)}
        </tbody>
      </table>
    </div>
  );
};

export default MMDTable;
