import ImmunizationForm from './ImmunizationForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as ImmunizationsActions } from 'modules/immunizations.module';

const NewImmunization = () => {
  const { requestCreateImmunization } = useBoundedActions(ImmunizationsActions);

  return (
    <div className="container p-2">
      <ImmunizationForm onSubmit={requestCreateImmunization} />
    </div>
  );
};

export default NewImmunization;
