import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import OrganizationTypeForm from './OrganizationTypeForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectOrganizationTypeById,
  UpdateOrganizationTypePayload,
  Creators as OrganizationTypesActions,
} from 'modules/organization-types.module';

const EditOrganizationType = () => {
  const { id } = useParams();

  const organizationTypeToEdit = useSelector((state) =>
    selectOrganizationTypeById(state, id),
  );

  const { requestUpdateOrganizationType } = useBoundedActions(
    OrganizationTypesActions,
  );

  const onEditOrganizationType = (values: UpdateOrganizationTypePayload) =>
    requestUpdateOrganizationType({ ...values, id });

  return (
    <div className="container p-2">
      <MMDLoader loading={!organizationTypeToEdit} />

      {organizationTypeToEdit && (
        <OrganizationTypeForm
          onSubmit={onEditOrganizationType}
          organizationType={organizationTypeToEdit}
          isEdit
        />
      )}
    </div>
  );
};

export default EditOrganizationType;
