export function getExtension(b64: string) {
  let ext = '.png';
  if (b64.includes('jpeg')) {
    ext = '.jpeg';
  }
  if (b64.includes('jpg')) {
    ext = '.jpg';
  }
  return ext;
}
