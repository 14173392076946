import MMDForm from '../forms/MMDForm';
import MMDTextInput from '../forms/MMDTextInput';
import MMDRadio from '../forms/MMDRadio';
import MMDButton from '../MMDButton';
import { Form } from '@mrnkr/redux-saga-toolbox';
import {
  Creators as PatientsActions,
  patientsFormValidator,
} from '../../modules/patients.module';
import { GENDERS } from 'utils/constants';
import { Patient } from 'typings';
import { FC, FormEvent, useCallback } from 'react';
import { getCoordinatesString } from 'utils/getCoordinatesString';

type PropTypes = {
  patient: Patient;
};

export const PatientForm: FC<PropTypes> = ({ patient }) => {
  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['firstName'].name}
          value={form.fields['firstName'].value}
          label="First Name"
          valid={form.fields['firstName'].valid}
          dirty={form.fields['firstName'].dirty}
          placeholder="John"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['lastName'].name}
          label="Last Name"
          valid={form.fields['lastName'].valid}
          dirty={form.fields['lastName'].dirty}
          placeholder="Doe"
          value={form.fields['lastName'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['email'].name}
          label="Email"
          valid={form.fields['email'].valid}
          dirty={form.fields['email'].dirty}
          placeholder="Jhon@doe.com"
          value={form.fields['email'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['address'].name}
          label="Address"
          valid={form.fields['address'].valid}
          dirty={form.fields['address'].dirty}
          placeholder="Heidomon 415 av 18"
          value={form.fields['address'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['birthday'].name}
          label="Birthday"
          valid={form.fields['birthday'].valid}
          dirty={form.fields['birthday'].dirty}
          placeholder="MM-DD-YYYY"
          value={form.fields['birthday'].value}
          onChange={onChange}
        />
        <MMDTextInput
          name={form.fields['city'].name}
          label="City"
          valid={form.fields['city'].valid}
          dirty={form.fields['city'].dirty}
          placeholder="Virginia"
          value={form.fields['city'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['state'].name}
          label="State"
          valid={form.fields['state'].valid}
          dirty={form.fields['state'].dirty}
          placeholder="VA"
          value={form.fields['state'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['zipCode'].name}
          label="ZIP Code"
          valid={form.fields['zipCode'].valid}
          dirty={form.fields['zipCode'].dirty}
          placeholder="112233"
          value={form.fields['zipCode'].value}
          onChange={onChange}
          required={true}
        />

        <MMDTextInput
          name={form.fields['coordinates'].name}
          label="Coordinates"
          valid={form.fields['coordinates'].valid}
          dirty={form.fields['coordinates'].dirty}
          placeholder="[lat,long]"
          value={form.fields['coordinates'].value}
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['phone'].name}
          label="Phone number"
          valid={form.fields['phone'].valid}
          dirty={form.fields['phone'].dirty}
          placeholder="(999) 999-9999"
          value={form.fields['phone'].value}
          onChange={onChange}
          required={true}
        />

        <div className="mb-2">Original phone – {patient.phone}</div>

        <MMDTextInput
          name={form.fields['ssn'].name}
          label="Ssn"
          valid={form.fields['ssn'].valid}
          dirty={form.fields['ssn'].dirty}
          placeholder="+1234"
          value={form.fields['ssn'].value}
          onChange={onChange}
        />
        <MMDRadio
          name={form.fields['gender'].name}
          valid={form.fields['gender'].valid}
          dirty={form.fields['gender'].dirty}
          label="Gender"
          value={form.fields['gender'].value}
          answers={GENDERS}
          onChange={onChange}
        />
        <MMDButton className="mt-2" isSubmit isPrimary text="Update" />
      </div>
    ),
    [],
  );

  if (!patient) {
    return null;
  }

  const formConfig = {
    formName: 'update-patient-form',
    fields: {
      id: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: patient.email,
      address: patient.address,
      birthday: patient.birthday,
      city: patient.city,
      state: patient.state,
      zipCode: patient.zipCode,
      coordinates: getCoordinatesString(patient.coordinates),
      phone: patient.phone,
      gender: patient.gender,
      avatarApproved: patient.avatarApproved,
      ssn: patient.ssn,
    },
    validator: patientsFormValidator,
    onSubmit: (values) => {
      return PatientsActions.requestUpdatePatient({
        id: values['id'],
        firstName: values['firstName'],
        lastName: values['lastName'],
        email: values['email'],
        address: values['address'],
        birthday: values['birthday'],
        city: values['city'],
        state: values['state'],
        zipCode: values['zipCode'],
        coordinates: values['coordinates'],
        phone: values['phone'],
        gender: values['gender'],
        avatarApproved: values['avatarApproved'],
        ssn: values['ssn'],
      });
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PatientForm;
