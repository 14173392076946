import { FC, useRef, useState } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormUnregister,
} from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import RemoveIcon from 'assets/remove-icon.png';
import { QuestionnaireFormValue } from 'typings';
import MMDTextInput from 'components/forms/MMDTextInput';

type Props = {
  register: UseFormRegister<QuestionnaireFormValue>;
  unregister: UseFormUnregister<QuestionnaireFormValue>;
  formErrors: FieldErrors<QuestionnaireFormValue>;
  questionOrder: number;
  readOnly: boolean;
  initialAnswersCount?: number;
};

const getInitialAnswers = (count?: number) => {
  if (count === undefined) {
    return [1, 2];
  }

  return Array.from({ length: count }, (_, i) => i + 1);
};

const CustomAnswers: FC<Props> = ({
  register,
  formErrors,
  questionOrder,
  readOnly,
  unregister,
  initialAnswersCount,
}) => {
  const [answerOptions, setAnswerOptions] = useState(
    getInitialAnswers(initialAnswersCount),
  );
  const answerOptionId = useRef(answerOptions.length + 1);

  const getOnRemoveOption = (optionToRemove: number) => () => {
    unregister(`blocks.${questionOrder}.answer.${optionToRemove}`);
    setAnswerOptions((options) =>
      options.filter((option) => option !== optionToRemove),
    );
  };

  const onAddAnswer = () =>
    setAnswerOptions((answers) => [...answers, answerOptionId.current++]);

  return (
    <div>
      <strong>Answer options</strong>

      {answerOptions.map((option, index) => {
        return (
          <div className="questionnaire-block__answer" key={option}>
            <MMDTextInput
              required
              label={`${index + 1} option`}
              {...register(`blocks.${questionOrder}.answer.${option}`, {
                required: true,
                setValueAs: (value: string) => value.trim(),
              })}
              valid={!formErrors['blocks']?.[questionOrder]?.answer?.[option]}
              readOnly={readOnly}
            />

            {answerOptions.length > 2 && !readOnly && (
              <div
                onClick={getOnRemoveOption(option)}
                className="questionnaire-block__answer__remove-icon mr-2"
              >
                <img src={RemoveIcon} alt="remove-icon" />
              </div>
            )}
          </div>
        );
      })}

      {!readOnly && (
        <MMDButton text="Add answer option" onClick={onAddAnswer} />
      )}
    </div>
  );
};

export default CustomAnswers;
