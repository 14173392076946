import { FC } from 'react';
import { Link } from 'react-router-dom';

import MMDModal from 'components/MMDModal';
import { useToggle } from 'hooks/useToggle';
import MMDButton from 'components/MMDButton';
import { ActionsProviderProps } from './types';
import { APPROVED, REJECTED, USER_TYPE } from 'utils/constants';
import { getCoordinatesString } from 'utils/getCoordinatesString';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';

export const ActionsProvider: FC<ActionsProviderProps> = ({
  provider,
  setModalState,
  providerTeacher,
  requestStudentUnlink,
  updateProviderHandler,
}) => {
  const [isUnlinkModalVisible, toggleUnlinkModal] = useToggle();

  const status = provider.status;
  const coordinates = getCoordinatesString(provider?.coordinates);
  const firstButtonArgs = {
    label:
      status === 'approved'
        ? 'Set pending'
        : `Approve ${provider.teacherId ? 'student' : 'provider'}`,
    updateProviderArgs:
      status === 'approved'
        ? {
            approved: REJECTED,
            adminVerified: REJECTED,
            status: 'pending',
            coordinates,
          }
        : {
            approved: APPROVED,
            adminVerified: APPROVED,
            status: 'approved',
            coordinates,
          },
    setModalState:
      status === 'approved'
        ? { visible: true, action: { type: 'pending', to: USER_TYPE.PROVIDER } }
        : ({
            visible: true,
            action: { type: 'approved', to: USER_TYPE.PROVIDER },
          } as any),
  };

  const secondButtonArgs = {
    label:
      status === 'rejected'
        ? 'Set pending'
        : `Reject ${provider.teacherId ? 'student' : 'provider'}`,
    setModalState:
      status === 'rejected'
        ? { visible: true, action: { type: 'pending', to: USER_TYPE.PROVIDER } }
        : ({
            visible: true,
            action: { type: 'rejected', to: USER_TYPE.PROVIDER },
          } as any),
  };

  const routeParams = [] as any;
  routeParams['teacherId'] = null;
  const search = prepareQueryWithOffset(0, routeParams);

  const canConvertToStudent =
    !provider?.events?.length && !provider?.ratings?.length;

  const onUnlinkStudent = () => {
    toggleUnlinkModal();
    requestStudentUnlink({ id: provider.id });
  };

  return (
    <div>
      <MMDButton
        className="my-2"
        text={firstButtonArgs.label}
        isSuccess
        onClick={(e) => {
          updateProviderHandler(e, {
            ...firstButtonArgs.updateProviderArgs,
            onSuccessUpdate: () => setModalState(firstButtonArgs.setModalState),
          });
        }}
      />
      {canConvertToStudent && (
        <Link to={`/convert-to-student/${provider.id}${search}`}>
          <MMDButton
            className="my-2"
            text={
              provider.teacherId
                ? 'Change Teacher'
                : `${
                    provider.status === 'approved'
                      ? 'Convert to student'
                      : 'Approve and Convert to student'
                  }`
            }
            isSuccess
          />
        </Link>
      )}
      <MMDButton
        className="mt-2"
        text={secondButtonArgs.label}
        isDanger
        onClick={() => setModalState(secondButtonArgs.setModalState)}
      />
      {provider.teacherId && (
        <MMDButton
          isDanger
          className="my-2"
          onClick={toggleUnlinkModal}
          text="Unlink student from provider"
        />
      )}

      <MMDModal
        visible={isUnlinkModalVisible}
        onRequestClose={toggleUnlinkModal}
      >
        <div className="has-text-centered">
          <big>
            Are you sure to unlink this student from provider
            <span className="has-text-danger">
              &nbsp;
              {providerTeacher?.firstName} {providerTeacher?.lastName}
            </span>
            ?
          </big>
        </div>

        <div className="flex is-justify-content-space-evenly mt-4">
          <MMDButton
            text="Cancel"
            isPrimary={false}
            onClick={toggleUnlinkModal}
          />

          <MMDButton isDanger text="Confirm" onClick={onUnlinkStudent} />
        </div>
      </MMDModal>
    </div>
  );
};
