import { useSelector } from 'react-redux';
import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';

import { GENDERS } from 'utils/constants';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import { Provider, Speciality } from 'typings';
import MMDRadio from 'components/forms/MMDRadio';
import MMDSelect from 'components/forms/MMDSelect';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';
import { getCoordinatesString } from 'utils/getCoordinatesString';
import { Creators as SpecialitiesActions } from 'modules/specialities.module';
import {
  selectCredentials,
  Creators as CredentialsActions,
} from 'modules/credentials.module';
import {
  providersFormValidator,
  Creators as ProvidersActions,
} from 'modules/providers.module';

type PropTypes = {
  ready: boolean;
  provider: Provider;
  specialities: Speciality[];
  isSpecialitiesLoading: boolean;
};

const CheckVet = ({
  setIsVet,
  credentials,
  form,
}: {
  setIsVet: (arg: boolean) => void;
  credentials: any[];
  form;
}) => {
  useEffect(() => {
    let vetSelected = false;
    const selectedCredential = credentials.find(
      (c) => c.label == form.fields['credential'].value,
    );
    if (selectedCredential && selectedCredential['category']) {
      (selectedCredential as any).category.map((cat) => {
        if (cat.name == 'Veterinary') {
          vetSelected = true;
        }
      });
    }
    form.fields['isVet'].value = vetSelected ? 't' : 'f';
    setIsVet(vetSelected);
  }, [form.fields['credential']['value'], credentials]);
  return null;
};

export const ProviderForm: FC<PropTypes> = ({
  ready,
  provider,
  specialities,
  isSpecialitiesLoading,
}) => {
  const [npiRequired, setNpiRequired] = useState(provider.credential !== 'DVM');
  const [isVet, setIsVet] = useState<boolean>();

  const credentials = useSelector(selectCredentials);

  const isSuperAdmin = useIsSuperAdminCheck();

  const { requestSpecialitiesForCredential, requestCredentialsSearch } =
    useBoundedActions({
      ...SpecialitiesActions,
      ...CredentialsActions,
    });

  const isSpecialitiesRequested = useRef(false);

  useEffect(() => {
    requestCredentialsSearch({ credential: '__limit=10000&__sort=-updatedAt' });
  }, []);

  useEffect(() => {
    if (!provider || !provider.credential) {
      return;
    }

    if (isSpecialitiesRequested.current) {
      return;
    }

    requestSpecialitiesForCredential({ credential: provider.credential });
    isSpecialitiesRequested.current = true;
  }, [requestSpecialitiesForCredential, provider]);

  const renderFormControls = useCallback(
    (
      form,
      onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
    ) => {
      const selectCredential = (e: FormEvent<HTMLSelectElement>) => {
        const input = e.target as HTMLSelectElement;
        const selectedCredential = credentials.find(
          (c) => c.value === input.value,
        );

        if (selectedCredential) {
          requestSpecialitiesForCredential({
            credential: selectedCredential.value,
          });
        }
        onChange(e);
      };

      const selectSpecialty = (e: FormEvent<HTMLSelectElement>) => {
        const input = e.target as HTMLSelectElement;
        const selectedSpecialty = specialities.find(
          (s) => s.name === input.value,
        );

        if (selectedSpecialty) {
          onChange({
            target: {
              name: 'monthlyPrice',
              value: selectedSpecialty.monthlyPrice,
            },
          } as any);
          onChange({
            target: {
              name: 'firstTimePrice',
              value: selectedSpecialty.firstTimePrice,
            },
          } as any);
          onChange({
            target: {
              name: 'oneTimePrice',
              value: selectedSpecialty.oneTimePrice,
            },
          } as any);
          onChange({
            target: {
              name: 'isPsychiatrist',
              value: selectedSpecialty.isPsychiatry.toString(),
            },
          } as any);
        }

        onChange(e);
      };

      const selectedSpecialtyObject = specialities.find(
        (s) => s.name === form.fields['speciality'].value,
      );

      return (
        <div className="container p-2">
          <MMDTextInput
            name={form.fields['firstName'].name}
            value={form.fields['firstName'].value}
            label="First Name"
            valid={form.fields['firstName'].valid}
            dirty={form.fields['firstName'].dirty}
            placeholder="John"
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['lastName'].name}
            label="Last Name"
            valid={form.fields['lastName'].valid}
            dirty={form.fields['lastName'].dirty}
            placeholder="Doe"
            value={form.fields['lastName'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['email'].name}
            label="Email"
            valid={form.fields['email'].valid}
            dirty={form.fields['email'].dirty}
            placeholder="Jhon@doe.com"
            value={form.fields['email'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['address'].name}
            label="Address"
            valid={form.fields['address'].valid}
            dirty={form.fields['address'].dirty}
            placeholder="Heidomon 415 av 18"
            value={form.fields['address'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['birthday'].name}
            label="Birthday"
            valid={form.fields['birthday'].valid}
            dirty={form.fields['birthday'].dirty}
            placeholder="MM-DD-YYYY"
            value={form.fields['birthday'].value}
            onChange={onChange}
          />
          <MMDTextInput
            name={form.fields['city'].name}
            label="City"
            valid={form.fields['city'].valid}
            dirty={form.fields['city'].dirty}
            placeholder="Virginia"
            value={form.fields['city'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['state'].name}
            label="State"
            valid={form.fields['state'].valid}
            dirty={form.fields['state'].dirty}
            placeholder="VA"
            value={form.fields['state'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['zipCode'].name}
            label="zipCode"
            valid={form.fields['zipCode'].valid}
            dirty={form.fields['zipCode'].dirty}
            placeholder="zipCode"
            value={form.fields['zipCode'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['coordinates'].name}
            label="Coordinates"
            valid={form.fields['coordinates'].valid}
            dirty={form.fields['coordinates'].dirty}
            placeholder="[lat,long]"
            value={form.fields['coordinates'].value}
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['phone'].name}
            label="Phone number"
            valid={form.fields['phone'].valid}
            dirty={form.fields['phone'].dirty}
            placeholder="(999) 999-9999"
            value={form.fields['phone'].value}
            onChange={onChange}
            required={true}
          />

          <div className="mb-2">Original phone – {provider.phone}</div>

          <MMDTextInput
            name={form.fields['npi'].name}
            label="NPI"
            valid={form.fields['npi'].valid}
            dirty={form.fields['npi'].dirty}
            placeholder="12456789"
            disabled={!isSuperAdmin}
            value={form.fields['npi'].value}
            onChange={onChange}
            required={npiRequired}
          />
          <MMDTextInput
            name={form.fields['pln'].name}
            label="PLN"
            valid={form.fields['pln'].valid}
            dirty={form.fields['pln'].dirty}
            placeholder="12456789"
            disabled={!isSuperAdmin}
            value={form.fields['pln'].value}
            onChange={onChange}
            required={true}
          />
          {!provider.teacherId && (
            <MMDTextInput
              name={form.fields['oneTimePrice'].name}
              label="One time price"
              valid={form.fields['oneTimePrice'].valid}
              dirty={form.fields['oneTimePrice'].dirty}
              placeholder="20$"
              value={form.fields['oneTimePrice'].value}
              onChange={onChange}
              required={true}
            />
          )}

          {form.fields['isPsychiatrist'].value === 'true' ? (
            <MMDTextInput
              name={form.fields['firstTimePrice'].name}
              label={
                selectedSpecialtyObject &&
                selectedSpecialtyObject.doctorType === 'psychology'
                  ? 'Extended consultation price'
                  : 'First time price'
              }
              valid={form.fields['firstTimePrice'].valid}
              dirty={form.fields['firstTimePrice'].dirty}
              placeholder="20$"
              value={form.fields['firstTimePrice'].value}
              onChange={onChange}
              required={form.fields['isPsychiatrist'].value === 'true'}
            />
          ) : null}

          {!provider.teacherId && (
            <MMDTextInput
              name={form.fields['monthlyPrice'].name}
              label="Monthly Price"
              valid={form.fields['monthlyPrice'].valid}
              dirty={form.fields['monthlyPrice'].dirty}
              placeholder="20$"
              value={form.fields['monthlyPrice'].value}
              onChange={onChange}
              required={true}
            />
          )}

          {!provider.teacherId && (
            <MMDTextInput
              name={form.fields['splitPercentage'].name}
              label="Split payment percentage"
              valid={form.fields['splitPercentage'].valid}
              dirty={form.fields['splitPercentage'].dirty}
              placeholder=""
              value={form.fields['splitPercentage'].value}
              onChange={onChange}
              required={true}
            />
          )}
          <MMDSelect
            name={form.fields['credential'].name}
            valid={form.fields['credential'].valid}
            dirty={form.fields['credential'].dirty}
            label="Select Credential"
            value={form.fields['credential'].value}
            options={credentials}
            onChange={selectCredential}
            required={true}
          />
          {!provider.teacherId && (
            <MMDSelect
              name={form.fields['speciality'].name}
              valid={form.fields['speciality'].valid}
              dirty={form.fields['speciality'].dirty}
              label="Select Specialty"
              value={form.fields['speciality'].value}
              options={specialities.map(({ id, name }) => ({ id, name }))}
              onChange={selectSpecialty}
              disabled={isSpecialitiesLoading}
            />
          )}
          {isVet && (
            <MMDTextInput
              name={form.fields['dea'].name}
              label="DEA"
              valid={form.fields['dea'].valid}
              dirty={form.fields['dea'].dirty}
              placeholder="12456789"
              value={form.fields['dea'].value}
              onChange={onChange}
              required={false}
            />
          )}
          {selectedSpecialtyObject && selectedSpecialtyObject.isPsychiatry && (
            <MMDCheckbox
              name={form.fields['isPsychiatrist'].name}
              label="Has additional time slot?"
              dirty={form.fields['isPsychiatrist'].dirty}
              value={form.fields['isPsychiatrist'].value as 'true' | 'false'}
              onChange={onChange}
            />
          )}

          <MMDRadio
            name={form.fields['gender'].name}
            valid={form.fields['gender'].valid}
            dirty={form.fields['gender'].dirty}
            value={form.fields['gender'].value}
            label="Gender"
            answers={GENDERS}
            onChange={onChange}
          />
          {!provider.teacherId && (
            <MMDTextInput
              name={form.fields['subsciptionPlanId'].name}
              label="Subcription plan"
              valid={form.fields['subsciptionPlanId'].valid}
              dirty={form.fields['subsciptionPlanId'].dirty}
              placeholder="subcription plan"
              value={form.fields['subsciptionPlanId'].value}
              onChange={onChange}
              required={true}
            />
          )}

          {!provider.teacherId && (
            <span>
              The subscription plan ID must be taken from Stripe. Changing the
              specialty will trigger an automatic update to this field. Manually
              updating it is utterly discouraged.
            </span>
          )}

          <MMDButton className="mt-4" isSubmit isPrimary text="Update" />
          <CheckVet setIsVet={setIsVet} credentials={credentials} form={form} />
        </div>
      );
    },
    [requestSpecialitiesForCredential, specialities, isVet],
  );

  if (!provider || !ready) {
    return null;
  }

  const formConfig = {
    formName: 'update-provider-form',
    fields: {
      id: provider.id,
      firstName: provider.firstName,
      lastName: provider.lastName,
      email: provider.email,
      address: provider.address,
      birthday: provider.birthday,
      city: provider.city,
      state: provider.state,
      phone: provider.phone,
      npi: provider.npi,
      dea: provider.dea,
      isVet: 'f',
      pln: provider.pln,
      gender: provider.gender,
      speciality:
        provider.profile &&
        provider.profile.specialities &&
        provider.profile.specialities.length
          ? provider.profile.specialities[0]
          : null,
      credential: provider.credential,
      oneTimePrice: provider.oneTimePrice,
      firstTimePrice: provider.firstTimePrice,
      monthlyPrice: provider.monthlyPrice,
      isPsychiatrist: provider.isPsychiatrist.toString(),
      avatarApproved: provider.avatarApproved,
      approved: provider.approved,
      subsciptionPlanId: provider.subsciptionPlanId,
      splitPercentage: provider.splitPercentage,
      zipCode: provider.zipCode,
      coordinates: getCoordinatesString(provider.coordinates),
      teacherId: provider.teacherId,
    },
    validator: providersFormValidator,
    onSubmit: (values) => {
      return ProvidersActions.requestUpdateProvider({
        id: values['id'],
        firstName: values['firstName'],
        lastName: values['lastName'],
        email: values['email'],
        address: values['address'],
        birthday: values['birthday'],
        city: values['city'],
        state: values['state'],
        phone: values['phone'],
        npi: values['npi'],
        dea: values['dea'],
        pln: values['pln'],
        gender: values['gender'],
        profile: {
          ...provider.profile,
          specialities: values['speciality'] ? [values['speciality']] : [],
        },
        credential: values['credential'],
        oneTimePrice: values['oneTimePrice'],
        firstTimePrice: values['firstTimePrice'],
        monthlyPrice: values['monthlyPrice'],
        isPsychiatrist: values['isPsychiatrist'] === 'true',
        avatarApproved: values['avatarApproved'],
        approved: values['approved'],
        subsciptionPlanId: values['subsciptionPlanId'],
        splitPercentage: values['splitPercentage'],
        zipCode: values['zipCode'],
        coordinates: values['coordinates'],
      });
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default ProviderForm;
