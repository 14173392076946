import { FC } from 'react';

import { Booking } from 'typings';

const BookingQuestionnaire: FC<Pick<Booking, 'answers'>> = ({ answers }) => (
  <>
    {answers.map((answer) => (
      <div className="column is-full" key={answer.id}>
        <h5 className="has-text-weight-bold">{answer.question.body}: </h5>
        {answer.body ?? 'No answer'}
      </div>
    ))}
  </>
);

export default BookingQuestionnaire;
