import { forwardRef, HTMLProps } from 'react';

type PropTypes = {
  label: string;
  rows?: number;
  cols?: number;
  valid?: boolean;
  dirty?: boolean;
} & HTMLProps<HTMLTextAreaElement>;

const MMDTextArea = forwardRef<HTMLTextAreaElement, PropTypes>(
  (
    {
      label,
      valid = true,
      dirty = true,
      rows = 20,
      cols = 15,
      value = '',
      placeholder = '',
      ...rest
    },
    ref,
  ) => {
    const notValid = !valid && dirty;

    return (
      <div className="field">
        <label className="label">{label}</label>
        <div className="control">
          <textarea
            ref={ref}
            style={{ height: '50%' }}
            placeholder={placeholder}
            className={`input ${notValid ? 'is-danger' : ''}`}
            rows={rows}
            cols={cols}
            value={value}
            {...rest}
          />
        </div>
      </div>
    );
  },
);

MMDTextArea.displayName = 'MMDTextArea';

export default MMDTextArea;
