import { useSelector } from 'react-redux';

import { ADMIN_ROLES } from 'utils/constants';
import { selectUserInfo } from 'modules/auth.module';

export const useIsSuperAdminCheck = () => {
  const currentUserInfo = useSelector(selectUserInfo);

  return currentUserInfo?.roleId === ADMIN_ROLES.SUPER_ADMIN;
};
