import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Route as RouteDefinition } from './routes';
import { SUPER_ADMIN_ROUTES } from 'utils/constants';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';

interface Props {
  routes: RouteDefinition[];
}

// The routes are declared in this way './routes/routes'
const AppRoutes: FC<Props> = ({ routes }) => {
  const isSuperAdmin = useIsSuperAdminCheck();

  const filterRoutes = (route: RouteDefinition): boolean =>
    route.component &&
    (isSuperAdmin ? true : !SUPER_ADMIN_ROUTES.includes(route.path));

  return (
    <Routes>
      {routes.filter(filterRoutes).map(({ path, component }, i) => (
        <Route key={i} path={path} Component={component} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
