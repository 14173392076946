import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { PrivacyPolicy } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  privacyPolicy: PrivacyPolicy;
  targetPath: string;
  onSendLive: (id: number) => void;
}

export const PrivacyPolicyRow: FC<Props> = ({ privacyPolicy, onSendLive }) => {
  const requestSendLive = useCallback(
    () => onSendLive(privacyPolicy.id),
    [privacyPolicy, onSendLive],
  );

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>ID: {privacyPolicy.id}</strong>
          <p>Live: {privacyPolicy.sendLive ? 'Yes' : 'No'}</p>
          {privacyPolicy.userType && (
            <p> User type: {privacyPolicy.userType}</p>
          )}
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        {!privacyPolicy.sendLive && (
          <Columns.Column size={1}>
            <MMDButton
              className="mt-2"
              isPrimary
              onClick={requestSendLive}
              text="Send live"
            />
          </Columns.Column>
        )}

        <Columns.Column size={1}>
          <Link
            state={{ html: privacyPolicy.content }}
            to={{
              pathname: '/previewHtml',
            }}
          >
            <MMDButton className="mt-2" isPrimary text="Preview" />
          </Link>
        </Columns.Column>

        {!privacyPolicy.sendLive ? (
          <Columns.Column size={1}>
            <Link to={`/privacy-policy/${privacyPolicy.id}`}>
              <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
            </Link>
          </Columns.Column>
        ) : null}
      </Columns>
    </Box>
  );
};

export default PrivacyPolicyRow;
