import { FC } from 'react';
import moment from 'moment';
import { Box, Heading, Media } from 'react-bulma-components';

import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  UserInvite,
  UserInviteStatus,
  Creators as UsersInvitesActions,
} from 'modules/users-invites.module';

const STATUS_COLORS: { [key in UserInviteStatus]: string } = {
  accepted: '#4caf50',
  pending: '#ff9800',
  rejected: '#ff0000',
  canceled: '#808080',
};

const InviteRow: FC<UserInvite> = ({ email, createdAt, status, id }) => {
  const isInvitePending = status === 'pending';

  const { cancelUserInvite } = useBoundedActions(UsersInvitesActions);

  const onCancelInvite = () => cancelUserInvite({ email, id });

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{email}</strong>

          <Heading subtitle size={6} renderAs="h6">
            Created at: {moment(createdAt).format('MM/DD/yy')}
          </Heading>

          <Heading subtitle size={6} renderAs="h6">
            Status:{' '}
            <span style={{ color: STATUS_COLORS[status] }}>
              {status.toUpperCase()}
            </span>
          </Heading>

          {isInvitePending && (
            <MMDButton text="Cancel" onClick={onCancelInvite} />
          )}
        </Media.Item>
      </Media>
    </Box>
  );
};

export default InviteRow;
