import { Dictionary } from '@mrnkr/redux-saga-toolbox';

export function prepareQueryWithOffset(
  nextOffset: number,
  params: Dictionary<string> = {},
): string {
  let query = '';

  Object.keys(params)
    .filter((key) => !['__limit', '__offset'].includes(key))
    .forEach((key) => {
      const value = params[key];
      if (value || value === null) {
        query += `${key}=${value}&`;
      }
    });

  return `?${query}__offset=${nextOffset}`;
}
