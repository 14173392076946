import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import {
  UserFields,
  selectUserInvitationLoading,
  Creators as AdminInvitationActions,
} from 'modules/user-invitation.module';
import RegisterUserForm from './RegisterUserForm';

const extractNumbersFromString = (maskedPhoneString: string) =>
  maskedPhoneString.match(/\d+/g).map(Number).join('');

const UserInvitation = () => {
  const [isInvitationAccepted, setIsInvitationAccepted] = useState(false);

  const { rejectInvite, acceptInvite } = useBoundedActions({
    ...AdminInvitationActions,
  });

  const location = useLocation();

  const { email } = paramsToDictionary(location.search);

  const onRejectInvite = () => {
    setIsInvitationAccepted(false);
    rejectInvite({ email });
  };

  const onAcceptInvite = () => setIsInvitationAccepted(true);

  const getOnRegister =
    () =>
    ({ phone, ...values }: Omit<UserFields, 'email'>) => {
      acceptInvite({
        email,
        phone: extractNumbersFromString(phone),
        ...values,
      });
    };

  const isInvitationRequestLoading = useSelector(selectUserInvitationLoading);

  return (
    <div className="container p-2 content is-justify-content-center is-flex-direction-column">
      <h1 className="has-text-centered">You are invited to be an admin</h1>

      <div className="mt-6 flex is-justify-content-center">
        {!isInvitationAccepted ? (
          <>
            <MMDButton
              isSecondary
              text="Reject"
              className="mr-6"
              isPrimary={false}
              onClick={onRejectInvite}
              disabled={isInvitationRequestLoading}
            />

            <MMDButton text="Accept" onClick={onAcceptInvite} />
          </>
        ) : (
          <RegisterUserForm onSubmit={getOnRegister()} />
        )}
      </div>
    </div>
  );
};

export default UserInvitation;
