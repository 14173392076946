import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDModal from 'components/MMDModal';
import MMDLoader from 'components/MMDLoader';
import MMDPager from 'components/MMDPager';
import { UserRow } from 'components/MMDUserRow';
import {
  AdminToolsState,
  Creators as AdminToolsActions,
} from '../modules/adminTools.module';
import {
  Creators as ProvidersActions,
  providerSelectors,
} from '../modules/providers.module';
import { MyState } from '../store';
import { Provider } from '../typings';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { push } from 'redux-first-history';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDSelect from '../components/forms/MMDSelect';
import MMDSearch from 'components/MMDSearch';
import _ from 'lodash';
import MMDButton from 'components/MMDButton';
import { Level } from 'react-bulma-components';

type Props = {
  providers: Provider[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  adminTools: AdminToolsState;
  push: typeof push;
} & typeof ProvidersActions &
  typeof AdminToolsActions;

const Providers: React.FC<Props> = ({
  providers,
  count,
  routeParams,
  push,
  requestResetPassword,
  loading,
  adminTools,
}) => {
  const [search, setSearch] = React.useState(undefined);
  const [showModal, setShowModal] = React.useState(false);
  const [avatarApproved, setImageState] = React.useState('all');
  const { resetPassword } = adminTools;

  const gotoPage = React.useCallback(
    (nextOffset: number) => {
      push(`providers${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const applyFilter = React.useCallback(() => {
    if (avatarApproved === 'all') {
      const newRouteParams = _.omit(routeParams, ['avatarApproved']);
      push(`providers${prepareQueryWithOffset(0, newRouteParams)}`);
    } else {
      avatarApproved !== undefined &&
        (routeParams.avatarApproved = avatarApproved);
      push(
        `providers${prepareQueryWithOffset(
          isNaN(parseInt(routeParams.__offset))
            ? 0
            : parseInt(routeParams.__offset),
          routeParams,
        )}`,
      );
    }
  }, [push, avatarApproved, search, routeParams]);

  const applySearch = React.useCallback(() => {
    let newRouteParams = { ...routeParams, __offset: '0' };
    if (search) {
      newRouteParams['search'] = search;
    } else {
      // @ts-ignore
      newRouteParams = _.omit(routeParams, ['search']);
    }
    push(
      `providers${prepareQueryWithOffset(
        isNaN(parseInt(newRouteParams.__offset))
          ? 0
          : parseInt(newRouteParams.__offset),
        newRouteParams,
      )}`,
    );
  }, [push, search, avatarApproved, routeParams]);

  const clearFilter = React.useCallback(() => {
    setImageState('all');
    push(`providers`);
    setSearch('');
  }, [push, avatarApproved]);

  return (
    <>
      <MMDLoader loading={loading || resetPassword.loading} />
      <div className="container p-2">
        <Level alignItems="flex-end">
          <Level.Side align="left" alignItems="flex-end">
            <Level.Item>
              <MMDSelect
                label="Image status"
                value={avatarApproved}
                options={[
                  { id: 1, name: 'All', value: 'all' },
                  { id: 1, name: 'Pending', value: 'false' },
                  { id: 2, name: 'Approved', value: 'true' },
                ]}
                type={'generic'}
                onExternalChange={(e) => {
                  setImageState(e.currentTarget.value);
                }}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Filter" isDanger onClick={applyFilter} />
            </Level.Item>
            <MMDSearch
              initialValue={search}
              onChange={(e) => {
                setSearch(
                  e.currentTarget.value !== ''
                    ? e.currentTarget.value
                    : undefined,
                );
              }}
              onSearch={() => {
                applySearch();
              }}
            />
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
        </Level>
        {providers.map((item: Provider) => (
          <UserRow
            user={item}
            key={item.id}
            targetPath={'providers'}
            onClickResetPassword={requestResetPassword}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
      <MMDModal
        visible={(resetPassword.success || resetPassword.error) && showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        {resetPassword.error && showModal && (
          <p>{resetPassword.error.message}</p>
        )}
        {resetPassword.success && showModal && (
          <p>{'The email was sent successfully'}</p>
        )}
      </MMDModal>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  providers: providerSelectors.selectAll(state.providers),
  loading: state.providers.loading,
  count: state.providers.count,
  routeParams: paramsToDictionary(state.router.location.search),
  resetPassword: state.adminTools.resetPassword,
  adminTools: state.adminTools,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...ProvidersActions,
      ...AdminToolsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
