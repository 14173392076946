import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface DropdownItem {
  text: string;
  href?: string;
  onClick?: () => void;
}

type Props = {
  title: string;
  className?: string;
  items: DropdownItem[];
  isPrimary?: boolean;
};

const MMDDropdown: FC<Props> = ({
  isPrimary = false,
  items,
  title,
  className = '',
}) => {
  const renderItem = ({ text, onClick, href }: DropdownItem, index: number) => {
    if (onClick) {
      return (
        <div
          onClick={onClick}
          key={index}
          className="dropdown-item is-hoverable"
        >
          {text}
        </div>
      );
    }

    if (href) {
      return (
        <Link key={href + index} to={href} className="dropdown-item">
          {text}
        </Link>
      );
    }

    return (
      <div
        key={index}
        className="dropdown-item is-hoverable is-hovered  is-hoverable"
      >
        {text}
      </div>
    );
  };

  return (
    <div className={`dropdown is-hoverable ${className}`}>
      <div className="dropdown-trigger">
        <div
          className={`button ${isPrimary ? 'is-primary' : 'is-success'}`}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span>{title}</span>

          <div className="ml-2 flex is-justify-content-center items-center">
            <span className="arrow down" />
          </div>
        </div>
      </div>

      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">{items.map(renderItem)}</div>
      </div>
    </div>
  );
};

export default MMDDropdown;
