import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push as reduxPush } from 'redux-first-history';

import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import BookingRow from 'pages/bookings-public/BookingPublicRow';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  selectBookingsPublic,
  selectBookingsPublicState,
} from 'modules/bookings-public.module';

export const BookingsPublic: FC = () => {
  const location = useLocation();

  const routeParams = paramsToDictionary(location.search);

  const bookingsPublic = useSelector(selectBookingsPublic);

  const { count, loading } = useSelector(selectBookingsPublicState);

  const { push } = useBoundedActions({
    push: reduxPush,
  });

  const gotoPage = useCallback(
    (nextOffset: number) =>
      push(`bookings-public${prepareQueryWithOffset(nextOffset, routeParams)}`),
    [push, routeParams],
  );

  const isNoBookings = !bookingsPublic?.length;

  return (
    <>
      <MMDLoader loading={loading} />

      <div className="container p-2">
        {isNoBookings ? (
          <span>No bookings</span>
        ) : (
          bookingsPublic.map((booking) => (
            <BookingRow key={booking.id} booking={booking} />
          ))
        )}
      </div>

      {!isNoBookings && (
        <MMDPager
          itemCount={count}
          onChange={gotoPage}
          currentOffset={routeParams.__offset}
        />
      )}
    </>
  );
};

export default BookingsPublic;
