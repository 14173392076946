import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push as reduxPush } from 'redux-first-history';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MyState } from 'store';
import { Provider } from 'typings';
import MMDModal from 'components/MMDModal';
import MMDPager from 'components/MMDPager';
import { APPROVED } from 'utils/constants';
import MMDLoader from 'components/MMDLoader';
import MMDSearch from 'components/MMDSearch';
import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { selectAdminToolsState } from 'modules/adminTools.module';
import { getCoordinatesString } from 'utils/getCoordinatesString';
import MMDSelectableUserRow from 'components/MMDSelectableUserRow';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  selectAllProviders,
  selectProviderById,
  selectProvidersState,
  Creators as ProvidersActions,
} from 'modules/providers.module';
import { Level } from 'react-bulma-components';

const ConvertToStudent = () => {
  const params = useParams<{ id: string }>();

  const provider = useSelector((state) => selectProviderById(state, params.id));
  const stateProviders = useSelector(selectAllProviders);
  const { loading, count } = useSelector(selectProvidersState);

  const adminTools = useSelector(selectAdminToolsState);

  const route = useSelector((state: MyState) => state.router);

  const routeParams = paramsToDictionary(route.location.search);

  const providers = useMemo(() => {
    const routeArray = route.location.pathname.split('/');
    return stateProviders.filter((f) => f.id !== routeArray[2]);
  }, [stateProviders, route.location.pathname]);

  const [search, setSearch] = useState(undefined);
  const [selectedTeacher, setSelectedTeacher] = useState<
    Provider | undefined
  >();
  const [showConvertStudentModal, setShowConvertStudentModal] = useState(false);

  const possibleStudent = useMemo(() => {
    const routeArray = route.location.pathname.split('/');
    return stateProviders.find((f) => f.id === routeArray[2]);
  }, [stateProviders, route.location]);

  const { push, requestUpdateProvider, requestProviderForConvertToStudent } =
    useBoundedActions({
      ...ProvidersActions,
      push: reduxPush,
    });

  const { resetPassword } = adminTools;

  useEffect(() => {
    requestProviderForConvertToStudent({ id: params.id });
  }, [route.location]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      const routeArray = route.location.pathname.split('/');
      push(
        `/convert-to-student/${routeArray[2]}${prepareQueryWithOffset(
          nextOffset,
          routeParams,
        )}`,
      );
    },
    [push, routeParams, route],
  );

  const applySearch = useCallback(() => {
    let newRouteParams = { ...routeParams, __offset: '0' };
    if (search) {
      newRouteParams['search'] = search;
    } else {
      newRouteParams = omit(routeParams, ['search']) as any;
    }
    const routeArray = route.location.pathname.split('/');
    push(
      `/convert-to-student/${routeArray[2]}${prepareQueryWithOffset(
        isNaN(parseInt(newRouteParams.__offset, 0))
          ? 0
          : parseInt(newRouteParams.__offset, 0),
        newRouteParams,
      )}`,
    );
  }, [push, search, routeParams, route]);

  return (
    <>
      <MMDLoader loading={loading || resetPassword.loading} />
      <div className="container p-2">
        <strong>Select a Doctor</strong>
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSearch
                initialValue={search}
                onChange={(e) => {
                  setSearch(
                    e.currentTarget.value !== ''
                      ? e.currentTarget.value
                      : undefined,
                  );
                }}
                onSearch={() => {
                  applySearch();
                }}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Convert" isDanger onClick={() => {}} />
            </Level.Item>
          </Level.Side>
        </Level>
        {providers.map((item: Provider) => (
          <MMDSelectableUserRow
            key={item.id}
            user={item}
            teacherId={provider && provider.teacherId}
            onClick={() => {
              setSelectedTeacher(item);
              setShowConvertStudentModal(true);
            }}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
      <MMDModal
        visible={!loading && showConvertStudentModal}
        onRequestClose={() => {
          setShowConvertStudentModal(false);
          setSelectedTeacher(undefined);
        }}
      >
        {selectedTeacher &&
          possibleStudent &&
          possibleStudent.teacherId &&
          `Please confirm that you'd like to switch
          ${possibleStudent.firstName} ${possibleStudent.lastName}'s
          preceptor to
          ${selectedTeacher.firstName} ${selectedTeacher.lastName}
          `}
        {selectedTeacher &&
          possibleStudent &&
          !possibleStudent.teacherId &&
          `Please confirm that you'd like to add
          ${possibleStudent.firstName} ${possibleStudent.lastName}'s
          preceptor to
          ${selectedTeacher.firstName} ${selectedTeacher.lastName}
          `}
        <div className="field is-grouped">
          <div className="control">
            <MMDButton
              text="Confirm"
              isPrimary
              onClick={() => {
                requestUpdateProvider({
                  ...possibleStudent,
                  //TODO
                  // @ts-ignore
                  coordinates: getCoordinatesString(
                    possibleStudent?.coordinates,
                  ),
                  approved: APPROVED,
                  adminVerified: APPROVED,
                  status: 'approved',
                  wasApprovedBefore: true,
                  teacherId: selectedTeacher.id,
                });
                setShowConvertStudentModal(false);
              }}
            />
          </div>
          <div className="control" />
        </div>
      </MMDModal>
    </>
  );
};

export default ConvertToStudent;
