import moment from 'moment/moment';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { Table } from 'react-bulma-components';
import { useLocation } from 'react-router-dom';
import { push as reduxPush } from 'redux-first-history/build/es6/actions';

import {
  AdminLog,
  selectAdminLogs,
  selectAdminLogsState,
} from 'modules/admin-logs.module';
import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';

const AdminLogs = () => {
  const location = useLocation();

  const routeParams = paramsToDictionary(location.search);

  const { loading, count } = useSelector(selectAdminLogsState);

  const adminLogs = useSelector(selectAdminLogs);

  const { push } = useBoundedActions({ push: reduxPush });
  const gotoPage = (nextOffset: number) =>
    push(`/admin-logs${prepareQueryWithOffset(nextOffset, routeParams)}`);

  const getUserInfo = (log: AdminLog) => `${log.firstName} ${log.lastName}`;

  return (
    <>
      <MMDLoader loading={loading} />

      <div className="p-2 px-5">
        <Table>
          <thead>
            <tr>
              <th>
                <abbr title="ID">UID/Document ID</abbr>
              </th>
              <th>User Info</th>
              <th>Request Info</th>
              <th>Data</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {adminLogs.map((log) => (
              <tr key={log.id}>
                <td>{log.id}</td>
                <td>{getUserInfo(log)}</td>
                <td>
                  <div className="flex is-flex-direction-column">
                    <p>
                      <span className="has-text-weight-bold">
                        Endpoint -&nbsp;
                      </span>

                      {log.endpoint}
                    </p>

                    <p className="py-2">
                      <span className="has-text-weight-bold">
                        Method -&nbsp;
                      </span>

                      {log.method}
                    </p>

                    <p>
                      <span className="has-text-weight-bold">
                        User Agent –&nbsp;
                      </span>

                      {log.userAgent}
                    </p>
                  </div>
                </td>
                <td>
                  <ReactJson src={log.requestBody} theme="bright:inverted" />
                </td>
                <td>
                  {moment(log.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <MMDPager
        itemCount={count}
        onChange={gotoPage}
        currentOffset={routeParams.__offset}
      />
    </>
  );
};

export default AdminLogs;
