import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import MMDTextInput from 'components/forms/MMDTextInput';

const TextBlock = forwardRef<
  HTMLInputElement,
  UseFormRegisterReturn & { readOnly: boolean; error?: string }
>((props, ref) => {
  return (
    <MMDTextInput
      required
      label="Text Block"
      placeholder="Enter text..."
      {...props}
      valid={!props.error}
      ref={ref}
    />
  );
});

TextBlock.displayName = 'TextBlock';

export default TextBlock;
