import { UserRoles } from '../../modules/users.module';

export const FORM_FIELDS = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  JOB_TITLE: 'jobTitle',
  ROLE_DESCRIPTION: 'roleDescription',
};

export const FORM_FIELDS_CONFIG = [
  { label: 'Email', name: FORM_FIELDS.EMAIL, disabled: true },
  { label: 'First Name', name: FORM_FIELDS.FIRST_NAME, required: true },
  { label: 'Last Name', name: FORM_FIELDS.LAST_NAME, required: true },
  { label: 'Job Title', name: FORM_FIELDS.JOB_TITLE },
  { label: 'Role description', name: FORM_FIELDS.ROLE_DESCRIPTION },
];

export const ADMIN_AVAILABLE_ACTIONS = {
  [UserRoles.ADMIN]:
    'Update user profiles,Review and approve pending prescriptions,Review policies',
  [UserRoles.SUPER_ADMIN]: '*',
};
