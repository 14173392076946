import { Action } from 'redux';
import { createSelector } from 'reselect';
import { OrganizationType, SagaIterator } from 'typings';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_ALL_ORGANIZATION_TYPES: string;
  REQUEST_CREATE_ORGANIZATION_TYPE: string;
  REQUEST_REMOVE_ORGANIZATION_TYPE: string;
  REQUEST_UPDATE_ORGANIZATION_TYPE: string;
  LOADING_ORGANIZATION_TYPES: string;
  COMMIT_ORGANIZATION_TYPES: string;
  COMMIT_ORGANIZATION_TYPE: string;
  REMOVE_ORGANIZATION_TYPE: string;
  ERROR_ORGANIZATION_TYPES: string;
}

export type CreateOrganizationTypePayload = Omit<OrganizationType, 'id'>;

export type UpdateOrganizationTypePayload = OrganizationType;

type RemoveOrganizationTypePayload = Pick<OrganizationType, 'id'>;

interface ActionCreators {
  requestAllOrganizationTypes: () => Action;
  requestCreateOrganizationType: (
    payload: CreateOrganizationTypePayload,
  ) => MyAction<OrganizationType>;
  requestUpdateOrganizationType: (
    payload: UpdateOrganizationTypePayload,
  ) => MyAction<UpdateOrganizationTypePayload>;
  requestRemoveOrganizationType: (
    payload: RemoveOrganizationTypePayload,
  ) => MyAction<OrganizationType>;
  loadingOrganizationTypes: () => Action;
  commitOrganizationTypes: (
    payload: Paginated<OrganizationType>,
  ) => MyAction<Paginated<OrganizationType>>;
  commitOrganizationType: (
    payload: OrganizationType,
  ) => MyAction<OrganizationType>;
  removeOrganizationType: (
    payload: RemoveOrganizationTypePayload,
  ) => MyAction<RemoveOrganizationTypePayload>;
  errorOrganizationTypes: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface OrganizationTypesState<TError extends Error = Error>
  extends EntityState<OrganizationType> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAllOrganizationTypes: [],
  requestCreateOrganizationType: ['payload'],
  requestUpdateOrganizationType: ['payload'],
  requestRemoveOrganizationType: ['payload'],
  commitOrganizationTypes: ['payload'],
  commitOrganizationType: ['payload'],
  removeOrganizationType: ['payload'],
  loadingOrganizationTypes: [],
  errorOrganizationTypes: ['error'],
});

const entityAdapter = createEntityAdapter<OrganizationType>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

const organizationTypesSelectors = entityAdapter.getSelectors();

function setLoading(state: OrganizationTypesState): OrganizationTypesState {
  return {
    ...state,
    loading: true,
  };
}

function commitOrganizationType(
  state: OrganizationTypesState,
  action: MyAction<OrganizationType>,
): OrganizationTypesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitOrganizationTypes(
  state: OrganizationTypesState,
  action: MyAction<Paginated<OrganizationType>>,
): OrganizationTypesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeOrganizationType(
  state: OrganizationTypesState,
  action: MyAction<RemoveOrganizationTypePayload>,
): OrganizationTypesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: OrganizationTypesState,
  { error }: ErrorAction<TError>,
): OrganizationTypesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const organizationTypesReducer = createReducer(initialState, {
  [Types.LOADING_ORGANIZATION_TYPES]: setLoading,
  [Types.COMMIT_ORGANIZATION_TYPES]: commitOrganizationTypes,
  [Types.COMMIT_ORGANIZATION_TYPE]: commitOrganizationType,
  [Types.REMOVE_ORGANIZATION_TYPE]: removeOrganizationType,
  [Types.ERROR_ORGANIZATION_TYPES]: setError,
});

const goBack = goBackFactory('/organization-types');

async function downloadOrganizationType({
  headers,
  id,
}: ArgsWithHeaders<Pick<OrganizationType, 'id'>>) {
  const result = await fetch(`${API_URL}/organization-types/${id}`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createOrganizationType({
  headers,
  ...payload
}: ArgsWithHeaders<CreateOrganizationTypePayload>) {
  const result = await fetch(`${API_URL}/organization-types`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function updateOrganizationType({
  id,
  headers,
  ...payload
}: ArgsWithHeaders<UpdateOrganizationTypePayload>) {
  const result = await fetch(`${API_URL}/organization-types/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function removeOrganizationTypeRequest({
  headers,
  id,
}: ArgsWithHeaders<Pick<OrganizationType, 'id'>>) {
  const result = await fetch(`${API_URL}/organization-types/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function getAllOrganizationTypes({ headers }: ArgsWithHeaders) {
  const response = await fetch(`${API_URL}/organization-types?__limit=1000`, {
    headers,
  });

  if (!response.ok) {
    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await response.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return response.json();
}

const requestAllOrganizationTypesWatcher = createSingleEventSaga<
  void,
  Paginated<OrganizationType>,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_ALL_ORGANIZATION_TYPES,
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: Creators.commitOrganizationTypes,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizationTypes,
  action: getAllOrganizationTypes,
  beforeAction: putAuthInfoInArgs,
});

const requestOrganizationTypesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<OrganizationType>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/organization-types'),
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: Creators.commitOrganizationTypes,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizationTypes,
  action: downloadUsingLocationQuery<OrganizationType>('organization-types'),
  beforeAction: putAuthInfoInArgs,
});

const requestOrganizationTypeWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  OrganizationType,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/organization-types/:id'),
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: Creators.commitOrganizationType,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizationTypes,
  action: downloadOrganizationType,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    Pick<OrganizationType, 'id'>,
    ArgsWithHeaders<Pick<OrganizationType, 'id'>>
  >(extractRouteParams('/organization-types/:id'), putAuthInfoInArgs),
});

const requestCreateOrganizationTypeWatcher = createSingleEventSaga<
  OrganizationType,
  OrganizationType,
  MyAction<OrganizationType>
>({
  takeEvery: Types.REQUEST_CREATE_ORGANIZATION_TYPE,
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorOrganizationTypes,
  action: createOrganizationType,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateOrganizationTypeWatcher = createSingleEventSaga<
  Partial<OrganizationType>,
  Partial<OrganizationType>,
  MyAction<Partial<OrganizationType>>
>({
  takeEvery: Types.REQUEST_UPDATE_ORGANIZATION_TYPE,
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorOrganizationTypes,
  action: updateOrganizationType,
  beforeAction: putAuthInfoInArgs,
});

const requestRemoveOrganizationTypeWatcher = createSingleEventSaga<
  Pick<OrganizationType, 'id'>,
  void,
  MyAction<Pick<OrganizationType, 'id'>>
>({
  takeEvery: Types.REQUEST_REMOVE_ORGANIZATION_TYPE,
  loadingAction: Creators.loadingOrganizationTypes,
  commitAction: Creators.removeOrganizationType,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizationTypes,
  action: removeOrganizationTypeRequest,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Pick<OrganizationType, 'id'>>,
  ): SagaIterator {
    return args;
  },
});

export const selectOrganizationTypesState = (state: MyState) =>
  state.organizationTypes;

export const selectOrganizationTypes = createSelector(
  selectOrganizationTypesState,
  (organizationState) =>
    organizationTypesSelectors.selectAll(organizationState),
);

export const selectOrganizationTypeById = createSelector(
  [selectOrganizationTypesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const organizationTypesSagas = [
  requestCreateOrganizationTypeWatcher,
  requestRemoveOrganizationTypeWatcher,
  requestUpdateOrganizationTypeWatcher,
  requestAllOrganizationTypesWatcher,
  requestOrganizationTypesWatcher,
  requestOrganizationTypeWatcher,
  goBack.watcher,
];
