import React from 'react';

type PropTypes = {
  loading: boolean;
  title?: string;
};

const MMDModal: React.FC<PropTypes> = ({ title = '', loading = false }) => {
  return (
    <div className={loading ? 'is-active pageloader' : 'pageloader'}>
      <span className="title">{title ? title : 'Loading'}</span>
    </div>
  );
};

export default MMDModal;
