import QRCode from 'react-qr-code';

import AppleLogo from 'assets/apple-logo.png';
import AndroidLogo from 'assets/android-logo.png';

const QR_CODE_STYLE = { height: 'auto', maxWidth: '60%' };
const LOGO_STYLE = { width: 20, height: 20 };

const AUTHENTICATOR_FOR_IOS =
  'https://apps.apple.com/us/app/google-authenticator/id388497605';
const AUTHENTICATOR_FOR_ANDROID =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1';

const DownloadGoogleAuthenticator = () => (
  <div className="flex is-flex-direction-column is-align-items-center mb-4">
    <span>Download For</span>

    <div className="flex is-justify-content-space-evenly">
      <div className="flex is-flex-direction-column is-align-items-center">
        <div className="flex mb-1 ">
          <span>IOS</span>

          <img
            src={AppleLogo}
            className="ml-1"
            alt="apple-logo"
            style={LOGO_STYLE}
          />
        </div>

        <QRCode style={QR_CODE_STYLE} value={AUTHENTICATOR_FOR_IOS} />
      </div>

      <div className="flex is-flex-direction-column is-align-items-center">
        <div className="flex mb-1 is-align-items-center">
          <span>Android</span>

          <img
            style={LOGO_STYLE}
            className="ml-1"
            src={AndroidLogo}
            alt="android-logo"
          />
        </div>

        <QRCode style={QR_CODE_STYLE} value={AUTHENTICATOR_FOR_ANDROID} />
      </div>
    </div>
  </div>
);

export default DownloadGoogleAuthenticator;
