import { FC } from 'react';

type PropTypes = {
  column: string;
  key: number;
  handleDelete: (column: string) => void;
};

const MMDDragAndDrop: FC<PropTypes> = ({ column, key, handleDelete }) => (
  <>
    <span
      key={key}
      style={{
        marginTop: '15px',
        borderColor: '#fc105b',
        borderRadius: '20px',
        boxShadow: '0px 0px 3px 0px rgb(252 16 91)',
      }}
      className={`button 'is-primary'`}
    >
      {column}
      <span
        onClick={() => handleDelete(column)}
        style={{
          color: '#fc105b',
          borderColor: 'solid #fc105b',
          borderRadius: '20px',
          marginLeft: '10px',
          fontSize: '14px',
        }}
      >
        x
      </span>
    </span>
  </>
);

export default MMDDragAndDrop;
