import { FC } from 'react';
import { useSelector } from 'react-redux';

import { OrganizationType } from 'typings';
import EntitiesTable from 'components/entities-table';
import OrganizationTypeRow from './OrganizationTypeRow';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectOrganizationTypes,
  selectOrganizationTypesState,
  Creators as OrganizationTypesActions,
} from 'modules/organization-types.module';

const OrganizationTypes: FC = () => {
  const { count, loading } = useSelector(selectOrganizationTypesState);

  const organizationTypes = useSelector(selectOrganizationTypes);

  const { requestRemoveOrganizationType } = useBoundedActions(
    OrganizationTypesActions,
  );

  const getOnDeleteOrganizationType = (id: number) => () =>
    requestRemoveOrganizationType({ id });

  return (
    <EntitiesTable
      count={count}
      loading={loading}
      withoutApproveFilter
      rootPath="organization-types"
      newEntityUrl="create-organization-type"
      elements={organizationTypes.map((item: OrganizationType) => (
        <OrganizationTypeRow
          key={item.id}
          organizationType={item}
          targetPath="organization-types"
          onDeleteClick={getOnDeleteOrganizationType(item.id)}
        />
      ))}
    />
  );
};

export default OrganizationTypes;
