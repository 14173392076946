import Select from 'react-select';
import { useSelector } from 'react-redux';
import { FC, useEffect, useRef, useState } from 'react';

import MMDModal from 'components/MMDModal';
import { useToggle } from 'hooks/useToggle';
import MMDButton from 'components/MMDButton';
import { Nullable, Option, Organization } from 'typings';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  Creators as OrganizationsActions,
  selectOrganizations,
} from 'modules/organizations.module';
import { Creators as PatientsActions } from 'modules/patients.module';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#f0f0f0' : 'white',
    borderColor: state.isFocused ? '#f81942' : '#dee2e6',
    '&:hover': {
      borderColor: '#f81942',
    },
    borderRadius: '22px',
    boxShadow: 'none',
  }),
  valueContainer: (styles) => ({ ...styles, padding: '6px 12px', margin: 0 }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected
      ? '#f81942'
      : state.isFocused
      ? '#f0f0f0'
      : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? 'none' : '#f0f0f0',
    },
    ':active': {
      backgroundColor: 'none',
    },
  }),
  input: (styles) => ({ ...styles, margin: 0, padding: 0 }),
  placeholder: (styles) => ({ ...styles, margin: 0 }),
};

type Props = {
  patientId: string;
  organization?: Nullable<Organization>;
};

const OrganizationSelect: FC<Props> = ({ patientId, organization }) => {
  const [isConfirmModalVisible, toggleConfirmModal] = useToggle();

  const organizationToUpdate = useRef<Nullable<Option>>(null);

  const [value, setValue] = useState<Nullable<Option>>(
    organization
      ? { label: organization.name, value: organization.id.toString() }
      : null,
  );

  const organizations = useSelector(selectOrganizations);

  const {
    attachOrganizationForPatient,
    removeOrganizationForPatient,
    requestAllOrganizations,
  } = useBoundedActions({ ...PatientsActions, ...OrganizationsActions });

  useEffect(() => {
    requestAllOrganizations();
  }, []);

  const organizationOptions = organizations.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  const onChange = (option: Nullable<Option>) => {
    organizationToUpdate.current = option;
    toggleConfirmModal();
  };

  const onConfirmOrganizationChange = () => {
    setValue(organizationToUpdate.current);
    if (organizationToUpdate.current) {
      attachOrganizationForPatient({
        patientId,
        organizationId: organizationToUpdate.current.value,
      });
    } else {
      removeOrganizationForPatient({ patientId });
    }
    organizationToUpdate.current = null;
    toggleConfirmModal();
  };

  const onCancelOrganizationChange = () => {
    toggleConfirmModal();
    organizationToUpdate.current = null;
  };

  const actionText = organizationToUpdate.current ? 'Change' : 'Delete';

  return (
    <>
      <div className="mb-2">
        <label className="label">Organization</label>

        <Select
          value={value}
          isSearchable
          isClearable
          defaultValue={
            organization
              ? { label: organization.name, value: organization.id.toString() }
              : null
          }
          options={organizationOptions}
          onChange={onChange}
          styles={customStyles}
        />
      </div>

      <MMDModal visible={isConfirmModalVisible} withoutClose>
        <div className="has-text-centered">
          <big>
            {actionText} the organization{' '}
            {organizationToUpdate.current ? (
              <span>
                to&nbsp;
                <strong className="has-text-danger">
                  {organizationToUpdate.current.label}
                </strong>
                &nbsp;
              </span>
            ) : (
              ''
            )}
            ?
          </big>
        </div>

        <div className="flex is-justify-content-space-evenly mt-4">
          <MMDButton
            text="Cancel"
            isPrimary={false}
            onClick={onCancelOrganizationChange}
          />

          <MMDButton
            isDanger
            text={actionText}
            onClick={onConfirmOrganizationChange}
          />
        </div>
      </MMDModal>
    </>
  );
};

export default OrganizationSelect;
