import { useForm } from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as AuthActions, EMAIL_REGEX } from 'modules/auth.module';

const EmailForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { checkUserInfo } = useBoundedActions(AuthActions);

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(checkUserInfo)}>
      <MMDTextInput
        required
        type="email"
        label="Email"
        valid={!errors.email}
        placeholder="juan@doe.com"
        {...register('email', {
          pattern: EMAIL_REGEX,
          required: true,
        })}
      />

      <MMDButton isSubmit isPrimary text="Reset" isDisabled={hasFormErrors} />
    </form>
  );
};

export default EmailForm;
