import moment from 'moment';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import { push } from 'redux-first-history';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import { FC, FormEvent, useCallback, useState } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Container, Level, Table } from 'react-bulma-components';

import { MyState } from 'store';
import { LogEntry } from 'typings';
import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { Creators as LogsActions, logsSelectors } from 'modules/logs.module';

type Props = {
  logs?: LogEntry[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof LogsActions;

const Logs: FC<Props> = ({ logs, count, routeParams, push, loading }) => {
  const [currentFilterValue, setCurrentFilterValue] = useState('');

  const onCurrentFilterChanged = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      setCurrentFilterValue(input.value);
    },
    [setCurrentFilterValue],
  );

  const applyFilter = useCallback(() => {
    push(`logs?userId=${currentFilterValue}`);
  }, [push, currentFilterValue]);

  const clearFilter = useCallback(() => {
    push('logs');
  }, [push]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`logs${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  return (
    <Container>
      <MMDLoader loading={loading} />
      <Level>
        <Level.Side align="left">
          <Level.Item>
            <MMDTextInput
              name="filter-value"
              label="Filter value:"
              value={currentFilterValue}
              onChange={onCurrentFilterChanged}
            />
          </Level.Item>
          <Level.Item>
            <MMDButton isPrimary onClick={applyFilter} text="Apply filter" />
          </Level.Item>
          <Level.Item>
            <MMDButton isSecondary onClick={clearFilter} text="Clear filter" />
          </Level.Item>
        </Level.Side>
      </Level>
      <div className="container p-2">
        <Table>
          <thead>
            <tr>
              <th>
                <abbr title="User ID">UID/Document ID</abbr>
              </th>
              <th>Model</th>
              <th>Document</th>
              <th>Operation</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td className="log-id">{log.documentId}</td>
                <td>{log.model}</td>
                <td>
                  <ReactJson
                    src={log.document}
                    theme="bright:inverted"
                    style={{ maxWidth: '500px' }}
                  />
                </td>
                <td>{log.operation}</td>
                <td>
                  {moment(log.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </Container>
  );
};

const mapStateToProps = (state: MyState) => ({
  logs: logsSelectors.selectAll(state.logs),
  loading: state.logs.loading,
  count: state.logs.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...LogsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
