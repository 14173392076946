import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectUsersState,
  Creators as UsersActions,
} from 'modules/users.module';
import EditUserForm from './EditUserForm';

const EditUser = () => {
  const { selectedUser, loading } = useSelector(selectUsersState);

  const { requestUpdateUser } = useBoundedActions(UsersActions);

  const { id } = useParams<{ id: string }>();

  const getOnUpdateUser = () => (values) =>
    requestUpdateUser({ id, user: values });

  return (
    <div className="container p-2 content is-justify-content-center is-flex-direction-column">
      <MMDLoader loading={loading} />

      {selectedUser && (
        <EditUserForm onSubmit={getOnUpdateUser()} user={selectedUser} />
      )}
    </div>
  );
};

export default EditUser;
