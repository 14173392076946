import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { call, put, take, takeEvery, select } from 'redux-saga/effects';

import { Predicate, LocationChangeActionPayload } from './typings';
import { replace } from 'redux-first-history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MyState } from 'store';
import { SagaIterator } from '../typings';

export const UNAUTHORIZED = 403;
export const SERVER_ERROR = 500;
export const NOT_FOUND = 404;
export const UNAVAILABLE_FOR_LEGAL_REASONS = 451;
export const INVALID_CREDENTIALS_ERROR = 'invalid_grant';
export const INVISIBLE_ERROR_MESSAGE = '<** no throw **>';
export const USER_NOT_FOUND_ERROR = 'User with this email not exists';
export const INVALID_CREDENTIALS = 'Wrong email or password';

export function onRoutes<T extends Action = Action>(
  ...paths: string[]
): Predicate<T> {
  return (action: T) =>
    action.type === '@@router/LOCATION_CHANGE' &&
    paths.some((path) =>
      matchRoute(path, (action as any).payload.location.pathname),
    );
}

export function onRoute<T extends Action = Action>(path: string): Predicate<T> {
  return (action: T) =>
    action.type === '@@router/LOCATION_CHANGE' &&
    matchRoute(path, (action as any).payload.location.pathname);
}

export function matchRoute(
  expectedPattern: string,
  actualPath: string,
): boolean {
  let match = true;
  const splitExpectedPattern = expectedPattern.split('/');
  const splitActualPath = actualPath.split('/');

  if (splitExpectedPattern.length !== splitActualPath.length) {
    return false;
  }

  for (let i = 0; i < splitExpectedPattern.length; i += 1) {
    if (splitExpectedPattern[i].includes(':')) {
      continue;
    }

    match = match && splitExpectedPattern[i] === splitActualPath[i];
  }

  return match;
}

export function extractRouteParams(
  pattern: string,
): (payload: LocationChangeActionPayload) => SagaIterator {
  const worker = (path: string) => {
    const result: Dictionary<string> = {};
    const splitPattern = pattern.split('/');
    const splitPath = path.split('/');

    if (!matchRoute(pattern, path)) {
      return result;
    }

    for (let i = 0; i < splitPattern.length; i = i + 1) {
      if (splitPattern[i].includes(':')) {
        result[splitPattern[i].replace(':', '')] = splitPath[i];
      }
    }

    return result;
  };

  return function* (payload: LocationChangeActionPayload): SagaIterator {
    const params: Dictionary<string> = yield call(
      worker,
      payload.location.pathname,
    );
    return params;
  };
}

function extractRoteParam(pattern: string, url: string) {
  const patternPath = pattern.split('/');

  const paramIndex = patternPath.findIndex((path) => path.includes(':'));

  if (paramIndex === -1) {
    return pattern;
  }

  const urlPath = url.split('/');

  patternPath[paramIndex] = urlPath[paramIndex];

  return patternPath.join('/');
}

export function goBackFactory(pattern) {
  if (!pattern) return;

  return {
    action: () => {
      return {
        type: `GO_BACK_${pattern}`,
      };
    },
    *watcher() {
      while (yield take(`GO_BACK_${pattern}`)) {
        const currentRoute: string = yield select(
          (state: MyState) => state.router.location.pathname,
        );

        if (!matchRoute(pattern, currentRoute)) {
          yield call(toast.success, 'Action executed successfully');
          yield put(
            replace(extractRoteParam(pattern, currentRoute).split('#')[0]),
          );
        }
      }
    },
  };
}

export function notificationFactory(entity) {
  function* notificationHandler() {
    try {
      const camelCasedEntity = entity
        .toLowerCase()
        .replace(/_\w/g, (m) => m[1].toUpperCase());
      const error = yield select(
        (state: MyState) => state[camelCasedEntity].error.message,
      );

      if (error !== '<** no throw **>') {
        yield toast.error(error);
      }
    } catch (err) {
      console.error('An error throwing an error... Really??');
    }
  }

  return {
    action: () => ({
      type: `ERROR_${entity}`,
    }),
    *watcher() {
      yield takeEvery(`ERROR_${entity}`, notificationHandler);
    },
  };
}
