import { Action } from 'redux';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';
import { call, put, select, take } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  ErrorAction,
  EntityState,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { API_URL } from 'config';
import { MyState } from 'store';
import { isNumber } from 'utils/isNumber';
import { noOpAction } from 'utils/noOpAction';
import { Provider, SagaIterator } from 'typings';
import { putAuthInfoInArgs } from './auth.module';
import { mapCoordinates } from 'utils/mapCoordinates';
import { COORD_REGEX, PHONE_REG_EXP } from 'utils/constants';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  onRoutes,
  goBackFactory,
  UNAUTHORIZED,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
  UNAVAILABLE_FOR_LEGAL_REASONS,
} from 'utils/onRoute';

interface PayProviderPayload {
  id: string;
  amount: number;
  direct?: boolean;
}

interface ActionTypes {
  REQUEST_UPDATE_PROVIDER: string;
  REQUEST_SPECIALITIES: string;
  REQUEST_CREDENTIALS: string;
  LOADING_PROVIDERS: string;
  COMMIT_PROVIDERS: string;
  COMMIT_PROVIDER: string;
  SUCCESS_PROVIDERS: string;
  ERROR_PROVIDERS: string;
  PAY_PROVIDER: string;
  COMMIT_PROVIDER_PAYMENT: string;
  REQUEST_PROVIDER: string;
  REQUEST_PROVIDER_FOR_CONVERT_TO_STUDENT: string;
  REQUEST_STUDENT_UNLINK: string;
}

interface ActionCreators {
  requestProvider: (payload: { id: string }) => MyAction<Provider>;
  requestUpdateProvider: (
    payload: Partial<Provider>,
  ) => MyAction<Partial<Provider>>;
  loadingProviders: () => Action;
  commitProviders: (
    payload: Paginated<Provider>,
  ) => MyAction<Paginated<Provider>>;
  commitProvider: (payload: Provider) => MyAction<Provider>;
  successProviders: (payload: Provider[]) => MyAction<Provider[]>;
  errorProviders: <TError extends Error>(error: TError) => ErrorAction<TError>;
  payProvider: (payload: PayProviderPayload) => MyAction<Provider>;
  commitProviderPayment: () => Action;
  requestProviderForConvertToStudent: (payload: {
    id: string;
  }) => MyAction<Provider>;
  requestStudentUnlink: (payload: { id: string }) => MyAction<{
    id: string;
  }>;
}

export interface ProvidersState<TError extends Error = Error>
  extends EntityState<Provider> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestProvider: ['payload'],
  requestUpdateProvider: ['payload'],
  loadingProviders: [],
  commitProviders: ['payload'],
  commitProvider: ['payload'],
  successProviders: ['payload'],
  errorProviders: ['error'],
  payProvider: ['payload'],
  commitProviderPayment: [],
  requestProviderForConvertToStudent: ['payload'],
  requestStudentUnlink: ['payload'],
});

const entityAdapter = createEntityAdapter<Provider>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const providerSelectors = entityAdapter.getSelectors();

function setLoading(state: ProvidersState): ProvidersState {
  return {
    ...state,
    loading: true,
  };
}

function commitProviders(
  state: ProvidersState,
  action: MyAction<Paginated<Provider>>,
): ProvidersState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitProvider(
  state: ProvidersState,
  action: MyAction<Provider>,
): ProvidersState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitProviderPayment(state: ProvidersState): ProvidersState {
  return {
    ...state,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: ProvidersState,
  { error }: ErrorAction<TError>,
): ProvidersState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const providersReducer = createReducer(initialState, {
  [Types.LOADING_PROVIDERS]: setLoading,
  [Types.COMMIT_PROVIDERS]: commitProviders,
  [Types.ERROR_PROVIDERS]: setError,
  [Types.COMMIT_PROVIDER]: commitProvider,
  [Types.COMMIT_PROVIDER_PAYMENT]: commitProviderPayment,
});

async function downloadProvider({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<Provider> {
  const result = await fetch(`${API_URL}/providers/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
  return result.json();
}

const isSpecialitySelected = (providerData: Partial<Provider>) =>
  providerData?.profile?.specialities?.length > 0;

function* updateProvider({
  headers,
  ...payload
}: ArgsWithHeaders<
  Partial<Provider> & { onSuccessUpdate?: () => void; coordinates: string }
>) {
  const { onSuccessUpdate, ...providerData } = payload;

  const provider = yield select((state) =>
    selectProviderById(state, payload.id),
  );

  const newProviderValues = { ...provider, ...providerData };

  const updatedProviderData = deepDiffBetweenObjects(
    {
      ...providerData,
      ...mapCoordinates(providerData.coordinates),
    },
    provider,
  );

  if (
    !isSpecialitySelected(newProviderValues) &&
    newProviderValues?.status !== 'rejected'
  ) {
    throw Error('Select speciality first');
  }

  if (!Object.keys(updatedProviderData).length) {
    return;
  }

  const result = yield call(
    fetch,
    `${API_URL}/providers/${newProviderValues.id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(updatedProviderData),
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    if (result.status === UNAVAILABLE_FOR_LEGAL_REASONS) {
      throw Error(
        "You can't change the teacher of a student subscribed to futures appointments",
      );
    }

    throw Error('There has been an error processing your request');
  }
}

async function putPayProvider({
  headers,
  id,
  amount,
  direct,
}: ArgsWithHeaders<PayProviderPayload>): Promise<void> {
  const result = await fetch(`${API_URL}/providers/${id}/pay`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ amount, direct }),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const { error } = await result.json();
    throw Error(error);
  }
  toast.success('Action executed successfully');
}

function* unlinkStudent({ headers, id }: ArgsWithHeaders<{ id: string }>) {
  const response = yield call(
    fetch,
    `${API_URL}/providers/unlink-student/${id}`,
    {
      headers,
      method: 'PUT',
    },
  );

  if (!response.ok) {
    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const { error } = yield call([response, 'json']);
    throw Error(error);
  }
  toast.success('Action executed successfully');

  yield put(Creators.requestProvider({ id }));
}

function* downloadStudentForApprove({ id }: { id: string }) {
  if (yield take(Types.COMMIT_PROVIDERS)) {
    yield put(Creators.requestProvider({ id }));
  }
}

const requestProvidersWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Provider>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoutes('/providers', '/convert-to-student/:id'),
  loadingAction: Creators.loadingProviders,
  commitAction: Creators.commitProviders,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: downloadUsingLocationQuery<Provider>('providers'),
  beforeAction: putAuthInfoInArgs,
});

const requestStudentWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Provider,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_PROVIDER_FOR_CONVERT_TO_STUDENT,
  loadingAction: Creators.loadingProviders,
  commitAction: noOpAction,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: downloadStudentForApprove,
});

const goBack = goBackFactory('/providers');

const requestUpdateProviderWatcher = createSingleEventSaga<
  Partial<Provider>,
  Partial<Provider>,
  MyAction<Partial<Provider>>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER,
  loadingAction: Creators.loadingProviders,
  commitAction: Creators.commitProvider,
  successAction: goBack.action,
  errorAction: Creators.errorProviders,
  action: updateProvider,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Partial<Provider>>,
  ): SagaIterator {
    return args;
  },
});

const requestProviderOnRouteWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Provider,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/providers/:id'),
  loadingAction: Creators.loadingProviders,
  commitAction: Creators.commitProvider,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: downloadProvider,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
  >(extractRouteParams('/providers/:id'), putAuthInfoInArgs),
});

const requestProviderByIdWatcher = createSingleEventSaga<
  { id: string },
  Provider,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_PROVIDER,
  loadingAction: Creators.loadingProviders,
  commitAction: Creators.commitProvider,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: downloadProvider,
  beforeAction: putAuthInfoInArgs,
});

const payProviderWatcher = createSingleEventSaga<
  PayProviderPayload,
  void,
  MyAction<PayProviderPayload>
>({
  takeEvery: Types.PAY_PROVIDER,
  loadingAction: Creators.loadingProviders,
  commitAction: Creators.commitProviderPayment,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: putPayProvider,
  beforeAction: putAuthInfoInArgs,
});

const unlinkStudentWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_STUDENT_UNLINK,
  loadingAction: Creators.loadingProviders,
  commitAction: noOpAction,
  successAction: noOpAction,
  errorAction: Creators.errorProviders,
  action: unlinkStudent,
  beforeAction: putAuthInfoInArgs,
});

export const providersSagas = [
  requestProviderOnRouteWatcher,
  requestUpdateProviderWatcher,
  requestProviderByIdWatcher,
  requestProvidersWatcher,
  requestStudentWatcher,
  unlinkStudentWatcher,
  payProviderWatcher,
  goBack.watcher,
];

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function providersFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    firstName: true,
    lastName: true,
    email: true,
    address: true,
    city: true,
    state: true,
    phone: true,
    npi: true,
    dea: true,
    pln: true,
    oneTimePrice: true,
    firstTimePrice: true,
    monthlyPrice: true,
    isPsychiatrist: true,
    splitPercentage: true,
    zipCode: true,
    coordinates: true,
  };
  if (
    values.credential !== 'DVM' &&
    (!values.npi || values.npi.toString().length === 0)
  ) {
    result.npi = false;
  }

  [
    'firstName',
    'lastName',
    'email',
    'address',
    'city',
    'state',
    'pln',
    'splitPercentage',
    'coordinates',
  ].forEach((k) => {
    result[k] = !!values[k] && values[k].toString().length > 0;
  });

  if (!values.phone.match(PHONE_REG_EXP)) {
    result.phone = false;
  }

  if (!values['email'].match(EMAIL_REGEX)) {
    result.email = false;
  }
  if (
    (!values['oneTimePrice'] ||
      !isNumber(values['oneTimePrice']) ||
      values['oneTimePrice'] === '0') &&
    !values.teacherId
  ) {
    result.oneTimePrice = false;
  }

  if (
    (!values['splitPercentage'] ||
      !isNumber(values['splitPercentage']) ||
      values['splitPercentage'] === '0') &&
    !values.teacherId
  ) {
    result.splitPercentage = false;
  }

  if (
    (!values['monthlyPrice'] ||
      !isNumber(values['monthlyPrice']) ||
      values['monthlyPrice'] === '0') &&
    !values.teacherId
  ) {
    result.monthlyPrice = false;
  }

  if (!values['zipCode']) {
    result.zipCode = false;
  }

  if (!values['coordinates'].match(COORD_REGEX)) {
    result.coordinates = false;
  }

  if (values['isPsychiatrist'] === 'true') {
    if (!isNumber(values['firstTimePrice'])) {
      result.firstTimePrice = false;
    }
  }
  // if (values["isVet"] == "t") {
  // if (values["dea"] == "") {
  //   result.dea = false;
  // } else {
  //   let dea = values["dea"]
  //   if (dea.length != 9) {
  //     result.dea = false;
  //   }
  //     const letters: string = dea.slice(0, 2)
  //     if (!["A", "B", "F", "G", "M", "P", "R", "X"].includes(letters.charAt(0)) || letters.charAt(1)!= (values["lastName"].charAt(0) as string).toUpperCase()) {
  //       result.dea = false;
  //     }
  //       const numbers: string = dea.slice(2,8)
  //       try{
  //       const check135 = parseInt(numbers.charAt(0)) + parseInt(numbers.charAt(2)) + parseInt(numbers.charAt(4))
  //       const check246 = (parseInt(numbers.charAt(1)) + parseInt(numbers.charAt(3)) + parseInt(numbers.charAt(5)))*2
  //       const check = (check135 + check246).toString()
  //       if (check.charAt(check.length - 1) != dea.charAt(8)) {
  //         result.dea = false;
  //       }
  //       } catch (e) {
  //         result.dea = false;
  //       }
  // }
  // }
  return Promise.resolve(result);
}

export const selectProvidersState = (state: MyState) => state.providers;

export const selectAllProviders = createSelector(
  selectProvidersState,
  providerSelectors.selectAll,
);

export const selectProviderById = createSelector(
  [selectProvidersState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectProvidersLoading = createSelector(
  selectProvidersState,
  (state) => state.loading,
);
