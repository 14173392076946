import { useSelector } from 'react-redux';
import React, { FC, PropsWithChildren } from 'react';
import { push as reduxPush } from 'redux-first-history';

import { MyState } from 'store';
import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';

import MMDSearchFilter from './MMDSearchFilter';

type Props = {
  loading: boolean;
  count: number;
  rootPath: string;
  newEntityUrl?: string;
  withoutSearch?: boolean;
  elements: React.JSX.Element[];
  withoutApproveFilter?: boolean;
};

const EntitiesTable: FC<PropsWithChildren<Props>> = ({
  count,
  loading,
  rootPath,
  elements,
  newEntityUrl,
  children,
  withoutApproveFilter = false,
  withoutSearch = false,
}) => {
  const locationSearch = useSelector(
    (state: MyState) => state.router.location.search,
  );

  const routeParams = paramsToDictionary(locationSearch);

  const { push } = useBoundedActions({ push: reduxPush });

  const gotoPage = (nextOffset: number) =>
    push(`${rootPath}${prepareQueryWithOffset(nextOffset, routeParams)}`);

  const hasElementsInList = elements.length > 0;

  return (
    <>
      <MMDLoader loading={loading} />

      <div className="container p-2">
        <MMDSearchFilter
          push={push}
          rootPath={rootPath}
          routeParams={routeParams}
          newEntityUrl={newEntityUrl}
          withoutSearch={withoutSearch}
          withoutApproveFilter={withoutApproveFilter}
        />
        {hasElementsInList ? (
          elements
        ) : (
          <h2 className="has-text-centered has-text-weight-bold mt-6 ">
            No items
          </h2>
        )}
      </div>

      {hasElementsInList && (
        <MMDPager
          itemCount={count}
          onChange={gotoPage}
          currentOffset={routeParams.__offset}
        />
      )}

      {children}
    </>
  );
};

export default EntitiesTable;
