import { FC, FormEvent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Level } from 'react-bulma-components';
import { push } from 'redux-first-history';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDLoader from '../components/MMDLoader';
import { MyState } from '../store';
import { SigninAttempt } from '../typings';
import { SIGNIN_ATTEMPT_FILTER } from '../utils/constants';
import {
  Creators as SigninAttemptActions,
  SigninAttemptSelectors,
} from 'modules/signin-attempts.module';
import MMDSelect from 'components/forms/MMDSelect';
import MMDPager from 'components/MMDPager';
import MMDTable from 'components/forms/MMDTable';
import MMDTextInput from 'components/forms/MMDTextInput';
import MMDButton from 'components/MMDButton';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';

type Props = {
  signinAttempts?: SigninAttempt[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof SigninAttemptActions;

const SigninAttempts: FC<Props> = ({
  count,
  signinAttempts,
  routeParams,
  push,
  loading,
}) => {
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentFilterValue, setCurrentFilterValue] = useState('');

  const onFilterChanged = useCallback(
    (e: FormEvent<HTMLSelectElement>) => {
      const input = e.target as HTMLSelectElement;
      setCurrentFilter(input.value);
    },
    [setCurrentFilter],
  );

  const onCurrentFilterChanged = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      setCurrentFilterValue(input.value);
    },
    [setCurrentFilterValue],
  );

  const applyFilter = useCallback(() => {
    push(`signin-attempts?${currentFilter}=${currentFilterValue}`);
  }, [push, currentFilter, currentFilterValue]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`signin-attempts${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const clearFilter = () => {
    push('signin-attempts');
    setCurrentFilter('');
    setCurrentFilterValue('');
  };

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSelect
                name="filter"
                label="Filter by..."
                value={currentFilter}
                options={SIGNIN_ATTEMPT_FILTER}
                onChange={onFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDTextInput
                name="filter-value"
                label="Filter value:"
                value={currentFilterValue}
                onChange={onCurrentFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton isPrimary onClick={applyFilter} text="Apply filter" />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
        </Level>
        <MMDTable
          titles={[
            'email',
            'ip',
            'location',
            'Device info',
            'created at',
            'updated at',
          ]}
          label="SigninAttempts"
          dataTable={signinAttempts}
          hasSigninAttempts
        />
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  signinAttempts: SigninAttemptSelectors.selectAll(state.signinAttempts),
  loading: state.signinAttempts.loading,
  count: state.signinAttempts.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...SigninAttemptActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SigninAttempts);
