import { Link } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import MMDButton from 'components/MMDButton';
import { BookingPublic, Nullable } from 'typings';
import { getFirebaseImage } from 'utils/Firebase';

type DownloadedBookingPublicFile = { image: string; id: number; name: string };

const BookingPublicFiles: FC<{ bookingPublic: BookingPublic }> = ({
  bookingPublic,
}) => {
  const [downloadedFiles, setDownloadedFiles] =
    useState<Nullable<DownloadedBookingPublicFile[]>>(null);

  useEffect(() => {
    Promise.all(
      bookingPublic.filesPublic.map((file) =>
        getFirebaseImage(
          `/bookings/${bookingPublic.eventId}/${bookingPublic.doctorId}/public/${file.fileNamePublic}`,
        ).then((image) => ({
          image,
          id: file.id,
          name: file.fileNamePublic,
        })),
      ),
    ).then(setDownloadedFiles);
  }, []);

  if (!downloadedFiles) {
    return <span>Loading...</span>;
  }

  return (
    <>
      {downloadedFiles.map((file) => (
        <Link
          key={file.id}
          to={file.image}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MMDButton isDanger className="mt-2" text={file.name} />
        </Link>
      ))}
    </>
  );
};

export default BookingPublicFiles;
