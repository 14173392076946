import {
  createSingleEventSaga,
  ErrorAction,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { auth } from '../utils/Firebase';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { UNAUTHORIZED, INVISIBLE_ERROR_MESSAGE } from 'utils/onRoute';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendPasswordResetEmail } from 'firebase/auth';
import { MyState } from '../store';
import { createSelector } from 'reselect';
import { specialitiesSelector } from './specialities.module';

interface ResetPasswordPayload {
  email: string;
}

interface ResetNotifyUpdateProviderPayload {
  body: string;
  id: string;
}

interface ActionTypes {
  REQUEST_RESET_PASSWORD: string;
  LOADING_RESET_PASSWORD: string;
  COMMIT_RESET_PASSWORD: string;
  SUCCESS_RESET_PASSWORD: string;
  ERROR_RESET_PASSWORD: string;

  REQUEST_NOTIFY_UPDATE_PROVIDER: string;
  LOADING_NOTIFY_UPDATE_PROVIDER: string;
  COMMIT_NOTIFY_UPDATE_PROVIDER: string;
  SUCCESS_NOTIFY_UPDATE_PROVIDER: string;
  ERROR_NOTIFY_UPDATE_PROVIDER: string;
}

interface ActionCreators {
  requestResetPassword: (email: ResetPasswordPayload) => Action;
  loadingResetPassword: () => Action;
  commitResetPassword: (payload: void) => MyAction<void>;
  successResetPassword: (payload: void) => MyAction<void>;
  errorResetPassword: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;

  requestNotifyUpdateProvider: (
    payload: ResetNotifyUpdateProviderPayload,
  ) => Action;
  loadingNotifyUpdateProvider: () => Action;
  commitNotifyUpdateProvider: (payload: void) => MyAction<void>;
  successNotifyUpdateProvider: (payload: void) => MyAction<void>;
  errorNotifyUpdateProvider: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface AdminToolsState<TError extends Error = Error> {
  resetPassword: {
    loading: boolean;
    success: boolean;
    error?: TError;
  };
  notifyUpdateProvider: {
    loading: boolean;
    success: boolean;
    error?: TError;
  };
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestResetPassword: ['payload'],
  loadingResetPassword: [],
  commitResetPassword: [],
  successResetPassword: [],
  errorResetPassword: ['error'],

  requestNotifyUpdateProvider: ['payload'],
  loadingNotifyUpdateProvider: [],
  commitNotifyUpdateProvider: [],
  successNotifyUpdateProvider: [],
  errorNotifyUpdateProvider: ['error'],
});

const initialState: AdminToolsState<Error> = {
  resetPassword: {
    success: false,
    loading: false,
  },
  notifyUpdateProvider: {
    success: false,
    loading: false,
  },
};

function setLoading(state: AdminToolsState): AdminToolsState {
  return {
    ...state,
    resetPassword: {
      success: false,
      loading: true,
    },
  };
}

function commitResetPassword(
  state: AdminToolsState,
  action: MyAction<void>,
): AdminToolsState {
  return {
    ...state,
    resetPassword: {
      success: true,
      loading: false,
    },
  };
}

function setError<TError extends Error = Error>(
  state: AdminToolsState,
  { error }: ErrorAction<TError>,
): AdminToolsState {
  return {
    ...state,
    resetPassword: {
      error,
      success: false,
      loading: false,
    },
  };
}

function setLoadingNotifyUpdateProvider(
  state: AdminToolsState,
): AdminToolsState {
  return {
    ...state,
    notifyUpdateProvider: {
      success: false,
      loading: true,
    },
  };
}

function commitNotifyUpdateProvider(
  state: AdminToolsState,
  action: MyAction<void>,
): AdminToolsState {
  return {
    ...state,
    notifyUpdateProvider: {
      success: true,
      loading: false,
    },
  };
}

function setErrorNotifyUpdateProvider<TError extends Error = Error>(
  state: AdminToolsState,
  { error }: ErrorAction<TError>,
): AdminToolsState {
  return {
    ...state,
    notifyUpdateProvider: {
      error,
      success: false,
      loading: false,
    },
  };
}

export const adminToolsReducer = createReducer(initialState, {
  [Types.LOADING_RESET_PASSWORD]: setLoading,
  [Types.COMMIT_RESET_PASSWORD]: commitResetPassword,
  [Types.ERROR_RESET_PASSWORD]: setError,
  [Types.LOADING_NOTIFY_UPDATE_PROVIDER]: setLoadingNotifyUpdateProvider,
  [Types.COMMIT_NOTIFY_UPDATE_PROVIDER]: commitNotifyUpdateProvider,
  [Types.ERROR_NOTIFY_UPDATE_PROVIDER]: setErrorNotifyUpdateProvider,
});

async function sendResetPassword({
  email,
}: ArgsWithHeaders<ResetPasswordPayload>): Promise<void> {
  try {
    await sendPasswordResetEmail(auth, email);
    return;
  } catch (e) {
    console.log(e);
    throw new Error('An error has occurred sending your recovery email');
  }
}

async function sendNotifyUpdateProvider({
  headers,
  ...payload
}: ArgsWithHeaders<ResetNotifyUpdateProviderPayload>): Promise<void> {
  const result = await fetch(
    `${API_URL}/providers/${payload.id}/notify-update-provide`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

const watcherResetPassword = createSingleEventSaga<
  object,
  void,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_RESET_PASSWORD,
  loadingAction: Creators.loadingResetPassword,
  commitAction: Creators.commitResetPassword,
  successAction: (payload: void) => {
    toast.success('Action executed successfully');

    return Creators.successResetPassword(payload);
  },
  errorAction: <TError extends Error>(error: TError) => {
    toast.error(error.message);

    return Creators.errorResetPassword(error);
  },
  action: sendResetPassword,
  beforeAction: putAuthInfoInArgs,
});

const watcherNotifyUpdateProvider = createSingleEventSaga<
  object,
  void,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_NOTIFY_UPDATE_PROVIDER,
  loadingAction: Creators.loadingNotifyUpdateProvider,
  commitAction: Creators.commitNotifyUpdateProvider,
  successAction: Creators.successNotifyUpdateProvider,
  errorAction: Creators.errorNotifyUpdateProvider,
  action: sendNotifyUpdateProvider,
  beforeAction: putAuthInfoInArgs,
});

export const adminToolsSagas = [
  watcherResetPassword,
  watcherNotifyUpdateProvider,
];

export const selectAdminToolsState = (state: MyState) => state.adminTools;
