import OrganizationTypeForm from './OrganizationTypeForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as OrganizationTypesActions } from 'modules/organization-types.module';

const NewOrganizationType = () => {
  const { requestCreateOrganizationType } = useBoundedActions(
    OrganizationTypesActions,
  );

  return (
    <div className="container p-2">
      <OrganizationTypeForm onSubmit={requestCreateOrganizationType} />
    </div>
  );
};

export default NewOrganizationType;
