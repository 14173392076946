import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  ErrorAction,
  EntityState,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { Credential, SagaIterator } from 'typings';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_CREATE_CREDENTIAL: string;
  REQUEST_UPDATE_CREDENTIAL: string;
  REQUEST_REMOVE_CREDENTIAL: string;
  LOADING_CREDENTIALS: string;
  COMMIT_CREDENTIAL: string;
  COMMIT_CREDENTIALS: string;
  ERROR_CREDENTIALS: string;
  REMOVE_CREDENTIAL: string;
  REQUEST_CREDENTIALS_SEARCH: string;
}

interface ActionCreators {
  requestCredentialsSearch: (payload: {
    credential: string;
  }) => MyAction<{ credential: string }>;
  requestCreateCredential: (payload: Credential) => MyAction<Credential>;
  requestUpdateCredential: (
    payload: Partial<Credential>,
  ) => MyAction<Partial<Credential>>;
  requestRemoveCredential: (
    payload: Partial<{ id: number }>,
  ) => MyAction<Partial<{ id: number }>>;
  loadingCredentials: () => Action;
  commitCredentials: (
    payload: Paginated<Credential>,
  ) => MyAction<Paginated<Credential>>;
  commitCredential: (payload: Credential) => MyAction<Credential>;
  removeCredential: (payload: { id: number }) => MyAction<{ id: number }>;
  errorCredentials: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface CredentialsState<TError extends Error = Error>
  extends EntityState<Credential> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateCredential: ['payload'],
  requestUpdateCredential: ['payload'],
  requestRemoveCredential: ['payload'],
  requestCredentialsSearch: ['payload'],
  requestCredentials: [],
  loadingCredentials: [],
  commitCredentials: ['payload'],
  commitCredential: ['payload'],
  errorCredentials: ['error'],
  removeCredential: ['payload'],
});

const entityAdapter = createEntityAdapter<Credential>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const credentialsSelector = entityAdapter.getSelectors();

function setLoading(state: CredentialsState): CredentialsState {
  return {
    ...state,
    loading: true,
  };
}

function commitCredentials(
  state: CredentialsState,
  action: MyAction<Paginated<Credential>>,
): CredentialsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitCredential(
  state: CredentialsState,
  action: MyAction<Credential>,
): CredentialsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: CredentialsState,
  { error }: ErrorAction<TError>,
): CredentialsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const credentialsReducer = createReducer(initialState, {
  [Types.LOADING_CREDENTIALS]: setLoading,
  [Types.COMMIT_CREDENTIALS]: commitCredentials,
  [Types.ERROR_CREDENTIALS]: setError,
  [Types.COMMIT_CREDENTIAL]: commitCredential,
  [Types.REMOVE_CREDENTIAL]: removeCredential,
});

async function requestCredentials({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<Credential> {
  const result = await fetch(`${API_URL}/credentials/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

function removeCredential(
  state: CredentialsState,
  action: MyAction<{ id: number }>,
): CredentialsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

async function createCredential({
  headers,
  ...payload
}: ArgsWithHeaders<Credential>): Promise<CreationResult<Credential>> {
  const result = await fetch(`${API_URL}/credentials`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });
  const res = await result.json();
  const categoriesIds = payload.category.map((id) => ({ id }));
  const resultCat = await fetch(
    `${API_URL}/credentials/categories/${res.createdId}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify({ categories: categoriesIds }),
    },
  );

  if (!result.ok || !resultCat.ok) {
    if (result.status === UNAUTHORIZED || resultCat.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return res;
}

async function downloadCredentialsSearch({
  headers,
  ...payload
}: ArgsWithHeaders<{ credential: string }>): Promise<Paginated<Credential>> {
  const result = await fetch(`${API_URL}/credentials?${payload.credential}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

function* updateCredential({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Credential>>): SagaIterator {
  const credential = yield select((state) =>
    selectCredentialsById(state, payload.id),
  );

  const updatedCredential = deepDiffBetweenObjects(payload, {
    ...credential,
    category: credential.category.map(({ id }) => id),
  });

  if (!Object.keys(updatedCredential).length) {
    return;
  }

  const result = yield call(fetch, `${API_URL}/credentials/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(updatedCredential),
  });

  const categoriesIds = payload.category.map((id) => ({ id }));

  const resultCat = yield call(
    fetch,
    `${API_URL}/credentials/categories/${payload.id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify({ categories: categoriesIds }),
    },
  );

  if (!result.ok || !resultCat.ok) {
    if (result.status === UNAUTHORIZED || resultCat.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteCredential({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<void> {
  const result = await fetch(`${API_URL}/credentials/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestCredentialWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Credential,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/credentials/:id'),
  loadingAction: Creators.loadingCredentials,
  commitAction: Creators.commitCredential,
  successAction: noOpAction,
  errorAction: Creators.errorCredentials,
  action: requestCredentials,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: number },
    ArgsWithHeaders<{ id: number }>
  >(extractRouteParams('/credentials/:id'), putAuthInfoInArgs),
});

const goBack = goBackFactory('/credentials');

const requestCreateCredentialWatcher = createSingleEventSaga<
  Credential,
  Credential,
  MyAction<Credential>
>({
  takeEvery: Types.REQUEST_CREATE_CREDENTIAL,
  loadingAction: Creators.loadingCredentials,
  commitAction: Creators.commitCredential,
  successAction: goBack.action,
  errorAction: Creators.errorCredentials,
  action: createCredential,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Credential>,
    { headers, ...args }: ArgsWithHeaders<Credential>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateCredentialWatcher = createSingleEventSaga<
  Partial<Credential>,
  Partial<Credential>,
  MyAction<Partial<Credential>>
>({
  takeEvery: Types.REQUEST_UPDATE_CREDENTIAL,
  loadingAction: Creators.loadingCredentials,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorCredentials,
  action: updateCredential,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteCredentialWatcher = createSingleEventSaga<
  { id: number },
  void,
  MyAction<{ id: number }>
>({
  takeEvery: Types.REQUEST_REMOVE_CREDENTIAL,
  loadingAction: Creators.loadingCredentials,
  commitAction: Creators.removeCredential,
  successAction: noOpAction,
  errorAction: Creators.errorCredentials,
  action: deleteCredential,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<{ id: number }>,
  ): SagaIterator {
    return args;
  },
});

const requestCredentialsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Credential>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/credentials'),
  loadingAction: Creators.loadingCredentials,
  commitAction: Creators.commitCredentials,
  successAction: noOpAction,
  errorAction: Creators.errorCredentials,
  action: downloadUsingLocationQuery<Credential>('credentials'),
  beforeAction: putAuthInfoInArgs,
});

const requestCredentialsSearchWatcher = createSingleEventSaga<
  { credential: string },
  Paginated<Credential>,
  MyAction<{ credential: string }>
>({
  takeEvery: Types.REQUEST_CREDENTIALS_SEARCH,
  loadingAction: Creators.loadingCredentials,
  commitAction: Creators.commitCredentials,
  successAction: noOpAction,
  errorAction: Creators.errorCredentials,
  action: downloadCredentialsSearch,
  beforeAction: putAuthInfoInArgs,
});

export const credentialsSagas = [
  requestCredentialsWatcher,
  requestCreateCredentialWatcher,
  requestUpdateCredentialWatcher,
  requestDeleteCredentialWatcher,
  requestCredentialWatcher,
  requestCredentialsSearchWatcher,
  goBack.watcher,
];

export function credentialFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    label: true,
    description: true,
  };

  if (!values['label'].trim()) {
    result['label'] = false;
  }

  if (!values['description'].trim()) {
    result['description'] = false;
  }

  return Promise.resolve(result);
}

const selectCredentialsState = (state: MyState) => state.credentials;

export const selectCredentials = createSelector(
  selectCredentialsState,
  (credentialsState) => credentialsSelector.selectAll(credentialsState),
);

export const selectCredentialsById = createSelector(
  [selectCredentialsState, (_, id) => id],
  (state, id) => state.entities[id],
);
