import { Action } from 'redux';
import { createSelector } from 'reselect';
import { Organization, SagaIterator } from 'typings';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  onRoutes,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_ALL_ORGANIZATIONS: string;
  REQUEST_CREATE_ORGANIZATION: string;
  REQUEST_REMOVE_ORGANIZATION: string;
  REQUEST_UPDATE_ORGANIZATION: string;
  LOADING_ORGANIZATIONS: string;
  COMMIT_ORGANIZATIONS: string;
  COMMIT_ORGANIZATION: string;
  REMOVE_ORGANIZATION: string;
  ERROR_ORGANIZATIONS: string;
}

export type CreateOrganizationPayload = Omit<
  Organization,
  'id' | 'recommendedImmunizations' | 'requiredImmunizations' | 'promoCodeId'
> & {
  promoCode?: 'true' | 'false';
  requiredImmunizations?: number[];
  recommendedImmunizations?: number[];
  immunizationRequired: boolean;
};

export type UpdateOrganizationPayload = Partial<
  Omit<
    Organization,
    | 'recommendedImmunizations'
    | 'requiredImmunizations'
    | 'promoCodeId'
    | 'organizationTypeId'
  >
> & {
  organizationTypeId: number;
  promoCode?: 'true' | 'false';
  requiredImmunizations?: number[];
  recommendedImmunizations?: number[];
};

type RemoveOrganizationPayload = Pick<Organization, 'id'>;

interface ActionCreators {
  requestAllOrganizations: () => Action;
  requestCreateOrganization: (
    payload: CreateOrganizationPayload,
  ) => MyAction<Organization>;
  requestUpdateOrganization: (
    payload: UpdateOrganizationPayload,
  ) => MyAction<RemoveOrganizationPayload>;
  requestRemoveOrganization: (
    payload: RemoveOrganizationPayload,
  ) => MyAction<Organization>;
  loadingOrganizations: () => Action;
  commitOrganizations: (
    payload: Paginated<Organization>,
  ) => MyAction<Paginated<Organization>>;
  commitOrganization: (payload: Organization) => MyAction<Organization>;
  removeOrganization: (
    payload: RemoveOrganizationPayload,
  ) => MyAction<RemoveOrganizationPayload>;
  errorOrganizations: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface OrganizationsState<TError extends Error = Error>
  extends EntityState<Organization> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAllOrganizations: [],
  requestCreateOrganization: ['payload'],
  requestUpdateOrganization: ['payload'],
  requestRemoveOrganization: ['payload'],
  loadingOrganizations: [],
  commitOrganizations: ['payload'],
  commitOrganization: ['payload'],
  removeOrganization: ['payload'],
  errorOrganizations: ['error'],
});

const entityAdapter = createEntityAdapter<Organization>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

const organizationsSelectors = entityAdapter.getSelectors();

function setLoading(state: OrganizationsState): OrganizationsState {
  return {
    ...state,
    loading: true,
  };
}

function commitOrganization(
  state: OrganizationsState,
  action: MyAction<Organization>,
): OrganizationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitOrganizations(
  state: OrganizationsState,
  action: MyAction<Paginated<Organization>>,
): OrganizationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeOrganization(
  state: OrganizationsState,
  action: MyAction<RemoveOrganizationPayload>,
): OrganizationsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: OrganizationsState,
  { error }: ErrorAction<TError>,
): OrganizationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const organizationsReducer = createReducer(initialState, {
  [Types.LOADING_ORGANIZATIONS]: setLoading,
  [Types.COMMIT_ORGANIZATIONS]: commitOrganizations,
  [Types.COMMIT_ORGANIZATION]: commitOrganization,
  [Types.REMOVE_ORGANIZATION]: removeOrganization,
  [Types.ERROR_ORGANIZATIONS]: setError,
});

const goBack = goBackFactory('/organizations');

async function downloadOrganization({
  headers,
  id,
}: ArgsWithHeaders<Pick<Organization, 'id'>>) {
  const result = await fetch(`${API_URL}/organizations/${id}`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function insertImmunizationsForOrganization({
  headers,
  organizationId,
  requiredImmunizations,
  recommendedImmunizations,
}: ArgsWithHeaders<
  Pick<
    CreateOrganizationPayload,
    'recommendedImmunizations' | 'requiredImmunizations'
  > & { organizationId: string }
>) {
  if (requiredImmunizations?.length) {
    await fetch(
      `${API_URL}/organization-immunizations/required/${organizationId} `,
      {
        headers,
        method: 'POST',
        body: JSON.stringify({ ids: requiredImmunizations }),
      },
    );
  }

  if (recommendedImmunizations?.length) {
    await fetch(
      `${API_URL}/organization-immunizations/recommended/${organizationId} `,
      {
        headers,
        method: 'POST',
        body: JSON.stringify({ ids: recommendedImmunizations }),
      },
    );
  }
}

async function createOrganization({
  headers,
  requiredImmunizations,
  recommendedImmunizations,
  promoCode,
  immunizationRequired,
  ...payload
}: ArgsWithHeaders<CreateOrganizationPayload>) {
  const result = await fetch(`${API_URL}/organizations`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  const { createdId: organizationId } = await result.json();

  if (promoCode === 'true') {
    await fetch(`${API_URL}/organizations/add-promo-code/${organizationId}`, {
      headers,
      method: 'POST',
    });
  } else if (promoCode === 'false') {
    await fetch(
      `${API_URL}/organizations/remove-promo-code/${organizationId}`,
      {
        headers,
        method: 'DELETE',
      },
    );
  }

  await insertImmunizationsForOrganization({
    headers,
    organizationId,
    requiredImmunizations,
    recommendedImmunizations,
  });

  return { createdId: organizationId };
}

async function updateOrganization({
  id,
  headers,
  promoCode,
  requiredImmunizations,
  recommendedImmunizations,
  ...payload
}: ArgsWithHeaders<UpdateOrganizationPayload>) {
  if (promoCode === 'true') {
    await fetch(`${API_URL}/organizations/add-promo-code/${id}`, {
      headers,
      method: 'POST',
    });
  } else if (promoCode === 'false') {
    await fetch(`${API_URL}/organizations/remove-promo-code/${id}`, {
      headers,
      method: 'DELETE',
    });
  }

  const result = await fetch(`${API_URL}/organizations/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  await insertImmunizationsForOrganization({
    headers,
    organizationId: id.toString(),
    requiredImmunizations,
    recommendedImmunizations,
  });
}

async function removeOrganizationRequest({
  headers,
  id,
}: ArgsWithHeaders<Pick<Organization, 'id'>>) {
  const result = await fetch(`${API_URL}/organizations/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function getAllOrganizations({ headers }: ArgsWithHeaders) {
  const response = await fetch(`${API_URL}/organizations?__limit=1000`, {
    headers,
  });

  if (!response.ok) {
    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await response.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return response.json();
}

const requestAllOrganizationsWatcher = createSingleEventSaga<
  void,
  Paginated<Organization>,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_ALL_ORGANIZATIONS,
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.commitOrganizations,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizations,
  action: getAllOrganizations,
  beforeAction: putAuthInfoInArgs,
});

const requestOrganizationsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Organization>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/organizations'),
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.commitOrganizations,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizations,
  action: downloadUsingLocationQuery<Organization>('organizations'),
  beforeAction: putAuthInfoInArgs,
});

const requestOrganizationWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Organization,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/organizations/:id'),
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.commitOrganization,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizations,
  action: downloadOrganization,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    Pick<Organization, 'id'>,
    ArgsWithHeaders<Pick<Organization, 'id'>>
  >(extractRouteParams('/organizations/:id'), putAuthInfoInArgs),
});

const requestCreateOrganizationWatcher = createSingleEventSaga<
  Organization,
  Organization,
  MyAction<Organization>
>({
  takeEvery: Types.REQUEST_CREATE_ORGANIZATION,
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.commitOrganization,
  successAction: goBack.action,
  errorAction: Creators.errorOrganizations,
  action: createOrganization,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Organization>,
    {
      headers,
      recommendedImmunizations,
      requiredImmunizations,
      ...args
    }: ArgsWithHeaders<Organization>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateOrganizationWatcher = createSingleEventSaga<
  Partial<Organization>,
  Partial<Organization>,
  MyAction<Partial<Organization>>
>({
  takeEvery: Types.REQUEST_UPDATE_ORGANIZATION,
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.commitOrganization,
  successAction: goBack.action,
  errorAction: Creators.errorOrganizations,
  action: updateOrganization,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    {
      headers,
      recommendedImmunizations,
      requiredImmunizations,
      ...args
    }: ArgsWithHeaders<Organization>,
  ): SagaIterator {
    return args;
  },
});

const requestRemoveOrganizationWatcher = createSingleEventSaga<
  Pick<Organization, 'id'>,
  void,
  MyAction<Pick<Organization, 'id'>>
>({
  takeEvery: Types.REQUEST_REMOVE_ORGANIZATION,
  loadingAction: Creators.loadingOrganizations,
  commitAction: Creators.removeOrganization,
  successAction: noOpAction,
  errorAction: Creators.errorOrganizations,
  action: removeOrganizationRequest,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Pick<Organization, 'id'>>,
  ): SagaIterator {
    return args;
  },
});

export const selectOrganizationsState = (state: MyState) => state.organizations;

export const selectOrganizations = createSelector(
  selectOrganizationsState,
  (organizationState) => organizationsSelectors.selectAll(organizationState),
);

export const selectOrganizationById = createSelector(
  [selectOrganizationsState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const organizationsSagas = [
  requestCreateOrganizationWatcher,
  requestRemoveOrganizationWatcher,
  requestUpdateOrganizationWatcher,
  requestAllOrganizationsWatcher,
  requestOrganizationsWatcher,
  requestOrganizationWatcher,
  goBack.watcher,
];
