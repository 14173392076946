import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  FORM_FIELDS,
  FORM_FIELDS_CONFIG,
  ADMIN_AVAILABLE_ACTIONS,
} from './config';
import { User } from 'modules/users.module';
import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import { UserFields } from 'modules/user-invitation.module';

type Props = {
  user: User;
  onSubmit: (formFields: Omit<UserFields, 'email'>) => void;
};

const EditUserForm: FC<Props> = ({ onSubmit, user }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [FORM_FIELDS.EMAIL]: user.email,
      [FORM_FIELDS.FIRST_NAME]: user.firstName,
      [FORM_FIELDS.LAST_NAME]: user.lastName,
      [FORM_FIELDS.ROLE_DESCRIPTION]: user.roleDescription ?? '',
      [FORM_FIELDS.JOB_TITLE]: user.jobTitle ?? '',
    },
  });

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      {FORM_FIELDS_CONFIG.map((fieldConfig) => (
        <Controller
          control={control}
          key={fieldConfig.name}
          name={fieldConfig.name}
          disabled={fieldConfig.disabled}
          rules={{
            required: fieldConfig.required,
          }}
          render={({ field: { ref: _, ...fieldControl } }) => (
            <MMDTextInput
              valid={!errors[fieldConfig.name]}
              {...fieldConfig}
              {...fieldControl}
            />
          )}
        />
      ))}

      <strong>Available actions</strong>

      <ul>
        {ADMIN_AVAILABLE_ACTIONS[user.roleId].split(',').map((action) => (
          <li key={action}>{action}</li>
        ))}
      </ul>

      <MMDButton isSubmit isPrimary text="Update" />
    </form>
  );
};

export default EditUserForm;
