import { createActions, createReducer } from 'reduxsauce';
import { Action } from 'redux';

import {
  ErrorAction,
  MyAction,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { putAuthInfoInArgs } from './auth.module';
import { PaymentFrequency } from '../typings';
import { noOpAction } from 'utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { API_URL } from '../config';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import moment from 'moment';

interface ActionTypes {
  REQUEST_CREATE_PAYMENT_FREQUENCY: string;
  LOADING_PAYMENT_FREQUENCY: string;
  ERROR_PAYMENT_FREQUENCY: string;
  COMMIT_PAYMENT_FREQUENCY: string;
}

interface PaymentFrequencyPayload {
  paymentFrequency: number;
}

interface ActionCreators {
  requestCreatePaymentFrequency: (
    payload: PaymentFrequencyPayload,
  ) => MyAction<PaymentFrequencyPayload>;
  loadingPaymentFrequency: () => Action;
  errorPaymentFrequency: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
  commitPaymentFrequency: (
    payload: PaymentFrequency,
  ) => MyAction<PaymentFrequency>;
}

const initialState = {
  loading: false,
  currentPaymentFrequency: {},
};

export interface PaymentFrequencyState<TError extends Error = Error> {
  loading: boolean;
  error?: TError;
  currentPaymentFrequency: PaymentFrequency;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePaymentFrequency: ['payload'],
  loadingPaymentFrequency: [],
  errorPaymentFrequency: ['error'],
  commitPaymentFrequency: ['payload'],
});

export const paymentFrequencyReducer = createReducer(initialState, {
  [Types.LOADING_PAYMENT_FREQUENCY]: setLoading,
  [Types.ERROR_PAYMENT_FREQUENCY]: setError,
  [Types.COMMIT_PAYMENT_FREQUENCY]: commitSplitPercentage,
});

function setLoading(state: PaymentFrequencyState): PaymentFrequencyState {
  return {
    ...state,
    loading: true,
  };
}

function commitSplitPercentage(
  state: PaymentFrequencyState,
  action: MyAction<PaymentFrequency>,
): PaymentFrequencyState {
  return {
    ...state,
    currentPaymentFrequency: action.payload,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PaymentFrequencyState,
  { error }: ErrorAction<TError>,
): PaymentFrequencyState {
  return {
    ...state,
    error,
    loading: false,
  };
}

async function createPaymentFrequency({
  headers,
  ...payload
}: ArgsWithHeaders<PaymentFrequencyPayload>): Promise<PaymentFrequency> {
  const now = moment().toISOString();
  const newPaymentFrequency = {
    frequencyInWeeks: payload.paymentFrequency,
    createdAt: now,
    updatedAt: now,
  };

  const result = await fetch(`${API_URL}/payment-frequencies`, {
    headers,
    method: 'POST',
    body: JSON.stringify(newPaymentFrequency),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadPaymentFrequency({
  headers,
}: ArgsWithHeaders<PaymentFrequencyPayload>): Promise<PaymentFrequency> {
  const result = await fetch(
    `${API_URL}/payment-frequencies?__sort=-createdAt&__limit=1`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  const topPaymentFrequency = await result.json();

  return topPaymentFrequency.data[0];
}

const requestCreatePaymentFrequencyWatcher = createSingleEventSaga<
  PaymentFrequency,
  PaymentFrequency,
  MyAction<PaymentFrequency>
>({
  takeEvery: Types.REQUEST_CREATE_PAYMENT_FREQUENCY,
  loadingAction: Creators.loadingPaymentFrequency,
  beforeAction: putAuthInfoInArgs,
  action: createPaymentFrequency,
  commitAction: Creators.commitPaymentFrequency,
  successAction: noOpAction,
  errorAction: Creators.errorPaymentFrequency,
});

const requestPaymentFrequencyWatcher = createSingleEventSaga<
  PaymentFrequency,
  PaymentFrequency,
  MyAction<PaymentFrequency>
>({
  takeEvery: onRoute('/payment-config'),
  loadingAction: Creators.loadingPaymentFrequency,
  beforeAction: putAuthInfoInArgs,
  action: downloadPaymentFrequency,
  commitAction: Creators.commitPaymentFrequency,
  successAction: noOpAction,
  errorAction: Creators.errorPaymentFrequency,
});

export const paymentFrequencySagas = [
  requestCreatePaymentFrequencyWatcher,
  requestPaymentFrequencyWatcher,
];
