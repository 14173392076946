import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from 'modules/auth.module';
import { BookingPublic, BookingPublicInList } from 'typings';
import { extractRouteParams, goBackFactory, onRoutes } from 'utils/onRoute';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';

interface ActionTypes {
  LOADING_PUBLIC_BOOKINGS: string;
  COMMIT_PUBLIC_BOOKING: string;
  COMMIT_PUBLIC_BOOKINGS: string;
  ERROR_PUBLIC_BOOKINGS: string;
}

interface ActionCreators {
  loadingPublicBookings: () => Action;
  commitPublicBooking: (payload: BookingPublic) => MyAction<BookingPublic>;
  commitPublicBookings: (
    payload: Paginated<BookingPublicInList>,
  ) => MyAction<Paginated<BookingPublicInList>>;
  errorPublicBookings: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface BookingsPublicState<TError extends Error = Error>
  extends EntityState<BookingPublicInList> {
  loading: boolean;
  count: number;
  error?: TError;
  bookingPublic: BookingPublic;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingPublicBookings: [],
  commitPublicBookings: ['payload'],
  commitPublicBooking: ['payload'],
  errorPublicBookings: ['error'],
});

const entityAdapter = createEntityAdapter<BookingPublicInList>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

export const bookingsPublicSelector = entityAdapter.getSelectors();

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

function setLoading(state: BookingsPublicState): BookingsPublicState {
  return {
    ...state,
    loading: true,
  };
}

function commitBookingsPublic(
  state: BookingsPublicState,
  action: MyAction<Paginated<BookingPublicInList>>,
): BookingsPublicState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    bookingPublic: null,
    count: action.payload.count,
    loading: false,
  };
}

function commitBookingPublic(
  state: BookingsPublicState,
  action: MyAction<BookingPublic>,
): BookingsPublicState {
  return {
    ...state,
    bookingPublic: action.payload,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: BookingsPublicState,
  { error }: ErrorAction<TError>,
): BookingsPublicState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const bookingsPublicReducer = createReducer(initialState, {
  [Types.LOADING_PUBLIC_BOOKINGS]: setLoading,
  [Types.COMMIT_PUBLIC_BOOKINGS]: commitBookingsPublic,
  [Types.COMMIT_PUBLIC_BOOKING]: commitBookingPublic,
  [Types.ERROR_PUBLIC_BOOKINGS]: setError,
});

function getActionForLoadPublicBookings(
  providerUrlSegment: string,
  patientUrlSegment: string,
) {
  return function* (payload: ArgsWithHeaders<LocationChangeActionPayload>) {
    const isProvider = payload.location.pathname.includes('provider');

    const params = yield call(
      extractRouteParams(isProvider ? providerUrlSegment : patientUrlSegment),
      payload,
    );

    const queryPath = isProvider ? 'get-by-doctor-id' : 'get-by-patient-id';

    return yield downloadUsingLocationQuery<BookingPublicInList>(
      `bookings-public/${queryPath}/${params.id}`,
    )(payload);
  };
}

function getActionForLoadBooking(
  providerUrlSegment: string,
  patientUrlSegment: string,
) {
  return function* (payload: ArgsWithHeaders<LocationChangeActionPayload>) {
    const isProvider = payload.location.pathname.includes('provider');

    const params = yield call(
      extractRouteParams(isProvider ? providerUrlSegment : patientUrlSegment),
      payload,
    );

    return yield downloadUsingLocationQuery<BookingPublicInList>(
      `bookings-public/${params.bookingId}`,
    )(payload);
  };
}

const allBookingsUrlSegment = '/:id/bookings-public';
const bookingUrlSegment = '/:id/bookings-public/:bookingId';

const urlForProvider = (url: string) => `/providers${url}`;

const urlForPatient = (url: string) => `/patients${url}`;

const requestBookingsPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<BookingPublicInList>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoutes(
    urlForProvider(allBookingsUrlSegment),
    urlForPatient(allBookingsUrlSegment),
  ),
  loadingAction: Creators.loadingPublicBookings,
  commitAction: Creators.commitPublicBookings,
  successAction: noOpAction,
  errorAction: Creators.errorPublicBookings,
  action: getActionForLoadPublicBookings(
    urlForProvider(allBookingsUrlSegment),
    urlForPatient(allBookingsUrlSegment),
  ),
  beforeAction: putAuthInfoInArgs,
});

const requestBookingPublicWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  BookingPublic,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoutes(
    urlForProvider(bookingUrlSegment),
    urlForPatient(bookingUrlSegment),
  ),
  loadingAction: Creators.loadingPublicBookings,
  commitAction: Creators.commitPublicBooking,
  successAction: noOpAction,
  errorAction: Creators.errorPublicBookings,
  action: getActionForLoadBooking(
    urlForProvider(bookingUrlSegment),
    urlForPatient(bookingUrlSegment),
  ),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/bookings-public');

export const bookingsPublicSagas = [
  requestBookingsPublicWatcher,
  requestBookingPublicWatcher,
  goBack.watcher,
];

export const selectBookingsPublicState = (state: MyState) =>
  state.bookingsPublic;

export const selectBookingsPublic = createSelector(
  selectBookingsPublicState,
  (state) => bookingsPublicSelector.selectAll(state),
);
