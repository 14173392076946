import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { PromoCode, SagaIterator } from '../typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import { noOpAction } from '../utils/noOpAction';
import { isNumber } from '../utils/isNumber';
import { downloadUsingLocationQuery } from '../utils/downloadUsingLocationQuery';
import { sum, reduce } from 'lodash';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface PromoCodePayload {
  id: number;
}

interface ActionTypes {
  REQUEST_CREATE_PROMO_CODE: string;
  REQUEST_UPDATE_PROMO_CODE: string;
  REQUEST_REMOVE_PROMO_CODE: string;
  LOADING_PROMO_CODES: string;
  COMMIT_PROMO_CODES: string;
  COMMIT_PROMO_CODE: string;
  REMOVE_PROMO_CODE: string;
  ERROR_PROMO_CODES: string;
}

interface ActionCreators {
  requestCreatePromoCode: (payload: PromoCode) => MyAction<PromoCode>;
  requestUpdatePromoCode: (
    payload: Partial<PromoCode>,
  ) => MyAction<Partial<PromoCode>>;
  requestRemovePromoCode: (
    payload: PromoCodePayload,
  ) => MyAction<PromoCodePayload>;
  loadingPromoCodes: () => Action;
  commitPromoCodes: (
    payload: Paginated<PromoCode>,
  ) => MyAction<Paginated<PromoCode>>;
  commitPromoCode: (payload: PromoCode) => MyAction<PromoCode>;
  removePromoCode: (payload: PromoCodePayload) => MyAction<PromoCodePayload>;
  errorPromoCodes: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface PromoCodesState<TError extends Error = Error>
  extends EntityState<PromoCode> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePromoCode: ['payload'],
  requestUpdatePromoCode: ['payload'],
  requestRemovePromoCode: ['payload'],
  loadingPromoCodes: [],
  commitPromoCodes: ['payload'],
  commitPromoCode: ['payload'],
  removePromoCode: ['payload'],
  errorPromoCodes: ['error'],
});

const entityAdapter = createEntityAdapter<PromoCode>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const promoCodesSelectors = entityAdapter.getSelectors();

function setLoading(state: PromoCodesState): PromoCodesState {
  return {
    ...state,
    loading: true,
  };
}

function commitPromoCode(
  state: PromoCodesState,
  action: MyAction<PromoCode>,
): PromoCodesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitPromoCodes(
  state: PromoCodesState,
  action: MyAction<Paginated<PromoCode>>,
): PromoCodesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removePromoCode(
  state: PromoCodesState,
  action: MyAction<PromoCodePayload>,
): PromoCodesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PromoCodesState,
  { error }: ErrorAction<TError>,
): PromoCodesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const promoCodeReducer = createReducer(initialState, {
  [Types.LOADING_PROMO_CODES]: setLoading,
  [Types.COMMIT_PROMO_CODE]: commitPromoCode,
  [Types.COMMIT_PROMO_CODES]: commitPromoCodes,
  [Types.REMOVE_PROMO_CODE]: removePromoCode,
  [Types.ERROR_PROMO_CODES]: setError,
});

async function downloadPromoCode({
  headers,
  ...payload
}: ArgsWithHeaders<PromoCodePayload>): Promise<PromoCode> {
  const result = await fetch(`${API_URL}/promo-codes/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  return result.json();
}

async function createPromoCode({
  headers,
  ...payload
}: ArgsWithHeaders<PromoCode>): Promise<CreationResult<PromoCode>> {
  const result = await fetch(`${API_URL}/promo-codes`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updatePromoCode({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<PromoCode>>): Promise<void> {
  const result = await fetch(`${API_URL}/promo-codes/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deletePromoCode({
  headers,
  ...payload
}: ArgsWithHeaders<PromoCodePayload>): Promise<void> {
  const result = await fetch(`${API_URL}/promo-codes/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestPromoCodeWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PromoCode,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/promo-codes/:id'),
  loadingAction: Creators.loadingPromoCodes,
  commitAction: Creators.commitPromoCode,
  successAction: noOpAction,
  errorAction: Creators.errorPromoCodes,
  action: downloadPromoCode,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    PromoCodePayload,
    ArgsWithHeaders<PromoCodePayload>
  >(extractRouteParams('/promo-codes/:id'), putAuthInfoInArgs),
});

const requestPromoCodesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<PromoCode>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/promo-codes'),
  loadingAction: Creators.loadingPromoCodes,
  commitAction: Creators.commitPromoCodes,
  successAction: noOpAction,
  errorAction: Creators.errorPromoCodes,
  action: downloadUsingLocationQuery<PromoCode>('promo-codes'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/promo-codes');

const requestCreatePromoCodeWatcher = createSingleEventSaga<
  PromoCode,
  PromoCode,
  MyAction<PromoCode>
>({
  takeEvery: Types.REQUEST_CREATE_PROMO_CODE,
  loadingAction: Creators.loadingPromoCodes,
  commitAction: Creators.commitPromoCode,
  successAction: goBack.action,
  errorAction: Creators.errorPromoCodes,
  action: createPromoCode,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<PromoCode>,
    { headers, ...args }: ArgsWithHeaders<PromoCode>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdatePromoCodeWatcher = createSingleEventSaga<
  Partial<PromoCode>,
  Partial<PromoCode>,
  MyAction<Partial<PromoCode>>
>({
  takeEvery: Types.REQUEST_UPDATE_PROMO_CODE,
  loadingAction: Creators.loadingPromoCodes,
  commitAction: Creators.commitPromoCode,
  successAction: goBack.action,
  errorAction: Creators.errorPromoCodes,
  action: updatePromoCode,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<PromoCode>,
  ): SagaIterator {
    return args;
  },
});

const requestDeletePromoCodeWatcher = createSingleEventSaga<
  PromoCodePayload,
  void,
  MyAction<PromoCodePayload>
>({
  takeEvery: Types.REQUEST_REMOVE_PROMO_CODE,
  loadingAction: Creators.loadingPromoCodes,
  commitAction: Creators.removePromoCode,
  successAction: noOpAction,
  errorAction: Creators.errorPromoCodes,
  action: deletePromoCode,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<PromoCodePayload>,
  ): SagaIterator {
    return args;
  },
});

export const promoCodeSagas = [
  requestPromoCodeWatcher,
  requestPromoCodesWatcher,
  requestCreatePromoCodeWatcher,
  requestUpdatePromoCodeWatcher,
  requestDeletePromoCodeWatcher,
  goBack.watcher,
];

export function promoCodeFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    code: true,
    percentageOff: true,
    amountOff: true,
    externalId: true,
  };

  if (values['code'].length === 0) {
    result.code = false;
  }

  const condFieldsLength = ['percentageOff', 'amountOff', 'externalId'].map(
    (n) => values[n].length,
  );

  if (
    sum(condFieldsLength) < 0 ||
    moreThanOneFieldWithValue(condFieldsLength)
  ) {
    result.percentageOff = false;
    result.amountOff = false;
    result.externalId = false;
  }

  return Promise.resolve(result);
}

const moreThanOneFieldWithValue = (condFieldsLength) =>
  reduce(condFieldsLength, (sum, n) => sum + (n > 0 ? 1 : 0), 0) > 1;

const selectPromoCodesState = (state: MyState) => state.promoCodes;

export const selectPromoCodeById = createSelector(
  [selectPromoCodesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectPromoCodesLoading = createSelector(
  selectPromoCodesState,
  (state) => state.loading,
);
