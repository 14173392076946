const TextAnswer = () => {
  return (
    <div>
      <strong>Text answer</strong>
    </div>
  );
};

TextAnswer.displayName = 'TextAnswer';

export default TextAnswer;
