import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { API_URL } from '../config';
import { SagaIterator, Template } from '../typings';
import { putAuthInfoInArgs } from './auth.module';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface ActionTypes {
  REQUEST_CREATE_TEMPLATE: string;
  REQUEST_UPDATE_TEMPLATE: string;
  REQUEST_REMOVE_TEMPLATE: string;
  LOADING_TEMPLATES: string;
  COMMIT_TEMPLATE: string;
  COMMIT_TEMPLATES: string;
  ERROR_TEMPLATES: string;
  REMOVE_TEMPLATE: string;
  REQUEST_TEMPLATES_SEARCH: string;
}

interface ActionCreators {
  requestTemplatesSearch: (payload: {
    template: string;
  }) => MyAction<{ template: string }>;
  requestCreateTemplate: (payload: Template) => MyAction<Template>;
  requestUpdateTemplate: (
    payload: Partial<Template>,
  ) => MyAction<Partial<Template>>;
  requestRemoveTemplate: (
    payload: Partial<{ id: number }>,
  ) => MyAction<Partial<{ id: number }>>;
  loadingTemplates: () => Action;
  commitTemplates: (
    payload: Paginated<Template>,
  ) => MyAction<Paginated<Template>>;
  commitTemplate: (payload: Template) => MyAction<Template>;
  removeTemplate: (payload: { id: number }) => MyAction<{ id: number }>;
  errorTemplates: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface TemplatesState<TError extends Error = Error>
  extends EntityState<Template> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateTemplate: ['payload'],
  requestUpdateTemplate: ['payload'],
  requestRemoveTemplate: ['payload'],
  requestTemplatesSearch: ['payload'],
  requestTemplates: [],
  loadingTemplates: [],
  commitTemplates: ['payload'],
  commitTemplate: ['payload'],
  errorTemplates: ['error'],
  removeTemplate: ['payload'],
});

const entityAdapter = createEntityAdapter<Template>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const templatesSelector = entityAdapter.getSelectors();

function setLoading(state: TemplatesState): TemplatesState {
  return {
    ...state,
    loading: true,
  };
}

function commitTemplates(
  state: TemplatesState,
  action: MyAction<Paginated<Template>>,
): TemplatesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitTemplate(
  state: TemplatesState,
  action: MyAction<Template>,
): TemplatesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: TemplatesState,
  { error }: ErrorAction<TError>,
): TemplatesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const templatesReducer = createReducer(initialState, {
  [Types.LOADING_TEMPLATES]: setLoading,
  [Types.COMMIT_TEMPLATES]: commitTemplates,
  [Types.ERROR_TEMPLATES]: setError,
  [Types.COMMIT_TEMPLATE]: commitTemplate,
  [Types.REMOVE_TEMPLATE]: removeTemplate,
});

async function requestTemplates({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<Template> {
  const result = await fetch(`${API_URL}/templates?id=${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

function removeTemplate(
  state: TemplatesState,
  action: MyAction<{ id: number }>,
): TemplatesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

async function createTemplate({
  headers,
  ...payload
}: ArgsWithHeaders<Template>): Promise<CreationResult<Template>> {
  const template = {
    id: payload.id,
    title: payload.title,
    credentials: payload.credentials.map((x) => Number(x)),
    sections: payload.sections.map((x, index) => {
      return { name: x, order: index };
    }),
    default: Boolean(payload.default),
  };

  const result = await fetch(`${API_URL}/templates`, {
    headers,
    method: 'POST',
    body: JSON.stringify(template),
  });
  const res = await result.json();

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return res;
}

async function downloadTemplatesSearch({
  headers,
  ...payload
}: ArgsWithHeaders<{ template: string }>): Promise<Paginated<Template>> {
  const result = await fetch(`${API_URL}/template?${payload.template}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateTemplate({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Template>>): Promise<void> {
  //{ id: 13, title: 'template 17',credentials: [ 49, 52 ],sections: [{"name":"Subjective", "order":1}], default: 'true' }
  const template = {
    id: payload.id,
    title: payload.title,
    credentials: payload.credentials.map((x) => Number(x)),
    sections: payload.sections.map((x, index) => {
      return { name: x, order: index };
    }),
    default: payload.default,
  };

  const result = await fetch(`${API_URL}/templates/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(template),
  });
  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteTemplate({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<void> {
  const result = await fetch(`${API_URL}/templates/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestTemplateWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Template,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/templates/:id'),
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.commitTemplate,
  successAction: noOpAction,
  errorAction: Creators.errorTemplates,
  action: requestTemplates,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: number },
    ArgsWithHeaders<{ id: number }>
  >(extractRouteParams('/templates/:id'), putAuthInfoInArgs),
  *afterAction(res?: Paginated<Template>): SagaIterator {
    return res ? res.data.pop() : undefined;
  },
});

const goBack = goBackFactory('/templates');

const requestCreateTemplateWatcher = createSingleEventSaga<
  Template,
  Template,
  MyAction<Template>
>({
  takeEvery: Types.REQUEST_CREATE_TEMPLATE,
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.commitTemplate,
  successAction: goBack.action,
  errorAction: Creators.errorTemplates,
  action: createTemplate,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Template>,
    { headers, ...args }: ArgsWithHeaders<Template>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateTemplateWatcher = createSingleEventSaga<
  Partial<Template>,
  Partial<Template>,
  MyAction<Partial<Template>>
>({
  takeEvery: Types.REQUEST_UPDATE_TEMPLATE,
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.commitTemplate,
  successAction: goBack.action,
  errorAction: Creators.errorTemplates,
  action: updateTemplate,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Template>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteTemplateWatcher = createSingleEventSaga<
  { id: number },
  void,
  MyAction<{ id: number }>
>({
  takeEvery: Types.REQUEST_REMOVE_TEMPLATE,
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.removeTemplate,
  successAction: noOpAction,
  errorAction: Creators.errorTemplates,
  action: deleteTemplate,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<{ id: number }>,
  ): SagaIterator {
    return args;
  },
});

const requestTemplatesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Template>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/templates'),
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.commitTemplates,
  successAction: noOpAction,
  errorAction: Creators.errorTemplates,
  action: downloadUsingLocationQuery<Template>('templates'),
  beforeAction: putAuthInfoInArgs,
});

const requestAllSpecialitiesForTemplateWatcher = createSingleEventSaga<
  { template: string },
  Paginated<Template>,
  MyAction<{ template: string }>
>({
  takeEvery: Types.REQUEST_TEMPLATES_SEARCH,
  loadingAction: Creators.loadingTemplates,
  commitAction: Creators.commitTemplates,
  successAction: noOpAction,
  errorAction: Creators.errorTemplates,
  action: downloadTemplatesSearch,
  beforeAction: putAuthInfoInArgs,
});

export const templatesSagas = [
  requestTemplatesWatcher,
  requestTemplateWatcher,
  requestCreateTemplateWatcher,
  requestUpdateTemplateWatcher,
  requestDeleteTemplateWatcher,
  requestAllSpecialitiesForTemplateWatcher,
  goBack.watcher,
];

export function templateFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    //label: true,
  };

  //  if (values["label"] === "" || !values["label"]) {
  //   result.label = false
  //  }

  return Promise.resolve(result);
}
export function TemplateFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {};
  //  if (values["name"] === "" || !values["name"]) {
  //   result.name = false
  //  }

  //  if (!isNumber(values["monthlyPrice"])) {
  //   result.monthlyPrice = false
  //  }

  //  if (values["isPsychiatry"] === "true") {
  //   if (!isNumber(values["firstTimePrice"])) {
  //    result.firstTimePrice = false
  //   }

  //   if (!isNumber(values["firstTimeLength"])) {
  //    result.firstTimeLength = false
  //   }

  //   if (values["doctorType"] === "" || !values["doctorType"]) {
  //    result.doctorType = false
  //   }
  //  }

  //  if (!isNumber(values["oneTimePrice"])) {
  //   result.oneTimePrice = false
  //  }

  //  if (!isNumber(values["timeSlotLength"])) {
  //   result.timeSlotLength = false
  //  }
  return Promise.resolve(result);
}

const selectTemplatesState = (state: MyState) => state.templates;

export const selectTemplatesById = createSelector(
  [selectTemplatesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectTemplatesLoading = createSelector(
  selectTemplatesState,
  (state) => state.loading,
);
