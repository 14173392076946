import { Dictionary } from '@mrnkr/redux-saga-toolbox';

export function paramsToDictionary(params: string): Dictionary<string> {
  const result: Dictionary<string> = {};

  params
    .substring(1)
    .split('&')
    .forEach((cond) => {
      const [key, value] = cond.split('=');
      result[key] = value;
    });

  return result;
}
