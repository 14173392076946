import ProviderLanguageForm from './ProviderLanguageForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as ProviderLanguagesActions } from 'modules/provider-languages.module';

const NewProviderLanguage = () => {
  const { requestCreateProviderLanguage } = useBoundedActions(
    ProviderLanguagesActions,
  );

  return (
    <div className="container p-2">
      <ProviderLanguageForm onSubmit={requestCreateProviderLanguage} />
    </div>
  );
};

export default NewProviderLanguage;
