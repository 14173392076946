export const MIN_DURATION = 5;
export const MAX_DURATION = 360;

export const validateDuration = (value: number) =>
  value <= MAX_DURATION && value >= MIN_DURATION;

export const getDurationError = (duration: number) =>
  new Map([
    [true, ''],
    [
      duration < MIN_DURATION || isNaN(duration),
      `Duration should be more than or equal ${MIN_DURATION} minutes`,
    ],
    [
      duration > MAX_DURATION,
      `Duration should be less than or equal ${MAX_DURATION} minutes`,
    ],
  ]).get(true);
