import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { call } from 'redux-saga/effects';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { SagaIterator, Session } from '../typings';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import { noOpAction } from '../utils/noOpAction';
import { onRoute, extractRouteParams } from 'utils/onRoute';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

interface EmailPayload {
  email: string;
}

type SessionId = {
  id: string;
};

interface ActionTypes {
  REQUEST_UPDATE_SESSION: string;
  REQUEST_SESSION: string;
  LOADING_SESSIONS: string;
  COMMIT_SESSIONS: string;
  COMMIT_SESSION: string;
  ERROR_SESSIONS: string;
}

interface ActionCreators {
  requestUpdateSession: (
    payload: Partial<Session>,
  ) => MyAction<Partial<Session>>;
  requestSession: (payload: Partial<Session>) => MyAction<Partial<Session>>;
  loadingSessions: () => Action;
  commitSessions: (payload: Paginated<Session>) => MyAction<Paginated<Session>>;
  commitSession: (payload: Session) => MyAction<Session>;
  errorSessions: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface SessionsState<TError extends Error = Error>
  extends EntityState<Session> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdateSession: ['payload'],
  requestSession: ['payload'],
  loadingSessions: [],
  commitSessions: ['payload'],
  commitSession: ['payload'],
  errorSessions: ['error'],
});

const entityAdapter = createEntityAdapter<Session>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const SessionSelectors = entityAdapter.getSelectors();

function setLoading(state: SessionsState): SessionsState {
  return {
    ...state,
    loading: true,
  };
}

function commitSession(
  state: SessionsState,
  action: MyAction<Session>,
): SessionsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitSessions(
  state: SessionsState,
  action: MyAction<Paginated<Session>>,
): SessionsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: SessionsState,
  { error }: ErrorAction<TError>,
): SessionsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const sessionReducer = createReducer(initialState, {
  [Types.LOADING_SESSIONS]: setLoading,
  [Types.COMMIT_SESSION]: commitSession,
  [Types.COMMIT_SESSIONS]: commitSessions,
  [Types.ERROR_SESSIONS]: setError,
});

async function downloadSession({
  headers,
  ...payload
}: ArgsWithHeaders<EmailPayload>): Promise<Session[]> {
  const result = await fetch(
    `${API_URL}/sessions?__sort=updatedAt&email=${payload.email}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  return result.json();
}

async function requestUpdateSession({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Session>>): Promise<void> {
  const result = await fetch(`${API_URL}/sessions/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
}

const sessionWatcherConfigurationFactory = function (urlSegment: string): any {
  return {
    takeEvery: onRoute(urlSegment),
    loadingAction: Creators.loadingSessions,
    commitAction: Creators.commitSessions,
    successAction: noOpAction,
    errorAction: Creators.errorSessions,
    action: downloadUsingLocationQuery<Session>('sessions'),
    beforeAction: composeSagas<
      LocationChangeActionPayload,
      SessionId,
      ArgsWithHeaders<SessionId>
    >(function* (payload: LocationChangeActionPayload): SagaIterator {
      const params = yield call(extractRouteParams(urlSegment), payload);

      return {
        ...payload,
        location: {
          ...payload.location,
          search: `?email=${params.email}`,
        },
      };
    }, putAuthInfoInArgs),
  };
};

const requestProviderSessionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Session>,
  MyAction<LocationChangeActionPayload>
>(sessionWatcherConfigurationFactory('/providers/:email/sessions'));

const requestPatientSessionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Session>,
  MyAction<LocationChangeActionPayload>
>(sessionWatcherConfigurationFactory('/patients/:email/sessions'));

const requestUpdateSessionWatcher = createSingleEventSaga<
  Partial<Session>,
  Partial<Session>,
  MyAction<Partial<Session>>
>({
  takeEvery: Types.REQUEST_UPDATE_SESSION,
  loadingAction: Creators.loadingSessions,
  commitAction: Creators.commitSession,
  successAction: noOpAction,
  errorAction: Creators.errorSessions,
  action: requestUpdateSession,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Session>,
  ): SagaIterator {
    return args;
  },
});

export const sessionSagas = [
  requestProviderSessionWatcher,
  requestPatientSessionWatcher,
  requestUpdateSessionWatcher,
];

export function sessionFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  return Promise.resolve(result);
}
