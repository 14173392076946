import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  ErrorAction,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { Nullable } from 'typings';
import { noOpAction } from 'utils/noOpAction';
import { ArgsWithHeaders } from 'utils/typings';
import { putDefaultHeadersInArgs } from './auth.module';
import {
  NOT_FOUND,
  USER_NOT_FOUND_ERROR,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface RequestMfaVerifyPayload {
  email: string;
  phone: string;
  isVerifiedByGoogle: boolean;
}

interface MfaPayload {
  email: string;
  code: string;
}

interface EnableMfaPayload {
  message: string;
  qrCode: string;
  success: boolean;
}

interface ActionTypes {
  REQUEST_MFA_VERIFY: string;
  VERIFY_MFA: string;
  VERIFY_SMS_MFA: string;
  REQUEST_MFA_CODE: string;
  SUCCESS_MFA: string;
  REQUEST_ENABLE_MFA: string;
  COMMIT_MFA_QR_CODE: string;
  CLEAR_MFA_QR_CODE: string;
  LOADING_MFA: string;
  ERROR_MFA: string;
  CLEAR_MFA_ERROR: string;
  CLEAR_MFA_VERIFY: string;
}

export interface MfaState<TError extends Error = Error> {
  mfaQrCode: Nullable<string>;
  isVerifyActive: boolean;
  isVerifySuccess: boolean;
  loading: boolean;
  phone: Nullable<string>;
  isVerifiedByGoogle: boolean;
  email?: string;
  error?: TError;
}

interface ActionCreators {
  requestMfaVerify: (payload: RequestMfaVerifyPayload) => Action;
  verifyMfa: (payload: MfaPayload) => Action;
  verifySmsMfa: (payload: MfaPayload) => Action;
  requestMfaCode: (payload: Pick<MfaPayload, 'email'>) => Action;
  successMfa: () => Action;
  requestEnableMfa: (payload: Pick<MfaPayload, 'email'>) => Action;
  commitMfaQrCode: (payload: EnableMfaPayload) => MyAction<EnableMfaPayload>;
  clearMfaQrCode: () => Action;
  loadingMfa: () => Action;
  errorMfa: <TError extends Error>(error: TError) => ErrorAction<TError>;
  clearMfaError: () => Action;
  clearMfaVerify: () => Action;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestMfaVerify: ['payload'],
  verifyMfa: ['payload'],
  verifySmsMfa: ['payload'],
  requestMfaCode: ['payload'],
  successMfa: [],
  requestEnableMfa: ['payload'],
  clearMfaQrCode: [],
  commitMfaQrCode: ['payload'],
  loadingMfa: [],
  errorMfa: ['error'],
  clearMfaError: [],
  clearMfaVerify: [],
});

const initialState: MfaState = {
  loading: false,
  mfaQrCode: null,
  isVerifyActive: false,
  isVerifySuccess: false,
  phone: null,
  isVerifiedByGoogle: false,
};

function requestMfaVerify(
  state: MfaState,
  action: MyAction<RequestMfaVerifyPayload>,
): MfaState {
  return {
    ...state,
    isVerifySuccess: false,
    isVerifyActive: true,
    email: action.payload.email,
    phone: action.payload.phone,
    isVerifiedByGoogle: action.payload.isVerifiedByGoogle,
  };
}

function successMfa(state: MfaState): MfaState {
  return {
    ...state,
    isVerifySuccess: true,
    isVerifyActive: false,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: MfaState,
  { error }: ErrorAction<TError>,
): MfaState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function commitMfaQrCode(
  state: MfaState,
  action: MyAction<EnableMfaPayload>,
): MfaState {
  return {
    ...state,
    loading: false,
    mfaQrCode: action.payload.qrCode,
  };
}

function clearMfaQrCode(state: MfaState): MfaState {
  return {
    ...state,
    mfaQrCode: null,
  };
}

function loadingMfa(state: MfaState): MfaState {
  return {
    ...state,
    error: undefined,
    loading: true,
  };
}

function clearError(state: MfaState): MfaState {
  return {
    ...state,
    error: undefined,
  };
}

function clearMfaVerify(state: MfaState): MfaState {
  return {
    ...state,
    isVerifyActive: false,
    isVerifySuccess: false,
  };
}

function enableMFa(state: MfaState): MfaState {
  return {
    ...state,
    loading: true,
  };
}

export const mfaReducer = createReducer(initialState, {
  [Types.REQUEST_MFA_VERIFY]: requestMfaVerify,
  [Types.SUCCESS_MFA]: successMfa,
  [Types.COMMIT_MFA_QR_CODE]: commitMfaQrCode,
  [Types.CLEAR_MFA_QR_CODE]: clearMfaQrCode,
  [Types.LOADING_MFA]: loadingMfa,
  [Types.ERROR_MFA]: setError,
  [Types.CLEAR_MFA_ERROR]: clearError,
  [Types.REQUEST_ENABLE_MFA]: enableMFa,
  [Types.CLEAR_MFA_VERIFY]: clearMfaVerify,
});

function* verifyMfa({
  headers,
  ...payload
}: ArgsWithHeaders<Pick<MfaPayload, 'code'>>): SagaIterator {
  const { email } = yield select(selectMfaState);

  const response = yield call(fetch, `${API_URL}/mfa/verify-mfa`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ email, ...payload }),
  });

  if (!response.ok) {
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }

  return yield call([response, 'json']);
}

function* verifySmsMfa({
  headers,
  ...payload
}: ArgsWithHeaders<Pick<MfaPayload, 'code'>>): SagaIterator {
  const { email } = yield select(selectMfaState);

  const response = yield call(fetch, `${API_URL}/mfa/verify-sms-mfa`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ email, ...payload }),
  });

  if (!response.ok) {
    if (response.status === NOT_FOUND) {
      throw Error(USER_NOT_FOUND_ERROR);
    }
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }

  return yield call([response, 'json']);
}

function* requestMfaCode({ headers }: ArgsWithHeaders): SagaIterator {
  const { email } = yield select(selectMfaState);

  const response = yield call(fetch, `${API_URL}/mfa/send-sms-mfa`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }
  return yield call([response, 'json']);
}

function* enableMfa({ headers }: ArgsWithHeaders): SagaIterator {
  const { email } = yield select(selectMfaState);

  const response = yield call(fetch, `${API_URL}/mfa/enable-mfa`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }

  return yield call([response, 'json']);
}

const enableMfaWatcher = createSingleEventSaga<
  Pick<MfaPayload, 'email'>,
  EnableMfaPayload,
  MyAction<Pick<MfaPayload, 'email'>>
>({
  takeEvery: Types.REQUEST_ENABLE_MFA,
  loadingAction: noOpAction,
  commitAction: Creators.commitMfaQrCode,
  successAction: noOpAction,
  errorAction: Creators.errorMfa,
  action: enableMfa,
  beforeAction: putDefaultHeadersInArgs,
});

const verifyMfaWatcher = createSingleEventSaga<
  Pick<MfaPayload, 'code'>,
  void,
  MyAction<Pick<MfaPayload, 'code'>>
>({
  takeEvery: Types.VERIFY_MFA,
  loadingAction: Creators.loadingMfa,
  commitAction: noOpAction,
  successAction: Creators.successMfa,
  errorAction: Creators.errorMfa,
  action: verifyMfa,
  beforeAction: putDefaultHeadersInArgs,
});

const verifySmsMfaWatcher = createSingleEventSaga<
  Pick<MfaPayload, 'code'>,
  void,
  MyAction<Pick<MfaPayload, 'code'>>
>({
  takeEvery: Types.VERIFY_SMS_MFA,
  loadingAction: Creators.loadingMfa,
  commitAction: noOpAction,
  successAction: Creators.successMfa,
  errorAction: Creators.errorMfa,
  action: verifySmsMfa,
  beforeAction: putDefaultHeadersInArgs,
});

const requestMfaCodeWatcher = createSingleEventSaga<void, void, Action>({
  takeEvery: Types.REQUEST_MFA_CODE,
  loadingAction: noOpAction,
  commitAction: noOpAction,
  successAction: noOpAction,
  errorAction: Creators.errorMfa,
  action: requestMfaCode,
  beforeAction: putDefaultHeadersInArgs,
});

export const mfaSagas = [
  requestMfaCodeWatcher,
  verifySmsMfaWatcher,
  verifyMfaWatcher,
  enableMfaWatcher,
];

export const selectMfaState = (state: MyState) => state.mfa;
