import React from 'react';

type PropTypes = {
  label?: string;
} & React.HTMLProps<HTMLTextAreaElement>;

const MMDFreeInput: React.FC<PropTypes> = ({ label, ...rest }) => {
  return (
    <div className={`field`}>
      {label && <label className="label">{label}</label>}
      <div className="control">
        <textarea className={`textarea `} {...rest} />
      </div>
    </div>
  );
};

export default MMDFreeInput;
