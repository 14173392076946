import { Moment } from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Dispatch, FC, SetStateAction } from 'react';

import { TIME_FORMAT } from './config';

type Props = {
  startTime: Moment;
  setStartTime: Dispatch<SetStateAction<Moment>>;
};

const StartTimePicker: FC<Props> = ({ startTime, setStartTime }) => {
  const onChangeStart = (value: Moment) => setStartTime(value);

  return (
    <div>
      <div className="align-self-center">Start:</div>

      <TimePicker
        use12Hours
        inputReadOnly
        value={startTime}
        showSecond={false}
        allowEmpty={false}
        format={TIME_FORMAT}
        onChange={onChangeStart}
      />
    </div>
  );
};

export default StartTimePicker;
