import { useSelector } from 'react-redux';

import PatientConsentForm from './PatientConsentForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectLivePatientConsent,
  Creators as PatientConsentActions,
} from 'modules/patient-consent.module';

const NewPatientConsent = () => {
  const livePatientConsents = useSelector(selectLivePatientConsent);

  const { requestCreatePatientConsent } = useBoundedActions(
    PatientConsentActions,
  );

  const onCreatePatientConsent = (values: { content: string }) =>
    requestCreatePatientConsent({ ...values, userType: 'Patient' });

  return (
    <div className="container p-2">
      <PatientConsentForm
        onSubmit={onCreatePatientConsent}
        patientConsent={livePatientConsents}
      />
    </div>
  );
};

export default NewPatientConsent;
