import { ButtonHTMLAttributes, FC, HTMLProps } from 'react';

type PropTypes = {
  isSubmit?: boolean;
  isPrimary?: boolean;
  isSuccess?: boolean;
  isDanger?: boolean;
  isSecondary?: boolean;
  isDisabled?: boolean;
  text: string;
} & HTMLProps<HTMLButtonElement | HTMLInputElement>;

const MMDButton: FC<PropTypes> = ({
  isSubmit = false,
  isPrimary = true,
  isSuccess = false,
  isDanger = false,
  isSecondary = false,
  isDisabled = false,
  className = '',
  text,
  ...rest
}) => {
  const btnClass = isPrimary ? 'is-primary' : isSecondary ? 'is-secondary' : '';
  const successClass = isSuccess ? 'is-success' : '';
  const dangerClass = isDanger ? 'is-danger' : '';

  return (
    <div className="control">
      <button
        type={isSubmit ? 'submit' : 'button'}
        className={`button ${btnClass} ${className} ${successClass} ${dangerClass}`}
        {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)}
        disabled={isDisabled}
      >
        {text}
      </button>
    </div>
  );
};

export default MMDButton;
