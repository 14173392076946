import { useSelector } from 'react-redux';

import QuestionnaireRow from './QuestionnaireRow';
import EntitiesTable from 'components/entities-table';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectQuestionnaires,
  selectQuestionnairesState,
  Creators as QuestionnaireActions,
} from 'modules/questionnaires.module';

const Questionnaire = () => {
  const { loading, count } = useSelector(selectQuestionnairesState);

  const questionnaires = useSelector(selectQuestionnaires);

  const { requestDeleteQuestionnaire } =
    useBoundedActions(QuestionnaireActions);

  return (
    <EntitiesTable
      count={count}
      withoutSearch
      loading={loading}
      withoutApproveFilter
      rootPath="questionnaire"
      newEntityUrl="create-questionnaire"
      elements={questionnaires.map((item) => (
        <QuestionnaireRow
          key={item.id}
          questionnaire={item}
          requestDelete={() => requestDeleteQuestionnaire({ id: item.id })}
          previewPath="questionnaire-preview"
        />
      ))}
    />
  );
};

export default Questionnaire;
