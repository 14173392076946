import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import { selectBookingsState } from 'modules/bookings.module';

import BookingFiles from './BookingFiles';
import BookingQuestionnaire from './BookingQuestionnaire';
import BookingPrescriptions from './BookingPrescriptions';

const Booking = () => {
  const { loading, booking } = useSelector(selectBookingsState);

  const location = useLocation();

  const isProvider = location.pathname.includes('provider');

  const user = isProvider ? booking?.doctor : booking?.patient;

  const isBookingCompleted = moment(booking?.endDate).isSameOrBefore(moment());

  return (
    <>
      <MMDLoader loading={loading} />

      {booking && (
        <div className="container p-2">
          <div className="columns is-multiline is-vcentered">
            <div className="column is-full">
              <h5 className="has-text-weight-bold">Booking info: </h5>

              {isBookingCompleted && !booking.canceled && (
                <div className="column is-full">
                  <strong
                    className={
                      booking.doctorAttended
                        ? 'has-text-success'
                        : 'has-text-danger'
                    }
                  >
                    Provider
                    {booking.doctorAttended ? ' was ' : " wasn't "}
                    attended
                  </strong>
                </div>
              )}

              {booking.canceled && (
                <div className="column is-full">
                  <strong className="has-text-danger">
                    Booking was canceled
                  </strong>
                </div>
              )}

              <div className="column is-full">
                <strong>
                  {user.firstName} {user.lastName}
                </strong>
                {'\u00A0'}
                <small>{user.email}</small>
              </div>

              <div className="column is-full">
                <h5 className="has-text-weight-bold">Date of consultation: </h5>
                {moment(booking.startDate).format('MM/DD/yy')}
              </div>

              <div className="column is-full">
                <h5 className="has-text-weight-bold">Time of consultation: </h5>
                {moment(booking.startDate).format('hh:mm A')}
              </div>
            </div>

            <div className="column is-full">
              <h5 className="has-text-weight-bold">Questionnaire: </h5>
              <BookingQuestionnaire answers={booking.answers} />
            </div>

            <h6 className="has-text-weight-bold">Visit History</h6>

            <div className="column is-full">
              <h5 className="has-text-weight-bold">Note: </h5>
              {booking.note?.note ?? '-'}
            </div>

            <div className="column is-full">
              <h5 className="has-text-weight-bold">Pharmacy: </h5>
              <Link to={`/pharmacies/${booking.pharmacy.id}`}>
                {booking.pharmacy.name}
              </Link>

              <h5 className="has-text-weight-bold">Prescriptions: </h5>
              <BookingPrescriptions prescriptions={booking.prescriptions} />
            </div>

            <div className="column is-full">
              <h5 className="has-text-weight-bold">Files: </h5>
              <BookingFiles booking={booking} />
            </div>

            <div className="column is-full">
              <h5 className="has-text-weight-bold">Feedback: </h5>
              {(isProvider ? booking.patientComment : booking.doctorComment) ??
                '-'}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
