import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { PatientConsent } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDTextArea from 'components/forms/MMDTextArea';

type Props = {
  isEdit?: boolean;
  patientConsent?: PatientConsent;
  onSubmit: (values: { content: string }) => void;
};

const PatientConsentForm: FC<Props> = ({
  onSubmit,
  patientConsent,
  isEdit = false,
}) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content: patientConsent?.content ?? '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MMDTextArea
        required
        cols={40}
        rows={40}
        label="Patient Consent"
        valid={!errors.content}
        value={watch('content')}
        placeholder="Enter the patient consent here"
        {...register('content', {
          required: true,
        })}
      />

      <MMDButton
        isSubmit
        isPrimary
        className="mt-2"
        text={isEdit ? 'Save changes' : 'Create'}
      />
    </form>
  );
};

export default PatientConsentForm;
