import { forwardRef } from 'react';
import Select, { GroupBase, Props } from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#f0f0f0' : 'white',
    borderColor: state.isFocused ? '#f81942' : '#dee2e6',
    '&:hover': {
      borderColor: '#f81942',
    },
    borderRadius: '22px',
    boxShadow: 'none',
  }),
  valueContainer: (styles) => ({ ...styles, padding: '6px 12px', margin: 0 }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected
      ? '#f81942'
      : state.isFocused
      ? '#f0f0f0'
      : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? 'none' : '#f0f0f0',
    },
    ':active': {
      backgroundColor: 'none',
    },
  }),
  input: (styles) => ({ ...styles, margin: 0, padding: 0 }),
  placeholder: (styles) => ({ ...styles, margin: 0 }),
};

const MMDiSelectNew = forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    {
      name,
      label,
      value,
      options,
      required,
      onChange,
      className,
      valid = true,
      ...props
    }: Omit<Props<Option, IsMulti, Group>, 'onChange'> & {
      name: string;
      label?: string;
      valid?: boolean;
      required?: boolean;
      onChange: (value: any) => void;
    },
    ref,
  ) => {
    return (
      <div className={className}>
        <label className={`label ${required ? 'required' : ''}`}>{label}</label>

        <Select
          ref={ref}
          name={name}
          value={value}
          options={options}
          onChange={onChange}
          styles={customStyles}
          className={!valid && 'react-select-error'}
          {...props}
        />
      </div>
    );
  },
);

MMDiSelectNew.displayName = 'MMDiSelectNew';

export default MMDiSelectNew;
