import { FC } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Heading, Media } from 'react-bulma-components';

import { BookingInList } from 'typings';
import MMDButton from 'components/MMDButton';

type Props = {
  booking: BookingInList;
};

const BookingRow: FC<Props> = ({ booking }) => {
  const isBookingCompleted = moment(booking.endDate).isSameOrBefore(moment());

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>
            {booking.firstName} {booking.lastName}
          </strong>
          {'\u00A0'}
          <small>{booking.email}</small>
          <Heading subtitle size={6} renderAs="h6">
            Date of consultation: {moment(booking.startDate).format('MM/DD/yy')}
          </Heading>
          <Heading subtitle size={6} renderAs="h6">
            Time of consultation: {moment(booking.startDate).format('hh:mm A')}
          </Heading>

          {isBookingCompleted && !booking.canceled && (
            <strong
              className={
                booking.doctorAttended ? 'has-text-success' : 'has-text-danger'
              }
            >
              Provider
              {booking.doctorAttended ? ' was ' : " wasn't "}
              attended
            </strong>
          )}
        </Media.Item>

        {booking.canceled && (
          <strong className="has-text-danger">Booking was canceled</strong>
        )}
      </Media>
      <Link to={booking.id.toString()}>
        <MMDButton className="mt-2" isPrimary text="Details" />
      </Link>
    </Box>
  );
};

export default BookingRow;
