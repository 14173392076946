import moment from 'moment';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { Condition } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  condition: Condition;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const ConditionRow: FC<Props> = ({
  condition,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(condition.id);
  }, [condition, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{condition.name}</strong>
          <p>Created at: {moment(condition.createdAt).format('LLLL')}</p>
          <p>Updated at: {moment(condition.updatedAt).format('LLLL')}</p>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${condition.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default ConditionRow;
