import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Level } from 'react-bulma-components';
import { bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import { FC, FormEvent, Fragment, useCallback, useState } from 'react';

import { MyState } from 'store';
import { Condition } from 'typings';
import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import MMDButton from 'components/MMDButton';
import MMDSelect from 'components/forms/MMDSelect';
import { SINGLE_NAME_FILTER } from 'utils/constants';
import ConditionRow from './conditions/ConditionRow';
import MMDTextInput from 'components/forms/MMDTextInput';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  conditionSelectors,
  Creators as ConditionActions,
} from 'modules/conditions.module';

type Props = {
  conditions?: Condition[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof ConditionActions;

const Pharmacies: FC<Props> = ({
  push,
  count,
  loading,
  conditions,
  routeParams,
  requestRemoveCondition,
}) => {
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentFilterValue, setCurrentFilterValue] = useState('');

  const onFilterChanged = useCallback(
    (e: FormEvent<HTMLSelectElement>) => {
      const input = e.target as HTMLSelectElement;
      setCurrentFilter(input.value);
    },
    [setCurrentFilter],
  );

  const onCurrentFilterChanged = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      setCurrentFilterValue(input.value);
    },
    [setCurrentFilterValue],
  );

  const applyFilter = useCallback(() => {
    push(`conditions?${currentFilter}=${currentFilterValue}`);
  }, [push, currentFilter, currentFilterValue]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`conditions${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const goToCreateCondition = useCallback(() => {
    push('/create-condition');
  }, [push]);

  const requestDeleteCondition = useCallback(
    (id: number) => {
      requestRemoveCondition({ id });
    },
    [requestRemoveCondition],
  );

  const clearFilter = () => {
    push('conditions');
    setCurrentFilter('');
    setCurrentFilterValue('');
  };

  return (
    <Fragment>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSelect
                name="filter"
                label="Filter by..."
                value={currentFilter}
                options={SINGLE_NAME_FILTER}
                onChange={onFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDTextInput
                name="filter-value"
                label="Filter value:"
                value={currentFilterValue}
                onChange={onCurrentFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton isPrimary onClick={applyFilter} text="Apply filter" />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <MMDSuperAdminButton
                text="New"
                isPrimary
                onClick={goToCreateCondition}
              />
            </Level.Item>
          </Level.Side>
        </Level>
        {conditions.map((item: Condition) => (
          <ConditionRow
            key={item.id}
            condition={item}
            targetPath={'conditions'}
            onDeleteClick={requestDeleteCondition}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: MyState) => ({
  conditions: conditionSelectors.selectAll(state.conditions),
  loading: state.conditions.loading,
  count: state.conditions.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...ConditionActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Pharmacies);
