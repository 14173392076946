import { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  credentialsSelector,
  Creators as CredentialsActions,
} from 'modules/credentials.module';
import { MyState } from 'store';
import { Credential } from 'typings';
import EntitiesTable from 'components/entities-table';

import CredentialRow from './credentials/CredentialRow';

type Props = {
  credentials?: Credential[];
  loading: boolean;
  count: number;
} & typeof CredentialsActions;

const Credentials: FC<Props> = ({
  count,
  loading,
  credentials,
  requestRemoveCredential,
}) => (
  <EntitiesTable
    count={count}
    loading={loading}
    rootPath="credentials"
    newEntityUrl="create-credential"
    elements={credentials
      .filter((item) => !!item)
      .map((item: Credential) => (
        <CredentialRow
          key={item.id}
          credential={item}
          targetPath={'credentials'}
          onDeleteClick={(id: number) => requestRemoveCredential({ id })}
        />
      ))}
  />
);

const mapStateToProps = (state: MyState) => ({
  credentials: credentialsSelector.selectAll(state.credentials),
  loading: state.credentials.loading,
  count: state.credentials.count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...CredentialsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);
