import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import { selectBookingsPublicState } from 'modules/bookings-public.module';
import BookingPublicFiles from 'pages/bookings-public/BookingPublicFiles';

const BookingPublic = () => {
  const { loading, bookingPublic } = useSelector(selectBookingsPublicState);

  const location = useLocation();

  const isProvider = location.pathname.includes('providers');

  const isBookingCompleted = moment(bookingPublic?.endDate).isSameOrBefore(
    moment(),
  );

  if (loading || !bookingPublic) {
    return <MMDLoader loading />;
  }

  const userData = isProvider ? bookingPublic.patient : bookingPublic.doctor;

  return (
    <div className="container p-2">
      <div className="columns is-multiline is-vcentered pt-2">
        <h5 className="has-text-weight-bold">Booking info: </h5>

        {userData && (
          <div className="column is-full">
            <strong>
              {userData.firstName} {userData.lastName}
            </strong>
            {'\u00A0'}
            <small>{userData.email}</small>
          </div>
        )}

        {isBookingCompleted && !bookingPublic.canceled && (
          <div className="column is-full">
            <strong
              className={
                bookingPublic.doctorAttended
                  ? 'has-text-success'
                  : 'has-text-danger'
              }
            >
              Provider
              {bookingPublic.doctorAttended ? ' was ' : " wasn't "}
              attended
            </strong>
          </div>
        )}

        {bookingPublic.canceled && (
          <div className="column is-full">
            <strong className="has-text-danger">Booking was canceled</strong>
          </div>
        )}

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Date of consultation: </h5>

          {moment(bookingPublic.startDate).format('MM/DD/yy')}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Time of consultation: </h5>

          {moment(bookingPublic.startDate).format('hh:mm A')}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Number participants: </h5>

          {bookingPublic.numberParticipant}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Patient email: </h5>

          {bookingPublic.patientEmail}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Patient phone: </h5>

          {bookingPublic.patientPhone ?? '-'}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Student email: </h5>

          {bookingPublic.studentEmail ?? '-'}
        </div>

        <h6 className="has-text-weight-bold mt-2">Visit History</h6>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Files: </h5>

          <BookingPublicFiles bookingPublic={bookingPublic} />
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Note: </h5>

          {bookingPublic.bookingNotePublic ?? '-'}
        </div>

        <div className="column is-full">
          <h5 className="has-text-weight-bold">Doctor feedback: </h5>

          {bookingPublic.doctorComment ?? '-'}
        </div>
      </div>
    </div>
  );
};

export default BookingPublic;
