import { FC } from 'react';
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import MMDDropdown from 'components/MMDDropdown';
import TextBlock from './components/blocks/TextBlock';
import LinkBlock from './components/blocks/LinkBlock';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import QuestionBlock from './components/blocks/QuestionBlock';
import { withBlockComponents } from './components/blocks/helpers';
import { Creators as QuestionnaireActions } from 'modules/questionnaires.module';
import {
  QuestionnaireFormValue,
  QuestionnaireBlockType,
  QuestionnaireQuestionAnswerType,
} from 'typings';

type Props = {
  initialValues?: QuestionnaireFormValue;
  isPreview?: boolean;
};

const QuestionnaireForm: FC<Props> = ({ initialValues, isPreview = false }) => {
  const {
    watch,
    control,
    trigger,
    register,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors },
  } = useForm<QuestionnaireFormValue>({
    defaultValues: {
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
      blocks: initialValues?.blocks ?? [],
    },
  });

  const { requestCreateQuestionnaire } =
    useBoundedActions(QuestionnaireActions);

  const { fields, append, remove } = useFieldArray({
    rules: {
      required: true,
      minLength: 1,
    },
    control,
    name: 'blocks',
  });

  const getOnBlockAdd = (type: QuestionnaireBlockType) => () => {
    const block = {
      type,
      text: '',
    };

    if (type === 'question') {
      block['isRequired'] = false;
    }

    append(block);
    if (type === 'question') {
      register(`blocks.${fields.length}.answer`, {
        validate: (answer) => answer !== undefined,
      });
    }
  };

  const getOnRemoveBlock = (index: number) => () => {
    const blockToRemove = fields[index];

    unregister(`blocks.${index}.text`);

    if (blockToRemove.type === 'question') {
      unregister(`blocks.${index}.answer`);
    }

    remove(index);
  };

  const getOnAddAnswer =
    (questionPosition: number) => (type: QuestionnaireQuestionAnswerType) => {
      setValue(`blocks.${questionPosition}.answer`, { type });
      trigger(`blocks.${questionPosition}.answer`);
    };

  const getOnRemoveAnswer = (questionPosition: number) => () => {
    setValue(`blocks.${questionPosition}.answer`, undefined);
  };

  const getBlockComponentByType = (
    type: QuestionnaireBlockType,
    index: number,
  ) => {
    switch (type) {
      case 'question':
        return (
          <QuestionBlock
            formErrors={errors}
            register={register}
            readOnly={isPreview}
            questionOrder={index}
            unregister={unregister}
            onAddAnswer={getOnAddAnswer(index)}
            answer={watch(`blocks.${index}.answer`)}
            onRemoveAnswer={getOnRemoveAnswer(index)}
          />
        );
      case 'link':
        return (
          <LinkBlock
            readOnly={isPreview}
            error={errors['blocks']?.[index]?.text?.message}
            {...register(`blocks.${index}.text`, {
              required: {
                value: true,
                message: 'This field is required',
              },
              pattern:
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
            })}
          />
        );
      case 'text':
        return (
          <TextBlock
            readOnly={isPreview}
            error={errors['blocks']?.[index]?.text?.message}
            {...register(`blocks.${index}.text`, {
              required: {
                value: true,
                message: 'This field is required',
              },
              maxLength: {
                value: 70,
                message: 'Max length is 70 characters',
              },
              setValueAs: (value: string) => value.trim(),
            })}
          />
        );
    }
  };

  const renderBlock = (
    block: FieldArrayWithId<QuestionnaireFormValue, 'blocks'>,
    index: number,
  ) => {
    const blockComponent = getBlockComponentByType(block.type, index);

    const blockOptions = {
      onRemove: getOnRemoveBlock(index),
      id: block.id,
      readOnly: isPreview,
    };

    if (block.type === 'question') {
      blockOptions['withRequiredCheckbox'] = true;
      blockOptions['isRequiredValue'] = watch(`blocks.${index}.isRequired`);
      blockOptions['onRequiredChange'] = (value: boolean) =>
        setValue(`blocks.${index}.isRequired`, value);
    }

    return withBlockComponents(blockComponent, blockOptions);
  };

  const isNoBlocksError = !!errors.blocks?.root;

  return (
    <div className="pb-5">
      <form onSubmit={handleSubmit(requestCreateQuestionnaire)}>
        <MMDTextInput
          required
          label="Name"
          readOnly={isPreview}
          valid={!errors.name}
          error={errors.name?.message}
          {...register('name', {
            required: {
              value: true,
              message: 'This field is required',
            },
            setValueAs: (value: string) => value.trim(),
            maxLength: {
              value: 70,
              message: 'Max length is 70 characters',
            },
          })}
        />

        <MMDTextInput
          required
          label="Description"
          readOnly={isPreview}
          valid={!errors.description}
          error={errors.description?.message}
          {...register('description', {
            required: {
              value: true,
              message: 'This field is required',
            },
            maxLength: {
              value: 100,
              message: 'Max length is 70 characters',
            },
            setValueAs: (value: string) => value.trim(),
          })}
        />

        <div>
          <strong className="mt-3 is-block questionnaire-block__line mb-3">
            Blocks
          </strong>

          {isNoBlocksError && (
            <span className="has-text-danger">
              You should add at least one block
            </span>
          )}
        </div>

        {fields.map(renderBlock)}

        {!isPreview && (
          <>
            <MMDDropdown
              isPrimary
              className="mb-2 mt-2"
              title="Add block"
              items={[
                {
                  text: 'Question Block',
                  onClick: getOnBlockAdd('question'),
                },
                {
                  text: 'Text Block',
                  onClick: getOnBlockAdd('text'),
                },
                {
                  text: 'Link Block',
                  onClick: getOnBlockAdd('link'),
                },
              ]}
            />

            <MMDButton isSubmit isPrimary className="mt-3" text={'Create'} />
          </>
        )}
      </form>
    </div>
  );
};

export default QuestionnaireForm;
