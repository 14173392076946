import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { Credential } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  credential: Credential;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const CredentialRow: FC<Props> = ({
  credential,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(credential.id);
  }, [credential, onDeleteClick]);
  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{credential.label}</strong>
          <Columns>
            <Columns.Column size={3}>
              <Heading subtitle size={6} renderAs="h6">
                Description: {credential.description || 'No info.'}
              </Heading>
            </Columns.Column>
            <Columns.Column size={6}>
              {credential.category.map((select) => (
                <span
                  key={select.id}
                  style={{
                    padding: '10px',
                    marginLeft: '12px',
                    borderColor: '#fc105b',
                    borderRadius: '20px',
                    boxShadow: '#ef034e 0px 0px 2px 1px',
                  }}
                >
                  {select.name}
                </span>
              ))}
            </Columns.Column>
          </Columns>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${credential.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default CredentialRow;
