import { Action } from 'redux';
import { createSelector } from 'reselect';
import { ProviderLanguage, SagaIterator } from 'typings';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_ALL_PROVIDER_LANGUAGES: string;
  REQUEST_CREATE_PROVIDER_LANGUAGE: string;
  REQUEST_REMOVE_PROVIDER_LANGUAGE: string;
  REQUEST_UPDATE_PROVIDER_LANGUAGE: string;
  LOADING_PROVIDER_LANGUAGES: string;
  COMMIT_PROVIDER_LANGUAGES: string;
  COMMIT_PROVIDER_LANGUAGE: string;
  REMOVE_PROVIDER_LANGUAGE: string;
  ERROR_PROVIDER_LANGUAGES: string;
}

export type CreateProviderLanguagePayload = Omit<ProviderLanguage, 'id'>;

export type UpdateProviderLanguagePayload = ProviderLanguage;

type RemoveProviderLanguagePayload = Pick<ProviderLanguage, 'id'>;

interface ActionCreators {
  requestAllProviderLanguages: () => Action;
  requestCreateProviderLanguage: (
    payload: CreateProviderLanguagePayload,
  ) => MyAction<ProviderLanguage>;
  requestUpdateProviderLanguage: (
    payload: UpdateProviderLanguagePayload,
  ) => MyAction<UpdateProviderLanguagePayload>;
  requestRemoveProviderLanguage: (
    payload: RemoveProviderLanguagePayload,
  ) => MyAction<ProviderLanguage>;
  loadingProviderLanguages: () => Action;
  commitProviderLanguages: (
    payload: Paginated<ProviderLanguage>,
  ) => MyAction<Paginated<ProviderLanguage>>;
  commitProviderLanguage: (
    payload: ProviderLanguage,
  ) => MyAction<ProviderLanguage>;
  removeProviderLanguage: (
    payload: RemoveProviderLanguagePayload,
  ) => MyAction<RemoveProviderLanguagePayload>;
  errorProviderLanguages: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface ProviderLanguagesState<TError extends Error = Error>
  extends EntityState<ProviderLanguage> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAllProviderLanguages: [],
  requestCreateProviderLanguage: ['payload'],
  requestUpdateProviderLanguage: ['payload'],
  requestRemoveProviderLanguage: ['payload'],
  commitProviderLanguages: ['payload'],
  commitProviderLanguage: ['payload'],
  removeProviderLanguage: ['payload'],
  loadingProviderLanguages: [],
  errorProviderLanguages: ['error'],
});

const entityAdapter = createEntityAdapter<ProviderLanguage>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

const providerLanguagesSelectors = entityAdapter.getSelectors();

function setLoading(state: ProviderLanguagesState): ProviderLanguagesState {
  return {
    ...state,
    loading: true,
  };
}

function commitProviderLanguage(
  state: ProviderLanguagesState,
  action: MyAction<ProviderLanguage>,
): ProviderLanguagesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitProviderLanguages(
  state: ProviderLanguagesState,
  action: MyAction<Paginated<ProviderLanguage>>,
): ProviderLanguagesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeProviderLanguage(
  state: ProviderLanguagesState,
  action: MyAction<RemoveProviderLanguagePayload>,
): ProviderLanguagesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: ProviderLanguagesState,
  { error }: ErrorAction<TError>,
): ProviderLanguagesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const providerLanguagesReducer = createReducer(initialState, {
  [Types.LOADING_PROVIDER_LANGUAGES]: setLoading,
  [Types.COMMIT_PROVIDER_LANGUAGES]: commitProviderLanguages,
  [Types.COMMIT_PROVIDER_LANGUAGE]: commitProviderLanguage,
  [Types.REMOVE_PROVIDER_LANGUAGE]: removeProviderLanguage,
  [Types.ERROR_PROVIDER_LANGUAGES]: setError,
});

const goBack = goBackFactory('/provider-languages');

async function downloadProviderLanguage({
  headers,
  id,
}: ArgsWithHeaders<Pick<ProviderLanguage, 'id'>>) {
  const result = await fetch(`${API_URL}/language/${id}`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createProviderLanguage({
  headers,
  ...payload
}: ArgsWithHeaders<CreateProviderLanguagePayload>) {
  const result = await fetch(`${API_URL}/language`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function updateProviderLanguage({
  id,
  headers,
  ...payload
}: ArgsWithHeaders<UpdateProviderLanguagePayload>) {
  const result = await fetch(`${API_URL}/language/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function removeProviderLanguageRequest({
  headers,
  id,
}: ArgsWithHeaders<Pick<ProviderLanguage, 'id'>>) {
  const result = await fetch(`${API_URL}/language/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function getAllProviderLanguages({ headers }: ArgsWithHeaders) {
  const response = await fetch(`${API_URL}/language?__limit=1000`, {
    headers,
  });

  if (!response.ok) {
    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await response.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return response.json();
}

const requestAllProviderLanguagesWatcher = createSingleEventSaga<
  void,
  Paginated<ProviderLanguage>,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_ALL_PROVIDER_LANGUAGES,
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: Creators.commitProviderLanguages,
  successAction: noOpAction,
  errorAction: Creators.errorProviderLanguages,
  action: getAllProviderLanguages,
  beforeAction: putAuthInfoInArgs,
});

const requestProviderLanguagesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<ProviderLanguage>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/provider-languages'),
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: Creators.commitProviderLanguages,
  successAction: noOpAction,
  errorAction: Creators.errorProviderLanguages,
  action: downloadUsingLocationQuery<ProviderLanguage>('language'),
  beforeAction: putAuthInfoInArgs,
});

const requestProviderLanguageWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  ProviderLanguage,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/provider-languages/:id'),
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: Creators.commitProviderLanguage,
  successAction: noOpAction,
  errorAction: Creators.errorProviderLanguages,
  action: downloadProviderLanguage,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    Pick<ProviderLanguage, 'id'>,
    ArgsWithHeaders<Pick<ProviderLanguage, 'id'>>
  >(extractRouteParams('/provider-languages/:id'), putAuthInfoInArgs),
});

const requestCreateProviderLanguageWatcher = createSingleEventSaga<
  ProviderLanguage,
  ProviderLanguage,
  MyAction<ProviderLanguage>
>({
  takeEvery: Types.REQUEST_CREATE_PROVIDER_LANGUAGE,
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorProviderLanguages,
  action: createProviderLanguage,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateProviderLanguageWatcher = createSingleEventSaga<
  Partial<ProviderLanguage>,
  Partial<ProviderLanguage>,
  MyAction<Partial<ProviderLanguage>>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_LANGUAGE,
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorProviderLanguages,
  action: updateProviderLanguage,
  beforeAction: putAuthInfoInArgs,
});

const requestRemoveProviderLanguageWatcher = createSingleEventSaga<
  Pick<ProviderLanguage, 'id'>,
  void,
  MyAction<Pick<ProviderLanguage, 'id'>>
>({
  takeEvery: Types.REQUEST_REMOVE_PROVIDER_LANGUAGE,
  loadingAction: Creators.loadingProviderLanguages,
  commitAction: Creators.removeProviderLanguage,
  successAction: noOpAction,
  errorAction: Creators.errorProviderLanguages,
  action: removeProviderLanguageRequest,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Pick<ProviderLanguage, 'id'>>,
  ): SagaIterator {
    return args;
  },
});

export const selectProviderLanguagesState = (state: MyState) =>
  state.providerLanguages;

export const selectProviderLanguages = createSelector(
  selectProviderLanguagesState,
  (providerLanguagesState) =>
    providerLanguagesSelectors.selectAll(providerLanguagesState),
);

export const selectProviderLanguageById = createSelector(
  [selectProviderLanguagesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const providerLanguagesSagas = [
  requestCreateProviderLanguageWatcher,
  requestRemoveProviderLanguageWatcher,
  requestUpdateProviderLanguageWatcher,
  requestAllProviderLanguagesWatcher,
  requestProviderLanguagesWatcher,
  requestProviderLanguageWatcher,
  goBack.watcher,
];
