import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import { PASSWORD_REG_EXP } from 'utils/constants';
import { selectMfaState } from 'modules/mfa.module';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as AuthActions } from 'modules/auth.module';
import { validatePassword } from 'pages/user-invitation/config';

const NewPasswordForm = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const { email } = useSelector(selectMfaState);

  const { requestResetPassword } = useBoundedActions(AuthActions);

  const hasFormErrors = Object.keys(errors).length > 0;

  const onSubmit = ({ password }) =>
    requestResetPassword({ email, newPassword: password });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MMDTextInput
        required
        label="Password"
        type="password"
        valid={!errors.password}
        error={validatePassword(watch('password'))}
        {...register('password', {
          pattern: PASSWORD_REG_EXP,
          required: true,
        })}
      />

      <MMDButton isSubmit isPrimary text="Reset" isDisabled={hasFormErrors} />
    </form>
  );
};

export default NewPasswordForm;
