import Select from 'react-select';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UseFormSetValue } from 'react-hook-form';

import { Nullable, Option } from 'typings';
import { ImmunizationFormValues } from './config';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectQuestionnaires,
  Creators as QuestionnaireActions,
} from 'modules/questionnaires.module';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#f0f0f0' : 'white',
    borderColor: state.isFocused ? '#f81942' : '#dee2e6',
    '&:hover': {
      borderColor: '#f81942',
    },
    borderRadius: '22px',
    boxShadow: 'none',
  }),
  valueContainer: (styles) => ({ ...styles, padding: '6px 12px', margin: 0 }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected
      ? '#f81942'
      : state.isFocused
      ? '#f0f0f0'
      : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? 'none' : '#f0f0f0',
    },
    ':active': {
      backgroundColor: 'none',
    },
  }),
  input: (styles) => ({ ...styles, margin: 0, padding: 0 }),
  placeholder: (styles) => ({ ...styles, margin: 0 }),
};

type Props = {
  selectedQuestionnaireId?: number;
  setValue: UseFormSetValue<ImmunizationFormValues>;
};

const QuestionnaireSelect: FC<Props> = ({
  selectedQuestionnaireId,
  setValue,
}) => {
  const questionnaires = useSelector(selectQuestionnaires);

  const { requestAllQuestionnaires } = useBoundedActions(QuestionnaireActions);

  useEffect(() => {
    requestAllQuestionnaires();
  }, []);

  const questionnairesOptions = questionnaires.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  const onChange = (option: Nullable<Option>) => {
    setValue('questionnaireId', option ? Number(option.value) : undefined);
  };

  const questionnaire = questionnairesOptions.find(
    ({ value }) => value === selectedQuestionnaireId?.toString(),
  );

  return (
    <div className="mb-2">
      <label className="label">Questionnaire</label>

      <Select
        isClearable
        isSearchable
        onChange={onChange}
        styles={customStyles}
        value={questionnaire}
        defaultValue={questionnaire}
        options={questionnairesOptions}
      />
    </div>
  );
};

export default QuestionnaireSelect;
