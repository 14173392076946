import { GeoPoint } from 'firebase/firestore';

import { COORD_REGEX } from './constants';

export const mapCoordinates = (coordinates: string) => {
  if (!coordinates || typeof coordinates !== 'string') {
    return {};
  }
  const matchedData = coordinates.match(COORD_REGEX);

  if (matchedData) {
    const [_, lat, lng] = matchedData;

    const geoPoint = new GeoPoint(Number(lat), Number(lng));

    return {
      coordinates: {
        latitude: geoPoint.latitude,
        longitude: geoPoint.longitude,
      },
    };
  }

  return {};
};
