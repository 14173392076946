import moment from 'moment/moment';

import { Timeslot } from 'typings';
import { Action, View } from 'react-calendar/dist/cjs/shared/types';

export const formatTime = (time: string) =>
  moment.utc(time).local().format('h:mm A');

export const getTimeslotDurationString = (timeslot: Timeslot) =>
  `${formatTime(timeslot.start.dateTime)} - ${formatTime(
    timeslot.end.dateTime,
  )} `;

export const formatLocalDate = (date: Date) =>
  moment(date).format('YYYY-MM-DD');

export const formatDate = (date?: string) =>
  moment.utc(date).local().format('YYYY-MM-DD');

const CALENDAR_MOVE_EVENTS: Action[] = ['next', 'next2', 'prev', 'prev2'];

export const shouldTriggerOnChange = (action: Action, view: View) =>
  view === 'month' &&
  (CALENDAR_MOVE_EVENTS.includes(action) || action === 'drillDown');

export const checkValueForCurrentMonth = (date: Date) => {
  if (
    moment().isSame(moment(date), 'month') &&
    moment().isSame(moment(date), 'year')
  ) {
    return moment().toDate();
  }

  return date;
};
