import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import OrganizationForm from './OrganizationForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectOrganizationById,
  UpdateOrganizationPayload,
  Creators as OrganizationsActions,
} from 'modules/organizations.module';

const EditOrganization = () => {
  const { id } = useParams();

  const organizationToEdit = useSelector((state) =>
    selectOrganizationById(state, id),
  );

  const { requestUpdateOrganization } = useBoundedActions(OrganizationsActions);

  const onEditOrganization = (values: UpdateOrganizationPayload) =>
    requestUpdateOrganization({ ...values, id });

  return (
    <div className="container p-2">
      <MMDLoader loading={!organizationToEdit} />

      {organizationToEdit &&
        organizationToEdit.requiredImmunizations !== undefined && (
          <OrganizationForm
            isEdit
            onSubmit={onEditOrganization}
            organization={organizationToEdit}
          />
        )}
    </div>
  );
};

export default EditOrganization;
