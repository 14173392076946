import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormEvent, useCallback } from 'react';
import { Form } from '@mrnkr/redux-saga-toolbox';

import { Medication } from 'typings';
import { YESORNO } from 'utils/constants';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import MMDRadio from 'components/forms/MMDRadio';
import MMDTextInput from 'components/forms/MMDTextInput';
import {
  selectMedicationById,
  medicationFormValidator,
  Creators as MedicationActions,
} from 'modules/medication.module';

const MedicationForm = () => {
  const params = useParams<{ id: string }>();

  const medication = useSelector((state) =>
    selectMedicationById(state, params.id),
  );

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="Name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter medication name"
          onChange={onChange}
          required
        />
        <MMDTextInput
          name={form.fields['dosage'].name}
          label="Dosage"
          valid={form.fields['dosage'].valid}
          dirty={form.fields['dosage'].dirty}
          placeholder="Enter dosage"
          value={form.fields['dosage'].value}
          onChange={onChange}
        />
        <MMDTextInput
          name={form.fields['frequency'].name}
          label="Frequency"
          valid={form.fields['frequency'].valid}
          dirty={form.fields['frequency'].dirty}
          placeholder="Enter frequency"
          value={form.fields['frequency'].value}
          onChange={onChange}
        />
        <MMDRadio
          required
          name={form.fields['adminApproval'].name}
          valid={form.fields['adminApproval'].valid}
          dirty={form.fields['adminApproval'].dirty}
          value={form.fields['adminApproval'].value.toString()}
          label="Admin Approval"
          answers={YESORNO}
          onChange={onChange}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={medication ? 'Update' : 'Create'}
        />
      </div>
    ),
    [medication],
  );

  if (!medication && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-medication-form-${params.id}`
      : 'create-medication-form',
    fields: {
      name: medication && medication.name ? medication.name : '',
      dosage: medication && medication.dosage ? medication.dosage : '',
      frequency: medication && medication.frequency ? medication.frequency : '',
      adminApproval:
        medication && medication.adminApproval !== undefined
          ? medication.adminApproval.toString()
          : '',
    },
    validator: medicationFormValidator,
    onSubmit: (values) =>
      medication && medication.id
        ? MedicationActions.requestUpdateMedication({
            id: medication.id,
            name: values['name'],
            dosage: values['dosage'],
            frequency: values['frequency'],
            adminApproval: values['adminApproval'] === 'true',
          })
        : MedicationActions.requestCreateMedication({
            name: values['name'],
            dosage: values['dosage'],
            frequency: values['frequency'],
            adminApproval: values['adminApproval'] === 'true',
          } as Medication),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default MedicationForm;
