import { useSelector } from 'react-redux';

import DurationForm from './DurationForm';
import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectPublicBookingDurationsState,
  Creators as PublicBookingDurationsActions,
} from 'modules/public-booking-durations.module';

const NewDuration = () => {
  const { loading, error } = useSelector(selectPublicBookingDurationsState);

  const { requestCreatePublicBookingDuration } = useBoundedActions(
    PublicBookingDurationsActions,
  );

  return (
    <div className="container p-2">
      <MMDLoader loading={loading} />

      <DurationForm
        durationError={error}
        onSubmit={requestCreatePublicBookingDuration}
      />
    </div>
  );
};

export default NewDuration;
