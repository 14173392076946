import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  ErrorAction,
  EntityState,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { Medication, SagaIterator } from 'typings';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';

interface MedicationPayload {
  id: number;
}

interface ActionTypes {
  REQUEST_CREATE_MEDICATION: string;
  REQUEST_UPDATE_MEDICATION: string;
  REQUEST_REMOVE_MEDICATION: string;
  LOADING_MEDICATIONS: string;
  COMMIT_MEDICATIONS: string;
  COMMIT_MEDICATION: string;
  REMOVE_MEDICATION: string;
  ERROR_MEDICATIONS: string;
}

interface ActionCreators {
  requestCreateMedication: (payload: Medication) => MyAction<Medication>;
  requestUpdateMedication: (
    payload: Partial<Medication>,
  ) => MyAction<Partial<Medication>>;
  requestRemoveMedication: (
    payload: MedicationPayload,
  ) => MyAction<MedicationPayload>;
  loadingMedications: () => Action;
  commitMedications: (
    payload: Paginated<Medication>,
  ) => MyAction<Paginated<Medication>>;
  commitMedication: (payload: Medication) => MyAction<Medication>;
  removeMedication: (payload: MedicationPayload) => MyAction<MedicationPayload>;
  errorMedications: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface MedicationsState<TError extends Error = Error>
  extends EntityState<Medication> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateMedication: ['payload'],
  requestUpdateMedication: ['payload'],
  requestRemoveMedication: ['payload'],
  loadingMedications: [],
  commitMedications: ['payload'],
  commitMedication: ['payload'],
  removeMedication: ['payload'],
  errorMedications: ['error'],
});

const entityAdapter = createEntityAdapter<Medication>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const medicationsSelectors = entityAdapter.getSelectors();

function setLoading(state: MedicationsState): MedicationsState {
  return {
    ...state,
    loading: true,
  };
}

function commitMedication(
  state: MedicationsState,
  action: MyAction<Medication>,
): MedicationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitMedications(
  state: MedicationsState,
  action: MyAction<Paginated<Medication>>,
): MedicationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeMedication(
  state: MedicationsState,
  action: MyAction<MedicationPayload>,
): MedicationsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: MedicationsState,
  { error }: ErrorAction<TError>,
): MedicationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const medicationReducer = createReducer(initialState, {
  [Types.LOADING_MEDICATIONS]: setLoading,
  [Types.COMMIT_MEDICATION]: commitMedication,
  [Types.COMMIT_MEDICATIONS]: commitMedications,
  [Types.REMOVE_MEDICATION]: removeMedication,
  [Types.ERROR_MEDICATIONS]: setError,
});

async function downloadMedication({
  headers,
  ...payload
}: ArgsWithHeaders<MedicationPayload>): Promise<Medication> {
  const result = await fetch(`${API_URL}/medications/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createMedication({
  headers,
  ...payload
}: ArgsWithHeaders<Medication>): Promise<CreationResult<Medication>> {
  const result = await fetch(`${API_URL}/medications`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

function* updateMedication({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Medication>>): SagaIterator {
  const medication = yield select((state) =>
    selectMedicationById(state, payload.id),
  );

  const updatedMedication = deepDiffBetweenObjects(payload, medication);

  if (!Object.keys(updatedMedication).length) {
    return;
  }

  const result = yield call(fetch, `${API_URL}/medications/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(updatedMedication),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteMedication({
  headers,
  ...payload
}: ArgsWithHeaders<MedicationPayload>): Promise<void> {
  const result = await fetch(`${API_URL}/medications/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestMedicationWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Medication,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/medications/:id'),
  loadingAction: Creators.loadingMedications,
  commitAction: Creators.commitMedication,
  successAction: noOpAction,
  errorAction: Creators.errorMedications,
  action: downloadMedication,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    MedicationPayload,
    ArgsWithHeaders<MedicationPayload>
  >(extractRouteParams('/medications/:id'), putAuthInfoInArgs),
});

const requestMedicationsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Medication>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/medications'),
  loadingAction: Creators.loadingMedications,
  commitAction: Creators.commitMedications,
  successAction: noOpAction,
  errorAction: Creators.errorMedications,
  action: downloadUsingLocationQuery<Medication>('medications'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/medications');

const requestCreateMedicationWatcher = createSingleEventSaga<
  Medication,
  Medication,
  MyAction<Medication>
>({
  takeEvery: Types.REQUEST_CREATE_MEDICATION,
  loadingAction: Creators.loadingMedications,
  commitAction: Creators.commitMedication,
  successAction: goBack.action,
  errorAction: Creators.errorMedications,
  action: createMedication,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Medication>,
    { headers, ...args }: ArgsWithHeaders<Medication>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateMedicationWatcher = createSingleEventSaga<
  Partial<Medication>,
  Partial<Medication>,
  MyAction<Partial<Medication>>
>({
  takeEvery: Types.REQUEST_UPDATE_MEDICATION,
  loadingAction: Creators.loadingMedications,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorMedications,
  action: updateMedication,
  beforeAction: putAuthInfoInArgs,
});

const requestDeleteMedicationWatcher = createSingleEventSaga<
  MedicationPayload,
  void,
  MyAction<MedicationPayload>
>({
  takeEvery: Types.REQUEST_REMOVE_MEDICATION,
  loadingAction: Creators.loadingMedications,
  commitAction: Creators.removeMedication,
  successAction: noOpAction,
  errorAction: Creators.errorMedications,
  action: deleteMedication,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<MedicationPayload>,
  ): SagaIterator {
    return args;
  },
});

export const medicationsSagas = [
  requestMedicationWatcher,
  requestMedicationsWatcher,
  requestCreateMedicationWatcher,
  requestUpdateMedicationWatcher,
  requestDeleteMedicationWatcher,
  goBack.watcher,
];

export function medicationFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
    dosage: true,
    frequency: true,
    adminApproval: true,
  };

  if (!values['adminApproval']) {
    result.adminApproval = false;
  }

  if (values['name'] === '' || !values['name']) {
    result.name = false;
  }

  return Promise.resolve(result);
}

const selectMedicationsState = (state: MyState) => state.medications;

export const selectMedicationById = createSelector(
  [selectMedicationsState, (_, id) => id],
  (state, id) => state.entities[id],
);
