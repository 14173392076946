import { FC, useMemo } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import ImmunizationsFile from './ImmunizationsFile';
import {
  PatientExemptionRequest,
  PatientImmunizationFile,
} from 'modules/patients.module';

type Props = {
  exemptionRequest: PatientExemptionRequest[];
  patientId: string;
};

const ExemptionRequestTable: FC<Props> = ({ exemptionRequest, patientId }) => {
  const columns = useMemo<MRT_ColumnDef<PatientExemptionRequest>[]>(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
        size: 150,
      },
      {
        accessorKey: 'files',
        header: 'Files',
        size: 100,
        enableSorting: false,
        Cell: (data) => (
          <ImmunizationsFile
            isExemptionRequest
            patientId={patientId}
            files={data.cell.getValue() as PatientImmunizationFile[]}
          />
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    enablePagination: false,
    enableGlobalFilter: false,
    enableFilters: false,
    state: {
      isLoading: false,
    },
    data: exemptionRequest,
  });

  return (
    <div className="mb-5">
      <p className="mb-2 ml-2">Exemption Request</p>

      <MaterialReactTable table={table} />
    </div>
  );
};

export default ExemptionRequestTable;
