import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  selectTimeslotsState,
  Creators as TimeslotActions,
  selectTimeslots,
} from 'modules/timeslots.module';
import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import moment from 'moment';

type Props = {
  selectedDate: Date;
  onAddTimeslot: () => void;
  isTimeslotFormVisible: boolean;
};

const TimeslotsControl: FC<Props> = ({
  selectedDate,
  onAddTimeslot,
  isTimeslotFormVisible,
}) => {
  const timeslots = useSelector(selectTimeslots);
  const { allTimeSlots } = useSelector(selectTimeslotsState);

  const { requestClearTimeslots, requestDeleteAllTimeslots } =
    useBoundedActions(TimeslotActions);

  const onClearTimeslots = () => {
    if (!allTimeSlots?.length) {
      alert('There are no timeslots to delete.');
      return;
    }

    requestClearTimeslots();
  };

  const deleteTimeslotsByDay = () => {
    const timeslotsByDay = timeslots.filter((timeslot) =>
      moment(timeslot.start.dateTime).isSame(selectedDate, 'day'),
    );

    if (!timeslotsByDay?.length) {
      alert('There are no time slots to delete.');
      return;
    }

    const timeslotsByDayIds = timeslotsByDay.map(({ id }) => id);
    requestDeleteAllTimeslots(timeslotsByDayIds);
  };

  return (
    <div className="mt-6 has-text-centered">
      {!isTimeslotFormVisible && (
        <MMDButton
          isPrimary
          text="Add timeslot"
          onClick={onAddTimeslot}
          className="is-align-items-center"
        />
      )}

      <MMDButton
        isPrimary
        text="Delete timeslots by day"
        className="is-align-items-center"
        onClick={deleteTimeslotsByDay}
      />

      <MMDButton
        isPrimary
        text="Delete all timeslots"
        onClick={onClearTimeslots}
        className="is-align-items-center"
      />
    </div>
  );
};

export default TimeslotsControl;
