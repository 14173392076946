import moment from 'moment';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';

import ImmunizationsFile from './ImmunizationsFile';
import {
  PatientImmunization,
  PatientImmunizationFile,
} from 'modules/patients.module';

type Props = {
  title: string;
  immunizations: PatientImmunization[];
  patientId: string;
};

const ImmunizationsTable: FC<Props> = ({ title, immunizations, patientId }) => {
  const columns = useMemo<MRT_ColumnDef<PatientImmunization>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'comment.comment',
        header: 'Comment',
        size: 100,
        enableSorting: false,
        Cell: (data) => <div>{data.renderedCellValue ?? '-'}</div>,
      },
      {
        accessorKey: 'files',
        header: 'Files',
        size: 100,
        enableSorting: false,
        Cell: (data) => (
          <ImmunizationsFile
            patientId={patientId}
            files={data.cell.getValue() as PatientImmunizationFile[]}
          />
        ),
      },
      // {
      //   accessorKey: 'questionary',
      //   header: 'Questionary',
      //   size: 200,
      //   enableSorting: false,
      //   accessorFn: () => 'Link to questionary',
      //   Cell: (data) => (
      //     <Link to="/" target="_blank" rel="noopener noreferrer">
      //       {data.renderedCellValue}
      //     </Link>
      //   ),
      // },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    enablePagination: false,
    enableGlobalFilter: false,
    enableFilters: false,
    state: {
      isLoading: false,
    },
    data: immunizations,
  });

  return (
    <div className="mb-5">
      <p className="mb-2 ml-2">{title}</p>

      <MaterialReactTable table={table} />
    </div>
  );
};

export default ImmunizationsTable;
