import { Link } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import { getFirebaseImage } from 'utils/Firebase';
import { PatientImmunizationFile } from 'modules/patients.module';

type Props = {
  isExemptionRequest?: boolean;
  files: PatientImmunizationFile[];
  patientId: string;
};

const ImmunizationsFile: FC<Props> = ({
  files,
  patientId,
  isExemptionRequest = false,
}) => {
  const [downloadedFiles, setDownloadedFiles] = useState(null);

  useEffect(() => {
    (async () => {
      const downloadedFiles = await Promise.all(
        files.map(async (file) => {
          const image = await getFirebaseImage(
            `/${
              isExemptionRequest
                ? 'patient-exemption-request-files'
                : 'patient-immunization-files'
            }/${patientId}/${file.fileName}`,
          );

          return {
            image,
            id: file.id,
            name: file.fileName,
          };
        }),
      );

      setDownloadedFiles(downloadedFiles);
    })();
  }, []);

  if (!downloadedFiles) {
    return <span>Loading...</span>;
  }

  const getFileName = (fileName) => {
    if (fileName.length < 10) {
      return fileName;
    }

    return `${fileName.substring(0, 6)}...`;
  };

  return (
    <div className="flex is-flex-wrap-wrap">
      {downloadedFiles.length > 0
        ? downloadedFiles.map((file) => (
            <Link
              key={file.id}
              to={file.image}
              className="mr-2 "
              target="_blank"
              rel="noopener noreferrer"
            >
              {getFileName(file.name)}
            </Link>
          ))
        : 'No files'}
    </div>
  );
};

export default ImmunizationsFile;
