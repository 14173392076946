import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { API_URL } from 'config';
import { MyState } from 'store';
import { noOpAction } from 'utils/noOpAction';
import { Credential, Nullable } from 'typings';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import { put, takeEvery } from 'redux-saga/effects';

export type PublicBookingDuration = {
  id: number;
  duration: number;
  createdAt: string;
  deletedAt: Nullable<string>;
  updatedAt: Nullable<string>;
};

interface ActionTypes {
  REQUEST_PUBLIC_BOOKING_DURATIONS: string;
  REQUEST_PUBLIC_BOOKING_DURATION: string;
  REQUEST_REMOVE_PUBLIC_BOOKING_DURATION: string;
  REQUEST_UPDATE_PUBLIC_BOOKING_DURATION: string;
  REQUEST_CREATE_PUBLIC_BOOKING_DURATION: string;
  LOADING_PUBLIC_BOOKING_DURATIONS: string;
  ERROR_PUBLIC_BOOKING_DURATIONS: string;
  COMMIT_PUBLIC_BOOKING_DURATIONS: string;
  COMMIT_PUBLIC_BOOKING_DURATION: string;
  REMOVE_PUBLIC_BOOKING_DURATION: string;
}

interface ActionCreators {
  requestPublicBookingDurations: () => MyAction<void>;
  requestPublicBookingDuration: (payload: { id: number }) => MyAction<void>;
  requestRemovePublicBookingDuration: (payload: {
    id: number;
  }) => MyAction<void>;
  requestUpdatePublicBookingDuration: (
    payload: PublicBookingDuration,
  ) => MyAction<void>;
  requestCreatePublicBookingDuration: (
    payload: Pick<PublicBookingDuration, 'duration'>,
  ) => MyAction<void>;
  loadingPublicBookingDurations: () => MyAction<void>;
  errorPublicBookingDurations: <TError extends Error>(
    error: Nullable<TError>,
  ) => ErrorAction<TError>;
  commitPublicBookingDurations: (
    payload: Paginated<PublicBookingDuration>,
  ) => MyAction<Paginated<PublicBookingDuration>>;
  commitPublicBookingDuration: (
    payload: PublicBookingDuration,
  ) => MyAction<PublicBookingDuration>;
  removePublicBookingDuration: (payload: {
    id: number;
  }) => MyAction<{ id: number }>;
}

export interface PublicBookingDurationsState<TError extends Error = Error>
  extends EntityState<PublicBookingDuration> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestPublicBookingDurations: [],
  requestPublicBookingDuration: ['payload'],
  requestRemovePublicBookingDuration: ['payload'],
  requestUpdatePublicBookingDuration: ['payload'],
  requestCreatePublicBookingDuration: ['payload'],
  loadingPublicBookingDurations: [],
  errorPublicBookingDurations: ['error'],
  commitPublicBookingDurations: ['payload'],
  commitPublicBookingDuration: ['payload'],
  removePublicBookingDuration: ['payload'],
});

const entityAdapter = createEntityAdapter<PublicBookingDuration>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const publicBookingDurationsSelector = entityAdapter.getSelectors();

const goBack = goBackFactory('/public-booking-durations');

function setLoading(
  state: PublicBookingDurationsState,
): PublicBookingDurationsState {
  return {
    ...state,
    loading: true,
  };
}

function setError<TError extends Error = Error>(
  state: PublicBookingDurationsState,
  { error }: ErrorAction<TError>,
): PublicBookingDurationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function commitPublicBookingDurations(
  state: PublicBookingDurationsState,
  action: MyAction<Paginated<PublicBookingDuration>>,
): PublicBookingDurationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitPublicBookingDuration(
  state: PublicBookingDurationsState,
  action: MyAction<PublicBookingDuration>,
): PublicBookingDurationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function removePublicBookingDuration(
  state: PublicBookingDurationsState,
  action: MyAction<{ id: number }>,
): PublicBookingDurationsState {
  return {
    ...entityAdapter.removeOne(action.payload.toString(), state),
    loading: false,
  };
}

function resetError(state: PublicBookingDurationsState) {
  return {
    ...state,
    error: null,
  };
}

export const publicBookingsDurationsReducer = createReducer(initialState, {
  [Types.LOADING_PUBLIC_BOOKING_DURATIONS]: setLoading,
  [Types.ERROR_PUBLIC_BOOKING_DURATIONS]: setError,
  [Types.COMMIT_PUBLIC_BOOKING_DURATIONS]: commitPublicBookingDurations,
  [Types.COMMIT_PUBLIC_BOOKING_DURATION]: commitPublicBookingDuration,
  [Types.REMOVE_PUBLIC_BOOKING_DURATION]: removePublicBookingDuration,
  [Types.REQUEST_CREATE_PUBLIC_BOOKING_DURATION]: resetError,
  [Types.REQUEST_UPDATE_PUBLIC_BOOKING_DURATION]: resetError,
});

async function getPublicBookingDuration({
  headers,
  id,
}: ArgsWithHeaders<Pick<PublicBookingDuration, 'id'>>) {
  const result = await fetch(`${API_URL}/booking-duration/${id}`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createPublicBookingDuration({
  headers,
  duration,
}: ArgsWithHeaders<Pick<PublicBookingDuration, 'duration'>>) {
  const result = await fetch(`${API_URL}/booking-duration`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ duration }),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await result.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }

  return result.json();
}

async function updatePublicBookingDuration({
  headers,
  duration,
  id,
}: ArgsWithHeaders<Pick<PublicBookingDuration, 'duration' | 'id'>>) {
  const result = await fetch(`${API_URL}/booking-duration/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ duration }),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const body = await result.json();

    throw Error(body.msg ?? 'There has been an error processing your request');
  }
}

async function deletePublicBookingDuration({
  headers,
  id,
}: ArgsWithHeaders<Pick<PublicBookingDuration, 'id'>>) {
  const result = await fetch(`${API_URL}/booking-duration/${id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return id;
}

const requestPublicBookingDurationWatcher = createSingleEventSaga({
  takeEvery: Types.REQUEST_PUBLIC_BOOKING_DURATION,
  loadingAction: Creators.loadingPublicBookingDurations,
  commitAction: Creators.commitPublicBookingDuration,
  successAction: noOpAction,
  errorAction: Creators.errorPublicBookingDurations,
  action: getPublicBookingDuration,
  beforeAction: putAuthInfoInArgs,
});

const requestPublicBookingDurationsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<PublicBookingDuration>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/public-booking-durations'),
  loadingAction: Creators.loadingPublicBookingDurations,
  commitAction: Creators.commitPublicBookingDurations,
  successAction: noOpAction,
  errorAction: Creators.errorPublicBookingDurations,
  action: downloadUsingLocationQuery<Credential>('booking-duration'),
  beforeAction: putAuthInfoInArgs,
});

const requestCreatePublicBookingDurationWatcher = createSingleEventSaga<
  Pick<PublicBookingDuration, 'duration'>,
  Pick<PublicBookingDuration, 'duration'>,
  MyAction<Pick<PublicBookingDuration, 'duration'>>
>({
  takeEvery: Types.REQUEST_CREATE_PUBLIC_BOOKING_DURATION,
  loadingAction: Creators.loadingPublicBookingDurations,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorPublicBookingDurations,
  action: createPublicBookingDuration,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdatePublicBookingDurationWatcher = createSingleEventSaga<
  Pick<PublicBookingDuration, 'duration' | 'id'>,
  Pick<PublicBookingDuration, 'duration' | 'id'>,
  MyAction<Pick<PublicBookingDuration, 'duration' | 'id'>>
>({
  takeEvery: Types.REQUEST_UPDATE_PUBLIC_BOOKING_DURATION,
  loadingAction: Creators.loadingPublicBookingDurations,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorPublicBookingDurations,
  action: updatePublicBookingDuration,
  beforeAction: putAuthInfoInArgs,
});

const requestDeletePublicBookingDurationWatcher = createSingleEventSaga<
  Pick<PublicBookingDuration, 'id'>,
  Pick<PublicBookingDuration, 'id'>,
  MyAction<Pick<PublicBookingDuration, 'id'>>
>({
  takeEvery: Types.REQUEST_REMOVE_PUBLIC_BOOKING_DURATION,
  loadingAction: Creators.loadingPublicBookingDurations,
  commitAction: Creators.removePublicBookingDuration,
  successAction: goBack.action,
  errorAction: Creators.errorPublicBookingDurations,
  action: deletePublicBookingDuration,
  beforeAction: putAuthInfoInArgs,
});

function* resetErrorWatcher() {
  yield takeEvery(onRoute('/public-booking-durations'), function* () {
    yield put(Creators.errorPublicBookingDurations(null));
  });
}

export const publicBookingDurationsSagas = [
  goBack.watcher,
  resetErrorWatcher,
  requestPublicBookingDurationWatcher,
  requestPublicBookingDurationsWatcher,
  requestUpdatePublicBookingDurationWatcher,
  requestDeletePublicBookingDurationWatcher,
  requestCreatePublicBookingDurationWatcher,
];

export const selectPublicBookingDurationsState = (state: MyState) =>
  state.publicBookingDuration;

export const selectPublicBookingDurations = createSelector(
  selectPublicBookingDurationsState,
  (publicBookingDurationsState) =>
    publicBookingDurationsSelector.selectAll(publicBookingDurationsState),
);

export const selectPublicBookingDurationById = createSelector(
  [selectPublicBookingDurationsState, (_, id) => id],
  (state, id) => state.entities[id],
);
