import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  ErrorAction,
  MyAction,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator, Transaction } from '../typings';
import {
  ArgsWithHeaders,
  Paginated,
  LocationChangeActionPayload,
} from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { noOpAction } from '../utils/noOpAction';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
  extractRouteParams,
} from '../utils/onRoute';
import { downloadUsingLocationQuery } from '../utils/downloadUsingLocationQuery';
import { call } from 'redux-saga/effects';

interface PayloadTransactionRefund {
  id: string;
  transaction_id: string;
  amount: number;
  reverseTransfer: boolean;
}

type TransactionId = {
  id: string;
};

interface ActionTypes {
  LOADING_TRANSACTIONS: string;
  COMMIT_TRANSACTIONS: string;
  ERROR_TRANSACTIONS: string;

  REQUEST_TRANSACTIONS_REFUND: string;
  COMMIT_TRANSACTIONS_REFUND: string;
}

interface ActionCreators {
  loadingTransactions: () => Action;
  commitTransactions: (
    payload: Paginated<Transaction>,
  ) => MyAction<Paginated<Transaction>>;
  errorTransactions: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;

  requestTransactionsRefund: (
    payload: PayloadTransactionRefund,
  ) => MyAction<Transaction>;
  commitTransactionsRefund: () => Action;
}

export interface TransactionsState<TError extends Error = Error>
  extends EntityState<Transaction> {
  loading: boolean;
  count: number;
  error?: TError;
  userId?: string;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingTransactions: [],
  commitTransactions: ['payload'],
  errorTransactions: ['error'],

  requestTransactionsRefund: ['payload'],
  commitTransactionsRefund: [],
});

const entityAdapter = createEntityAdapter<Transaction>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const transactionSelectors = entityAdapter.getSelectors();

function setLoading(state: TransactionsState): TransactionsState {
  return {
    ...state,
    loading: true,
  };
}

function commitTransactions(
  state: TransactionsState,
  action: MyAction<Paginated<Transaction>>,
): TransactionsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitTransactionsRefund(state: TransactionsState): TransactionsState {
  return {
    ...state,
    loading: false,
  };
}

function requestTransactionsRefund(
  state: TransactionsState,
  action: MyAction<PayloadTransactionRefund>,
): TransactionsState {
  return {
    ...state,
    userId: action.payload.id,
  };
}

function setError<TError extends Error = Error>(
  state: TransactionsState,
  { error }: ErrorAction<TError>,
): TransactionsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const transactionsReducer = createReducer(initialState, {
  [Types.LOADING_TRANSACTIONS]: setLoading,
  [Types.COMMIT_TRANSACTIONS]: commitTransactions,
  [Types.ERROR_TRANSACTIONS]: setError,
  [Types.REQUEST_TRANSACTIONS_REFUND]: requestTransactionsRefund,
  [Types.COMMIT_TRANSACTIONS_REFUND]: commitTransactionsRefund,
});

async function putTransactions({
  headers,
  id,
  transaction_id,
  amount,
  reverseTransfer,
}: ArgsWithHeaders<PayloadTransactionRefund>): Promise<void> {
  const result = await fetch(
    `${API_URL}/patients/${id}/transactions/refund/${transaction_id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify({ amount, reverseTransfer }),
    },
  );
  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    const error = await result.json();
    throw Error(error);
  }
}

const transactionWatcherConfigurationFactory = function (
  transactionEndpointRoot: string,
): any {
  const urlSegment = `/${transactionEndpointRoot}/:id/transactions`;
  return {
    takeEvery: onRoute(urlSegment),
    loadingAction: Creators.loadingTransactions,
    commitAction: Creators.commitTransactions,
    successAction: noOpAction,
    errorAction: Creators.errorTransactions,
    action: downloadUsingLocationQuery<Transaction>(transactionEndpointRoot),
    beforeAction: composeSagas<
      LocationChangeActionPayload,
      TransactionId,
      ArgsWithHeaders<TransactionId>
    >(function* (payload: LocationChangeActionPayload): SagaIterator {
      const params = yield call(extractRouteParams(urlSegment), payload);
      const offset = payload.location.search || '?__offset=0';
      return {
        ...payload,
        location: {
          ...payload.location,
          search: `/${params.id}/transactions${offset}`,
        },
      };
    }, putAuthInfoInArgs),
  };
};

const paymentTransactionWatcherConfigurationFactory = function (
  transactionEndpointRoot: string,
): any {
  const urlSegment = `/${transactionEndpointRoot}/:id/payment-transactions`;
  return {
    takeEvery: onRoute(urlSegment),
    loadingAction: Creators.loadingTransactions,
    commitAction: Creators.commitTransactions,
    successAction: noOpAction,
    errorAction: Creators.errorTransactions,
    action: downloadUsingLocationQuery<Transaction>(transactionEndpointRoot),
    beforeAction: composeSagas<
      LocationChangeActionPayload,
      TransactionId,
      ArgsWithHeaders<TransactionId>
    >(function* (payload: LocationChangeActionPayload): SagaIterator {
      const params = yield call(extractRouteParams(urlSegment), payload);
      const offset = payload.location.search || '?__offset=0';
      return {
        ...payload,
        location: {
          ...payload.location,
          search: `/${params.id}/payment-transactions${offset}`,
        },
      };
    }, putAuthInfoInArgs),
  };
};

const requestProviderTransactionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Transaction>,
  MyAction<LocationChangeActionPayload>
>(transactionWatcherConfigurationFactory('providers'));

const requestProviderPaymentTransactionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Transaction>,
  MyAction<LocationChangeActionPayload>
>(paymentTransactionWatcherConfigurationFactory('providers'));

const requestPatientTransactionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Transaction>,
  MyAction<LocationChangeActionPayload>
>(transactionWatcherConfigurationFactory('patients'));

const requestTransactionsRefundWatcher = createSingleEventSaga<
  object,
  Transaction[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_TRANSACTIONS_REFUND,
  loadingAction: Creators.loadingTransactions,
  commitAction: Creators.commitTransactionsRefund,
  successAction: noOpAction,
  errorAction: Creators.errorTransactions,
  action: putTransactions,
  beforeAction: putAuthInfoInArgs,
});

export const transactionsSagas = [
  requestProviderTransactionsWatcher,
  requestPatientTransactionsWatcher,
  requestTransactionsRefundWatcher,
  requestProviderPaymentTransactionsWatcher,
];
