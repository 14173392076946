import { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  specialitiesSelector,
  Creators as SpecialitiesActions,
} from 'modules/specialities.module';
import { MyState } from 'store';
import { Speciality } from 'typings';
import EntitiesTable from 'components/entities-table';
import SpecialityRow from 'pages/specialities/SpecialityRow';

type Props = {
  count: number;
  loading: boolean;
  specialities?: Speciality[];
} & typeof SpecialitiesActions;

const Specialities: FC<Props> = ({
  count,
  loading,
  specialities,
  requestRemoveSpeciality,
}) => (
  <EntitiesTable
    count={count}
    loading={loading}
    rootPath="specialities"
    newEntityUrl="create-speciality"
    elements={specialities
      .filter((item) => !!item)
      .map((item: Speciality) => (
        <SpecialityRow
          key={item.id}
          speciality={item}
          targetPath={'specialities'}
          onDeleteClick={(id: number) => requestRemoveSpeciality({ id })}
        />
      ))}
  />
);

const mapStateToProps = (state: MyState) => ({
  specialities: specialitiesSelector.selectAll(state.specialities),
  loading: state.specialities.loading,
  count: state.specialities.count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...SpecialitiesActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Specialities);
