import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { Notification, SagaIterator } from '../typings';
import { ArgsWithHeaders, Paginated } from '../utils/typings';
import {
  onRoute,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { downloadUsingLocationQuery } from '../utils/downloadUsingLocationQuery';
import { MyState } from '../store';
import { createSelector } from 'reselect';

export interface NotificationId {
  id: string;
}

export interface NotificationPayload {
  id: string;
  name: string;
  description: string;
  title: string;
  body: string;
  createdAt: Date;
  updatedAt: Date;
}

interface ActionTypes {
  LOADING_NOTIFICATIONS: string;
  COMMIT_NOTIFICATIONS: string;
  COMMIT_NOTIFICATION: string;
  REQUEST_NOTIFICATION: string;
  SUCCESS_NOTIFICATIONS: string;
  ERROR_NOTIFICATIONS: string;
  REQUEST_UPDATE_NOTIFICATION: string;
}

interface ActionCreators {
  requestNotification: (payload: NotificationId) => MyAction<NotificationId>;
  requestUpdateNotification: (
    payload: NotificationPayload,
  ) => MyAction<NotificationPayload>;
  loadingNotifications: () => Action;
  commitNotifications: (payload: Notification[]) => MyAction<Notification[]>;
  commitNotification: (payload: Notification) => MyAction<Notification>;
  successNotifications: (payload: Notification[]) => MyAction<Notification[]>;
  errorNotifications: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface NotificationsState<TError extends Error = Error>
  extends EntityState<Notification> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestNotification: ['payload'],
  requestUpdateNotification: ['payload'],
  loadingNotifications: [],
  commitNotifications: ['payload'],
  commitNotification: ['payload'],
  successNotifications: ['payload'],
  errorNotifications: ['error'],
});

const entityAdapter = createEntityAdapter<Notification>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const notificationsSelectors = entityAdapter.getSelectors();

function setLoading(state: NotificationsState): NotificationsState {
  return {
    ...state,
    loading: true,
  };
}

function commitNotifications(
  state: NotificationsState,
  action: MyAction<Paginated<Notification>>,
): NotificationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitNotification(
  state: NotificationsState,
  action: MyAction<Notification>,
): NotificationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: NotificationsState,
  { error }: ErrorAction<TError>,
): NotificationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const notificationsReducer = createReducer(initialState, {
  [Types.LOADING_NOTIFICATIONS]: setLoading,
  [Types.COMMIT_NOTIFICATIONS]: commitNotifications,
  [Types.ERROR_NOTIFICATIONS]: setError,
  [Types.COMMIT_NOTIFICATION]: commitNotification,
});

async function getNotification({
  headers,
  ...payload
}: ArgsWithHeaders<NotificationId>): Promise<Notification> {
  const result = await fetch(`${API_URL}/notifications/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateNotification({
  headers,
  ...payload
}: ArgsWithHeaders<NotificationPayload>): Promise<void> {
  const result = await fetch(`${API_URL}/notifications/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const goBack = goBackFactory('/push-notifications');

const requestUpdateNotificationWatcher = createSingleEventSaga<
  object,
  Notification[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPDATE_NOTIFICATION,
  loadingAction: Creators.loadingNotifications,
  commitAction: Creators.commitNotification,
  successAction: goBack.action,
  errorAction: Creators.errorNotifications,
  action: updateNotification,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Notification>,
  ): SagaIterator {
    return args;
  },
});

const requestNotificationsWatcher = createSingleEventSaga<
  object,
  Notification[],
  MyAction<object>
>({
  takeEvery: onRoute('/push-notifications'),
  loadingAction: Creators.loadingNotifications,
  commitAction: Creators.commitNotifications,
  successAction: Creators.successNotifications,
  errorAction: Creators.errorNotifications,
  action: downloadUsingLocationQuery<Notification>('notifications'),
  beforeAction: putAuthInfoInArgs,
});

const requestNotificationWatcher = createSingleEventSaga<
  object,
  Notification[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_NOTIFICATION,
  loadingAction: Creators.loadingNotifications,
  commitAction: Creators.commitNotification,
  successAction: Creators.successNotifications,
  errorAction: Creators.errorNotifications,
  action: getNotification,
  beforeAction: putAuthInfoInArgs,
});

export const notificationsSagas = [
  requestNotificationsWatcher,
  requestUpdateNotificationWatcher,
  requestNotificationWatcher,
  goBack.watcher,
];

export function notificationFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    title: true,
    name: true,
    description: true,
    body: true,
  };

  result.title = values['title'].length > 0;
  result.body = values['body'].length > 0;

  return Promise.resolve(result);
}

const selectNotificationsState = (state: MyState) => state.notifications;

export const selectNotificationById = createSelector(
  [selectNotificationsState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectNotificationsLoading = createSelector(
  selectNotificationsState,
  (state) => state.loading,
);
