import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { push } from 'redux-first-history';

import MMDTextInput from 'components/forms/MMDTextInput';
import MMDGenericTable from 'components/MMDGenericTable';
import MMDLoader from 'components/MMDLoader';
import MMDModal from 'components/MMDModal';
import MMDPager from 'components/MMDPager';
import MMDButton from '../components/MMDButton';
import {
  Creators as TransactionsActions,
  transactionSelectors,
} from '../modules/transactions.module';
import { MyState } from '../store';
import { Transaction } from '../typings';
import { USER_TYPE } from '../utils/constants';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  transactions: Transaction[];
  loading: boolean;
  count: number;
  push: typeof push;
} & typeof TransactionsActions;

const columnRefund: FC<{ action: () => void; disabled: boolean }> = ({
  action,
  disabled,
}) => (
  <MMDButton
    isSubmit
    isPrimary
    text="Refund"
    disabled={disabled}
    onClick={() => {
      action();
    }}
  />
);

const calTitles = (userType: string) => {
  const type =
    userType === USER_TYPE.PROVIDERS ? USER_TYPE.PATIENT : USER_TYPE.PROVIDER;
  const titles = [type, 'type', 'amount', 'createdAt', 'updatedAt'];
  if (userType === USER_TYPE.PATIENTS) {
    titles.unshift('transaction ID');
    titles.push('refund');
  }
  return titles;
};

const dataTable = (
  data: Transaction[],
  userType: string,
  callback: (item: Transaction) => void,
) => {
  return data.map((item) => {
    const name =
      userType === USER_TYPE.PROVIDERS
        ? `${item.patient.firstName} ${item.patient.lastName}`
        : `${item.doctor.firstName} ${item.doctor.lastName}`;

    const type =
      item.refundedTransactionId === null
        ? item.subscriptionId === null
          ? 'video call'
          : 'subcription'
        : 'refund';
    const buttonRefund = columnRefund({
      action: () => {
        if (parseFloat(item.amount) > 0 && item.amount !== null) {
          callback(item);
        }
      },
      disabled: parseFloat(item.amount) < 0 || item.amount === null,
    });

    const result = {
      name,
      type,
      amount: item.amount,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    };

    if (userType === USER_TYPE.PATIENTS) {
      let columnRefund =
        item.refundedTransactionId !== null
          ? item.refundedTransactionId
          : buttonRefund;
      columnRefund = !item.transactionId ? '' : columnRefund;
      return {
        pushtransactionId: item.transactionId,
        ...result,
        columnRefund,
      };
    }
    return result;
  });
};

export const Transactions: FC<Props> = ({
  count,
  push,
  transactions,
  loading,
  requestTransactionsRefund,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState<Transaction>();
  const [refund, setRefund] = useState<string>();
  const [reverseTransfer, setReverseTransfer] = useState(false);

  const location = useLocation();

  const userType = location.pathname.includes(USER_TYPE.PROVIDERS)
    ? USER_TYPE.PROVIDERS
    : USER_TYPE.PATIENTS;

  const titles = calTitles(userType);
  const callback = (item: Transaction) => {
    setCurrentItem(item);
    setRefund(item.amount);
    setShowModal(true);
  };
  const table = dataTable(transactions, userType, callback);

  const routeParams = paramsToDictionary(location.search);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`transactions${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <MMDGenericTable
          titles={titles}
          dataTable={table}
          label="Transactions"
        />
        <MMDModal
          visible={showModal}
          onRequestClose={() => {
            setShowModal(false);
          }}
        >
          {
            <div className="field">
              {!confirm ? (
                <MMDTextInput
                  label="Amount to refund, this must be less than the amount of the transaction"
                  type="number"
                  value={refund}
                  onChange={(evt) => {
                    const value = evt.currentTarget.value.replace(/\+|-/gi, '');
                    if (parseFloat(value) <= parseFloat(currentItem.amount)) {
                      setRefund(value);
                    }
                    if ('' === evt.currentTarget.value) setRefund(null);
                  }}
                />
              ) : null}
              {confirm ? (
                <div>
                  <h4>Amount: {refund}</h4>
                  <h4>Are you sure you want to make the refund?</h4>
                  <div>
                    <MMDCheckbox
                      name="reverse_transfer"
                      label="Do you want to revert the transfer to the doctor?"
                      value={reverseTransfer ? 'true' : 'false'}
                      onChange={(ev) => {
                        const boolValue = (ev.target as any).value === 'true';
                        setReverseTransfer(boolValue);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className="columns">
                <div className="column is-2">
                  <MMDButton
                    text={!confirm ? 'Next' : 'Confirm'}
                    isSuccess
                    onClick={(e) => {
                      if (!confirm) {
                        setConfirm(true);
                      } else {
                        setShowModal(false);
                        requestTransactionsRefund({
                          reverseTransfer,
                          id: currentItem.patientId,
                          transaction_id: currentItem.id,
                          amount: Number(refund),
                        });
                        setConfirm(false);
                        setRefund(null);
                        setReverseTransfer(false);
                      }
                    }}
                  />
                </div>
                <div className="column ">
                  <MMDButton
                    text="Cancel"
                    isDanger
                    onClick={(e) => {
                      if (!confirm) {
                        setShowModal(false);
                      } else {
                        setConfirm(false);
                      }
                      setRefund(null);
                    }}
                  />
                </div>
              </div>
            </div>
          }
        </MMDModal>
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  transactions: transactionSelectors.selectAll(state.transactions),
  loading: state.transactions.loading,
  count: state.transactions.count ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...TransactionsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
