import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { Allergy, SagaIterator } from '../typings';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface AllergyPayload {
  id: number;
}

interface ActionTypes {
  REQUEST_CREATE_ALLERGY: string;
  REQUEST_UPDATE_ALLERGY: string;
  REQUEST_REMOVE_ALLERGY: string;
  LOADING_ALLERGIES: string;
  COMMIT_ALLERGIES: string;
  COMMIT_ALLERGY: string;
  REMOVE_ALLERGY: string;
  ERROR_ALLERGIES: string;
}

interface ActionCreators {
  requestCreateAllergy: (payload: Allergy) => MyAction<Allergy>;
  requestUpdateAllergy: (
    payload: Partial<Allergy>,
  ) => MyAction<Partial<Allergy>>;
  requestRemoveAllergy: (payload: AllergyPayload) => MyAction<AllergyPayload>;
  loadingAllergies: () => Action;
  commitAllergies: (
    payload: Paginated<Allergy>,
  ) => MyAction<Paginated<Allergy>>;
  commitAllergy: (payload: Allergy) => MyAction<Allergy>;
  removeAllergy: (payload: AllergyPayload) => MyAction<AllergyPayload>;
  errorAllergies: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface AllergiesState<TError extends Error = Error>
  extends EntityState<Allergy> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateAllergy: ['payload'],
  requestUpdateAllergy: ['payload'],
  requestRemoveAllergy: ['payload'],
  loadingAllergies: [],
  commitAllergies: ['payload'],
  commitAllergy: ['payload'],
  removeAllergy: ['payload'],
  errorAllergies: ['error'],
});

const entityAdapter = createEntityAdapter<Allergy>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const allergySelectors = entityAdapter.getSelectors();

function setLoading(state: AllergiesState): AllergiesState {
  return {
    ...state,
    loading: true,
  };
}

function commitAllergy(
  state: AllergiesState,
  action: MyAction<Allergy>,
): AllergiesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitAllergies(
  state: AllergiesState,
  action: MyAction<Paginated<Allergy>>,
): AllergiesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeAllergy(
  state: AllergiesState,
  action: MyAction<AllergyPayload>,
): AllergiesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: AllergiesState,
  { error }: ErrorAction<TError>,
): AllergiesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const allergyReducer = createReducer(initialState, {
  [Types.LOADING_ALLERGIES]: setLoading,
  [Types.COMMIT_ALLERGY]: commitAllergy,
  [Types.COMMIT_ALLERGIES]: commitAllergies,
  [Types.REMOVE_ALLERGY]: removeAllergy,
  [Types.ERROR_ALLERGIES]: setError,
});

async function downloadAllergyById({
  headers,
  ...payload
}: ArgsWithHeaders<AllergyPayload>): Promise<Allergy> {
  const result = await fetch(`${API_URL}/allergies/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createAllergy({
  headers,
  ...payload
}: ArgsWithHeaders<Allergy>): Promise<CreationResult<Allergy>> {
  const result = await fetch(`${API_URL}/allergies`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateAllergy({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Allergy>>): Promise<void> {
  const result = await fetch(`${API_URL}/allergies/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteAllergy({
  headers,
  ...payload
}: ArgsWithHeaders<Allergy>): Promise<void> {
  const result = await fetch(`${API_URL}/allergies/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestAllergyWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Allergy,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/allergies/:id'),
  loadingAction: Creators.loadingAllergies,
  commitAction: Creators.commitAllergy,
  successAction: noOpAction,
  errorAction: Creators.errorAllergies,
  action: downloadAllergyById,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    AllergyPayload,
    ArgsWithHeaders<AllergyPayload>
  >(extractRouteParams('/allergies/:id'), putAuthInfoInArgs),
});

const requestAllergiesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Allergy>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/allergies'),
  loadingAction: Creators.loadingAllergies,
  commitAction: Creators.commitAllergies,
  successAction: noOpAction,
  errorAction: Creators.errorAllergies,
  action: downloadUsingLocationQuery<Allergy>('allergies'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/allergies');

const requestCreateAllergyWatcher = createSingleEventSaga<
  Allergy,
  Allergy,
  MyAction<Allergy>
>({
  takeEvery: Types.REQUEST_CREATE_ALLERGY,
  loadingAction: Creators.loadingAllergies,
  commitAction: Creators.commitAllergy,
  successAction: goBack.action,
  errorAction: Creators.errorAllergies,
  action: createAllergy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Allergy>,
    { headers, ...args }: ArgsWithHeaders<Allergy>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateAllergyWatcher = createSingleEventSaga<
  Partial<Allergy>,
  Partial<Allergy>,
  MyAction<Partial<Allergy>>
>({
  takeEvery: Types.REQUEST_UPDATE_ALLERGY,
  loadingAction: Creators.loadingAllergies,
  commitAction: Creators.commitAllergy,
  successAction: goBack.action,
  errorAction: Creators.errorAllergies,
  action: updateAllergy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Allergy>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteAllergyWatcher = createSingleEventSaga<
  AllergyPayload,
  void,
  MyAction<AllergyPayload>
>({
  takeEvery: Types.REQUEST_REMOVE_ALLERGY,
  loadingAction: Creators.loadingAllergies,
  commitAction: Creators.removeAllergy,
  successAction: noOpAction,
  errorAction: Creators.errorAllergies,
  action: deleteAllergy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<AllergyPayload>,
  ): SagaIterator {
    return args;
  },
});

export const allergySagas = [
  requestAllergyWatcher,
  requestAllergiesWatcher,
  requestCreateAllergyWatcher,
  requestUpdateAllergyWatcher,
  requestDeleteAllergyWatcher,
  goBack.watcher,
];

export function allergyFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
  };

  result.name = values['name'].length > 0;

  return Promise.resolve(result);
}

const selectAllergiesState = (state: MyState) => state.allergies;

export const selectAllergiesById = createSelector(
  [selectAllergiesState, (_, id) => id],
  (state, id) => state.entities[id],
);
