import React from 'react';
import MMDForm from '../forms/MMDForm';
import MMDTextInput from '../forms/MMDTextInput';
import MMDButton from '../MMDButton';
import { Form } from '@mrnkr/redux-saga-toolbox';
import {
  Creators as PetActions,
  petsFormValidator,
} from '../../modules/patients/pets.module';
import { Pet } from 'typings';

type PropTypes = {
  patient: Pet;
};

export const PetForm: React.FC<PropTypes> = ({ patient }) => {
  const renderFormControls = React.useCallback(
    (form: Form, onChange: (e: React.FormEvent<HTMLInputElement>) => void) => {
      return (
        <div className="container p-2">
          <MMDTextInput
            name={form.fields['name'].name}
            value={form.fields['name'].value}
            label="Name"
            valid={form.fields['name'].valid}
            dirty={form.fields['name'].dirty}
            placeholder="Fido"
            onChange={onChange}
            required
          />
          <MMDTextInput
            name={form.fields['birthday'].name}
            label="Birthday"
            valid={form.fields['birthday'].valid}
            dirty={form.fields['birthday'].dirty}
            placeholder="MM-DD-YYYY"
            value={form.fields['birthday'].value}
            onChange={onChange}
          />
          <MMDTextInput
            name={form.fields['type'].name}
            value={form.fields['type'].value}
            label="Type"
            valid={form.fields['type'].valid}
            dirty={form.fields['type'].dirty}
            placeholder="Dog"
            onChange={onChange}
            required
          />
          <MMDTextInput
            name={form.fields['breed'].name}
            value={form.fields['breed'].value}
            label="Breed"
            valid={form.fields['breed'].valid}
            dirty={form.fields['breed'].dirty}
            placeholder="Labradoodle"
            onChange={onChange}
            required
          />
          <MMDButton className="mt-2" isSubmit isPrimary text="Update" />
        </div>
      );
    },
    [],
  );

  if (!patient) {
    return null;
  }

  const formConfig = {
    formName: 'update-pet-form',
    fields: {
      name: patient.name,
      breed: patient.breed,
      birthday: patient.birthday,
      type: patient.type,
    },
    validator: petsFormValidator,
    onSubmit: (values) => {
      return PetActions.requestUpdatePet({
        id: patient.id,
        name: values['name'],
        breed: values['breed'],
        birthday: values['birthday'],
        type: values['type'],
        parentId: patient.parentId,
      });
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PetForm;
