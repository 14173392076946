import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { OrganizationType } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import { CreateOrganizationTypePayload } from 'modules/organization-types.module';

interface Props {
  organizationType?: OrganizationType;
  isEdit?: boolean;
  onSubmit: (values: CreateOrganizationTypePayload) => void;
}

const OrganizationTypeForm: FC<Props> = ({
  organizationType,
  isEdit = false,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    defaultValues: { name: organizationType?.name ?? '' },
  });

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MMDTextInput
        required
        label="Name"
        valid={!errors.name}
        {...register('name', {
          required: true,
          setValueAs: (value: string) => value.trim(),
        })}
      />
      <MMDButton
        isSubmit
        isPrimary
        className="mt-3"
        isDisabled={hasFormErrors}
        text={isEdit ? 'Save changes' : 'Create'}
      />
    </form>
  );
};

export default OrganizationTypeForm;
