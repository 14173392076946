import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Immunization } from 'typings';
import ImmunizationRow from './ImmunizationRow';
import EntitiesTable from 'components/entities-table';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as ImmunizationsActions } from 'modules/immunizations.module';
import {
  selectImmunizations,
  selectImmunizationsState,
} from 'modules/immunizations.module';

const Immunizations: FC = () => {
  const { count, loading } = useSelector(selectImmunizationsState);

  const immunizations = useSelector(selectImmunizations);

  const { requestRemoveImmunization } = useBoundedActions(ImmunizationsActions);

  const getOnDeleteOrganization = (id: number) => () =>
    requestRemoveImmunization({ id });

  return (
    <EntitiesTable
      count={count}
      loading={loading}
      withoutApproveFilter
      rootPath="immunizations"
      newEntityUrl="create-immunization"
      elements={immunizations.map((item: Immunization) => (
        <ImmunizationRow
          key={item.id}
          immunization={item}
          targetPath="immunizations"
          onDelete={getOnDeleteOrganization(item.id)}
        />
      ))}
    />
  );
};

export default Immunizations;
