import { FC, HTMLProps } from 'react';

type Option = { name: string; id: number };

type PropTypes = {
  label: string;
  dirty?: boolean;
  value?: any;
  options?: Option[];
  credential: any;
  isPsychiatry: any;
} & HTMLProps<HTMLInputElement>;

const MMDCheckboxMulti: FC<PropTypes> = ({
  label,
  dirty = true,
  value,
  options,
  selected,
  onChange,
  credential,
  isPsychiatry,
  ...rest
}) => {
  const getIsOptionChecked = (option: Option) =>
    value.some(({ id }) => id === option.id);

  const handleSelect = (option: Option) => {
    const isChecked = getIsOptionChecked(option);

    if (option.id == 2) {
      const check = {
        target: { name: 'isPsychiatry', value: isChecked ? 'false' : 'true' },
      };
      onChange(check as any);
    }

    const onChangeEvent = {
      target: {
        name: 'categories',
        value: isChecked
          ? value.filter(({ id }) => id !== option.id)
          : [...value, option],
      },
    };

    onChange(onChangeEvent as any);
  };

  return (
    <>
      <div className="field">
        <label className={`label`}>{label}</label>
      </div>
      <div style={{ display: 'flex' }}>
        {options.map((op) => (
          <div className="control" key={op.id}>
            <label className="mr-2">{op['name']}</label>
            <input
              checked={getIsOptionChecked(op)}
              className="mr-2"
              type="checkbox"
              onChange={() => {
                handleSelect(op);
              }}
              {...rest}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default MMDCheckboxMulti;
