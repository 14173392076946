import { FormEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@mrnkr/redux-saga-toolbox';

import MMDForm from '../components/forms/MMDForm';
import MMDTextInput from '../components/forms/MMDTextInput';
import MMDButton from '../components/MMDButton';
import {
  Creators as PromoCodeActions,
  promoCodeFormValidator,
  selectPromoCodeById,
} from '../modules/promo-code.module';
import { PromoCode } from 'typings';
import { putDefaultValueSafe } from 'utils/formUtils';
import { useParams } from 'react-router-dom';

const PromoCodeForm = () => {
  const params = useParams<{ id: string }>();

  const promoCode = useSelector((state) =>
    selectPromoCodeById(state, params.id),
  );

  const [placeholder, setPlaceholder] = useState(
    promoCode
      ? promoCode.externalId !== undefined && promoCode.externalId !== ''
      : false,
  );

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['code'].name}
          value={form.fields['code'].value}
          label="Code"
          valid={form.fields['code'].valid}
          dirty={form.fields['code'].dirty}
          placeholder="ENTER_YOUR_CODE_HERE"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['percentageOff'].name}
          label="Off %"
          valid={form.fields['percentageOff'].valid}
          dirty={form.fields['percentageOff'].dirty}
          placeholder={placeholder ? 'See in Stripe' : "Don't type 100% here"}
          value={form.fields['percentageOff'].value}
          onChange={onChange}
          required={false}
        />
        <MMDTextInput
          name={form.fields['amountOff'].name}
          label="Amount Off"
          valid={form.fields['amountOff'].valid}
          dirty={form.fields['amountOff'].dirty}
          placeholder={placeholder ? 'See in Stripe' : '$$$'}
          value={form.fields['amountOff'].value}
          onChange={onChange}
          required={false}
        />
        <MMDTextInput
          name={form.fields['externalId'].name}
          label="Stripe ID"
          valid={form.fields['externalId'].valid}
          dirty={form.fields['externalId'].dirty}
          placeholder="Enter the Stripe ID here"
          value={form.fields['externalId'].value}
          onChange={(e) => {
            setPlaceholder(e.currentTarget.value !== '');
            onChange(e);
          }}
          required={false}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={promoCode ? 'Update' : 'Create'}
        />
      </div>
    ),
    [promoCode, placeholder],
  );

  if (!promoCode && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-promo-code-form-${params.id}`
      : 'create-promo-code-form',
    fields: {
      code: putDefaultValueSafe(promoCode, 'code'),
      percentageOff: putDefaultValueSafe(promoCode, 'percentageOff'),
      amountOff: putDefaultValueSafe(promoCode, 'amountOff'),
      externalId: putDefaultValueSafe(promoCode, 'externalId'),
    },
    validator: promoCodeFormValidator,
    onSubmit: (values) => {
      console.log(promoCode);
      return promoCode && promoCode.id
        ? PromoCodeActions.requestUpdatePromoCode({
            id: promoCode.id,
            code: values['code'],
            percentageOff:
              values['percentageOff'] !== ''
                ? parseFloat(values['percentageOff'])
                : 0,
            amountOff:
              values['amountOff'] !== '' ? parseFloat(values['amountOff']) : 0,
            externalId: values['externalId'],
          })
        : PromoCodeActions.requestCreatePromoCode({
            code: values['code'],
            percentageOff:
              values['percentageOff'] !== ''
                ? parseFloat(values['percentageOff'])
                : 0,
            amountOff:
              values['amountOff'] !== '' ? parseFloat(values['amountOff']) : 0,
            externalId: values['externalId'],
          } as PromoCode);
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PromoCodeForm;
