import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import _ from 'lodash';
import MMDModal from 'components/MMDModal';
import MMDLoader from 'components/MMDLoader';
import MMDPager from 'components/MMDPager';
import { UserRow } from 'components/MMDUserRow';
import {
  AdminToolsState,
  Creators as AdminToolsActions,
} from '../modules/adminTools.module';
import {
  Creators as PatientsActions,
  patientsSelectors,
} from '../modules/patients.module';
import { MyState } from '../store';
import { Patient } from '../typings';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDSearch from 'components/MMDSearch';
import MMDButton from 'components/MMDButton';
import { FC, useCallback, useState } from 'react';
import { Level } from 'react-bulma-components';

type Props = {
  patients: Patient[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
  adminTools: AdminToolsState;
} & typeof PatientsActions &
  typeof AdminToolsActions;

const Patients: FC<Props> = ({
  patients,
  count,
  routeParams,
  push,
  requestResetPassword,
  loading,
  adminTools,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState(undefined);
  const applySearch = useCallback(() => {
    let newRouteParams = { ...routeParams, __offset: '0' };
    if (search) {
      newRouteParams['search'] = search;
    } else {
      // @ts-ignore
      newRouteParams = _.omit(routeParams, ['search']);
    }
    push(
      `patients${prepareQueryWithOffset(
        isNaN(parseInt(newRouteParams.__offset))
          ? 0
          : parseInt(newRouteParams.__offset),
        newRouteParams,
      )}`,
    );
  }, [push, search, routeParams]);
  const clearFilter = useCallback(() => {
    push(`patients`);
    setSearch('');
  }, [push]);
  const { resetPassword } = adminTools;

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`patients${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  return (
    <>
      <MMDLoader loading={loading || resetPassword.loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSearch
                initialValue={search}
                onChange={(e) => {
                  setSearch(
                    e.currentTarget.value !== ''
                      ? e.currentTarget.value
                      : undefined,
                  );
                }}
                onSearch={() => {
                  applySearch();
                }}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
        </Level>
      </div>
      <div className="container p-2">
        {patients.map((item: Patient) => (
          <UserRow
            user={item}
            key={item.id}
            targetPath={'patients'}
            onClickResetPassword={requestResetPassword}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />

      <MMDModal
        visible={(resetPassword.success || resetPassword.error) && showModal}
        onRequestClose={() => setShowModal(false)}
      >
        {resetPassword.error && showModal && (
          <p>{resetPassword.error.message}</p>
        )}
        {resetPassword.success && showModal && (
          <p>The email was sent successfully</p>
        )}
      </MMDModal>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  patients: patientsSelectors.selectAll(state.patients),
  adminTools: state.adminTools,
  resetPassword: state.adminTools.resetPassword,
  loading: state.patients.loading,
  count: state.patients.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...PatientsActions,
      ...AdminToolsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
