import React from 'react';

import RemoveIcon from 'assets/remove-icon.png';
import MMDCheckbox from 'components/forms/MMDCheckbox';

interface BlockComponentOptions {
  onRemove: () => void;
  id: string;
  withRequiredCheckbox?: boolean;
  isRequiredValue?: boolean;
  onRequiredChange?: (value: boolean) => void;
  readOnly: boolean;
}

export const withBlockComponents = (
  component: React.ReactNode,
  {
    id,
    readOnly,
    onRemove,
    isRequiredValue,
    onRequiredChange,
    withRequiredCheckbox,
  }: BlockComponentOptions,
) => {
  return (
    <div className="questionnaire-block mb-3 pb-1" key={id}>
      {withRequiredCheckbox && (
        <MMDCheckbox
          className="mt-1"
          readOnly={readOnly}
          label="Required question"
          value={String(isRequiredValue)}
          onChange={({ target }) =>
            // @ts-ignore
            onRequiredChange(target.value === 'true')
          }
        />
      )}

      {component}

      {!readOnly && (
        <div onClick={onRemove} className="questionnaire-block__remove-icon">
          <img src={RemoveIcon} alt="remove-icon" />
        </div>
      )}
    </div>
  );
};
