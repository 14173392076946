import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useToggle } from 'hooks/useToggle';
import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import DownloadGoogleAuthenticator from './DownloadGoogleAuthenticator';
import { selectMfaState, Creators as MfaActions } from 'modules/mfa.module';

type Props = {
  onFinishEnable: () => void;
};

const EnableMfa: FC<Props> = ({ onFinishEnable }) => {
  const { mfaQrCode } = useSelector(selectMfaState);

  const [isGoogleAuthenticatorActive, toggleGoogleAuthenticator] = useToggle();

  const { clearMfaQrCode } = useBoundedActions(MfaActions);

  const onDoneClick = () => {
    onFinishEnable();
    clearMfaQrCode();
  };

  return (
    <div>
      <p className="has-text-centered has-text-weight-bold">Enable MFA</p>

      <div className="has-text-centered">
        <span>Scan with </span>

        <span
          onClick={toggleGoogleAuthenticator}
          className="has-text-weight-bold has-pointer is-underlined"
        >
          Google Authenticator
        </span>
      </div>

      <div className="flex is-flex-direction-column is-justify-content-center is-align-items-center mt-4">
        {isGoogleAuthenticatorActive ? (
          <>
            <DownloadGoogleAuthenticator />

            <MMDButton text="Go Back" onClick={toggleGoogleAuthenticator} />
          </>
        ) : (
          <>
            <img src={mfaQrCode} alt="mfa code" />

            <MMDButton className="mt-4" text="Done" onClick={onDoneClick} />
          </>
        )}
      </div>
    </div>
  );
};

export default EnableMfa;
