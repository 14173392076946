import React from 'react';
import RemoveIcon from 'assets/remove-icon.png';

export const withAnswerComponents = (
  component: React.ReactNode,
  onRemove: () => void,
  readOnly?: boolean,
) => {
  return (
    <div className="questionnaire-block__answer mb-3">
      {component}
      {!readOnly && (
        <div
          onClick={onRemove}
          className="questionnaire-block__answer__remove-icon"
        >
          <img src={RemoveIcon} alt="remove-icon" />
        </div>
      )}
    </div>
  );
};
