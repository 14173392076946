import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Level } from 'react-bulma-components';
import { push } from 'redux-first-history';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDLoader from '../components/MMDLoader';
import { MyState } from '../store';
import { Prescription } from '../typings';
import { STATUS_FILTER } from '../utils/constants';
import MMDButton from '../components/MMDButton';
import {
  Creators as PrescriptionActions,
  PrescriptionSelectors,
} from 'modules/prescriptions.module';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDSelect from 'components/forms/MMDSelect';
import MMDPager from 'components/MMDPager';
import MMDTable from 'components/forms/MMDTable';
import MMDModal from 'components/MMDModal';
import MMDFreeInput from 'components/MMDFreeInput';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { FC, FormEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  prescriptions?: Prescription[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof PrescriptionActions;

const Prescriptions: FC<Props> = ({
  prescriptions,
  count,
  routeParams,
  push,
  requestUpdatePrescription,
  loading,
  requestResendPrescription,
}) => {
  const params = useParams<{ status?: string }>();

  const [approvalFilter, setApprovalFilter] = useState(
    params.status === 'pending',
  );
  const [failedFilter, setFailedFilter] = useState(
    params.status === 'send_fax_error',
  );
  const [currentFilter, setCurrentFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [lastId, setLastId] = useState();
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const [focusedPrescription, setFocusedPrescription] = useState(0);

  const onFilterChanged = useCallback(
    (e: FormEvent<HTMLSelectElement>) => {
      const input = e.target as HTMLSelectElement;
      setCurrentFilter(input.value);
      if (input.value === 'sent' || input.value === 'deny') {
        push(`prescriptions?status=${input.value}`);
      } else {
        push('prescriptions');
      }
    },
    [push, setCurrentFilter],
  );

  const showOnlyNotApproved = useCallback(() => {
    const nextApprovalFilter = !approvalFilter;
    setApprovalFilter(nextApprovalFilter);
    push(`prescriptions${nextApprovalFilter ? '?status=pending' : ''}`);
  }, [push, approvalFilter, setApprovalFilter]);

  const showOnlyFailed = useCallback(() => {
    const nextFailedFilter = !failedFilter;
    setFailedFilter(nextFailedFilter);
    push(`prescriptions${nextFailedFilter ? '?status=send_fax_error' : ''}`);
  }, [push, failedFilter, setFailedFilter]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`prescriptions${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const clearFilter = () => {
    push('prescriptions');
    setCurrentFilter('');
    setApprovalFilter(params.status === 'pending');
    setFailedFilter(params.status === 'send_fax_error');
  };

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSelect
                name="filter"
                label="Filter by..."
                value={currentFilter}
                options={STATUS_FILTER}
                onChange={onFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDCheckbox
                name="admin-approval"
                label="Show only pending prescriptions"
                value={approvalFilter ? 'true' : 'false'}
                onChange={showOnlyNotApproved}
              />
            </Level.Item>
            <Level.Item>
              <MMDCheckbox
                name="admin-approval"
                label="Show only failed prescriptions"
                value={failedFilter ? 'true' : 'false'}
                onChange={showOnlyFailed}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
        </Level>
        <MMDTable
          titles={[
            'Date',
            'Doctor',
            'Prescription Info',
            'Pharmacy',
            'Fax',
            'Status',
            'Actions',
          ]}
          label="Prescriptions"
          dataTable={prescriptions}
          isPrescriptions
          setShowModal={setShowModal}
          setFocusedPrescription={setFocusedPrescription}
          resendPrescription={(id: any, payload: Partial<Prescription>) => {
            setLastId(id);
            if (id) setShowModalInfo(true);
            requestResendPrescription(payload);
          }}
        />
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
      <MMDModal
        visible={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <MMDFreeInput
          label="Deny Reason"
          onChange={(evt) => {
            setDenyReason(evt.currentTarget.value);
          }}
        />
        <div className="field is-grouped">
          <div className="control">
            <MMDButton
              text="Cancel"
              isPrimary
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="control">
            <MMDButton
              text="Send"
              isDanger
              onClick={(e) => {
                requestUpdatePrescription({
                  ...prescriptions[focusedPrescription],
                  status: 'deny',
                  denyReason,
                });
                setShowModal(false);
              }}
            />
          </div>
        </div>
      </MMDModal>
      <MMDModal
        visible={
          !loading &&
          showModalInfo &&
          prescriptions.some(
            (p) => p.id === lastId && p.status === 'send_fax_error',
          )
        }
        onRequestClose={() => {
          setShowModalInfo(false);
        }}
      >
        The pharmacy must have a valid fax number and be approved to send a fax.
        Or check your api key Documo time not expired
        <div className="field is-grouped">
          <div className="control">
            <MMDButton
              text="Ok"
              isPrimary
              onClick={() => setShowModalInfo(false)}
            />
          </div>
          <div className="control"></div>
        </div>
      </MMDModal>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  prescriptions: PrescriptionSelectors.selectAll(state.prescriptions),
  loading: state.prescriptions.loading,
  count: state.prescriptions.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...PrescriptionActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Prescriptions);
