import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { Pharmacy } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  pharmacy: Pharmacy;
  targetPath: string;
  onDeleteClick: (id: string) => void;
}

export const PharmacyRow: FC<Props> = ({
  pharmacy,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(pharmacy.id);
  }, [pharmacy, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{pharmacy.name}</strong>
          <p>{pharmacy.address}</p>
          <p>{pharmacy.phone}</p>
          {pharmacy.fax ? <p>{pharmacy.fax}</p> : null}
          <p>{pharmacy.approved ? 'Approved' : 'Not approved'}</p>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${pharmacy.id}`}>
            <MMDButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default PharmacyRow;
