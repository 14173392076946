import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  selectTimeslotsState,
  Creators as TimeslotActions,
} from 'modules/timeslots.module';
import { Timeslot } from 'typings';
import { useToggle } from 'hooks/useToggle';
import MMDLoader from 'components/MMDLoader';
import TimeslotsList from './components/TimeslotsList';
import TimeslotDetail from './components/TimeslotDetail';
import { useBoundedActions } from 'hooks/useBoundedActions';
import TimeslotsControl from './components/TimeslotsControl';
import { selectProviderById } from 'modules/providers.module';
import TimeslotsCalendar from './components/TimeslotsCalendar';
import TimeslotEditModal from './components/TimeslotEditModal';
import { Creators as ProvidersActions } from 'modules/providers.module';

const Availability = () => {
  const { loading } = useSelector(selectTimeslotsState);

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [isTimeslotFormActive, toggleTimeslotForm] = useToggle();
  const [isTimeslotEditModalActive, toggleTimeslotEditModal] = useToggle();
  const [selectedTimeslot, setSelectedTimeslot] = useState(undefined);
  const [actionType, setActionType] = useState('add');

  const { id } = useParams();

  const {
    commitTimeslot,
    requestTimeslots,
    requestAllTimeslots,
    requestProvider,
  } = useBoundedActions({ ...TimeslotActions, ...ProvidersActions });

  const provider = useSelector((store) => selectProviderById(store, id));

  useEffect(() => {
    if (provider) {
      return;
    }

    requestProvider({ id });
  }, [provider]);

  useEffect(() => {
    requestTimeslots({
      query: `?month=${moment().format('YYYY-MM-DD')}`,
    });
    requestAllTimeslots();
  }, []);

  const onAddTimeslot = () => {
    commitTimeslot({ timeslot: null });
    setActionType('add');
    toggleTimeslotForm();
  };

  const onCancelTimeslotAdding = () => toggleTimeslotForm();

  const onEditTimeslot = (timeslot: Timeslot) => {
    setSelectedTimeslot(timeslot);

    if (timeslot.recurringEventId) {
      toggleTimeslotEditModal();
      return;
    }

    setActionType('only');
    toggleTimeslotForm();
  };

  const getOnEditTimeslot = (actionType: string) => () => {
    setActionType(actionType);
    toggleTimeslotForm();
    toggleTimeslotEditModal();
  };

  if (provider && (!provider.squareCustomerId || !provider.profile)) {
    return (
      <div className="container has-text-centered pt-5">
        <span className="has-text-weight-bold">
          {!provider?.squareCustomerId
            ? 'Provider should add credit card to edit availability'
            : 'Fill provider profile'}
        </span>
      </div>
    );
  }

  return (
    <div className="container">
      <MMDLoader loading={loading} />

      <div className="columns mt-6">
        <div className="column flex is-flex-direction-column is-align-items-center">
          <TimeslotsCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />

          <TimeslotsControl
            selectedDate={selectedDate}
            onAddTimeslot={onAddTimeslot}
            isTimeslotFormVisible={isTimeslotFormActive}
          />
        </div>

        <div className="column">
          <div className="has-text-centered mb-3">
            Timeslots for{' '}
            <span className="has-text-weight-bold">
              {moment(selectedDate).format('MMM DD YYYY')}
            </span>
          </div>

          <div className="timeslots">
            {isTimeslotFormActive ? (
              <TimeslotDetail
                selectedTimeslot={selectedTimeslot}
                onHideModal={onCancelTimeslotAdding}
                provider={provider}
                actionType={actionType}
                selectedDate={selectedDate}
              />
            ) : (
              <TimeslotsList
                currentDate={selectedDate}
                onEditTimeslot={onEditTimeslot}
              />
            )}
          </div>
        </div>
      </div>

      <TimeslotEditModal
        onClose={toggleTimeslotEditModal}
        visible={isTimeslotEditModalActive}
        onEditOneTimeslot={getOnEditTimeslot('only')}
        onEditTimeslotAllTimeslots={getOnEditTimeslot('all')}
      />
    </div>
  );
};

export default Availability;
