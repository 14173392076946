import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { PHONE_REG_EXP } from 'utils/constants';
import { putAuthInfoInArgs } from './auth.module';
import { mapCoordinates } from 'utils/mapCoordinates';
import { Paginated, ArgsWithHeaders } from 'utils/typings';
import { Patient, File, SagaIterator, Nullable } from 'typings';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface PatientId {
  id: string;
}

interface PatientPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  birthday: string;
  city: string;
  state: string;
  zipCode: string;
  coordinates: string;
  phone: string;
  gender: string;
  avatarApproved: boolean;
  ssn: string;
  enable?: boolean;
}

export interface Report {
  url: string;
  data: any;
}

interface AttachOrganizationPayload {
  patientId: string;
  organizationId: string;
}

interface PatientImmunizationData {
  recommendedImmunizations: PatientImmunization[];
  requiredImmunizations: PatientImmunization[];
  patientExemptionRequest: PatientExemptionRequest[];
}

export interface PatientImmunizationFile {
  id: number;
  fileName: string;
}

export interface PatientImmunization {
  comment: {
    id: number;
    comment: string;
  };
  createdAt: string;
  deletedAt: Nullable<string>;
  description: string;
  files: PatientImmunizationFile[];
  id: number;
  isRequired: boolean;
  name: string;
  numberOfRequiredDocuments: number;
  updatedAt: string;
}

export interface PatientExemptionRequestFile {
  id: number;
  fileName: string;
}

export interface PatientExemptionRequest {
  createdAt: string;
  deletedAt: Nullable<string>;
  files: PatientExemptionRequestFile[];
  id: number;
  type: string;
  updatedAt: string;
}

interface ActionTypes {
  REQUEST_PATIENT: string;
  REQUEST_PATIENT_IMMUNIZATIONS: string;
  COMMIT_PATIENT_IMMUNIZATIONS: string;
  LOADING_PATIENT_IMMUNIZATIONS: string;
  REQUEST_UPDATE_PATIENT: string;
  LOADING_PATIENTS: string;
  NOT_LOADING_PATIENTS: string;
  COMMIT_PATIENTS: string;
  COMMIT_PATIENT: string;
  SUCCESS_PATIENTS: string;
  ERROR_PATIENTS: string;

  LOADING_PATIENT_REPORT: string;
  REQUEST_PATIENT_REPORT: string;
  COMMIT_PATIENT_REPORT: string;

  LOADING_PATIENT_FILES: string;
  REQUEST_PATIENT_FILES: string;
  COMMIT_PATIENT_FILES: string;

  ATTACH_ORGANIZATION_FOR_PATIENT: string;
  REMOVE_ORGANIZATION_FOR_PATIENT: string;
}

interface ActionCreators {
  requestPatientImmunizations: (
    payload: PatientId,
  ) => MyAction<PatientImmunizationData>;
  commitPatientImmunizations: (
    payload: PatientImmunizationData,
  ) => MyAction<PatientImmunizationData>;
  loadingPatientImmunizations: () => Action;
  requestPatient: (payload: PatientId) => MyAction<Patient>;
  requestPatientReport: (payload: PatientId) => MyAction<void>;
  requestPatientFiles: (payload: PatientId) => MyAction<void>;
  requestUpdatePatient: (payload: PatientPayload) => MyAction<PatientPayload>;
  loadingPatients: () => Action;
  notLoadingPatients: () => Action;
  loadingPatientReport: () => Action;
  loadingPatientFiles: () => Action;
  commitPatients: (payload: Paginated<Patient>) => MyAction<Paginated<Patient>>;
  commitPatient: (payload: Patient) => MyAction<Patient>;
  commitPatientReport: (payload: Report) => MyAction<Report>;
  commitPatientFiles: (payload: string) => MyAction<File[]>;
  successPatients: (payload: Patient[]) => MyAction<Patient[]>;
  attachOrganizationForPatient: (
    payload: AttachOrganizationPayload,
  ) => MyAction<AttachOrganizationPayload>;
  removeOrganizationForPatient: (
    payload: Pick<AttachOrganizationPayload, 'patientId'>,
  ) => Pick<AttachOrganizationPayload, 'patientId'>;
  errorPatients: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface PatientsState<TError extends Error = Error>
  extends EntityState<Patient> {
  loading: boolean;
  count: number;
  error?: TError;
  report?: Report;
  loadingReport: boolean;
  files: File[];
  loadingFiles: boolean;
  versionReport: number;
  immunizations: Nullable<PatientImmunizationData>;
  loadingImmunizations: boolean;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestPatientImmunizations: ['payload'],
  commitPatientImmunizations: ['payload'],
  loadingPatientImmunizations: [],
  requestPatients: ['payload'],
  requestPatient: ['payload'],
  requestPatientReport: ['payload'],
  requestPatientFiles: ['payload'],
  loadingPatients: [],
  notLoadingPatients: [],
  loadingPatientReport: [],
  loadingPatientFiles: [],
  commitPatients: ['payload'],
  commitPatient: ['payload'],
  commitPatientReport: ['payload'],
  commitPatientFiles: ['payload'],
  successPatients: ['payload'],
  errorPatients: ['error'],
  requestUpdatePatient: ['payload'],
  attachOrganizationForPatient: ['payload'],
  removeOrganizationForPatient: ['payload'],
});

const entityAdapter = createEntityAdapter<Patient>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
  loadingReport: false,
  loadingFiles: false,
  files: [],
  versionReport: 0,
  immunizations: null,
  loadingImmunizations: false,
});
export const patientsSelectors = entityAdapter.getSelectors();

function setLoading(state: PatientsState): PatientsState {
  return {
    ...state,
    loading: true,
  };
}

function setNotLoading(state: PatientsState): PatientsState {
  return {
    ...state,
    loading: false,
  };
}

function setLoadingReport(state: PatientsState): PatientsState {
  return {
    ...state,
    loadingReport: true,
  };
}

function setLoadingFiles(state: PatientsState): PatientsState {
  return {
    ...state,
    loadingFiles: true,
  };
}

function commitPatients(
  state: PatientsState,
  action: MyAction<Paginated<Patient>>,
): PatientsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitPatient(
  state: PatientsState,
  action: MyAction<Patient>,
): PatientsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitPatientReport(
  state: PatientsState,
  action: MyAction<Report>,
): PatientsState {
  return {
    ...state,
    report: action.payload,
    loadingReport: false,
    versionReport: state.versionReport + 1,
  };
}

function commitPatientFiles(
  state: PatientsState,
  action: MyAction<File[]>,
): PatientsState {
  return {
    ...state,
    files: action.payload,
    loadingFiles: false,
  };
}

function setError<TError extends Error = Error>(
  state: PatientsState,
  { error }: ErrorAction<TError>,
): PatientsState {
  return {
    ...state,
    error,
    loading: false,
    loadingImmunizations: false,
  };
}

function loadingPatientImmunizations(state: PatientsState): PatientsState {
  return {
    ...state,
    loadingImmunizations: true,
  };
}

function commitPatientImmunizations(
  state: PatientsState,
  action: MyAction<PatientImmunizationData>,
): PatientsState {
  return {
    ...state,
    immunizations: action.payload,
    loadingImmunizations: false,
  };
}

export const patientsReducer = createReducer(initialState, {
  [Types.LOADING_PATIENTS]: setLoading,
  [Types.NOT_LOADING_PATIENTS]: setNotLoading,
  [Types.LOADING_PATIENT_REPORT]: setLoadingReport,
  [Types.LOADING_PATIENT_FILES]: setLoadingFiles,
  [Types.COMMIT_PATIENTS]: commitPatients,
  [Types.COMMIT_PATIENT_REPORT]: commitPatientReport,
  [Types.COMMIT_PATIENT_FILES]: commitPatientFiles,
  [Types.ERROR_PATIENTS]: setError,
  [Types.COMMIT_PATIENT]: commitPatient,
  [Types.LOADING_PATIENT_IMMUNIZATIONS]: loadingPatientImmunizations,
  [Types.COMMIT_PATIENT_IMMUNIZATIONS]: commitPatientImmunizations,
});

async function downloadPatient({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<Patient> {
  const result = await fetch(`${API_URL}/patients/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}


function* updatePatient({
  headers,
  ...payload
}: ArgsWithHeaders<PatientPayload>) {
  const patient = yield select((state) => selectPatientById(state, payload.id));

  const updatedPatientData = deepDiffBetweenObjects(
    {
      ...payload,
      ...mapCoordinates(payload.coordinates),
    },
    patient,
  );

  if (!Object.keys(updatedPatientData).length) {
    return;
  }

  const result = yield call(fetch, `${API_URL}/patients/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(updatedPatientData),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function downloadPatientReport({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<Report> {
  const result = await fetch(
    `${API_URL}/patients/${payload.id}/report/lifestyle`,
    {
      headers,
      method: 'GET',
    },
  );

  const data = await result.blob();
  if (!data) {
    const error = await result.json();
    throw Error(error);
  }

  const url = URL.createObjectURL(data);
  return {
    url,
    data,
  };
}

async function downloadPatientFiles({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<string> {
  const result = await fetch(`${API_URL}/patients/${payload.id}/files`, {
    headers,
    method: 'GET',
  });

  return result.json();
}

async function downloadPatientImmunizations({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<string> {
  const result = await fetch(
    `${API_URL}/patients/${payload.id}/immunizations`,
    {
      headers,
      method: 'GET',
    },
  );

  return result.json();
}

async function attachOrganizationForPatient({
  headers,
  organizationId,
  patientId,
}: ArgsWithHeaders<{ patientId: string; organizationId: string }>) {
  return fetch(
    `${API_URL}/patients/${patientId}/add-patient-to-organization/${organizationId}`,
    {
      headers,
      method: 'PUT',
    },
  );
}

async function removeOrganizationForPatient({
  headers,
  patientId,
}: ArgsWithHeaders<{ patientId: string }>) {
  return fetch(
    `${API_URL}/patients/remove-patient-from-organization/${patientId}`,
    {
      headers,
      method: 'DELETE',
    },
  );
}

const goBack = goBackFactory('/patients');

const attachOrganizationForPatientWatcher = createSingleEventSaga<
  AttachOrganizationPayload,
  void,
  MyAction<AttachOrganizationPayload>
>({
  takeEvery: Types.ATTACH_ORGANIZATION_FOR_PATIENT,
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.notLoadingPatients,
  successAction: noOpAction,
  errorAction: Creators.errorPatients,
  action: attachOrganizationForPatient,
  beforeAction: putAuthInfoInArgs,
});

const removeOrganizationForPatientWatcher = createSingleEventSaga<
  Pick<AttachOrganizationPayload, 'patientId'>,
  void,
  MyAction<Pick<AttachOrganizationPayload, 'patientId'>>
>({
  takeEvery: Types.REMOVE_ORGANIZATION_FOR_PATIENT,
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.notLoadingPatients,
  successAction: noOpAction,
  errorAction: Creators.errorPatients,
  action: removeOrganizationForPatient,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdatePatientWatcher = createSingleEventSaga<
  object,
  Patient,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_UPDATE_PATIENT,
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.commitPatient,
  successAction: goBack.action,
  errorAction: Creators.errorPatients,
  action: updatePatient,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Patient>,
  ): SagaIterator {
    return args;
  },
});

const requestPatientsWatcher = createSingleEventSaga<
  object,
  Patient[],
  MyAction<object>
>({
  takeEvery: onRoute('/patients'),
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.commitPatients,
  successAction: Creators.successPatients,
  errorAction: Creators.errorPatients,
  action: downloadUsingLocationQuery<Patient>('patients'),
  beforeAction: putAuthInfoInArgs,
});

const requestPatientWatcher = createSingleEventSaga<
  PatientId,
  Patient,
  MyAction<PatientId>
>({
  takeEvery: Types.REQUEST_PATIENT,
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.commitPatient,
  successAction: noOpAction,
  errorAction: Creators.errorPatients,
  action: downloadPatient,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientReportWatcher = createSingleEventSaga<
  Report,
  Patient[],
  MyAction<Report>
>({
  takeEvery: Types.REQUEST_PATIENT_REPORT,
  loadingAction: Creators.loadingPatientReport,
  commitAction: Creators.commitPatientReport,
  successAction: Creators.successPatients,
  errorAction: Creators.errorPatients,
  action: downloadPatientReport,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientFilesWatcher = createSingleEventSaga<
  File[] | string,
  Patient[],
  MyAction<File[]>
>({
  takeEvery: Types.REQUEST_PATIENT_FILES,
  loadingAction: Creators.loadingPatientFiles,
  commitAction: Creators.commitPatientFiles,
  successAction: Creators.successPatients,
  errorAction: Creators.errorPatients,
  action: downloadPatientFiles,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientImmunizationsWatcher = createSingleEventSaga<
  PatientImmunizationData,
  void,
  MyAction<PatientImmunizationData>
>({
  takeEvery: Types.REQUEST_PATIENT_IMMUNIZATIONS,
  loadingAction: Creators.loadingPatientImmunizations,
  commitAction: Creators.commitPatientImmunizations,
  successAction: noOpAction,
  errorAction: Creators.errorPatients,
  action: downloadPatientImmunizations,
  beforeAction: putAuthInfoInArgs,
});

export const patientsSagas = [
  attachOrganizationForPatientWatcher,
  removeOrganizationForPatientWatcher,
  requestPatientImmunizationsWatcher,
  requestUpdatePatientWatcher,
  requestPatientReportWatcher,
  requestPatientFilesWatcher,
  requestPatientsWatcher,
  requestPatientWatcher,
  goBack.watcher,
];

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const COORD_REGEX = /^\[([\d.-]+),([\d.-]+)\]$/i;

export function patientsFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result: Record<string, any> = {
    email: true,
    coordinates: true,
    phone: true,
  };

  ['address', 'city', 'email', 'firstName', 'id', 'lastName', 'state'].forEach(
    (k) => {
      result[k] = !!values[k] && values[k].toString().length > 0;
    },
  );

  if (!values.phone.match(PHONE_REG_EXP)) {
    result.phone = false;
  }

  if (!values['email'].match(EMAIL_REGEX)) {
    result.email = false;
  }

  if (!values['coordinates'].match(COORD_REGEX)) {
    result.coordinates = false;
  }

  return Promise.resolve(result);
}

export const selectPatientsState = (state: MyState) => state.patients;

export const selectPatientById = createSelector(
  [selectPatientsState, (_, id) => id],
  (state, id) => state.entities[id],
);
