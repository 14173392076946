import { useSelector } from 'react-redux';

import DurationRow from './DurationRow';
import EntitiesTable from 'components/entities-table';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectPublicBookingDurations,
  selectPublicBookingDurationsState,
  Creators as PublicBookingDurationsActions,
} from 'modules/public-booking-durations.module';

const PublicBookingDurations = () => {
  const { count, loading } = useSelector(selectPublicBookingDurationsState);

  const publicBookingDurations = useSelector(selectPublicBookingDurations);

  const { requestRemovePublicBookingDuration } = useBoundedActions(
    PublicBookingDurationsActions,
  );
  const getOnDeletePublicBooking = (id: number) => () =>
    requestRemovePublicBookingDuration({ id });

  return (
    <EntitiesTable
      count={count}
      withoutSearch
      loading={loading}
      rootPath="/public-booking-durations"
      newEntityUrl="/create-public-booking-duration"
      elements={publicBookingDurations.map((duration) => (
        <DurationRow
          key={duration.id}
          duration={duration}
          targetPath="public-booking-durations"
          onDelete={getOnDeletePublicBooking(duration.id)}
        />
      ))}
    />
  );
};

export default PublicBookingDurations;
