import { FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDForm from '../components/forms/MMDForm';
import MMDTextArea from '../components/forms/MMDTextArea';
import MMDButton from '../components/MMDButton';
import {
  Creators as TermsAndConditionsActions,
  selectTermsOfConditionsState,
  termAndConditionFormValidator,
  termAndConditionSelectors,
} from '../modules/terms-and-conditions.module';
import { TermsAndConditions } from 'typings';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const TermsAndConditionsForm = () => {
  const params = useParams<{ id?: string; userType?: string }>();

  const termsAndConditionsState = useSelector(selectTermsOfConditionsState);

  const termsAndConditions = params.id
    ? termsAndConditionsState.entities[params.id]
    : termAndConditionSelectors
        .selectAll(termsAndConditionsState)
        .filter((t) => t.userType === params.userType && t.sendLive)
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt, 'milliseconds'))
        .pop();

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLTextAreaElement>) => void) => {
      const editing = !!params.id;

      return (
        <div className="container p-2">
          <MMDTextArea
            name={form.fields['content'].name}
            value={form.fields['content'].value}
            label="Terms and Conditions"
            valid={form.fields['content'].valid}
            dirty={form.fields['content'].dirty}
            placeholder="Write the terms and conditions here..."
            onChange={onChange}
            rows={40}
            cols={40}
            required={true}
          />
          <MMDButton
            className="mt-2"
            isSubmit
            isPrimary
            text={editing ? 'Save changes' : 'Create'}
          />
        </div>
      );
    },
    [params],
  );

  if (!termsAndConditions) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-terms-and-conditions-form-${params.id}`
      : 'create-terms-and-conditions-form',
    fields: {
      content: termsAndConditions ? termsAndConditions.content : '',
      userType: termsAndConditions ? termsAndConditions.userType : '',
    },
    validator: termAndConditionFormValidator,
    onSubmit: (values: Dictionary<string>) =>
      params.id
        ? TermsAndConditionsActions.requestUpdateTermsAndConditions({
            id: termsAndConditions.id,
            content: values['content'],
            userType: values['userType'],
          } as TermsAndConditions)
        : TermsAndConditionsActions.requestCreateTermsAndConditions({
            content: values['content'],
            userType: values['userType'],
          } as TermsAndConditions),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default TermsAndConditionsForm;
