import { Link } from 'react-router-dom';
import { FC, useCallback } from 'react';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { Speciality } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  speciality: Speciality;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const SpecialityRow: FC<Props> = ({
  speciality,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(speciality.id);
  }, [speciality, onDeleteClick]);
  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{speciality.name}</strong>
          <Heading subtitle size={6} renderAs="h6">
            Created by:{' '}
            {speciality.creator ? (
              <Link to={`/providers/${speciality.creator.id}`}>
                {speciality.creator.email}
              </Link>
            ) : (
              'No info.'
            )}
          </Heading>
          <Heading subtitle size={6} renderAs="h6">
            Credential: {speciality.credential || 'No info.'}
          </Heading>
          <Heading subtitle size={6} renderAs="h6">
            Approved: {speciality.approved ? 'Yes' : 'No'}
          </Heading>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${speciality.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default SpecialityRow;
