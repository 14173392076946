import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import { Form } from '@mrnkr/redux-saga-toolbox';
import MMDTextInput from 'components/forms/MMDTextInput';
import { FormEvent, useCallback, useEffect, useState } from 'react';

import {
  selectTemplatesById,
  TemplateFormValidator,
  Creators as TemplatesActions,
} from 'modules/templates.module';
import { Template } from 'typings';
import { putDefaultValueSafe } from 'utils/formUtils';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { useBoundedActions } from 'hooks/useBoundedActions';
import MMDSelectCred from 'components/forms/MMDSelectCred';
import MMDCreateLabels from 'components/forms/MMDCreateLabels';
import {
  selectCredentials,
  Creators as CredentialsActions,
} from 'modules/credentials.module';

const TemplateForm = () => {
  const [credentialsIds, setCredentialsIds] = useState();
  const [sectionsName, setSectionsName] = useState();

  const params = useParams<{ id: string }>();

  const template = useSelector((state) =>
    selectTemplatesById(state, params.id),
  );

  const credentials = useSelector(selectCredentials);

  const { requestCredentialsSearch } = useBoundedActions(CredentialsActions);

  useEffect(() => {
    requestCredentialsSearch({ credential: '__limit=10000&__sort=-updatedAt' });
  }, []);

  const renderFormControls = useCallback(
    (
      form: Form,
      onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
    ) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['title'].name}
          value={form.fields['title'].value}
          label="Template name"
          valid={form.fields['title'].valid}
          dirty={form.fields['title'].dirty}
          placeholder="Enter template name"
          onChange={onChange}
          required={true}
        />

        <MMDSelectCred
          name={form.fields['credentials'].name}
          value={form.fields['credentials'].value}
          label="Credential"
          valid={form.fields['credentials'].valid}
          dirty={form.fields['credentials'].dirty}
          credentialAux={template ? template['credentials'] : []}
          options={credentials}
          onChange={onChange}
          setCredentialsIds={setCredentialsIds}
          required={true}
        />

        <MMDCreateLabels
          name={form.fields['sections'].name}
          value={form.fields['sections'].value}
          label="Credential"
          valid={form.fields['sections'].valid}
          dirty={form.fields['sections'].dirty}
          sectionsAux={template ? template.sections : []}
          onChange={onChange}
          setSectionsName={setSectionsName}
          required={true}
        />

        <div>
          <MMDCheckbox
            name={form.fields['default'].name}
            label="Show template for all credentials"
            dirty={form.fields['default'].dirty}
            value={form.fields['default'].value as 'true' | 'false'}
            onChange={onChange}
          />

          <div style={{ display: 'none' }}>
            {(form.fields['credentials'].value = credentialsIds)}
          </div>
          <div style={{ display: 'none' }}>
            {(form.fields['sections'].value = sectionsName)}
          </div>
          <MMDButton
            className="mt-2"
            isSubmit
            isPrimary
            text={template ? 'Update' : 'Create'}
          />
        </div>
      </div>
    ),
    [template, credentialsIds, sectionsName],
  );
  if (!template && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-template-form-${params.id}`
      : 'create-template-form',
    fields: {
      title: putDefaultValueSafe(template, 'title'),
      credentials: putDefaultValueSafe(template, 'credentials'),
      sections: putDefaultValueSafe(template, 'sections'),
      default: putDefaultValueSafe(template, 'default'),
    },
    validator: TemplateFormValidator,
    onSubmit: (values) =>
      template && template.id
        ? TemplatesActions.requestUpdateTemplate({
            id: template.id,
            title: values['title'],
            credentials: values['credentials'],
            sections: values['sections'],
            default: values['default'] === '' ? 'false' : values['default'],
          })
        : TemplatesActions.requestCreateTemplate({
            title: values['title'],
            credentials: values['credentials'],
            sections: values['sections'],
            default: values['default'] === '' ? 'false' : values['default'],
          } as Template),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default TemplateForm;
