import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PatientConsentForm from './PatientConsentForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectPatientConsentById,
  Creators as PatientConsentActions,
} from 'modules/patient-consent.module';

const EditPatientConsent = () => {
  const { id } = useParams();

  const editedPatientConsent = useSelector((state) =>
    selectPatientConsentById(state, id),
  );

  const { requestUpdatePatientConsent } = useBoundedActions(
    PatientConsentActions,
  );

  const onEditPatientConsent = (values: { content: string }) =>
    requestUpdatePatientConsent({ ...values, userType: 'Patient', id });

  return (
    <div className="container p-2">
      <PatientConsentForm
        isEdit
        onSubmit={onEditPatientConsent}
        patientConsent={editedPatientConsent}
      />
    </div>
  );
};

export default EditPatientConsent;
