export const DOCUMENT_NUMBERS_OPTIONS = Array.from(
  { length: 5 },
  (_, index) => ({
    id: index,
    value: (index + 1).toString(),
    name: (index + 1).toString(),
  }),
);

export type ImmunizationFormValues = {
  name: string;
  description?: string;
  numberOfRequiredDocuments: string;
  questionnaireId?: number;
};
