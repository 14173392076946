import { Component, FC, Fragment, HTMLProps } from 'react';

type PropTypes = {
  label?: string;
  titles?: string[];
  dataTable?: string[][] | object[] | Component[][];
} & HTMLProps<HTMLInputElement>;

const MMDGenericTable: FC<PropTypes> = ({
  label,
  titles = [],
  dataTable = [[]],
}) => {
  const renderRow = (row, i) => {
    const normalize = Array.isArray(row) ? row : Object.values(row);
    return (
      <tr key={i}>
        {normalize.map((item, index) => (
          <th key={`${item}${index}`}>{item}</th>
        ))}
      </tr>
    );
  };

  return (
    <Fragment>
      <label className="label">{label}</label>
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            {titles.map((title, i) => (
              <th key={i}>
                <abbr title={title}>{title}</abbr>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{dataTable.map(renderRow)}</tbody>
      </table>
    </Fragment>
  );
};

export default MMDGenericTable;
