import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { PrivacyPolicy, SagaIterator } from '../typings';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { MyState } from '../store';

interface PrivacyPolicyPayload {
  id: string;
}

interface UserTypePayload {
  userType: string;
}

interface ActionTypes {
  REQUEST_CREATE_PRIVACY_POLICY: string;
  REQUEST_UPDATE_PRIVACY_POLICY: string;
  LOADING_PRIVACY_POLICIES: string;
  COMMIT_PRIVACY_POLICIES: string;
  COMMIT_PRIVACY_POLICY: string;
  COMMIT_PRIVACY_POLICY_UPDATE: string;
  ERROR_PRIVACY_POLICIES: string;
}

interface ActionCreators {
  requestCreatePrivacyPolicy: (
    payload: PrivacyPolicy,
  ) => MyAction<PrivacyPolicy>;
  requestUpdatePrivacyPolicy: (
    payload: Partial<PrivacyPolicy>,
  ) => MyAction<Partial<PrivacyPolicy>>;
  loadingPrivacyPolicies: () => Action;
  commitPrivacyPolicies: (
    payload: PrivacyPolicy[],
  ) => MyAction<PrivacyPolicy[]>;
  commitPrivacyPolicy: (payload: PrivacyPolicy) => MyAction<PrivacyPolicy>;
  commitPrivacyPolicyUpdate: (
    payload: Partial<PrivacyPolicy>,
  ) => MyAction<Partial<PrivacyPolicy>>;
  errorPrivacyPolicies: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface PrivacyPoliciesState<TError extends Error = Error>
  extends EntityState<PrivacyPolicy> {
  loading: boolean;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreatePrivacyPolicy: ['payload'],
  requestUpdatePrivacyPolicy: ['payload'],
  loadingPrivacyPolicies: [],
  commitPrivacyPolicies: ['payload'],
  commitPrivacyPolicy: ['payload'],
  commitPrivacyPolicyUpdate: ['payload'],
  errorPrivacyPolicies: ['error'],
});

const entityAdapter = createEntityAdapter<PrivacyPolicy>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
});
export const privacyPolicySelectors = entityAdapter.getSelectors();

function setLoading(state: PrivacyPoliciesState): PrivacyPoliciesState {
  return {
    ...state,
    loading: true,
  };
}

function commitPrivacyPolicy(
  state: PrivacyPoliciesState,
  action: MyAction<PrivacyPolicy>,
): PrivacyPoliciesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitPrivacyPolicies(
  state: PrivacyPoliciesState,
  action: MyAction<PrivacyPolicy[]>,
): PrivacyPoliciesState {
  return {
    ...entityAdapter.addAll(action.payload, state),
    loading: false,
  };
}

function commitPrivacyPolicyUpdate(
  state: PrivacyPoliciesState,
  { payload: { id, ...diff } }: MyAction<Partial<PrivacyPolicy>>,
): PrivacyPoliciesState {
  return {
    ...entityAdapter.updateOne({ id: id.toString(), changes: diff }, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: PrivacyPoliciesState,
  { error }: ErrorAction<TError>,
): PrivacyPoliciesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const privacyPolicyReducer = createReducer(initialState, {
  [Types.LOADING_PRIVACY_POLICIES]: setLoading,
  [Types.COMMIT_PRIVACY_POLICIES]: commitPrivacyPolicies,
  [Types.COMMIT_PRIVACY_POLICY]: commitPrivacyPolicy,
  [Types.COMMIT_PRIVACY_POLICY_UPDATE]: commitPrivacyPolicyUpdate,
  [Types.ERROR_PRIVACY_POLICIES]: setError,
});

async function downloadPrivacyPolicies({
  headers,
  ...payload
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<PrivacyPolicy[]> {
  const location = payload.location.search
    ? `${payload.location.search}&__sort=-updatedAt`
    : `${payload.location.search}?__sort=-updatedAt`;
  const result = await fetch(`${API_URL}/privacy-policy${location}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadPrivacyPolicy({
  headers,
  ...payload
}: ArgsWithHeaders<UserTypePayload>): Promise<PrivacyPolicy[]> {
  const result = await fetch(
    `${API_URL}/privacy-policy?userType=${payload.userType}&sendLive=true&__sort=-updatedAt&__limit=1`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadSinglePrivacyPolicy({
  headers,
  ...payload
}: ArgsWithHeaders<PrivacyPolicyPayload>): Promise<PrivacyPolicy> {
  const result = await fetch(`${API_URL}/privacy-policy/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createPrivacyPolicy({
  headers,
  ...payload
}: ArgsWithHeaders<PrivacyPolicy>): Promise<CreationResult<PrivacyPolicy>> {
  const result = await fetch(`${API_URL}/privacy-policy`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updatePrivacyPolicy({
  headers,
  id,
  ...diff
}: ArgsWithHeaders<Partial<PrivacyPolicy>>): Promise<void> {
  const result = await fetch(`${API_URL}/privacy-policy/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(diff),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestPrivacyPolicyWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PrivacyPolicy,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/create-privacy-policy/:userType'),
  loadingAction: Creators.loadingPrivacyPolicies,
  commitAction: Creators.commitPrivacyPolicy,
  successAction: noOpAction,
  errorAction: Creators.errorPrivacyPolicies,
  action: downloadPrivacyPolicy,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    UserTypePayload,
    ArgsWithHeaders<UserTypePayload>
  >(extractRouteParams('/create-privacy-policy/:userType'), putAuthInfoInArgs),
  *afterAction(res: PrivacyPolicy[]): SagaIterator {
    if (res && res.length) {
      return res.pop();
    }
  },
});

const requestPrivacyPoliciesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PrivacyPolicy[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/privacy-policy'),
  loadingAction: Creators.loadingPrivacyPolicies,
  commitAction: Creators.commitPrivacyPolicies,
  successAction: noOpAction,
  errorAction: Creators.errorPrivacyPolicies,
  action: downloadPrivacyPolicies,
  beforeAction: putAuthInfoInArgs,
});

const requestSinglePrivacyPolicyWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  PrivacyPolicy,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/privacy-policy/:id'),
  loadingAction: Creators.loadingPrivacyPolicies,
  commitAction: Creators.commitPrivacyPolicy,
  successAction: noOpAction,
  errorAction: Creators.errorPrivacyPolicies,
  action: downloadSinglePrivacyPolicy,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    PrivacyPolicyPayload,
    ArgsWithHeaders<PrivacyPolicyPayload>
  >(extractRouteParams('/privacy-policy/:id'), putAuthInfoInArgs),
});

const goBack = goBackFactory('/privacy-policy');

const requestCreatePrivacyPolicyWatcher = createSingleEventSaga<
  PrivacyPolicy,
  PrivacyPolicy,
  MyAction<PrivacyPolicy>
>({
  takeEvery: Types.REQUEST_CREATE_PRIVACY_POLICY,
  loadingAction: Creators.loadingPrivacyPolicies,
  commitAction: Creators.commitPrivacyPolicy,
  successAction: goBack.action,
  errorAction: Creators.errorPrivacyPolicies,
  action: createPrivacyPolicy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<PrivacyPolicy>,
    { headers, ...args }: ArgsWithHeaders<PrivacyPolicy>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateWatcher = createSingleEventSaga<
  Partial<PrivacyPolicy>,
  Partial<PrivacyPolicy>,
  MyAction<Partial<PrivacyPolicy>>
>({
  takeEvery: Types.REQUEST_UPDATE_PRIVACY_POLICY,
  loadingAction: Creators.loadingPrivacyPolicies,
  commitAction: Creators.commitPrivacyPolicyUpdate,
  successAction: goBack.action,
  errorAction: Creators.errorPrivacyPolicies,
  action: updatePrivacyPolicy,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Partial<PrivacyPolicy>>,
  ): SagaIterator {
    return args;
  },
});

export const privacyPolicySagas = [
  requestPrivacyPolicyWatcher,
  requestPrivacyPoliciesWatcher,
  requestSinglePrivacyPolicyWatcher,
  requestCreatePrivacyPolicyWatcher,
  requestUpdateWatcher,
  goBack.watcher,
];

export function privacyPolicyFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  result.content = values['content'].length > 0;

  return Promise.resolve(result);
}

export const selectPrivacyPolicyState = (state: MyState) =>
  state.privacyPolicies;
