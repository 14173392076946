import { FC } from 'react';

import MMDModal from 'components/MMDModal';
import MMDButton from 'components/MMDButton';

type Props = {
  visible: boolean;
  onClose: () => void;
  onEditOneTimeslot: () => void;
  onEditTimeslotAllTimeslots: () => void;
};

const TimeslotEditModal: FC<Props> = ({
  visible,
  onClose,
  onEditOneTimeslot,
  onEditTimeslotAllTimeslots,
}) => (
  <MMDModal visible={visible} onRequestClose={onClose} withoutClose>
    <p>Do you want to edit only this event or all the recurring events?</p>

    <div className="flex is-justify-content-space-evenly mt-5">
      <MMDButton isPrimary onClick={onClose} text="Cancel" />

      <MMDButton
        isPrimary
        onClick={onEditOneTimeslot}
        text=" Only this timeslot"
      />

      <MMDButton
        isPrimary
        onClick={onEditTimeslotAllTimeslots}
        text="All timeslots"
      />
    </div>
  </MMDModal>
);

export default TimeslotEditModal;
