import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import ProviderLanguageForm from './ProviderLanguageForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectProviderLanguageById,
  UpdateProviderLanguagePayload,
  Creators as ProviderLanguagesActions,
} from 'modules/provider-languages.module';

const EditProviderLanguage = () => {
  const { id } = useParams();

  const providerLanguageToEdit = useSelector((state) =>
    selectProviderLanguageById(state, id),
  );

  const { requestUpdateProviderLanguage } = useBoundedActions(
    ProviderLanguagesActions,
  );

  const onEditProviderLanguage = (values: UpdateProviderLanguagePayload) =>
    requestUpdateProviderLanguage({ ...values, id });

  return (
    <div className="container p-2">
      <MMDLoader loading={!providerLanguageToEdit} />

      {providerLanguageToEdit && (
        <ProviderLanguageForm
          onSubmit={onEditProviderLanguage}
          providerLanguage={providerLanguageToEdit}
          isEdit
        />
      )}
    </div>
  );
};

export default EditProviderLanguage;
