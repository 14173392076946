import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Component, FormEvent, ReactNode } from 'react';
import { FormInitialConfiguration, Form } from '@mrnkr/redux-saga-toolbox';

import {
  Creators as FormActions,
  formSelectors as selectors,
} from 'modules/forms.module';

type PropTypes = {
  formConfig: Omit<FormInitialConfiguration, 'unregisterOnSubmit'>;
  form: Form;
  renderFormControls: (
    form: Form,
    onChange: (e: FormEvent<HTMLInputElement>) => void,
  ) => ReactNode;
} & typeof FormActions;

class MMDForm extends Component<PropTypes> {
  componentDidMount() {
    const { formConfig, form, registerForm } = this.props;
    if (isEmpty(form)) {
      registerForm({ ...formConfig, unregisterOnSubmit: false });
    }
  }

  componentWillUnmount() {
    const { formConfig, clearForm } = this.props;
    clearForm(formConfig.formName);
  }

  onChange = (e: FormEvent<HTMLInputElement>) => {
    const { onFormChange, formConfig } = this.props;

    const input = e.target as HTMLInputElement;
    onFormChange({
      formName: formConfig.formName,
      fieldName: input.name,
      nextValue: input.value,
    });
  };
  render() {
    const { formConfig, form, renderFormControls, submitForm } = this.props;

    return (
      <form
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          submitForm(formConfig.formName);
        }}
      >
        {form ? renderFormControls(form, this.onChange) : null}
      </form>
    );
  }
}

const mapStateToProps = (state, { formConfig }: PropTypes) => ({
  form: selectors.selectAll(formConfig.formName)(state as never),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...FormActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MMDForm);
