import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { LogEntry } from '../typings';
import { LocationChangeActionPayload, Paginated } from '../utils/typings';
import { onRoute } from 'utils/onRoute';
import { noOpAction } from 'utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

interface ActionTypes {
  LOADING_LOGS: string;
  COMMIT_LOGS: string;
  ERROR_LOGS: string;
}

interface ActionCreators {
  loadingLogs: () => Action;
  commitLogs: (payload: Paginated<LogEntry>) => MyAction<Paginated<LogEntry>>;
  errorLogs: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface LogsState<TError extends Error = Error>
  extends EntityState<LogEntry> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingLogs: [],
  commitLogs: ['payload'],
  errorLogs: ['error'],
});

const entityAdapter = createEntityAdapter<LogEntry>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const logsSelectors = entityAdapter.getSelectors();

function setLoading(state: LogsState): LogsState {
  return {
    ...state,
    loading: true,
  };
}

function commitLogs(
  state: LogsState,
  action: MyAction<Paginated<LogEntry>>,
): LogsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: LogsState,
  { error }: ErrorAction<TError>,
): LogsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const logsReducer = createReducer(initialState, {
  [Types.LOADING_LOGS]: setLoading,
  [Types.COMMIT_LOGS]: commitLogs,
  [Types.ERROR_LOGS]: setError,
});

const requestLogsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<LogEntry>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/logs'),
  loadingAction: Creators.loadingLogs,
  commitAction: Creators.commitLogs,
  successAction: noOpAction,
  errorAction: Creators.errorLogs,
  action: downloadUsingLocationQuery<LogEntry>('logs'),
  beforeAction: putAuthInfoInArgs,
});

export const logsSagas = [requestLogsWatcher];
