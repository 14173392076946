import { Action } from 'redux';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import { createSelector } from 'reselect';
import { call, put, take } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  ErrorAction,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import {
  NOT_FOUND,
  UNAUTHORIZED,
  SERVER_ERROR,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { ArgsWithHeaders } from 'utils/typings';
import { putDefaultHeadersInArgs } from './auth.module';
import { Creators as MfaActions, Types as MfaTypes } from 'modules/mfa.module';

interface ActionTypes {
  LOADING_INVITE: string;
  REJECT_INVITE: string;
  ACCEPT_INVITE: string;
  ERROR_INVITE: string;
}

export interface UserFields {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  phone: string;
  password: string;
}

interface RejectInvitePayload {
  email: string;
}

type AcceptInvitePayload = UserFields;

export interface UserInvitationState {
  loading: boolean;
}

interface ActionCreators {
  loadingInvite: () => Action;
  acceptInvite: (payload: UserFields) => MyAction<UserFields>;
  rejectInvite: (
    payload: Pick<UserFields, 'email'>,
  ) => MyAction<Pick<UserFields, 'email'>>;
  errorInvite: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingInvite: [],
  acceptInvite: ['payload'],
  rejectInvite: ['payload'],
  errorInvite: ['error'],
});

const goBack = goBackFactory('/');

const initialState = {
  loading: false,
};

function setLoading(state: UserInvitationState): UserInvitationState {
  return {
    loading: !state.loading,
  };
}

export const userInvitationReducer = createReducer(initialState, {
  [Types.LOADING_INVITE]: setLoading,
  [Types.ERROR_INVITE]: setLoading,
});

const errorActionWithToast = <TError extends Error>(error: TError) => {
  toast.error(error.message);

  return Creators.errorInvite(error);
};

function* checkInviteExpiration(response: Response) {
  if ([NOT_FOUND, SERVER_ERROR].includes(response.status)) {
    yield put(push('/'));
    throw Error('This invite is expired');
  }
}

function* acceptInvite({ headers, ...payload }: ArgsWithHeaders<UserFields>) {
  const result = yield call(fetch, `${API_URL}/users/admin/accept`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    yield checkInviteExpiration(result);

    throw Error('There has been an error processing your request');
  }

  yield put(
    MfaActions.requestMfaVerify({
      email: payload.email,
      phone: payload.phone.slice(-4),
      isVerifiedByGoogle: false,
    }),
  );

  yield take(MfaTypes.SUCCESS_MFA);
}

function* rejectInvite({
  headers,
  ...payload
}: ArgsWithHeaders<RejectInvitePayload>) {
  const result = yield call(fetch, `${API_URL}/users/admin/decline`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    yield checkInviteExpiration(result);

    throw Error('There has been an error processing your request');
  }
}

const acceptInviteWatcher = createSingleEventSaga<
  AcceptInvitePayload,
  void,
  MyAction<AcceptInvitePayload>
>({
  takeEvery: Types.ACCEPT_INVITE,
  loadingAction: Creators.loadingInvite,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: errorActionWithToast,
  action: acceptInvite,
  beforeAction: putDefaultHeadersInArgs,
});

const rejectInviteWatcher = createSingleEventSaga<
  RejectInvitePayload,
  void,
  MyAction<RejectInvitePayload>
>({
  takeEvery: Types.REJECT_INVITE,
  loadingAction: Creators.loadingInvite,
  commitAction: Creators.loadingInvite,
  successAction: goBack.action,
  errorAction: errorActionWithToast,
  action: rejectInvite,
  beforeAction: putDefaultHeadersInArgs,
});

export const selectUserInvitationState = (state: MyState) =>
  state.userInvitation;

export const selectUserInvitationLoading = createSelector(
  selectUserInvitationState,
  (state) => state.loading,
);

export const userInviteSagas = [
  acceptInviteWatcher,
  rejectInviteWatcher,
  goBack.watcher,
];
