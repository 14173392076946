import { Action } from 'redux';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';
import { call } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';

import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import { Nullable, SagaIterator } from 'typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import { MyState } from 'store';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

export enum UserRoles {
  SUPER_ADMIN = 1,
  ADMIN = 2,
}

export interface User {
  createdAt: string;
  deletedAt: string;
  email: string;
  firstName: string;
  lastName: string;
  roleDescription?: string;
  id: string;
  roleId: UserRoles;
  updatedAt: string;
  jobTitle: string;
}

interface ActionTypes {
  REQUEST_USERS: string;
  LOADING_USERS: string;
  COMMIT_USERS: string;
  ERROR_USERS: string;
  REQUEST_DELETE_USER: string;
  COMMIT_SELECTED_USER: string;
  REQUEST_UPDATE_USER: string;
  REMOVE_USER: string;
}

type UpdateUserPayload = { id: string; user: Partial<User> };

interface ActionCreators {
  requestUsers: () => Action;
  loadingUsers: () => Action;
  removeUser: (payload: { id: string }) => Action;
  requestDeleteUser: (payload: string) => Action;
  commitSelectedUser: (payload: User) => Action;
  requestUpdateUser: (payload: UpdateUserPayload) => Action;
  commitUsers: (payload: Paginated<User>) => MyAction<Paginated<User>>;
  errorUsers: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface UsersState<TError extends Error = Error>
  extends EntityState<User> {
  loading: boolean;
  count: number;
  selectedUser: Nullable<User>;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUsers: [],
  loadingUsers: [],
  requestDeleteUser: ['payload'],
  commitSelectedUser: ['payload'],
  requestUpdateUser: ['payload'],
  removeUser: ['payload'],
  commitUsers: ['payload'],
  errorUsers: ['error'],
});

const entityAdapter = createEntityAdapter<User>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
  selectedUser: null,
});

export const usersSelector = entityAdapter.getSelectors();

function setLoading(state: UsersState): UsersState {
  return {
    ...state,
    loading: true,
  };
}

function commitUsers(
  state: UsersState,
  action: MyAction<Paginated<User>>,
): UsersState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitSelectedUser(
  state: UsersState,
  action: MyAction<User>,
): UsersState {
  return {
    ...state,
    loading: false,
    selectedUser: action.payload,
  };
}

function setError<TError extends Error = Error>(
  state: UsersState,
  { error }: ErrorAction<TError>,
): UsersState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function removeUser(
  state: UsersState,
  action: MyAction<{ id: string }>,
): UsersState {
  return {
    ...entityAdapter.removeOne(action.payload.id, state),
    count: state.count - 1,
    loading: false,
  };
}

export const usersReducer = createReducer(initialState, {
  [Types.LOADING_USERS]: setLoading,
  [Types.COMMIT_USERS]: commitUsers,
  [Types.COMMIT_SELECTED_USER]: commitSelectedUser,
  [Types.ERROR_USERS]: setError,
  [Types.REMOVE_USER]: removeUser,
});

const goBack = goBackFactory('/users');

async function deleteUser({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<void> {
  const result = await fetch(`${API_URL}/users/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function updateUser({
  headers,
  ...payload
}: ArgsWithHeaders<UpdateUserPayload>): Promise<void> {
  const result = await fetch(`${API_URL}/users/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload.user),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestDeleteUserWatcher = createSingleEventSaga<
  { id: string },
  void,
  MyAction<{ id: string }>
>({
  takeEvery: Types.REQUEST_DELETE_USER,
  loadingAction: Creators.loadingUsers,
  commitAction: Creators.removeUser,
  successAction: noOpAction,
  errorAction: Creators.errorUsers,
  action: deleteUser,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<{ id: string }>,
  ): SagaIterator {
    return args;
  },
});

const requestUsersWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<User>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/users'),
  loadingAction: Creators.loadingUsers,
  commitAction: Creators.commitUsers,
  successAction: noOpAction,
  errorAction: Creators.errorUsers,
  action: downloadUsingLocationQuery<User>('users'),
  beforeAction: putAuthInfoInArgs,
});

const requestSelectedUserWatcher = createSingleEventSaga<
  { id: string },
  User,
  MyAction<{ id: string }>
>({
  takeEvery: onRoute('/users/:id'),
  loadingAction: Creators.loadingUsers,
  commitAction: Creators.commitSelectedUser,
  successAction: noOpAction,
  errorAction: Creators.errorUsers,
  action: function* (payload: ArgsWithHeaders<LocationChangeActionPayload>) {
    const params = yield call(extractRouteParams('/users/:id'), payload);

    return yield downloadUsingLocationQuery<User>(`users/${params.id}`)(
      payload,
    );
  },
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateUserWatcher = createSingleEventSaga<
  UpdateUserPayload,
  User,
  MyAction<UpdateUserPayload>
>({
  takeEvery: Types.REQUEST_UPDATE_USER,
  loadingAction: Creators.loadingUsers,
  commitAction: Creators.commitSelectedUser,
  successAction: goBack.action,
  errorAction: Creators.errorUsers,
  action: updateUser,
  beforeAction: putAuthInfoInArgs,
});

export const usersSagas = [
  requestSelectedUserWatcher,
  requestUpdateUserWatcher,
  requestDeleteUserWatcher,
  requestUsersWatcher,
];

export const selectUsersState = (state: MyState) => state.users;

export const selectUsers = createSelector(selectUsersState, (state) =>
  usersSelector.selectAll(state),
);
