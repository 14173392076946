import React, { Fragment } from 'react';

import MMDLoader from '../components/MMDLoader';
import { useLocation } from 'react-router-dom';

type Props = {
  location: any;
  loading: boolean;
};

const PreviewHtml: React.FC<Props> = ({ loading }) => {
  const location = useLocation();

  const { html } = location.state;

  return (
    <Fragment>
      <MMDLoader loading={loading} />
      <div className="container" dangerouslySetInnerHTML={{ __html: html }} />
    </Fragment>
  );
};

export default PreviewHtml;
