import { FC } from 'react';

import { Timeslot } from 'typings';
import { getTimeslotDurationString } from './helpers';

type Props = {
  isTimeslot?: boolean;
  timeslot?: Timeslot;
  text?: string;
  isPlus?: boolean;
  onClick?: any;
  isEdit?: boolean;
};

const TimeslotButton: FC<Props> = ({
  timeslot,
  isTimeslot = false,
  isPlus = false,
  isEdit = false,
  text,
  onClick,
}) => (
  <div className="appointment-item pt-3 pb-3 column">
    <div
      className="flex is-justify-content-space-between"
      style={{ cursor: 'pointer' }}
      onClick={() => onClick(timeslot)}
    >
      <div>
        {isTimeslot && timeslot ? (
          <span style={{ fontSize: 16 }}>
            {getTimeslotDurationString(timeslot)}

            {timeslot.recurringEventId && (
              <span style={{ color: '#DB386C' }}>REPEAT</span>
            )}
          </span>
        ) : (
          <strong>{text}</strong>
        )}
      </div>
      <div>
        {isPlus && <span className="is-plus">+</span>}
        {isEdit && <span className="is-edit">✎</span>}
      </div>
    </div>
  </div>
);

export default TimeslotButton;
