import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import MMDButton from 'components/MMDButton';
import MMDLoader from 'components/MMDLoader';
import { push } from 'redux-first-history';
import { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { LicensedState } from 'typings';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { Creators as LicensedStatesActions } from '../modules/licensed-states.module';
import { MyState } from '../store';
import LicensedStateRow from './licensed-state/LicensedStateRow';
import MMDPager from 'components/MMDPager';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { useParams } from 'react-router-dom';

type Props = {
  loading: boolean;
  routeParams: Dictionary<string>;
  licensedStates: LicensedState[];
  push: typeof push;
  count: number;
} & typeof LicensedStatesActions;

const LicensedStates: FC<Props> = ({
  loading,
  licensedStates,
  push,
  requestRemoveLicensedState,
  routeParams,
  count,
}) => {
  const params = useParams<{ id: string }>();

  const goToCreateLicensedState = useCallback(() => {
    push(`/providers/${params.id}/create-licensed-states`);
  }, [push]);

  const requestDeleteLicensedState = useCallback(
    (id: string) => {
      requestRemoveLicensedState({ id });
    },
    [requestRemoveLicensedState],
  );

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(
        `/providers/${params.id}/licensed-states${prepareQueryWithOffset(
          nextOffset,
          routeParams,
        )}`,
      );
    },
    [push, routeParams, params],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <div className="v-center">
          <MMDButton
            className="mt-2"
            isPrimary
            onClick={goToCreateLicensedState}
            text="Add state"
          />
        </div>
      </div>
      <div className="container p-2">
        {licensedStates.map((item) => (
          <LicensedStateRow
            key={item.id}
            licensedState={item}
            onEdit={() => {}}
            onDelete={requestDeleteLicensedState}
            providerId={params.id}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => {
  const licensedStates = Object.keys(state.licensedStates.entities).map(
    (item) => state.licensedStates.entities[item],
  );
  return {
    loading: state.licensedStates.loading,
    licensedStates,
    count: state.licensedStates.count,
    routeParams: paramsToDictionary(state.router.location.search),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...LicensedStatesActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LicensedStates);
