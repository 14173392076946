import { useBoundedActions } from 'hooks/useBoundedActions';

import OrganizationForm from './OrganizationForm';
import { Creators as OrganizationsActions } from 'modules/organizations.module';

const NewOrganization = () => {
  const { requestCreateOrganization } = useBoundedActions(OrganizationsActions);

  return (
    <div className="container p-2">
      <OrganizationForm isEdit={false} onSubmit={requestCreateOrganization} />
    </div>
  );
};

export default NewOrganization;
