import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import { Level } from 'react-bulma-components';
import { FC, FormEvent, useCallback, useState } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { MyState } from 'store';
import MMDLoader from 'components/MMDLoader';
import { TermsAndConditions } from 'typings';
import MMDButton from 'components/MMDButton';
import { USER_TYPE_FILTER } from 'utils/constants';
import MMDSelect from 'components/forms/MMDSelect';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';
import TermsAndConditionsRow from 'pages/terms-and-conditions/TermsAndConditionsRow';
import {
  termAndConditionSelectors,
  Creators as TermsAndConditionsActions,
} from 'modules/terms-and-conditions.module';

type Props = {
  termsAndConditions?: TermsAndConditions[];
  loading: boolean;
  push: typeof push;
} & typeof TermsAndConditionsActions;

const TermsAndCondition: FC<Props> = ({
  termsAndConditions,
  push,
  requestUpdateTermsAndConditions,
  loading,
}) => {
  const params = useParams<{ sendLive?: string }>();
  const isSuperAdmin = useIsSuperAdminCheck();
  const [liveFilter, setLiveFilter] = useState(params.sendLive === 'true');
  const [currentFilter, setCurrentFilter] = useState('');

  const onFilterChanged = useCallback(
    (e: FormEvent<HTMLSelectElement>) => {
      const input = e.target as HTMLSelectElement;
      setCurrentFilter(input.value);
      if (input.value === 'Patient' || input.value === 'Doctor') {
        push(`terms-and-conditions?userType=${input.value}`);
      } else {
        push('terms-and-conditions');
      }
    },
    [push, setCurrentFilter],
  );

  const goToCreateTermsAndConditions = useCallback(
    (userType) => {
      push(`/create-term-and-condition/${userType}`);
    },
    [push],
  );

  const onSendLive = useCallback(
    (id: number) => {
      requestUpdateTermsAndConditions({ id, sendLive: true });
      setTimeout(() => push('/terms-and-conditions'), 0);
    },
    [requestUpdateTermsAndConditions],
  );

  const showOnlyNotLive = useCallback(() => {
    const nextLiveFilter = !liveFilter;
    setLiveFilter(nextLiveFilter);

    if (nextLiveFilter) {
      push(`terms-and-conditions?sendLive=${nextLiveFilter}`);
    } else {
      push('terms-and-conditions');
    }
  }, [push, liveFilter, setLiveFilter]);

  const clearFilter = () => {
    push('terms-and-conditions');
    setLiveFilter(params.sendLive === 'true');
    setCurrentFilter('');
  };

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDSelect
                name="filter"
                label="Filter by..."
                value={currentFilter}
                options={USER_TYPE_FILTER}
                onChange={onFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDCheckbox
                name="admin-approval"
                label="Show only live terms and conditions"
                value={liveFilter ? 'true' : 'false'}
                onChange={showOnlyNotLive}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton text="Clean all" isDanger onClick={clearFilter} />
            </Level.Item>
          </Level.Side>
          {isSuperAdmin && (
            <Level.Side align="right">
              <Level.Item>
                <h5>Create new version for: </h5>
              </Level.Item>
              <Level.Item>
                <MMDButton
                  isPrimary
                  onClick={(e) => goToCreateTermsAndConditions('Patient')}
                  text="Patient"
                />
              </Level.Item>
              <Level.Item>
                <MMDButton
                  isPrimary
                  onClick={(e) => goToCreateTermsAndConditions('Doctor')}
                  text="Provider"
                />
              </Level.Item>
            </Level.Side>
          )}
        </Level>
        {termsAndConditions.map((item: TermsAndConditions) => (
          <TermsAndConditionsRow
            key={item.id}
            termsAndConditions={item}
            targetPath={'termsAndConditions'}
            onSendLive={onSendLive}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  termsAndConditions: termAndConditionSelectors.selectAll(
    state.termsAndConditions,
  ),
  loading: state.termsAndConditions.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...TermsAndConditionsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
