import { Link } from 'react-router-dom';
import React, { FC, useCallback } from 'react';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { PromoCode } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  promoCode: PromoCode;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const PromoCodeRow: FC<Props> = ({
  promoCode,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(promoCode.id);
  }, [promoCode, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>CODE {promoCode.code || promoCode.externalId}</strong>
          {promoCode.externalId ? (
            <Heading subtitle size={6} renderAs="h6">
              See in Stripe
            </Heading>
          ) : promoCode.percentageOff > 0 ? (
            <Heading subtitle size={6} renderAs="h6">
              Off %: {promoCode.percentageOff}
            </Heading>
          ) : (
            <Heading subtitle size={6} renderAs="h6">
              Amount Off: ${promoCode.amountOff}
            </Heading>
          )}
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${promoCode.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default PromoCodeRow;
