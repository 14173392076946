import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call, select } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  Dictionary,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { API_URL } from 'config';
import { COORD_REGEX } from 'utils/constants';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { LicensedState, SagaIterator } from 'typings';
import { deepDiffBetweenObjects } from 'utils/deepDiffBetweenObjects';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';

type LicensedStatesId = {
  id: string;
};

interface ActionTypes {
  REQUEST_CREATE_LICENSED_STATE: string;
  REQUEST_UPDATE_LICENSED_STATE: string;
  REQUEST_REMOVE_LICENSED_STATE: string;

  COMMIT_CREATE_LICENSED_STATE: string;
  COMMIT_REMOVE_LICENSED_STATE: string;

  LOADING_LICENSED_STATES: string;
  COMMIT_LICENSED_STATES: string;

  ERROR_LICENSED_STATES: string;
}

interface ActionCreators {
  requestCreateLicensedState: (
    payload: LicensedState,
  ) => MyAction<LicensedState>;
  requestUpdateLicensedState: (
    payload: Partial<LicensedState>,
  ) => MyAction<Partial<LicensedState>>;
  requestRemoveLicensedState: (
    payload: LicensedStatesId,
  ) => MyAction<LicensedStatesId>;

  commitCreateLicensedState: (
    payload: LicensedState,
  ) => MyAction<LicensedState>;
  commitRemoveLicensedState: (
    payload: LicensedStatesId,
  ) => MyAction<LicensedStatesId>;

  loadingLicensedStates: () => Action;
  commitLicensedStates: (
    payload: Paginated<LicensedState>,
  ) => MyAction<Paginated<LicensedState>>;

  errorLicensedStates: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface LicensedStatesState<TError extends Error = Error>
  extends EntityState<LicensedState> {
  loading: boolean;
  count: number;
  error?: TError;
  userId?: string;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateLicensedState: ['payload'],
  requestUpdateLicensedState: ['payload'],
  requestRemoveLicensedState: ['payload'],

  commitCreateLicensedState: ['payload'],
  commitRemoveLicensedState: ['payload'],

  loadingLicensedStates: [],
  commitLicensedStates: ['payload'],

  errorLicensedStates: ['error'],
});

const entityAdapter = createEntityAdapter<LicensedState>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const licensedStatesSelectors = entityAdapter.getSelectors();

function setLoading(state: LicensedStatesState): LicensedStatesState {
  return {
    ...state,
    loading: true,
  };
}

function commitLicensedStates(
  state: LicensedStatesState,
  action: MyAction<Paginated<LicensedState>>,
): LicensedStatesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitCreateLicensedState(
  state: LicensedStatesState,
  action: MyAction<LicensedState>,
): LicensedStatesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitRemoveLicensedState(
  state: LicensedStatesState,
  action: MyAction<LicensedState>,
): LicensedStatesState {
  return {
    ...entityAdapter.removeOne(action.payload.id, state),
    count: state.count - 1,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: LicensedStatesState,
  { error }: ErrorAction<TError>,
): LicensedStatesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const LicensedStatesReducer = createReducer(initialState, {
  [Types.COMMIT_CREATE_LICENSED_STATE]: commitCreateLicensedState,
  [Types.COMMIT_REMOVE_LICENSED_STATE]: commitRemoveLicensedState,

  [Types.LOADING_LICENSED_STATES]: setLoading,
  [Types.COMMIT_LICENSED_STATES]: commitLicensedStates,

  [Types.ERROR_LICENSED_STATES]: setError,
});

const paymentTransactionWatcherConfigurationFactory = (
  transactionEndpointRoot: string,
): any => {
  const urlSegment = `/${transactionEndpointRoot}/:id/licensed-states`;
  return {
    takeEvery: onRoute(urlSegment),
    loadingAction: Creators.loadingLicensedStates,
    commitAction: Creators.commitLicensedStates,
    successAction: noOpAction,
    errorAction: Creators.errorLicensedStates,
    action: downloadUsingLocationQuery<LicensedState>(transactionEndpointRoot),
    beforeAction: composeSagas<
      LocationChangeActionPayload,
      LicensedStatesId,
      ArgsWithHeaders<LicensedStatesId>
    >(function* (payload: LocationChangeActionPayload): SagaIterator {
      const params = yield call(extractRouteParams(urlSegment), payload);
      const offset = payload.location.search || '?__offset=0';
      const sort = '&__sort=name';
      return {
        ...payload,
        location: {
          ...payload.location,
          search: `/${params.id}/licensed-states${offset}${sort}`,
        },
      };
    }, putAuthInfoInArgs),
  };
};

const requestProviderPaymentTransactionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<LicensedState>,
  MyAction<LocationChangeActionPayload>
>(paymentTransactionWatcherConfigurationFactory('providers'));

const goBack = goBackFactory('/providers/:id/licensed-states');

async function createLicensedState({
  headers,
  ...payload
}: ArgsWithHeaders<LicensedState>): Promise<CreationResult<LicensedState>> {
  const result = await fetch(`${API_URL}/licensed-states`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

const requestCreateLicensedStateWatcher = createSingleEventSaga<
  LicensedState,
  LicensedState,
  MyAction<LicensedState>
>({
  takeEvery: Types.REQUEST_CREATE_LICENSED_STATE,
  loadingAction: Creators.loadingLicensedStates,
  commitAction: Creators.commitCreateLicensedState,
  successAction: goBack.action,
  errorAction: Creators.errorLicensedStates,
  action: createLicensedState,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<LicensedState>,
    { headers, ...args }: ArgsWithHeaders<LicensedState>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

async function deleteLicensedStates({
  headers,
  ...payload
}: ArgsWithHeaders<LicensedStatesId>): Promise<void> {
  const result = await fetch(`${API_URL}/licensed-states/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestDeleteLicensedStatesWatcher = createSingleEventSaga<
  LicensedStatesId,
  void,
  MyAction<LicensedStatesId>
>({
  takeEvery: Types.REQUEST_REMOVE_LICENSED_STATE,
  loadingAction: Creators.loadingLicensedStates,
  commitAction: Creators.commitRemoveLicensedState,
  successAction: noOpAction,
  errorAction: Creators.errorLicensedStates,
  action: deleteLicensedStates,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<LicensedStatesId>,
  ): SagaIterator {
    return args;
  },
});

function* updateLicensedStates({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<LicensedState>>): SagaIterator {
  const state = yield select((state) =>
    selectLicensedStatesById(state, payload.id),
  );

  const updatedState = deepDiffBetweenObjects(payload, state);

  if (!Object.keys(updatedState).length) {
    return;
  }

  const result = yield call(fetch, `${API_URL}/licensed-states/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestUpdateLicensedStateWatcher = createSingleEventSaga<
  Partial<LicensedState>,
  Partial<LicensedState>,
  MyAction<Partial<LicensedState>>
>({
  takeEvery: Types.REQUEST_UPDATE_LICENSED_STATE,
  loadingAction: Creators.loadingLicensedStates,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: Creators.errorLicensedStates,
  action: updateLicensedStates,
  beforeAction: putAuthInfoInArgs,
});

export const LicensedStatesSagas = [
  requestProviderPaymentTransactionsWatcher,
  requestCreateLicensedStateWatcher,
  requestDeleteLicensedStatesWatcher,
  requestUpdateLicensedStateWatcher,
  goBack.watcher,
];

export function licensedStatesFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
    coordinates: true,
  };

  if (values['name'] === '' || !values['name']) {
    result.name = false;
  }

  if (values['coordinates'] === '' || !values['coordinates']) {
    result.coordinates = false;
  }

  if (!values['coordinates'].match(COORD_REGEX)) {
    result.coordinates = false;
  }

  return Promise.resolve(result);
}
const selectLicensedStatesState = (state: MyState) => state.licensedStates;

export const selectLicensedStatesById = createSelector(
  [selectLicensedStatesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectLicensedStatesLoading = createSelector(
  selectLicensedStatesState,
  (state) => state.loading,
);
