interface Coordinates {
  latitude?: number;
  _latitude?: number;
  _longitude?: number;
  longitude?: number;
}

export const getCoordinatesString = (coordinates: Coordinates) => {
  if (!coordinates) {
    return '';
  }

  return `[${coordinates._latitude ?? coordinates.latitude},${
    coordinates._longitude ?? coordinates.longitude
  }]`;
};
