import MMDGenericTable from 'components/MMDGenericTable';
import MMDLoader from 'components/MMDLoader';
import MMDPager from 'components/MMDPager';
import { push } from 'redux-first-history';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  Creators as TransactionsActions,
  transactionSelectors,
} from '../modules/transactions.module';
import { MyState } from '../store';
import { Transaction } from '../typings';
import { useLocation } from 'react-router-dom';

type Props = {
  transactions: Transaction[];
  loading: boolean;
  count: number;
  push: typeof push;
} & typeof TransactionsActions;

const calTitles = () => {
  const titles = ['amount', 'createdAt', 'updatedAt'];
  return titles;
};

const dataTable = (data: Transaction[]) => {
  return data.map((item) => {
    const result = {
      amount: item.amount,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    };
    return result;
  });
};

export const PaymentTransactions: React.FC<Props> = ({
  count,
  push,
  transactions,
  loading,
}) => {
  const titles = calTitles();
  const table = dataTable(transactions);

  const location = useLocation();

  const routeParams = paramsToDictionary(location.search);

  const gotoPage = React.useCallback(
    (nextOffset: number) => {
      push(`transactions${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <MMDGenericTable
          titles={titles}
          dataTable={table}
          label="Payment Transactions"
        />
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  transactions: transactionSelectors.selectAll(state.transactions),
  loading: state.transactions.loading,
  count: state.transactions.count,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      ...TransactionsActions,
      push,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentTransactions);
