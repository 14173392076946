import QuestionnaireForm from './QuestionnaireForm';

const NewQuestionnaire = () => {
  return (
    <div className="container p-2">
      <QuestionnaireForm />
    </div>
  );
};

export default NewQuestionnaire;
