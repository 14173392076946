import { API_URL } from '../config';
import { appendPageLimit } from './appendPageLimit';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
  Paginated,
} from './typings';
import { UNAUTHORIZED, INVISIBLE_ERROR_MESSAGE } from './onRoute';

export const downloadUsingLocationQuery =
  <T>(endpoint: string) =>
  async ({
    headers,
    ...payload
  }: ArgsWithHeaders<LocationChangeActionPayload>): Promise<Paginated<T>> => {
    const result = await fetch(
      `${API_URL}/${endpoint}${appendPageLimit(
        payload.location.search,
      )}&__sort=-updatedAt`,
      {
        headers,
        method: 'GET',
      },
    );

    if (!result.ok) {
      if (result.status === UNAUTHORIZED) {
        throw Error(INVISIBLE_ERROR_MESSAGE);
      }
      throw Error('There has been an error processing your request');
    }

    return result.json();
  };
