import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { Affiliation, SagaIterator } from '../typings';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from '../utils/downloadUsingLocationQuery';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface AffiliationPayload {
  id: number;
}

interface ActionTypes {
  REQUEST_CREATE_AFFILIATION: string;
  REQUEST_UPDATE_AFFILIATION: string;
  REQUEST_REMOVE_AFFILIATION: string;
  LOADING_AFFILIATIONS: string;
  COMMIT_AFFILIATIONS: string;
  COMMIT_AFFILIATION: string;
  REMOVE_AFFILIATION: string;
  ERROR_AFFILIATIONS: string;
}

interface ActionCreators {
  requestCreateAffiliation: (payload: Affiliation) => MyAction<Affiliation>;
  requestUpdateAffiliation: (
    payload: Partial<Affiliation>,
  ) => MyAction<Partial<Affiliation>>;
  requestRemoveAffiliation: (
    payload: AffiliationPayload,
  ) => MyAction<AffiliationPayload>;
  loadingAffiliations: () => Action;
  commitAffiliations: (
    payload: Paginated<Affiliation>,
  ) => MyAction<Paginated<Affiliation>>;
  commitAffiliation: (payload: Affiliation) => MyAction<Affiliation>;
  removeAffiliation: (
    payload: AffiliationPayload,
  ) => MyAction<AffiliationPayload>;
  errorAffiliations: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface AffiliationsState<TError extends Error = Error>
  extends EntityState<Affiliation> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateAffiliation: ['payload'],
  requestUpdateAffiliation: ['payload'],
  requestRemoveAffiliation: ['payload'],
  loadingAffiliations: [],
  commitAffiliations: ['payload'],
  commitAffiliation: ['payload'],
  removeAffiliation: ['payload'],
  errorAffiliations: ['error'],
});

const entityAdapter = createEntityAdapter<Affiliation>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const affiliationsSelectors = entityAdapter.getSelectors();

function setLoading(state: AffiliationsState): AffiliationsState {
  return {
    ...state,
    loading: true,
  };
}

function commitAffiliation(
  state: AffiliationsState,
  action: MyAction<Affiliation>,
): AffiliationsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitAffiliations(
  state: AffiliationsState,
  action: MyAction<Paginated<Affiliation>>,
): AffiliationsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeAffiliation(
  state: AffiliationsState,
  action: MyAction<AffiliationPayload>,
): AffiliationsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: AffiliationsState,
  { error }: ErrorAction<TError>,
): AffiliationsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const affiliationReducer = createReducer(initialState, {
  [Types.LOADING_AFFILIATIONS]: setLoading,
  [Types.COMMIT_AFFILIATION]: commitAffiliation,
  [Types.COMMIT_AFFILIATIONS]: commitAffiliations,
  [Types.REMOVE_AFFILIATION]: removeAffiliation,
  [Types.ERROR_AFFILIATIONS]: setError,
});

async function downloadAffiliation({
  headers,
  ...payload
}: ArgsWithHeaders<AffiliationPayload>): Promise<Affiliation> {
  const result = await fetch(`${API_URL}/affiliations/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createAffiliation({
  headers,
  ...payload
}: ArgsWithHeaders<Affiliation>): Promise<CreationResult<Affiliation>> {
  const result = await fetch(`${API_URL}/affiliations`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateAffiliation({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Affiliation>>): Promise<void> {
  const result = await fetch(`${API_URL}/affiliations/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteAffiliation({
  headers,
  ...payload
}: ArgsWithHeaders<AffiliationPayload>): Promise<void> {
  const result = await fetch(`${API_URL}/affiliations/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestAffiliationWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Affiliation,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/affiliations/:id'),
  loadingAction: Creators.loadingAffiliations,
  commitAction: Creators.commitAffiliation,
  successAction: noOpAction,
  errorAction: Creators.errorAffiliations,
  action: downloadAffiliation,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    AffiliationPayload,
    ArgsWithHeaders<AffiliationPayload>
  >(extractRouteParams('/affiliations/:id'), putAuthInfoInArgs),
});

const requestAffiliationsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Affiliation>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/affiliations'),
  loadingAction: Creators.loadingAffiliations,
  commitAction: Creators.commitAffiliations,
  successAction: noOpAction,
  errorAction: Creators.errorAffiliations,
  action: downloadUsingLocationQuery<Affiliation>('affiliations'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/affiliations');

const requestCreateAffiliationWatcher = createSingleEventSaga<
  Affiliation,
  Affiliation,
  MyAction<Affiliation>
>({
  takeEvery: Types.REQUEST_CREATE_AFFILIATION,
  loadingAction: Creators.loadingAffiliations,
  commitAction: Creators.commitAffiliation,
  successAction: goBack.action,
  errorAction: Creators.errorAffiliations,
  action: createAffiliation,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Affiliation>,
    { headers, ...args }: ArgsWithHeaders<Affiliation>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateAffiliationWatcher = createSingleEventSaga<
  Partial<Affiliation>,
  Partial<Affiliation>,
  MyAction<Partial<Affiliation>>
>({
  takeEvery: Types.REQUEST_UPDATE_AFFILIATION,
  loadingAction: Creators.loadingAffiliations,
  commitAction: Creators.commitAffiliation,
  successAction: goBack.action,
  errorAction: Creators.errorAffiliations,
  action: updateAffiliation,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Affiliation>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteAffiliationWatcher = createSingleEventSaga<
  AffiliationPayload,
  void,
  MyAction<AffiliationPayload>
>({
  takeEvery: Types.REQUEST_REMOVE_AFFILIATION,
  loadingAction: Creators.loadingAffiliations,
  commitAction: Creators.removeAffiliation,
  successAction: noOpAction,
  errorAction: Creators.errorAffiliations,
  action: deleteAffiliation,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<AffiliationPayload>,
  ): SagaIterator {
    return args;
  },
});

export const affiliationSagas = [
  requestAffiliationWatcher,
  requestAffiliationsWatcher,
  requestCreateAffiliationWatcher,
  requestUpdateAffiliationWatcher,
  requestDeleteAffiliationWatcher,
  goBack.watcher,
];

export function affiliationFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
    approved: true,
  };

  result.name = values['name'].length > 0;

  return Promise.resolve(result);
}

const selectAffiliationState = (state: MyState) => state.affiliations;

export const selectAffiliationById = createSelector(
  [selectAffiliationState, (_, id) => id],
  (state, id) => state.entities[id],
);
