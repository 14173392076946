import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHookFormMask } from 'use-mask-input';
import { Controller, useForm } from 'react-hook-form';

import MMDButton from 'components/MMDButton';
import { Option, Organization } from 'typings';
import { PHONE_REG_EXP } from 'utils/constants';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDTextInput from 'components/forms/MMDTextInput';
import {
  ConvertedOrganizationFormValues,
  OrganizationFormValues,
} from './types';
import { useBoundedActions } from 'hooks/useBoundedActions';
import MMDiSelectNew from 'components/forms/MMDiSelectNew';
import { selectImmunizations } from 'modules/immunizations.module';
import { Creators as ImmunizationsActions } from 'modules/immunizations.module';
import {
  convertFormValues,
  mapOptionsForSelect,
  filterImmunizationOptions,
} from './helpers';
import {
  Creators as OrganizationTypesActions,
  selectOrganizationTypes,
} from '../../modules/organization-types.module';

type Props = {
  isEdit?: boolean;
  organization?: Organization;
  onSubmit: (values: ConvertedOrganizationFormValues) => void;
};

const OrganizationForm: FC<Props> = ({ isEdit, organization, onSubmit }) => {
  const {
    watch,
    control,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationFormValues>({
    mode: 'onChange',
    defaultValues: {
      name: organization?.name ?? '',
      organizationTypeId: organization?.type
        ? {
            label: organization.type.name,
            value: organization.type.id.toString(),
          }
        : undefined,
      address: organization?.address ?? '',
      phone: organization?.phone ?? '',
      immunizationRequired: organization?.requiredImmunizations?.length
        ? 'true'
        : 'false',
      requiredImmunizations: mapOptionsForSelect(
        organization?.requiredImmunizations ?? [],
      ),
      recommendedImmunizations: mapOptionsForSelect(
        organization?.recommendedImmunizations ?? [],
      ),
      promoCode: organization?.promoCodeId ? 'true' : 'false',
    },
  });

  const { requestAllImmunizations, requestAllOrganizationTypes } =
    useBoundedActions({
      ...ImmunizationsActions,
      ...OrganizationTypesActions,
    });

  useEffect(() => {
    requestAllImmunizations();
    requestAllOrganizationTypes();
  }, []);

  const hasFormErrors = Object.keys(errors).length > 0;

  const registerWithMask = useHookFormMask(register);

  const immunizationCheckboxValue = watch('immunizationRequired');

  const promoCodeCheckboxValue = watch('promoCode');

  const isImmunizationRequired = immunizationCheckboxValue === 'true';

  const organizationTypes = useSelector(selectOrganizationTypes);

  const immunizations = useSelector(selectImmunizations);

  const requiredImmunizations = filterImmunizationOptions(
    immunizations,
    watch('recommendedImmunizations'),
  );

  const recommendedImmunizations = filterImmunizationOptions(
    immunizations,
    watch('requiredImmunizations') as Option[],
  );

  useEffect(() => {
    clearErrors('requiredImmunizations');
  }, [!isImmunizationRequired]);

  return (
    <form onSubmit={handleSubmit(convertFormValues(onSubmit, organization))}>
      <MMDTextInput
        required
        label="Name"
        valid={!errors.name}
        {...register('name', {
          required: true,
          setValueAs: (value: string) => value.trim(),
        })}
      />

      <Controller
        control={control}
        rules={{
          required: true,
        }}
        name="organizationTypeId"
        render={({ field: { onChange, value, name } }) => (
          <MMDiSelectNew
            required
            name={name}
            value={value}
            className="mt-3"
            onChange={onChange}
            valid={!errors.organizationTypeId}
            options={mapOptionsForSelect(organizationTypes)}
            label="Type"
          />
        )}
      />

      <MMDTextInput
        required
        label="Address"
        valid={!errors.address}
        {...register('address', {
          required: true,
          setValueAs: (value: string) => value.trim(),
        })}
      />

      <MMDTextInput
        required
        withoutMask
        label="Phone"
        valid={!errors.phone}
        {...registerWithMask('phone', ['(###) ###-####'], {
          pattern: PHONE_REG_EXP,
          required: true,
          setValueAs: (value: string) => value.trim(),
        })}
      />

      <MMDCheckbox
        className="mt-3"
        label="Promo code"
        value={promoCodeCheckboxValue}
        name="promoCode"
        onChange={({ target }) =>
          // @ts-ignore
          setValue('promoCode', target.value, {
            shouldValidate: true,
          })
        }
      />

      <MMDCheckbox
        className="mt-3"
        label="Immunization required"
        value={immunizationCheckboxValue}
        name="immunizationRequired"
        onChange={({ target }) =>
          // @ts-ignore
          setValue('immunizationRequired', target.value, {
            shouldValidate: true,
          })
        }
      />

      {isImmunizationRequired && (
        <>
          <Controller
            control={control}
            rules={{
              validate: (value, formValues) =>
                formValues.immunizationRequired === 'false' ||
                value?.length > 0,
            }}
            name="requiredImmunizations"
            render={({ field: { onChange, value, name } }) => (
              <MMDiSelectNew
                required
                isMulti
                name={name}
                value={value}
                className="mt-3"
                onChange={onChange}
                valid={!errors.requiredImmunizations}
                options={mapOptionsForSelect(requiredImmunizations)}
                label="Required immunizations"
              />
            )}
          />

          <Controller
            control={control}
            name="recommendedImmunizations"
            render={({ field: { onChange, value, name } }) => (
              <MMDiSelectNew
                isMulti
                className="mt-3"
                name={name}
                value={value}
                onChange={onChange}
                options={mapOptionsForSelect(recommendedImmunizations)}
                label="Recommended immunizations"
              />
            )}
          />
        </>
      )}

      <MMDButton
        isSubmit
        isPrimary
        className="mt-3"
        isDisabled={hasFormErrors}
        text={isEdit ? 'Save changes' : 'Create'}
      />
    </form>
  );
};

export default OrganizationForm;
