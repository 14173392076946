import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { Affiliation } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  affiliation: Affiliation;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const AffiliationRow: FC<Props> = ({
  affiliation,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(affiliation.id);
  }, [affiliation, onDeleteClick]);
  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{affiliation.name}</strong>
          <Heading subtitle size={6} renderAs="h6">
            Created by:{' '}
            {affiliation.creator ? (
              <Link to={`/providers/${affiliation.creator.id}`}>
                {affiliation.creator.email}
              </Link>
            ) : (
              'No info.'
            )}
          </Heading>
          <Heading subtitle size={6} renderAs="h6">
            Approved: {affiliation.approved ? 'Yes' : 'No'}
          </Heading>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${affiliation.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default AffiliationRow;
