import { FC } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormUnregister,
} from 'react-hook-form';

import TextAnswer from '../answers/TextAnswer';
import MMDDropdown from 'components/MMDDropdown';
import CustomAnswers from '../answers/CustomAnswers';
import MMDTextInput from 'components/forms/MMDTextInput';
import { withAnswerComponents } from '../answers/helpers';
import {
  QuestionnaireAnswer,
  QuestionnaireFormValue,
  QuestionnaireQuestionAnswerType,
} from 'typings';

type Props = {
  formErrors: FieldErrors<QuestionnaireFormValue>;
  onAddAnswer: (type: QuestionnaireQuestionAnswerType) => void;
  onRemoveAnswer: () => void;
  answer?: QuestionnaireAnswer;
  questionOrder: number;
  register: UseFormRegister<QuestionnaireFormValue>;
  unregister: UseFormUnregister<QuestionnaireFormValue>;
  readOnly: boolean;
};

const QuestionBlock: FC<Props> = ({
  answer,
  register,
  unregister,
  formErrors,
  onAddAnswer,
  questionOrder,
  onRemoveAnswer,
  readOnly,
}) => {
  const getAnswerComponentByType = (type: QuestionnaireQuestionAnswerType) => {
    switch (type) {
      case 'text':
        return TextAnswer;
      case 'radio':
        return CustomAnswers;
    }
  };

  const renderAnswerComponent = () => {
    if (!answer) {
      return (
        <MMDDropdown
          isPrimary
          className="mb-2 mt-2"
          title="Add answer"
          items={[
            {
              text: 'Text answer',
              onClick: () => onAddAnswer('text'),
            },
            {
              text: 'Multiple answers',
              onClick: () => onAddAnswer('radio'),
            },
          ]}
        />
      );
    }

    const AnswerComponent = getAnswerComponentByType(answer.type);

    if (answer.type === 'radio') {
      const initialAnswersCount =
        Object.values(answer).length > 1
          ? Object.values(answer).length - 1
          : undefined;

      return withAnswerComponents(
        <AnswerComponent
          readOnly={readOnly}
          register={register}
          unregister={unregister}
          formErrors={formErrors}
          questionOrder={questionOrder}
          initialAnswersCount={initialAnswersCount}
        />,
        onRemoveAnswer,
        readOnly,
      );
    }

    return withAnswerComponents(
      // @ts-ignore
      <AnswerComponent />,
      onRemoveAnswer,
      readOnly,
    );
  };

  return (
    <div>
      <MMDTextInput
        required
        readOnly={readOnly}
        label="Question Block"
        placeholder="Enter question..."
        {...register(`blocks.${questionOrder}.text`, {
          required: {
            value: true,
            message: 'This field is required',
          },
          setValueAs: (value: string) => value.trim(),
          maxLength: {
            value: 70,
            message: 'Max length is 70 characters',
          },
        })}
        valid={!formErrors['blocks']?.[questionOrder]?.text}
        error={formErrors['blocks']?.[questionOrder]?.text?.message}
      />

      <div className="ml-3">
        <div>
          <span className="mt-1 is-block mb-1">Answer</span>

          {formErrors['blocks']?.[questionOrder]?.answer && (
            <span className="has-text-danger">You should add answer</span>
          )}
        </div>

        {renderAnswerComponent()}
      </div>
    </div>
  );
};

QuestionBlock.displayName = 'QuestionBlock';

export default QuestionBlock;
