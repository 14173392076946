import { Action } from 'redux';
import { createSelector } from 'reselect';
import { call } from 'redux-saga/effects';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import { Booking, BookingInList } from 'typings';
import { noOpAction } from 'utils/noOpAction';
import { extractRouteParams, goBackFactory, onRoutes } from 'utils/onRoute';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

import { putAuthInfoInArgs } from './auth.module';
import { MyState } from '../store';

interface ActionTypes {
  LOADING_BOOKINGS: string;
  COMMIT_BOOKING: string;
  COMMIT_BOOKINGS: string;
  ERROR_BOOKINGS: string;
}

interface ActionCreators {
  loadingBookings: () => Action;
  commitBooking: (payload: Booking) => MyAction<Booking>;
  commitBookings: (
    payload: Paginated<BookingInList>,
  ) => MyAction<Paginated<BookingInList>>;
  errorBookings: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface BookingsState<TError extends Error = Error>
  extends EntityState<BookingInList> {
  loading: boolean;
  count: number;
  error?: TError;
  booking: Booking;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingBookings: [],
  commitBookings: ['payload'],
  commitBooking: ['payload'],
  errorBookings: ['error'],
});

const entityAdapter = createEntityAdapter<BookingInList>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export const bookingsSelector = entityAdapter.getSelectors();

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

function setLoading(state: BookingsState): BookingsState {
  return {
    ...state,
    loading: true,
  };
}

function commitCredentials(
  state: BookingsState,
  action: MyAction<Paginated<BookingInList>>,
): BookingsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitBooking(
  state: BookingsState,
  action: MyAction<Booking>,
): BookingsState {
  return {
    ...state,
    booking: action.payload,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: BookingsState,
  { error }: ErrorAction<TError>,
): BookingsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const bookingsReducer = createReducer(initialState, {
  [Types.LOADING_BOOKINGS]: setLoading,
  [Types.COMMIT_BOOKINGS]: commitCredentials,
  [Types.ERROR_BOOKINGS]: setError,
  [Types.COMMIT_BOOKING]: commitBooking,
});

function getActionForLoadBookings(
  providerUrlSegment: string,
  patientUrlSegment: string,
) {
  return function* (payload: ArgsWithHeaders<LocationChangeActionPayload>) {
    const isProvider = payload.location.pathname.includes('provider');

    const params = yield call(
      extractRouteParams(isProvider ? providerUrlSegment : patientUrlSegment),
      payload,
    );

    const queryPath = isProvider ? 'get-by-doctor-id' : 'get-by-patient-id';

    return yield downloadUsingLocationQuery<BookingInList>(
      `bookings/${queryPath}/${params.id}`,
    )(payload);
  };
}

function getActionForLoadBooking(
  providerUrlSegment: string,
  patientUrlSegment: string,
) {
  return function* (payload: ArgsWithHeaders<LocationChangeActionPayload>) {
    const isProvider = payload.location.pathname.includes('provider');

    const params = yield call(
      extractRouteParams(isProvider ? providerUrlSegment : patientUrlSegment),
      payload,
    );

    return yield downloadUsingLocationQuery<BookingInList>(
      `bookings/${params.bookingId}`,
    )(payload);
  };
}

const allBookingsUrlSegment = '/:id/bookings';
const bookingUrlSegment = '/:id/bookings/:bookingId';

const urlForProvider = (url: string) => `/providers${url}`;

const urlForPatient = (url: string) => `/patients${url}`;

const requestBookingsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<BookingInList>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoutes(
    urlForProvider(allBookingsUrlSegment),
    urlForPatient(allBookingsUrlSegment),
  ),
  loadingAction: Creators.loadingBookings,
  commitAction: Creators.commitBookings,
  successAction: noOpAction,
  errorAction: Creators.errorBookings,
  action: getActionForLoadBookings(
    urlForProvider(allBookingsUrlSegment),
    urlForPatient(allBookingsUrlSegment),
  ),
  beforeAction: putAuthInfoInArgs,
});

const requestBookingWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoutes(
    urlForProvider(bookingUrlSegment),
    urlForPatient(bookingUrlSegment),
  ),
  loadingAction: Creators.loadingBookings,
  commitAction: Creators.commitBooking,
  successAction: noOpAction,
  errorAction: Creators.errorBookings,
  action: getActionForLoadBooking(
    urlForProvider(bookingUrlSegment),
    urlForPatient(bookingUrlSegment),
  ),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/bookings');

export const bookingsSagas = [
  requestBookingsWatcher,
  requestBookingWatcher,
  goBack.watcher,
];

export const selectBookingsState = (state: MyState) => state.bookings;

export const selectBookings = createSelector(selectBookingsState, (state) =>
  bookingsSelector.selectAll(state),
);
