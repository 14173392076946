import {
  FC,
  Dispatch,
  useState,
  HTMLProps,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';

import {
  selectCredentials,
  Creators as CredentialsActions,
} from 'modules/credentials.module';
import { useBoundedActions } from 'hooks/useBoundedActions';

type MMDSelectType = 'generic' | 'form';

type PropTypes = {
  label: string;
  valid?: boolean;
  dirty?: boolean;
  value?: string;
  credentialAux?: any;
  options?: any[];
  type?: MMDSelectType;
  setCredentialsIds: Dispatch<SetStateAction<string>>;
  onExternalChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & HTMLProps<HTMLSelectElement>;

const MMDSelectCred: FC<PropTypes> = ({
  setCredentialsIds,
  type = 'form',
  label,
  onChange,
  onExternalChange = undefined,
  valid = true,
  dirty = true,
  credentialAux = [],
  options = [],
  ...rest
}) => {
  const [selected, setSelected] = useState(
    credentialAux.map((x) => JSON.stringify(x).toString()),
  );
  const [selectedNames, setSelectedNames] = useState([]);
  const credentials = useSelector(selectCredentials);

  const { requestCredentialsSearch } = useBoundedActions(CredentialsActions);

  useEffect(() => {
    requestCredentialsSearch({ credential: '__limit=10000&__sort=-updatedAt' });
  }, []);

  const handleChange = (value) => {
    if (!selected.includes(value)) {
      setSelected([...selected, value]);
    }
  };

  useEffect(() => {
    setCredentialsIds(
      selected.map((select) => select.split(',')[0].split(':')[1]),
    );
    setSelectedNames(
      selected.map((select) =>
        select.split(',')[1].split(':')[1].split('}')[0].slice(1, -1),
      ),
    );
  }, [selected]);

  const handleDelete = (value) => {
    const selectedAux = selected;
    const arr = [];
    selectedAux.map((select) => {
      if (!select.includes(value)) {
        arr.push(select);
      }
    });
    setSelected(arr);
  };

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div style={{ display: 'flex' }} className="control">
        <div style={{ width: '150px', minWidth: '150px' }} className="select">
          <select
            style={{ width: '150px', minWidth: '150px' }}
            onChange={(e) => handleChange(e.target.value)}
            {...rest}
          >
            {type === 'form' && <option>{label}</option>}
            {options[0]
              ? options.map((op) => (
                  <option
                    key={`${op.id}-${op.value || op.name}`}
                    value={JSON.stringify(op)}
                  >
                    {op.label}
                  </option>
                ))
              : credentials.map((op) => (
                  <option
                    key={`${op.id}-${op.value}`}
                    value={JSON.stringify(op)}
                  >
                    {op.label}
                  </option>
                ))}
          </select>
        </div>
        <div style={{ display: 'flex' }}>
          {selectedNames.map((select, index) => (
            <span
              key={index}
              style={{
                marginLeft: '12px',
                borderColor: '#fc105b',
                borderRadius: '20px',
                boxShadow: '0px 0px 3px 0px rgb(252 16 91)',
              }}
              className={`button 'is-primary'`}
              onClick={() => handleDelete(select)}
            >
              {select}{' '}
              <span
                style={{
                  color: '#fc105b',
                  borderColor: 'solid #fc105b',
                  borderRadius: '20px',
                  marginLeft: '10px',
                  fontSize: '14px',
                }}
              >
                x
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MMDSelectCred;
