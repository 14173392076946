import { FC } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Heading, Media } from 'react-bulma-components';

import MMDButton from 'components/MMDButton';
import { BookingPublicInList } from 'typings';

type Props = {
  booking: BookingPublicInList;
};

const BookingPublicRow: FC<Props> = ({ booking }) => {
  const isBookingCompleted = moment(booking.endDate).isSameOrBefore(moment());

  const isProvider = location.pathname.includes('providers');

  const userData = isProvider ? booking.patient : booking.doctor;

  return (
    <Box>
      <Media>
        <Media.Item>
          {userData && (
            <div className="mb-2">
              <strong>
                {userData.firstName} {userData.lastName}
              </strong>
              {'\u00A0'}
              <small>{userData.email}</small>
            </div>
          )}

          <Heading subtitle size={6} renderAs="h6">
            Date of consultation: {moment(booking.startDate).format('MM/DD/yy')}
          </Heading>

          <Heading subtitle size={6} renderAs="h6">
            Time of consultation: {moment(booking.startDate).format('hh:mm A')}
          </Heading>

          {isBookingCompleted && !booking.canceled && (
            <strong
              className={
                booking.doctorAttended ? 'has-text-success' : 'has-text-danger'
              }
            >
              Provider
              {booking.doctorAttended ? ' was ' : " wasn't "}
              attended
            </strong>
          )}
        </Media.Item>

        {booking.canceled && (
          <strong className="has-text-danger">Booking was canceled</strong>
        )}
      </Media>

      <Link to={booking.id.toString()}>
        <MMDButton className="mt-2" isPrimary text="Details" />
      </Link>
    </Box>
  );
};

export default BookingPublicRow;
