import {
  createSingleEventSaga,
  Dictionary,
  ErrorAction,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { toast } from 'react-toastify';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { API_URL, AUTH_API_URL } from '../config';
import { MyState } from '../store';
import { auth } from '../utils/Firebase';
import {
  NOT_FOUND,
  UNAUTHORIZED,
  INVALID_CREDENTIALS,
  USER_NOT_FOUND_ERROR,
  INVISIBLE_ERROR_MESSAGE,
  INVALID_CREDENTIALS_ERROR,
} from 'utils/onRoute';
import { ArgsWithHeaders } from 'utils/typings';
import {
  signInWithCustomToken,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { Nullable, SagaIterator } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { User } from './users.module';
import { Types as MfaTypes, Creators as MfaActions } from './mfa.module';
import { routesWithoutAuth } from 'pages/routes/routes';

interface AuthPayload {
  email: string;
  password: string;
}

interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  tokenType: string;
}

interface ResetPasswordPayload {
  newPassword: string;
  email: string;
}

interface CheckUserInfoPayload {
  email: string;
}

export interface AuthResult {
  access_token: string;
  refresh_token: string;
  expiration: string;
  token_type: string;
  firebase_token: string;
}

interface ActionTypes {
  REQUEST_AUTH: string;
  REQUEST_CHANGE_PASSWORD: string;
  REQUEST_AUTH_OUT: string;
  REQUEST_REFRESH_AUTH: string;
  LOADING_AUTH: string;
  LOADING_AUTH_OUT: string;
  COMMIT_AUTH: string;
  COMMIT_AUTH_OUT: string;
  SUCCESS_AUTH: string;
  ERROR_AUTH: string;
  REQUEST_USER_INFO: string;
  COMMIT_USER_INFO: string;
  CHECK_USER_INFO: string;
  REQUEST_RESET_PASSWORD: string;
  SUCCESS_PASSWORD_RESET: string;
  CLEAR_AUTH_ERROR: string;
}

interface ActionCreators {
  requestAuth: (payload: AuthPayload) => MyAction<AuthPayload>;
  requestChangePassword: (
    payload: ChangePasswordPayload,
  ) => MyAction<ChangePasswordPayload>;
  requestAuthOut: () => MyAction<void>;
  requestRefreshAuth: () => Action;
  loadingAuth: () => Action;
  loadingAuthOut: () => Action;
  commitAuth: (payload: AuthResult | AuthPayload) => MyAction<AuthResult>;
  commitAuthOut: () => MyAction<void>;
  successAuth: (payload?: AuthResult) => MyAction<AuthResult>;
  errorAuth: <TError extends Error>(error: TError) => ErrorAction<TError>;
  requestUserInfo: () => Action;
  commitUserInfo: (payload: User) => MyAction<User>;
  requestResetPassword: (payload: ResetPasswordPayload) => Action;
  successPasswordReset: () => Action;
  checkUserInfo: (payload: CheckUserInfoPayload) => Action;
  clearAuthError: () => Action;
}

export interface AuthState<TError extends Error = Error>
  extends Partial<AuthResult> {
  authenticated: boolean;
  loading: boolean;
  userInfo: Nullable<User>;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAuth: ['payload'],
  requestChangePassword: ['payload'],
  requestAuthOut: [],
  requestRefreshAuth: [],
  loadingAuth: [],
  loadingAuthOut: [],
  commitAuth: ['payload'],
  commitAuthOut: [],
  successAuth: ['payload'],
  errorAuth: ['error'],
  requestUserInfo: [],
  commitUserInfo: ['payload'],
  requestResetPassword: ['payload'],
  successPasswordReset: [],
  checkUserInfo: ['payload'],
  clearAuthError: [],
});

function setLoading(state: AuthState): AuthState {
  return {
    ...state,
    loading: true,
  };
}

function setLoadingOut(state: AuthState): AuthState {
  return {
    ...state,
    loading: true,
  };
}

function commitAuthResult(
  state: AuthState,
  action: MyAction<AuthResult>,
): AuthState {
  return {
    ...state,
    loading: false,
    authenticated: true,
    ...action.payload,
  };
}

function commitUserInfo(state: AuthState, action: MyAction<User>): AuthState {
  return {
    ...state,
    userInfo: action.payload,
  };
}

function commitAuthResultOut(state: AuthState): AuthState {
  return {
    loading: false,
    authenticated: false,
    userInfo: null,
  };
}

function setError<TError extends Error = Error>(
  state: AuthState,
  { error }: ErrorAction<TError>,
): AuthState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function clearAuthError(state: AuthState): AuthState {
  return {
    ...state,
    error: undefined,
  };
}

const initialState: AuthState = {
  authenticated: false,
  loading: false,
  userInfo: null,
};

export const authReducer = createReducer(initialState, {
  [Types.LOADING_AUTH]: setLoading,
  [Types.LOADING_AUTH_OUT]: setLoadingOut,
  [Types.COMMIT_AUTH]: commitAuthResult,
  [Types.COMMIT_AUTH_OUT]: commitAuthResultOut,
  [Types.ERROR_AUTH]: setError,
  [Types.COMMIT_USER_INFO]: commitUserInfo,
  [Types.CLEAR_AUTH_ERROR]: clearAuthError,
});

function* authenticate(args: AuthPayload): SagaIterator {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const response = yield call(fetch, AUTH_API_URL, {
    headers,
    method: 'POST',
    body: `grant_type=password&username=${args.email}&password=${args.password}`,
  });

  if (!response.ok) {
    const body = yield call([response, 'json']);

    if (body.error === INVALID_CREDENTIALS_ERROR) {
      throw Error(INVALID_CREDENTIALS);
    }

    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  const authInfo = yield call([response, 'json']);

  yield put(
    MfaActions.requestMfaVerify({
      email: args.email,
      phone: authInfo.phone,
      isVerifiedByGoogle: authInfo.verifiedByGoogle,
    }),
  );

  if (yield take(MfaTypes.SUCCESS_MFA)) {
    return authInfo;
  }
}

async function changePassword({
  headers,
  ...payload
}: ArgsWithHeaders<ChangePasswordPayload>): Promise<ChangePasswordPayload> {
  const response = await fetch(`${API_URL}/users/admin/change-password`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });
  const result = await response.json();
  if (result.message) {
    throw Error(result.message);
  }
  return result;
}

async function requestUserInfo({
  headers,
}: ArgsWithHeaders<ChangePasswordPayload>) {
  const response = await fetch(`${API_URL}/users/own-details`, {
    headers,
  });

  const result = await response.json();
  if (result.message) {
    throw Error(result.message);
  }

  return result;
}

async function resetPassword({
  headers,
  ...payload
}: ArgsWithHeaders<ResetPasswordPayload>) {
  const response = await fetch(`${API_URL}/users/reset-password`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }
}

async function checkUserInfo({
  headers,
  ...payload
}: ArgsWithHeaders<CheckUserInfoPayload>) {
  const response = await fetch(
    `${API_URL}/users/check-user-info?email=${payload.email}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    if (response.status === NOT_FOUND) {
      throw Error(USER_NOT_FOUND_ERROR);
    }
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }

  const userData = await response.json();

  return {
    email: payload.email,
    phone: userData.phone,
    isVerifiedByGoogle: userData.verifiedByGoogle,
  };
}

function* navigateAfterResetPassword() {
  yield takeLatest(Types.SUCCESS_PASSWORD_RESET, function* () {
    yield call(toast.success, 'Action executed successfully');
    yield put(push('/'));
  });
}

const authenticateWatcher = createSingleEventSaga<
  AuthPayload,
  AuthResult,
  MyAction<AuthPayload>
>({
  takeEvery: Types.REQUEST_AUTH,
  loadingAction: Creators.loadingAuth,
  commitAction: Creators.commitAuth,
  successAction: Creators.successAuth,
  errorAction: Creators.errorAuth,
  action: authenticate,
  afterAction: persistLocalStorage,
});

const changePasswordWatcher = createSingleEventSaga<
  AuthPayload,
  AuthResult,
  MyAction<AuthPayload>
>({
  takeEvery: Types.REQUEST_CHANGE_PASSWORD,
  loadingAction: Creators.loadingAuth,
  commitAction: Creators.commitAuth,
  successAction: Creators.successAuth,
  errorAction: Creators.errorAuth,
  action: changePassword,
  afterAction: persistLocalStorage,
  beforeAction: putAuthInfoInArgs,
});

const resetPasswordWatcher = createSingleEventSaga<
  ResetPasswordPayload,
  void,
  MyAction<ResetPasswordPayload>
>({
  takeEvery: Types.REQUEST_RESET_PASSWORD,
  loadingAction: Creators.loadingAuth,
  commitAction: noOpAction,
  successAction: Creators.successPasswordReset,
  errorAction: noOpAction,
  action: resetPassword,
  beforeAction: putDefaultHeadersInArgs,
});

const checkUserInfoWatcher = createSingleEventSaga<
  CheckUserInfoPayload,
  void,
  MyAction<CheckUserInfoPayload>
>({
  takeEvery: Types.CHECK_USER_INFO,
  loadingAction: Creators.loadingAuth,
  commitAction: MfaActions.requestMfaVerify,
  successAction: noOpAction,
  errorAction: Creators.errorAuth,
  action: checkUserInfo,
  beforeAction: putDefaultHeadersInArgs,
});

const requestUserInfoWatcher = createSingleEventSaga<
  void,
  User,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_USER_INFO,
  loadingAction: Creators.loadingAuth,
  commitAction: Creators.commitUserInfo,
  successAction: noOpAction,
  errorAction: Creators.errorAuth,
  action: requestUserInfo,
  beforeAction: putAuthInfoInArgs,
});

const authenticateOutWatcher = createSingleEventSaga<
  void,
  void,
  MyAction<void>
>({
  takeEvery: Types.REQUEST_AUTH_OUT,
  loadingAction: Creators.loadingAuthOut,
  commitAction: Creators.commitAuthOut,
  successAction: Creators.commitAuthOut,
  errorAction: Creators.errorAuth,
  action: signOut,
});

async function signOut() {
  await firebaseSignOut(auth);
  localStorage.removeItem('moment.session');
}

async function refreshAuth(args: AuthResult): Promise<AuthResult> {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const response = await fetch(AUTH_API_URL, {
    headers,
    method: 'POST',
    body: `grant_type=refresh_token&refresh_token=${args.refresh_token}`,
  });

  if (response.status === UNAUTHORIZED) {
    throw Error(INVISIBLE_ERROR_MESSAGE);
  }

  if (!response.ok) {
    if (response.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return response.json();
}

function isExpired(authState: AuthState | AuthResult): boolean {
  const expirationDate = new Date(authState.expiration);
  const now = new Date();

  return expirationDate < now;
}

export function* putAuthInfoInArgs(args): SagaIterator {
  let authState: AuthState = yield select((state: MyState) => state.auth);

  const isExpiredAuth = isExpired(authState);

  if (isExpiredAuth || !authState?.authenticated) {
    if (isExpiredAuth) {
      yield put(Creators.requestRefreshAuth());
    }

    if (yield take(Types.COMMIT_AUTH)) {
      authState = yield select((state: MyState) => state.auth);
    }
  }

  const headers = new Headers();
  headers.append(
    'Authorization',
    `${authState.token_type} ${authState.access_token}`,
  );
  headers.append('Content-Type', 'application/json');

  return { ...args, headers };
}

export function* putDefaultHeadersInArgs(args): SagaIterator {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  return { ...args, headers };
}

const refreshAuthWatcher = createSingleEventSaga<object, AuthResult, Action>({
  takeEvery: Types.REQUEST_REFRESH_AUTH,
  loadingAction: Creators.loadingAuth,
  commitAction: Creators.commitAuth,
  successAction: Creators.successAuth,
  errorAction: Creators.errorAuth,
  action: refreshAuth,
  *beforeAction(): SagaIterator {
    return yield select((state: MyState) => state.auth);
  },
  afterAction: persistLocalStorage,
});

function* restoreSession() {
  const authInfo: AuthResult = JSON.parse(
    localStorage.getItem('moment.session'),
  );

  const {
    location: { pathname, search },
  } = yield select((state: MyState) => state.router);

  if (routesWithoutAuth.includes(pathname)) {
    return;
  }

  if (!authInfo || isExpired(authInfo)) {
    yield put(push('/'));
    return;
  }
  const tokenIsValid = yield call(isCustomTokenValid, authInfo.firebase_token);

  if (!tokenIsValid) {
    yield put(push('/'));
    return;
  }

  yield put(Creators.commitAuth(authInfo));
  yield put(Creators.requestUserInfo());

  if (pathname === '/') {
    yield put(push('/providers'));
  } else {
    yield put(push(`${pathname}${search}`));
  }
}

async function isCustomTokenValid(token: string): Promise<boolean> {
  try {
    await signInWithCustomToken(auth, token);
    return true;
  } catch (err) {
    return false;
  }
}

function* navigateAfterAuth() {
  while (yield take(Types.SUCCESS_AUTH)) {
    yield put(Creators.requestUserInfo());
    yield put(push('/providers'));
  }
}

function* persistLocalStorage(args: AuthResult): SagaIterator {
  yield call([localStorage, 'setItem'], 'moment.session', JSON.stringify(args));
  yield call(signInWithCustomToken, auth, args.firebase_token);
  return args;
}

export const authSagas = [
  checkUserInfoWatcher,
  navigateAfterResetPassword,
  resetPasswordWatcher,
  authenticateWatcher,
  refreshAuthWatcher,
  restoreSession,
  navigateAfterAuth,
  authenticateOutWatcher,
  changePasswordWatcher,
  requestUserInfoWatcher,
];

export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function authFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    email: true,
    password: true,
  };

  if (!values['email'].trim().match(EMAIL_REGEX)) {
    result.email = false;
  }

  if (values['password'].trim().length < 3) {
    result.password = false;
  }

  return Promise.resolve(result);
}

export function changePasswordFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  };
  if (values['newPassword'].length < 3) {
    result.newPassword = false;
  }

  if (values['confirmPassword'] !== values['newPassword']) {
    result.confirmPassword = false;
  }
  return Promise.resolve(result);
}

export const selectAuthState = (state: MyState) => state.auth;

export const selectUserInfo = createSelector(
  selectAuthState,
  (state) => state.userInfo,
);
