import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { SagaIterator, TermsAndConditions } from '../typings';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
} from '../utils/typings';
import {
  onRoute,
  extractRouteParams,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
  goBackFactory,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { MyState } from '../store';
import { createSelector } from 'reselect';
import moment from 'moment/moment';

interface TermsAndConditionsPayload {
  id: string;
}

interface UserTypePayload {
  userType: string;
}

interface ActionTypes {
  REQUEST_CREATE_TERMS_AND_CONDITIONS: string;
  REQUEST_UPDATE_TERMS_AND_CONDITIONS: string;
  LOADING_TERMS_AND_CONDITIONS: string;
  COMMIT_TERMS_AND_CONDITIONS: string;
  COMMIT_TERM_AND_CONDITION: string;
  COMMIT_TERMS_AND_CONDITIONS_UPDATE: string;
  ERROR_TERMS_AND_CONDITIONS: string;
}

interface ActionCreators {
  requestCreateTermsAndConditions: (
    payload: TermsAndConditions,
  ) => MyAction<TermsAndConditions>;
  requestUpdateTermsAndConditions: (
    payload: Partial<TermsAndConditions>,
  ) => MyAction<Partial<TermsAndConditions>>;
  loadingTermsAndConditions: () => Action;
  commitTermsAndConditions: (
    payload: TermsAndConditions[],
  ) => MyAction<TermsAndConditions[]>;
  commitTermAndCondition: (
    payload: TermsAndConditions,
  ) => MyAction<TermsAndConditions>;
  commitTermsAndConditionsUpdate: (
    payload: Partial<TermsAndConditions>,
  ) => MyAction<Partial<TermsAndConditions>>;
  errorTermsAndConditions: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface TermsAndConditionsState<TError extends Error = Error>
  extends EntityState<TermsAndConditions> {
  loading: boolean;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateTermsAndConditions: ['payload'],
  requestUpdateTermsAndConditions: ['payload'],
  loadingTermsAndConditions: [],
  commitTermsAndConditions: ['payload'],
  commitTermAndCondition: ['payload'],
  commitTermsAndConditionsUpdate: ['payload'],
  errorTermsAndConditions: ['error'],
});

const entityAdapter = createEntityAdapter<TermsAndConditions>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
});
export const termAndConditionSelectors = entityAdapter.getSelectors();

function setLoading(state: TermsAndConditionsState): TermsAndConditionsState {
  return {
    ...state,
    loading: true,
  };
}

function commitTermAndCondition(
  state: TermsAndConditionsState,
  action: MyAction<TermsAndConditions>,
): TermsAndConditionsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitTermsAndConditions(
  state: TermsAndConditionsState,
  action: MyAction<TermsAndConditions[]>,
): TermsAndConditionsState {
  return {
    ...entityAdapter.addAll(action.payload, state),
    loading: false,
  };
}

function commitTermsAndConditionsUpdate(
  state: TermsAndConditionsState,
  { payload: { id, ...diff } }: MyAction<Partial<TermsAndConditions>>,
): TermsAndConditionsState {
  return {
    ...entityAdapter.updateOne({ id: id.toString(), changes: diff }, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: TermsAndConditionsState,
  { error }: ErrorAction<TError>,
): TermsAndConditionsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const termAndConditionReducer = createReducer(initialState, {
  [Types.LOADING_TERMS_AND_CONDITIONS]: setLoading,
  [Types.COMMIT_TERM_AND_CONDITION]: commitTermAndCondition,
  [Types.COMMIT_TERMS_AND_CONDITIONS]: commitTermsAndConditions,
  [Types.COMMIT_TERMS_AND_CONDITIONS_UPDATE]: commitTermsAndConditionsUpdate,
  [Types.ERROR_TERMS_AND_CONDITIONS]: setError,
});

async function downloadTermsAndConditions({
  headers,
  ...payload
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<
  TermsAndConditions[]
> {
  const location = payload.location.search
    ? `${payload.location.search}&__sort=-updatedAt`
    : `${payload.location.search}?__sort=-updatedAt`;
  const result = await fetch(`${API_URL}/terms-and-conditions${location}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadTermAndCondition({
  headers,
  ...payload
}: ArgsWithHeaders<UserTypePayload>): Promise<TermsAndConditions> {
  const result = await fetch(
    `${API_URL}/terms-and-conditions?userType=${payload.userType}&sendLive=true&__sort=-updatedAt&__limit=1`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function downloadSingleTermsAndConditionsPolicy({
  headers,
  ...payload
}: ArgsWithHeaders<TermsAndConditionsPayload>): Promise<TermsAndConditions> {
  const result = await fetch(`${API_URL}/terms-and-conditions/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createTermsAndConditions({
  headers,
  ...payload
}: ArgsWithHeaders<TermsAndConditions>): Promise<
  CreationResult<TermsAndConditions>
> {
  const result = await fetch(`${API_URL}/terms-and-conditions`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateTermsAndConditions({
  headers,
  id,
  ...diff
}: ArgsWithHeaders<Partial<TermsAndConditions>>): Promise<void> {
  const result = await fetch(`${API_URL}/terms-and-conditions/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(diff),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestTermAndConditionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  TermsAndConditions,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/create-term-and-condition/:userType'),
  loadingAction: Creators.loadingTermsAndConditions,
  commitAction: Creators.commitTermAndCondition,
  successAction: noOpAction,
  errorAction: Creators.errorTermsAndConditions,
  action: downloadTermAndCondition,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    UserTypePayload,
    ArgsWithHeaders<UserTypePayload>
  >(
    extractRouteParams('/create-term-and-condition/:userType'),
    putAuthInfoInArgs,
  ),
  *afterAction(res: TermsAndConditions[]): SagaIterator {
    if (res && res.length) {
      return res.pop();
    }
  },
});

const requestTermsAndConditionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  TermsAndConditions[],
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/terms-and-conditions'),
  loadingAction: Creators.loadingTermsAndConditions,
  commitAction: Creators.commitTermsAndConditions,
  successAction: noOpAction,
  errorAction: Creators.errorTermsAndConditions,
  action: downloadTermsAndConditions,
  beforeAction: putAuthInfoInArgs,
});

const requestSingleTermsAndConditionsPolicyWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  TermsAndConditions,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/terms-and-conditions/:id'),
  loadingAction: Creators.loadingTermsAndConditions,
  commitAction: Creators.commitTermAndCondition,
  successAction: noOpAction,
  errorAction: Creators.errorTermsAndConditions,
  action: downloadSingleTermsAndConditionsPolicy,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    TermsAndConditionsPayload,
    ArgsWithHeaders<TermsAndConditionsPayload>
  >(extractRouteParams('/terms-and-conditions/:id'), putAuthInfoInArgs),
});

const goBack = goBackFactory('/terms-and-conditions');

const requestCreateTermsAndConditionsWatcher = createSingleEventSaga<
  TermsAndConditions,
  TermsAndConditions,
  MyAction<TermsAndConditions>
>({
  takeEvery: Types.REQUEST_CREATE_TERMS_AND_CONDITIONS,
  loadingAction: Creators.loadingTermsAndConditions,
  commitAction: Creators.commitTermAndCondition,
  successAction: goBack.action,
  errorAction: Creators.errorTermsAndConditions,
  action: createTermsAndConditions,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<TermsAndConditions>,
    { headers, ...args }: ArgsWithHeaders<TermsAndConditions>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateWatcher = createSingleEventSaga<
  Partial<TermsAndConditions>,
  Partial<TermsAndConditions>,
  MyAction<Partial<TermsAndConditions>>
>({
  takeEvery: Types.REQUEST_UPDATE_TERMS_AND_CONDITIONS,
  loadingAction: Creators.loadingTermsAndConditions,
  commitAction: Creators.commitTermsAndConditionsUpdate,
  successAction: goBack.action,
  errorAction: Creators.errorTermsAndConditions,
  action: updateTermsAndConditions,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Partial<TermsAndConditions>>,
  ): SagaIterator {
    return args;
  },
});

export const termAndConditionSagas = [
  requestTermAndConditionWatcher,
  requestTermsAndConditionsWatcher,
  requestSingleTermsAndConditionsPolicyWatcher,
  requestUpdateWatcher,
  requestCreateTermsAndConditionsWatcher,
  goBack.watcher,
];

export function termAndConditionFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  result.content = values['content'].length > 0;

  return Promise.resolve(result);
}

export const selectTermsOfConditionsState = (state: MyState) =>
  state.termsAndConditions;
