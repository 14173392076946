import { Action } from 'redux';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { Nullable } from 'typings';
import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import {
  Paginated,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
  goBackFactory,
  SERVER_ERROR,
} from 'utils/onRoute';
import { toast } from 'react-toastify';

export type UserInviteStatus = 'accepted' | 'pending' | 'rejected' | 'canceled';

export interface UserInvite {
  id: number;
  email: string;
  status: UserInviteStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: Nullable<string>;
}

interface ActionTypes {
  LOADING_USERS_INVITES: string;
  COMMIT_USERS_INVITES: string;
  ERROR_USERS_INVITES: string;
  REQUEST_USER_INVITE: string;
  CANCEL_USER_INVITE: string;
  UPDATE_USER_INVITE: string;
}

type EmailPayload = { email: string; id: string };

interface ActionCreators {
  loadingUsersInvites: () => Action;
  commitUsersInvites: (
    payload: Paginated<UserInvite>,
  ) => MyAction<Paginated<UserInvite>>;
  requestUserInvite: (payload: string) => Action;
  cancelUserInvite: (payload: EmailPayload) => MyAction<EmailPayload>;
  updateUserInvite: (payload: EmailPayload) => Action;
  errorUsersInvites: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface UsersInvitesState<TError extends Error = Error>
  extends EntityState<UserInvite> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingUsersInvites: [],
  commitUsersInvites: ['payload'],
  requestUserInvite: ['payload'],
  cancelUserInvite: ['payload'],
  updateUserInvite: ['payload'],
  errorUsersInvites: ['error'],
});

const goBack = goBackFactory('/users');

const entityAdapter = createEntityAdapter<UserInvite>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const usersInvitesSelector = entityAdapter.getSelectors();

function setLoading(state: UsersInvitesState): UsersInvitesState {
  return {
    ...state,
    loading: true,
  };
}

function commitUsersInvites(
  state: UsersInvitesState,
  action: MyAction<Paginated<UserInvite>>,
): UsersInvitesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function updateUserInvite(
  state: UsersInvitesState,
  action: MyAction<string>,
): UsersInvitesState {
  return {
    ...entityAdapter.updateOne(
      { id: action.payload.toString(), changes: { status: 'canceled' } },
      state,
    ),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: UsersInvitesState,
  { error }: ErrorAction<TError>,
): UsersInvitesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const usersInvitesReducer = createReducer(initialState, {
  [Types.LOADING_USERS_INVITES]: setLoading,
  [Types.COMMIT_USERS_INVITES]: commitUsersInvites,
  [Types.UPDATE_USER_INVITE]: updateUserInvite,
  [Types.ERROR_USERS_INVITES]: setError,
});

async function inviteUser({
  headers,
  ...payload
}: ArgsWithHeaders<Pick<EmailPayload, 'email'>>) {
  const result = await fetch(`${API_URL}/users/send-invite`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    if (result.status === SERVER_ERROR) {
      throw Error('This user already has the invite');
    }

    throw Error('There has been an error processing your request');
  }

  return result;
}

const requestUserInviteWatcher = createSingleEventSaga<
  string,
  string,
  MyAction<string>
>({
  takeEvery: Types.REQUEST_USER_INVITE,
  loadingAction: noOpAction,
  commitAction: noOpAction,
  successAction: goBack.action,
  errorAction: <TError extends Error>(error: TError) => {
    toast.error(error.message);

    return Creators.errorUsersInvites(error);
  },
  action: inviteUser,
  beforeAction: putAuthInfoInArgs,
});

async function cancelInvite({
  headers,
  ...payload
}: ArgsWithHeaders<EmailPayload>) {
  const result = await fetch(`${API_URL}/users/cancel-invite`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }
    throw Error('There has been an error processing your request');
  }

  return payload.id;
}

const requestCancelInviteWatcher = createSingleEventSaga<
  EmailPayload,
  EmailPayload,
  MyAction<EmailPayload>
>({
  takeEvery: Types.CANCEL_USER_INVITE,
  loadingAction: Creators.loadingUsersInvites,
  commitAction: Creators.updateUserInvite,
  successAction: noOpAction,
  errorAction: Creators.errorUsersInvites,
  action: cancelInvite,
  beforeAction: putAuthInfoInArgs,
});

const requestUsersInvitesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<UserInvite>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/invites'),
  loadingAction: Creators.loadingUsersInvites,
  commitAction: Creators.commitUsersInvites,
  successAction: noOpAction,
  errorAction: Creators.errorUsersInvites,
  action: downloadUsingLocationQuery<UserInvite>('users/invites'),
  beforeAction: putAuthInfoInArgs,
});

export const usersInvitesSagas = [
  requestUsersInvitesWatcher,
  requestCancelInviteWatcher,
  requestUserInviteWatcher,
  goBack.watcher,
];

export const selectUsersInvitesState = (state: MyState) => state.usersInvites;

export const selectUsersInvites = createSelector(
  selectUsersInvitesState,
  (state) => usersInvitesSelector.selectAll(state),
);
