import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { Condition, SagaIterator } from '../typings';
import {
  onRoute,
  extractRouteParams,
  goBackFactory,
  INVISIBLE_ERROR_MESSAGE,
  UNAUTHORIZED,
} from '../utils/onRoute';
import {
  ArgsWithHeaders,
  CreationResult,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import { MyState } from '../store';
import { createSelector } from 'reselect';

interface ConditionPayload {
  id: number;
}

interface ActionTypes {
  REQUEST_CREATE_CONDITION: string;
  REQUEST_UPDATE_CONDITION: string;
  REQUEST_REMOVE_CONDITION: string;
  LOADING_CONDITIONS: string;
  COMMIT_CONDITIONS: string;
  COMMIT_CONDITION: string;
  REMOVE_CONDITION: string;
  ERROR_CONDITIONS: string;
}

interface ActionCreators {
  requestCreateCondition: (payload: Condition) => MyAction<Condition>;
  requestUpdateCondition: (
    payload: Partial<Condition>,
  ) => MyAction<Partial<Condition>>;
  requestRemoveCondition: (
    payload: ConditionPayload,
  ) => MyAction<ConditionPayload>;
  loadingConditions: () => Action;
  commitConditions: (
    payload: Paginated<Condition>,
  ) => MyAction<Paginated<Condition>>;
  commitCondition: (payload: Condition) => MyAction<Condition>;
  removeCondition: (payload: ConditionPayload) => MyAction<ConditionPayload>;
  errorConditions: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface ConditionsState<TError extends Error = Error>
  extends EntityState<Condition> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateCondition: ['payload'],
  requestUpdateCondition: ['payload'],
  requestRemoveCondition: ['payload'],
  loadingConditions: [],
  commitConditions: ['payload'],
  commitCondition: ['payload'],
  removeCondition: ['payload'],
  errorConditions: ['error'],
});

const entityAdapter = createEntityAdapter<Condition>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const conditionSelectors = entityAdapter.getSelectors();

function setLoading(state: ConditionsState): ConditionsState {
  return {
    ...state,
    loading: true,
  };
}

function commitCondition(
  state: ConditionsState,
  action: MyAction<Condition>,
): ConditionsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitConditions(
  state: ConditionsState,
  action: MyAction<Paginated<Condition>>,
): ConditionsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function removeCondition(
  state: ConditionsState,
  action: MyAction<ConditionPayload>,
): ConditionsState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: ConditionsState,
  { error }: ErrorAction<TError>,
): ConditionsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const conditionReducer = createReducer(initialState, {
  [Types.LOADING_CONDITIONS]: setLoading,
  [Types.COMMIT_CONDITION]: commitCondition,
  [Types.COMMIT_CONDITIONS]: commitConditions,
  [Types.REMOVE_CONDITION]: removeCondition,
  [Types.ERROR_CONDITIONS]: setError,
});

async function downloadCondition({
  headers,
  ...payload
}: ArgsWithHeaders<ConditionPayload>): Promise<Condition> {
  const result = await fetch(`${API_URL}/conditions/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function createCondition({
  headers,
  ...payload
}: ArgsWithHeaders<Condition>): Promise<CreationResult<Condition>> {
  const result = await fetch(`${API_URL}/conditions`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function updateCondition({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Condition>>): Promise<void> {
  const result = await fetch(`${API_URL}/conditions/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteCondition({
  headers,
  ...payload
}: ArgsWithHeaders<Condition>): Promise<void> {
  const result = await fetch(`${API_URL}/conditions/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestConditionWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Condition,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/conditions/:id'),
  loadingAction: Creators.loadingConditions,
  commitAction: Creators.commitCondition,
  successAction: noOpAction,
  errorAction: Creators.errorConditions,
  action: downloadCondition,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    ConditionPayload,
    ArgsWithHeaders<ConditionPayload>
  >(extractRouteParams('/conditions/:id'), putAuthInfoInArgs),
});

const requestConditionsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<Condition>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/conditions'),
  loadingAction: Creators.loadingConditions,
  commitAction: Creators.commitConditions,
  successAction: noOpAction,
  errorAction: Creators.errorConditions,
  action: downloadUsingLocationQuery<Condition>('conditions'),
  beforeAction: putAuthInfoInArgs,
});

const goBack = goBackFactory('/conditions');

const requestCreateConditionWatcher = createSingleEventSaga<
  Condition,
  Condition,
  MyAction<Condition>
>({
  takeEvery: Types.REQUEST_CREATE_CONDITION,
  loadingAction: Creators.loadingConditions,
  commitAction: Creators.commitCondition,
  successAction: goBack.action,
  errorAction: Creators.errorConditions,
  action: createCondition,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<Condition>,
    { headers, ...args }: ArgsWithHeaders<Condition>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateConditionWatcher = createSingleEventSaga<
  Partial<Condition>,
  Partial<Condition>,
  MyAction<Partial<Condition>>
>({
  takeEvery: Types.REQUEST_UPDATE_CONDITION,
  loadingAction: Creators.loadingConditions,
  commitAction: Creators.commitCondition,
  successAction: goBack.action,
  errorAction: Creators.errorConditions,
  action: updateCondition,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Condition>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteConditionWatcher = createSingleEventSaga<
  ConditionPayload,
  void,
  MyAction<ConditionPayload>
>({
  takeEvery: Types.REQUEST_REMOVE_CONDITION,
  loadingAction: Creators.loadingConditions,
  commitAction: Creators.removeCondition,
  successAction: noOpAction,
  errorAction: Creators.errorConditions,
  action: deleteCondition,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<ConditionPayload>,
  ): SagaIterator {
    return args;
  },
});

export const conditionSagas = [
  requestConditionWatcher,
  requestConditionsWatcher,
  requestCreateConditionWatcher,
  requestUpdateConditionWatcher,
  requestDeleteConditionWatcher,
  goBack.watcher,
];

export function conditionFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
  };

  result.name = values['name'].length > 0;

  return Promise.resolve(result);
}

const selectConditionsState = (state: MyState) => state.conditions;

export const selectConditionsById = createSelector(
  [selectConditionsState, (_, id) => id],
  (state, id) => state.entities[id],
);
