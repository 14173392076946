import moment from 'moment';
import Switch from 'react-switch';
import { useSelector } from 'react-redux';
import momentTimezone from 'moment-timezone';
import { FC, useEffect, useState } from 'react';

import {
  selectTimeslotsState,
  Creators as TimeslotActions,
} from 'modules/timeslots.module';
import { Timeslot, Provider } from 'typings';
import MMDButton from 'components/MMDButton';
import EndTimePicker from './timeslot-form/EndTimePicker';
import { useBoundedActions } from 'hooks/useBoundedActions';
import StartTimePicker from './timeslot-form/StartTimePicker';

type Props = {
  selectedTimeslot: Timeslot;
  provider: Provider;
  actionType: any;
  selectedDate: Date;
  onHideModal: () => void;
};

const initialDays = [
  { day: 'SU', selected: false },
  { day: 'MO', selected: false },
  { day: 'TU', selected: false },
  { day: 'WE', selected: false },
  { day: 'TH', selected: false },
  { day: 'FR', selected: false },
  { day: 'SA', selected: false },
];

const PSYCHOLOGY = 'psychology';

const TimeslotDetail: FC<Props> = ({
  selectedTimeslot,
  provider,
  actionType,
  selectedDate,
  onHideModal,
}) => {
  const { timeslot } = useSelector(selectTimeslotsState);

  const daySelectedInitial = moment(selectedDate).format('dd').toUpperCase();
  const [isRepeat, setIsRepeat] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [isFirstTime, setFirstTime] = useState(false);
  const [days, setDays] = useState(initialDays);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());
  const [isWeekly, setIsWeekly] = useState(false);
  const [allDisabled, setAllDisabled] = useState(false);
  const [monthlyDisabled, setIsMonthlyDisabled] = useState(false);

  const {
    requestTimeslot,
    requestCreateTimeslot,
    requestUpdateTimeslot,
    requestDeleteTimeslot,
  } = useBoundedActions(TimeslotActions);

  const bookingDuration = isFirstTime
    ? provider.profile.firstTimeLength
    : provider.profile.timeSlotLength;

  const setupTimeslot = () => {
    if (!timeslot) {
      return;
    }

    if (actionType === 'all') {
      setStartTime(moment(timeslot.start.dateTime));
      setEndTime(moment(timeslot.end.dateTime));
      setIsRepeat(true);

      const weeklyTimeslot =
        !!timeslot.recurrence && timeslot.recurrence[0].includes('WEEKLY');
      setIsWeekly(weeklyTimeslot);
      setIsMonthly(
        !!timeslot.recurrence && timeslot.recurrence[0].includes('MONTHLY'),
      );

      if (weeklyTimeslot) {
        const daysSelected =
          !!timeslot.recurrence &&
          timeslot.recurrence[0].split('BYDAY=')[1].split(',');
        setDays(
          days.map(({ day }) => ({
            day,
            selected: daysSelected.includes(day),
          })),
        );
        setIsMonthlyDisabled(true);
      }
    }

    if (actionType === 'only') {
      setStartTime(moment(timeslot.start.dateTime));
      setEndTime(moment(timeslot.end.dateTime));
      setAllDisabled(true);
    }
  };

  useEffect(() => {
    if (actionType === 'add') {
      return;
    }

    requestTimeslot({
      id:
        actionType === 'all'
          ? selectedTimeslot.recurringEventId
          : selectedTimeslot.id,
    });

    setupTimeslot();
  }, [timeslot?.id, actionType, selectedTimeslot]);

  const isRepeatHandler = (value) => {
    setIsRepeat(!isRepeat);
    setIsWeekly(!isWeekly);
    if (value) {
      const newDay = days.map(({ day }) => ({
        day,
        selected: day === daySelectedInitial ? value : !value,
      }));
      setDays(newDay);
    } else {
      setDays(initialDays);
      setIsWeekly(value);
      setIsMonthly(value);
    }
  };

  const isMonthlyHandler = (value) => {
    setIsMonthly(!isMonthly);
    if (value) {
      setDays(initialDays);
      setIsRepeat(value);
      setIsWeekly(!value);
    } else {
      setIsRepeat(value);
    }
  };

  const selectDay = (index) => {
    if (isRepeat && isWeekly) {
      const newDays = [...days];
      newDays[index].selected = !newDays[index].selected;
      setDays(newDays);
    }
  };

  const createTimeslotItem = (selectedStartDate, selectedEndDate) => {
    const newTimeslot: any = {
      startDate: selectedStartDate.utc().format(),
      endDate: selectedEndDate.utc().format(),
      type: 'VIDEO',
      isFirstTimeConsultationSlot: isFirstTime,
      timeZone: momentTimezone.tz.guess(),
    };

    if (isRepeat) newTimeslot.freq = isWeekly ? 'WEEKLY' : 'MONTHLY';

    if (isWeekly) {
      newTimeslot.week = days.flatMap((day) => (day.selected ? day.day : []));
    }

    return newTimeslot;
  };

  const sendTimeslotHandler = async () => {
    onHideModal();

    const selectedStartDate = moment(selectedDate).set({
      hour: startTime.get('hour'),
      minutes: startTime.get('minute'),
    });
    const selectedEndDate = moment(selectedStartDate).add(
      bookingDuration,
      'minutes',
    );

    if (actionType === 'all') {
      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        selectedEndDate,
      );
      newTimeslot.id = selectedTimeslot.recurringEventId;
      newTimeslot.recurringEventId = selectedTimeslot.recurringEventId;

      requestUpdateTimeslot(newTimeslot);
    } else if (actionType === 'only') {
      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        selectedEndDate,
      );
      newTimeslot.id = selectedTimeslot.id;

      requestUpdateTimeslot(newTimeslot);
    } else {
      const numberOfSlots = Math.floor(
        endTime.diff(startTime, 'minutes') / bookingDuration,
      );

      const newTimeslot: any = createTimeslotItem(
        selectedStartDate,
        numberOfSlots > 1
          ? moment(selectedDate).set({
              hour: endTime.get('hour'),
              minutes: endTime.get('minute'),
            })
          : selectedEndDate,
      );

      requestCreateTimeslot(newTimeslot);
    }
  };

  const deleteTimeslotHandler = () => {
    onHideModal();
    requestDeleteTimeslot({
      id:
        actionType === 'all' ? selectedTimeslot.recurringEventId : timeslot.id,
    });
  };

  const isFirstTimeHandler = (value) => {
    setFirstTime(value);
    setEndTime(moment.utc(startTime, 'h:mm A').add(bookingDuration, 'minutes'));
  };

  return (
    <div>
      <div className="pt-2">
        <div className="justify-content-center has-text-centered mb-4">
          <small>Video Consultation Time</small>
        </div>
        <div className="flex is-justify-content-space-evenly">
          <StartTimePicker startTime={startTime} setStartTime={setStartTime} />

          <EndTimePicker
            endTime={endTime}
            startTime={startTime}
            setEndTime={setEndTime}
            bookingDuration={bookingDuration}
          />
        </div>

        {!allDisabled && (
          <div>
            <div
              style={{
                borderTop: '1px solid black',
                marginTop: 20,
                marginBottom: 20,
              }}
            />
            <div>
              <div>Repeat:</div>
              <Switch
                onChange={isRepeatHandler}
                onColor="#F81942"
                checked={isRepeat}
              />
            </div>

            <div className="cell-day-button">
              {days.map((item, index) => (
                <div
                  key={index}
                  className="day-view"
                  style={{
                    backgroundColor: item.selected ? '#F81942' : '#E9E9E9',
                  }}
                  onClick={() => selectDay(index)}
                >
                  <span
                    style={{
                      color: item.selected ? '#ffffff' : 'black',
                      fontSize: 12,
                    }}
                  >
                    {item.day[0]}
                  </span>
                </div>
              ))}
            </div>

            {!allDisabled && provider.isPsychiatrist && (
              <>
                <div>
                  {provider.profile.doctorType === PSYCHOLOGY
                    ? 'Use for extended time consultation:'
                    : 'Use for first time consultation:'}
                </div>
                <div>
                  <Switch
                    onChange={isFirstTimeHandler}
                    disabled={!provider.isPsychiatrist}
                    onColor="#F81942"
                    checked={isFirstTime}
                  />
                </div>
              </>
            )}

            {!(allDisabled || monthlyDisabled) && (
              <>
                <div>Monthly:</div>
                <div>
                  <Switch
                    onChange={isMonthlyHandler}
                    disabled={monthlyDisabled}
                    onColor="#F81942"
                    checked={isMonthly}
                  />
                </div>
              </>
            )}
          </div>
        )}

        <div className="mt-4 has-text-centered">
          <MMDButton isPrimary onClick={sendTimeslotHandler} text="Done" />

          <MMDButton text="Cancel" onClick={onHideModal} />

          {timeslot?.id && (
            <div className="mt-2">
              <MMDButton
                onClick={deleteTimeslotHandler}
                text="Delete timeslot"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeslotDetail;
