import { FC } from 'react';

import { Booking } from 'typings';

const titles = ['Date', 'Info', 'Status'];

const BookingPrescriptions: FC<Pick<Booking, 'prescriptions'>> = ({
  prescriptions,
}) => {
  if (!prescriptions?.length) {
    return <span>-</span>;
  }

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          {titles.map((title, i) => (
            <th key={i}>
              <abbr title={title}>{title}</abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {prescriptions.map((prescription) => (
          <tr key={prescription.id}>
            <th>{prescription.createdAt}</th>
            <th style={{ whiteSpace: 'pre-line' }}>
              {`Medication: ${prescription.name}
            Dosage: ${prescription.dosage}
            Quantity: ${prescription.quantity || 0}
            Frequency: ${prescription.frequency || 0}
            Method refills: ${prescription.methodRefills || 0}
            Days of supply: ${prescription.daysOfsupply || 0}
            Auto refills days: ${prescription.autoRefillsDays || 0}`}
            </th>
            <th>{prescription.status}</th>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BookingPrescriptions;
