import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Heading, Media } from 'react-bulma-components';

import { Notification } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  notification: Notification;
  targetPath: string;
}

export const NotificationRow: FC<Props> = ({ notification, targetPath }) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>{`Title: ${notification.title}`}</strong>{' '}
        <small> id: {notification.id}</small>
        <Heading
          subtitle
          size={6}
          renderAs="h6"
        >{`Name: ${notification.name}`}</Heading>
        <Heading
          subtitle
          size={6}
          renderAs="h6"
        >{`Description: ${notification.description}`}</Heading>
        <Heading
          subtitle
          size={6}
          renderAs="h6"
        >{`Body: ${notification.body}`}</Heading>
        <Heading subtitle size={6} renderAs="h6">
          CreatedAt: {notification.createdAt}
        </Heading>
        <Heading subtitle size={6} renderAs="h6">
          Updated At: {notification.updatedAt}
        </Heading>
      </Media.Item>
    </Media>
    <Media.Item></Media.Item>
    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${notification.id}`}>
          <MMDSuperAdminButton
            isSubmit
            isPrimary
            text="Edit"
            className="mt-2"
          />
        </Link>
      </Columns.Column>
    </Columns>
  </Box>
);

export default NotificationRow;
