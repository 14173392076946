import moment from 'moment';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { OrganizationType } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

interface Props {
  organizationType: OrganizationType;
  targetPath: string;
  onDeleteClick: (id: number) => void;
}

export const OrganizationTypeRow: FC<Props> = ({
  organizationType,
  onDeleteClick,
  targetPath,
}) => {
  const requestDelete = useCallback(() => {
    onDeleteClick(organizationType.id);
  }, [organizationType, onDeleteClick]);

  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{organizationType.name}</strong>
          <p>Created at: {moment(organizationType.createdAt).format('LLLL')}</p>
          <p>Updated at: {moment(organizationType.updatedAt).format('LLLL')}</p>
        </Media.Item>
      </Media>
      <Media.Item></Media.Item>
      <Columns>
        <Columns.Column size={1}>
          <Link to={`/${targetPath}/${organizationType.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={requestDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default OrganizationTypeRow;
