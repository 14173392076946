import React from 'react';
import { Box, Heading, Media, Columns } from 'react-bulma-components';
import { Patient, Provider, User } from '../typings';
import MMDButton from './MMDButton';

interface Props {
  user: Provider | User | Patient;
  onClick: () => void;
  key?: React.ReactText;
  teacherId?: string;
}

export const SelectableUserRow: React.FC<Props> = ({
  user,
  onClick,
  key,
  teacherId,
}) => {
  return (
    <Box onClick={onClick} key={key}>
      <Media>
        <Media.Item>
          <strong>{`${user.firstName} ${user.lastName}`}</strong>{' '}
          <small>{user.email}</small>
          {user.address && user.city && user.state ? (
            <Heading
              subtitle
              size={6}
              renderAs="h6"
            >{`${user.address}, ${user.city}, ${user.state}`}</Heading>
          ) : null}
          <Heading
            subtitle
            size={6}
            renderAs="h6"
          >{`Phone: ${user.phone}`}</Heading>
        </Media.Item>
      </Media>
      {teacherId !== user.id && (
        <Columns>
          <Columns.Column size={1}>
            <MMDButton
              isSubmit
              isPrimary
              text="Turn teacher"
              onClick={onClick}
            />
          </Columns.Column>
        </Columns>
      )}
    </Box>
  );
};

export default SelectableUserRow;
