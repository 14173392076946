import { forwardRef, HTMLProps } from 'react';
import { PatternFormat } from 'react-number-format';

import { Nullable } from 'typings';

type PropTypes = {
  label: string;
  valid?: boolean;
  dirty?: boolean;
  required?: boolean;
  withoutMask?: boolean;
  error?: Nullable<string>;
} & HTMLProps<HTMLInputElement>;

const PHONE_MASKS = {
  phone: '(###) ###-####',
  fax: '+1(###)-###-####',
};

const MMDTextInput = forwardRef<HTMLInputElement, PropTypes>(
  (
    {
      withoutMask = false,
      label,
      valid = true,
      dirty = true,
      required = false,
      error = null,
      ...rest
    },
    ref,
  ) => {
    const isMaskedInput = ['phone', 'fax'].includes(rest.name) && !withoutMask;

    return (
      <div className="field">
        <label
          className={`label ${required && !rest.readOnly ? 'required' : ''}`}
        >
          {label}
        </label>
        <div className={`control ${rest.readOnly ? 'no-click-event' : ''}`}>
          {isMaskedInput ? (
            // @ts-ignore
            <PatternFormat
              mask="_"
              getInputRef={ref}
              allowEmptyFormatting
              format={PHONE_MASKS[rest.name]}
              className={`input ${!valid ? 'is-danger' : ''}`}
              {...rest}
              type="tel"
            />
          ) : (
            <input
              className={`input ${!valid ? 'is-danger' : ''}`}
              {...rest}
              ref={ref}
            />
          )}

          {error && (
            <span className={`line-break ${!valid && 'has-text-danger'}`}>
              {error}
            </span>
          )}
        </div>
      </div>
    );
  },
);

MMDTextInput.displayName = 'MMDTextInput';

export default MMDTextInput;
