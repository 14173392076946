import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import { useBoundedActions } from 'hooks/useBoundedActions';
import ImmunizationsTable from 'components/patient/ImmunizationTable';
import {
  selectPatientById,
  selectPatientsState,
  Creators as PatientsActions,
} from 'modules/patients.module';
import ExemptionRequestTable from '../../components/patient/ExemptionRequestTable';

const PatientImmunizations = () => {
  const params = useParams();

  const { requestPatient, requestPatientImmunizations } =
    useBoundedActions(PatientsActions);

  useEffect(() => {
    requestPatient({ id: params.id });
    requestPatientImmunizations({ id: params.id });
  }, []);

  const { immunizations } = useSelector(selectPatientsState);

  const patient = useSelector((state) => selectPatientById(state, params.id));

  const patientName = `${patient?.firstName} ${patient?.lastName}`;

  const isLoading = !patient || !immunizations;

  return (
    <div className="container">
      <MMDLoader loading={isLoading} />

      <div className="mb-4">
        <strong>{patientName}'s immunizations</strong>
      </div>

      <ImmunizationsTable
        title="Required"
        patientId={params.id}
        immunizations={immunizations?.requiredImmunizations ?? []}
      />

      <ImmunizationsTable
        title="Recommended"
        patientId={params.id}
        immunizations={immunizations?.recommendedImmunizations ?? []}
      />

      {immunizations?.patientExemptionRequest && (
        <ExemptionRequestTable
          patientId={params.id}
          exemptionRequest={immunizations?.patientExemptionRequest}
        />
      )}
    </div>
  );
};

export default PatientImmunizations;
