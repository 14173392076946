import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions } from 'reduxsauce';

import { putAuthInfoInArgs } from '../auth.module';
import { API_URL } from '../../config';
import { ArgsWithHeaders } from '../../utils/typings';
import {
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import { Pet, SagaIterator } from 'typings';

interface ActionTypes {
  REQUEST_UPDATE_PET: string;
  LOADING_PET: string;
  COMMIT_PET: string;
  ERROR_PET: string;
}

interface ActionCreators {
  requestUpdatePet: (payload: Pet) => MyAction<Pet>;
  loadingPet: () => Action;
  commitPet: (payload: Pet) => MyAction<Pet>;
  errorPet: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdatePet: ['payload'],
  loadingPet: [],
  commitPet: ['payload'],
  errorPet: ['error'],
});

async function updatePet({
  headers,
  ...payload
}: ArgsWithHeaders<Pet>): Promise<void> {
  const result = await fetch(
    `${API_URL}/patients/${payload.parentId}/pets/${payload.id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const goBack = goBackFactory('/patients#pets');

const requestUpdatePetWatcher = createSingleEventSaga<Pet, Pet, MyAction<Pet>>({
  takeEvery: Types.REQUEST_UPDATE_PET,
  loadingAction: Creators.loadingPet,
  commitAction: Creators.commitPet,
  successAction: goBack.action,
  errorAction: Creators.errorPet,
  action: updatePet,
  beforeAction: putAuthInfoInArgs,
  *afterAction(_, { headers, ...args }: ArgsWithHeaders<Pet>): SagaIterator {
    return args;
  },
});

export const petSagas = [requestUpdatePetWatcher, goBack.watcher];

export function petsFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    name: true,
    breed: true,
    birthday: true,
    type: true,
  };

  ['name', 'breed', 'type'].forEach((k) => {
    result[k] = !!values[k] && values[k].toString().length > 0;
  });

  return Promise.resolve(result);
}
