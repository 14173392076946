import { Dictionary } from '@mrnkr/redux-saga-toolbox';

export const COLORS: Dictionary<string> = {
  white: '#FFFFFF',
  black: '#000000',
  pink: '#DB386C',
};

export const ADMIN_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
};

export const SUPER_ADMIN_ROUTES = [
  '/payment-config',
  '/create-templates',
  '/users',
  '/users/:id',
  '/invite-user',
  '/users/invites',
  '/create-promo-code',
  '/create-medication',
  '/create-pharmacy',
  '/create-credential',
  '/create-speciality',
  '/create-affiliation',
  '/create-condition',
  '/create-allergy',
  '/create-term-and-condition/:userType',
  '/create-privacy-policy/:userType',
];

export const MAX_ITEM = Number.MAX_SAFE_INTEGER;
export const PAGE_LEN = parseInt(process.env.REACT_APP_PAGE_LEN, 10);

//deleted constant credential DEPRECATED.

export const PAYMENT_FREQUENCIES = [
  { id: 0, name: 'Weekly', value: '1' },
  { id: 1, name: 'Biweekly', value: '2' },
  { id: 2, name: 'Monthly', value: '4' },
];

export const GENDERS = [
  { key: 0, label: 'Male', value: 'male' },
  { key: 1, label: 'Female', value: 'female' },
];

export const PHARMACY_FILTERS = [
  { id: 0, name: 'Address', value: 'address__ire' },
  { id: 2, name: 'Phone Number', value: 'phone__ire' },
  { id: 3, name: 'Fax number', value: 'fax__ire' },
];

export const SPECIALITY_FILTERS = [
  { id: 0, name: 'Name', value: 'name__ire' },
  { id: 1, name: 'Credential', value: 'credential__ire' },
];

export const CREDENTIAL_FILTERS = [
  { id: 0, name: 'Name', value: 'label__ire' },
];

export const PROVIDERS_FILTERS = [
  { id: 0, name: 'Name', value: 'name__ire' },
  { id: 1, name: 'Image Status', value: 'image_status__ire' },
];

export const SINGLE_NAME_FILTER = [{ id: 0, name: 'Name', value: 'name__ire' }];

export const YESORNO = [
  { key: 0, label: 'Yes', value: 'true' },
  { key: 1, label: 'No', value: 'false' },
];

export const USER_TYPE_FILTER = [
  { id: 0, name: 'Patient', value: 'Patient' },
  { id: 1, name: 'Doctor', value: 'Doctor' },
];

export const STATUS_FILTER = [
  { id: 0, name: 'Sent', value: 'sent' },
  { id: 1, name: 'Deny', value: 'deny' },
];

export const SIGNIN_ATTEMPT_FILTER = [
  { id: 0, name: 'Email', value: 'email__ire' },
  { id: 1, name: 'IP', value: 'ip__ire' },
  { id: 2, name: 'Location', value: 'location__ire' },
];

export const SESSIONS_FILTER = [
  { id: 0, name: 'IP', value: 'ip__ire' },
  { id: 1, name: 'Location', value: 'location__ire' },
];

export const ENTITIES = [
  'AUTH',
  'PROVIDERS',
  'PATIENTS',
  'SPECIALITIES',
  'CREDENTIALS',
  'NOTIFICATIONS',
  'PHARMACIES',
  'PROMO_CODES',
  'TRANSACTIONS',
  'MEDICATIONS',
  'AFFILIATIONS',
  'CONDITIONS',
  'ALLERGIES',
  'SPLIT_PERCENTAGE',
  'TERMS_AND_CONDITIONS',
  'PRIVACY_POLICIES',
  'PAYMENT_FREQUENCY',
  'PRESCRIPTIONS',
  'SESSION',
  'SIGNIN_ATTEMPTS',
  'LOGS',
];

export const APPROVED = true;
export const REJECTED = false;

export const USER_TYPE = {
  PROVIDER: 'provider',
  PROVIDERS: 'providers',
  PATIENT: 'patient',
  PATIENTS: 'patients',
};

export const NUMBER_REGEX = /^[+-]?([0-9]*[.])?[0-9]+$/g;
export const COORD_REGEX = /^\[([\d.-]+),([\d.-]+)\]$/i;

export const PASSWORD_REG_EXP =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{16,}$/;
export const PHONE_REG_EXP = /^\(\d{3}\) \d{3}-\d{4}$/;
export const FAX_REG_EXP = /^\+1\(\d{3}\)-\d{3}-\d{4}$/;
// with optional spaces and ()
///^\+\(?\d{3}\)?\s*\d{3}\s*\d{4}$/

export enum API_ERRORS_CODE {
  FORBID_DELETE_PHARMACY = 1000,
}
