import React from 'react';
import MMDForm from '../forms/MMDForm';
import MMDTextInput from '../forms/MMDTextInput';
import MMDRadio from '../forms/MMDRadio';
import MMDButton from '../MMDButton';
import { Form } from '@mrnkr/redux-saga-toolbox';
import {
  Creators as ChildrenActions,
  childrenFormValidator,
} from '../../modules/patients/children.module';
import { GENDERS } from 'utils/constants';
import { Child } from 'typings';

type PropTypes = {
  patient: Child;
};

export const ChildForm: React.FC<PropTypes> = ({ patient }) => {
  const renderFormControls = React.useCallback(
    (form: Form, onChange: (e: React.FormEvent<HTMLInputElement>) => void) => {
      return (
        <div className="container p-2">
          <MMDTextInput
            name={form.fields['firstName'].name}
            value={form.fields['firstName'].value}
            label="First Name"
            valid={form.fields['firstName'].valid}
            dirty={form.fields['firstName'].dirty}
            placeholder="John"
            onChange={onChange}
            required
          />
          <MMDTextInput
            name={form.fields['lastName'].name}
            label="Last Name"
            valid={form.fields['lastName'].valid}
            dirty={form.fields['lastName'].dirty}
            placeholder="Doe"
            value={form.fields['lastName'].value}
            onChange={onChange}
            required
          />
          <MMDTextInput
            name={form.fields['birthday'].name}
            label="Birthday"
            valid={form.fields['birthday'].valid}
            dirty={form.fields['birthday'].dirty}
            placeholder="MM-DD-YYYY"
            value={form.fields['birthday'].value}
            onChange={onChange}
          />
          <MMDRadio
            name={form.fields['sex'].name}
            valid={form.fields['sex'].valid}
            dirty={form.fields['sex'].dirty}
            label="Gender"
            value={form.fields['sex'].value}
            answers={GENDERS}
            onChange={onChange}
          />
          <MMDButton className="mt-2" isSubmit isPrimary text="Update" />
        </div>
      );
    },
    [],
  );

  if (!patient) {
    return null;
  }

  const formConfig = {
    formName: 'update-child-form',
    fields: {
      firstName: patient.firstName,
      lastName: patient.lastName,
      birthday: patient.birthday,
      sex: patient.sex,
    },
    validator: childrenFormValidator,
    onSubmit: (values) => {
      return ChildrenActions.requestUpdateChild({
        id: patient.id,
        firstName: values['firstName'],
        lastName: values['lastName'],
        birthday: values['birthday'],
        sex: values['sex'],
        parentId: patient.parentId,
      });
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default ChildForm;
