import { push } from 'redux-first-history';
import { Level } from 'react-bulma-components';
import { FC, FormEvent, useState } from 'react';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDButton from 'components/MMDButton';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDTextInput from 'components/forms/MMDTextInput';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { useFilterSearchParam } from 'hooks/useFilterSearchParam';

type Props = {
  rootPath: string;
  push: typeof push;
  newEntityUrl?: string;
  withoutSearch: boolean;
  withoutApproveFilter: boolean;
  routeParams: Dictionary<string>;
};

const MMDSearchFilter: FC<Props> = ({
  push,
  rootPath,
  newEntityUrl,
  withoutSearch,
  routeParams,
  withoutApproveFilter,
}) => {
  const [search, setSearch] = useState(routeParams.search ?? '');
  const [isApproveFilter, setIsApproveFilter] = useState(
    routeParams.approved === 'false',
  );

  const [addApprovedParam, removeApprovedParam] =
    useFilterSearchParam('approved');

  const [addSearchParam, removeSearchParam] = useFilterSearchParam('search');

  const onSearchChanged = (e: FormEvent<HTMLInputElement>) =>
    setSearch((e.target as HTMLInputElement).value);

  const showOnlyNotApproved = () =>
    setIsApproveFilter((isApproveFilter) => {
      push(isApproveFilter ? removeApprovedParam() : addApprovedParam(false));
      return !isApproveFilter;
    });

  const onSearch = () =>
    push(search.length ? addSearchParam(search) : removeSearchParam());

  const goToCreateEntity = () => push(newEntityUrl);

  const clearFilter = () => {
    push(rootPath);
    setSearch('');
    setIsApproveFilter(false);
  };

  return (
    <Level>
      {!withoutSearch && (
        <Level.Side align="left">
          <Level.Item>
            <MMDTextInput
              value={search}
              name="search-value"
              placeholder="search..."
              label="Search value:"
              onChange={onSearchChanged}
            />
          </Level.Item>
          <Level.Item>
            <MMDButton isPrimary text="Search" onClick={onSearch} />
          </Level.Item>
          {!withoutApproveFilter && (
            <Level.Item>
              <MMDCheckbox
                name="admin-approval"
                label="Not approved"
                onChange={showOnlyNotApproved}
                value={isApproveFilter ? 'true' : 'false'}
              />
            </Level.Item>
          )}
          <Level.Item>
            <MMDButton text="Clean all" isDanger onClick={clearFilter} />
          </Level.Item>
        </Level.Side>
      )}
      <Level.Side align="right">
        {newEntityUrl && (
          <Level.Item>
            <MMDSuperAdminButton
              text="New"
              isPrimary
              onClick={goToCreateEntity}
            />
          </Level.Item>
        )}
      </Level.Side>
    </Level>
  );
};

export default MMDSearchFilter;
