import { PASSWORD_REG_EXP } from 'utils/constants';

export const FORM_FIELDS_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  JOB_TITLE: 'jobTitle',
  PASSWORD: 'password',
  PHONE: 'phone',
};

export function validatePassword(password: string) {
  if (!PASSWORD_REG_EXP.test(password)) {
    return [
      {
        regExp: /^.{16,}$/,
        error: 'At least 16 characters.',
      },
      {
        regExp: /(?=.*[0-9])/,
        error: 'At least one number.',
      },
      {
        regExp: /(?=.*[!@#$%^&*])/,
        error: 'At least one special character.',
      },
      {
        regExp: /(?=.*[a-z])/,
        error: 'At least one lowercase letter.',
      },
      {
        regExp: /(?=.*[A-Z])/,
        error: 'At least one uppercase letter.',
      },
    ].reduce(
      (message, { regExp, error }) =>
        !regExp.test(password) ? message + `- ${error}\n` : message,
      'Password must meet the following criteria:\n',
    );
  }

  return null;
}
