import { Dictionary } from '@mrnkr/redux-saga-toolbox';

export function putDefaultValueSafe<T>(obj: T, key: keyof T): string {
  return obj && obj[key] ? obj[key].toString() : '';
}
export function putDefaultValueSafeAny<T>(obj: T, key: keyof T): any {
  return obj && obj[key] ? obj[key] : '';
}

export function getBooleanFieldValue(
  values: Dictionary<string>,
  key: string,
): boolean {
  return ['true', 'yes'].includes(values[key]) ? true : false;
}

export function getNumericFieldValue(
  values: Dictionary<string>,
  key: string,
): number {
  const val = parseFloat(values[key]);

  if (isNaN(val)) {
    throw Error(
      `Cannot transform ${values[key]} in field ${key} into a number`,
    );
  }

  return val;
}
