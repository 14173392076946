import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import difference from 'lodash/difference';

export const deepDiffBetweenObjects = (
  object: Record<string, any>,
  base: Record<string, any>,
) => {
  const changes = (object: Record<string, any>, base: Record<string, any>) =>
    transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        if (isArray(value)) {
          const diffs = difference(value, base[key]);

          if (diffs.length > 0 || value.length !== base[key].length) {
            result[key] = value;
          }
        } else if (isObject(value) && isObject(base[key])) {
          result[key] = changes(value, base[key]);
        } else {
          result[key] = value;
        }
      }
    });

  return changes(object, base);
};
