import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MMDLoader from 'components/MMDLoader';
import NotificationForm from './NotificationForm';
import {
  Creators as NotificationsActions,
  selectNotificationById,
  selectNotificationsLoading,
} from '../../modules/notifications.module';
import { useParams } from 'react-router-dom';
import { useBoundedActions } from '../../hooks/useBoundedActions';
import { useFirstMount } from '../../hooks/useFirstMount';

const NotificationPage: FC = () => {
  const { requestNotification } = useBoundedActions(NotificationsActions);

  const params = useParams();

  const isFirstMount = useFirstMount();

  const loading = useSelector(selectNotificationsLoading);

  const notification = useSelector((state) =>
    selectNotificationById(state, params.id),
  );

  useEffect(() => {
    if (!isFirstMount) {
      return;
    }

    requestNotification({ id: params.id });
  }, [isFirstMount, params, requestNotification]);

  return (
    <div className="container">
      <MMDLoader loading={loading} />
      <NotificationForm notification={notification} />
    </div>
  );
};

export default NotificationPage;
