import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ProviderLanguage } from 'typings';
import EntitiesTable from 'components/entities-table';
import ProviderLanguageRow from './ProviderLanguageRow';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectProviderLanguagesState,
  selectProviderLanguages,
  Creators as ProviderLanguagesActions,
} from 'modules/provider-languages.module';

const ProviderLanguages: FC = () => {
  const { count, loading } = useSelector(selectProviderLanguagesState);

  const providerLanguages = useSelector(selectProviderLanguages);

  const { requestRemoveProviderLanguage } = useBoundedActions(
    ProviderLanguagesActions,
  );

  const getOnDeleteProviderLanguage = (id: number) => () =>
    requestRemoveProviderLanguage({ id });

  return (
    <EntitiesTable
      count={count}
      loading={loading}
      withoutApproveFilter
      withoutSearch
      rootPath="provider-languages"
      newEntityUrl="create-provider-language"
      elements={providerLanguages.map((item: ProviderLanguage) => (
        <ProviderLanguageRow
          key={item.id}
          providerLanguage={item}
          targetPath="provider-languages"
          onDeleteClick={getOnDeleteProviderLanguage(item.id)}
        />
      ))}
    />
  );
};

export default ProviderLanguages;
