import { useSelector } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { FC, useEffect, useRef, useState } from 'react';

import { selectMfaState } from 'modules/mfa.module';

type Props = {
  onVerify: (values: { code: string }) => void;
  isVerifyBySms: boolean;
};

const MfaCodeForm: FC<Props> = ({ onVerify, isVerifyBySms }) => {
  const {
    phone,
    error: mfaError,
    loading: isLoadingMfa,
  } = useSelector(selectMfaState);

  const codeRef = useRef(null);
  const [mfaCode, setMfaCode] = useState('');

  function clear() {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input[0] = '';
    codeRef.current.state.input[1] = '';
    codeRef.current.state.input[2] = '';
    codeRef.current.state.input[3] = '';
    codeRef.current.state.input[4] = '';
    codeRef.current.state.input[5] = '';
    setMfaCode('');
  }

  // Effect for clear on mount
  useEffect(() => {
    clear();
  }, []);

  // Effect for clear on error
  useEffect(() => {
    if (!mfaError) {
      return;
    }

    clear();
  }, [mfaError]);

  useEffect(() => {
    if (mfaCode.length !== 6) {
      return;
    }

    onVerify({ code: mfaCode });
  }, [mfaCode]);

  return (
    <div className="flex is-flex-direction-column">
      <span className="mb-2">Enter MFA code</span>

      {isVerifyBySms && (
        <span className="mb-2">
          SMS was sent for phone with this end numbers –{' '}
          <span className="has-text-weight-bold">{phone}</span>
        </span>
      )}

      {mfaError && (
        <p className="mb-3 has-text-weight-bold has-text-danger">
          Not valid MFA code
        </p>
      )}

      <ReactCodeInput
        fields={6}
        name="code"
        ref={codeRef}
        value={mfaCode}
        inputMode="numeric"
        pattern="/^[0-9]+$/"
        onChange={setMfaCode}
        disabled={isLoadingMfa}
      />
    </div>
  );
};

export default MfaCodeForm;
