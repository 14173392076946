import { useSelector } from 'react-redux';
import { FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@mrnkr/redux-saga-toolbox';

import {
  selectSpecialityById,
  specialityFormValidator,
  Creators as SpecialityActions,
} from 'modules/specialities.module';
import { Speciality } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import MMDRadio from 'components/forms/MMDRadio';
import MMDSelect from 'components/forms/MMDSelect';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import MMDCheckboxMulti from 'components/forms/MMDCheckboxMulti';
import {
  selectCredentials,
  Creators as CredentialsActions,
} from 'modules/credentials.module';
import {
  putDefaultValueSafe,
  getBooleanFieldValue,
  getNumericFieldValue,
} from 'utils/formUtils';

export const DOCTOR_TYPES_OPTIONS = [
  { key: 0, label: 'Additional time slot', value: 'psychology' },
  { key: 1, label: 'Initial consultation time slot', value: 'psychiatrist' },
];

const SpecialityForm = () => {
  const params = useParams<{ id: string }>();

  const speciality = useSelector((state) =>
    selectSpecialityById(state, params.id),
  );

  const credentials = useSelector(selectCredentials);

  const { requestCredentialsSearch } = useBoundedActions(CredentialsActions);

  useEffect(() => {
    requestCredentialsSearch({ credential: '__limit=10000&__sort=-updatedAt' });
  }, []);

  const renderFormControls = (
    form: Form,
    onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
  ) => {
    const selectedCredential = credentials.find(
      ({ value }) => value === form.fields['credential'].value,
    );

    const categoryCred = selectedCredential ? selectedCredential.category : [];

    const onChangeCredential = (
      event: FormEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
      onChange({
        target: { name: 'isPsychiatry', value: 'false' },
      } as any);

      onChange({
        target: { name: 'categories', value: [] },
      } as any);

      onChange(event);
    };

    return (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="Name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter speciality name"
          onChange={onChange}
          required={true}
        />

        {form.fields['isPsychiatry'].value === 'true' && (
          <MMDRadio
            name={form.fields['doctorType'].name}
            valid={form.fields['doctorType'].valid}
            dirty={form.fields['doctorType'].dirty}
            label="Additional Slots Behavior"
            value={form.fields['doctorType'].value}
            answers={DOCTOR_TYPES_OPTIONS}
            onChange={onChange}
            required
          />
        )}

        <MMDSelect
          name={form.fields['credential'].name}
          valid={form.fields['credential'].valid}
          dirty={form.fields['credential'].dirty}
          label="Select Credential"
          value={form.fields['credential'].value}
          options={credentials}
          onChange={onChangeCredential}
          required={true}
        />
        {!!categoryCred?.length && (
          <MMDCheckboxMulti
            name={form.fields['categories'].name}
            label="Categories"
            dirty={form.fields['categories'].dirty}
            value={form.fields['categories'].value}
            onChange={onChange}
            options={categoryCred}
            isPsychiatry={form.fields['isPsychiatry'].value}
            credential={form.fields['credential'].value || ''}
          />
        )}

        <MMDTextInput
          name={form.fields['timeSlotLength'].name}
          value={form.fields['timeSlotLength'].value}
          label="Timeslot length"
          valid={form.fields['timeSlotLength'].valid}
          dirty={form.fields['timeSlotLength'].dirty}
          placeholder="Enter default timeslot length for specialty"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['monthlyPrice'].name}
          value={form.fields['monthlyPrice'].value}
          label="Default monthly price"
          valid={form.fields['monthlyPrice'].valid}
          dirty={form.fields['monthlyPrice'].dirty}
          placeholder="Enter a default monthly price for specialty"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['oneTimePrice'].name}
          value={form.fields['oneTimePrice'].value}
          label="One time price for specialty"
          valid={form.fields['oneTimePrice'].valid}
          dirty={form.fields['oneTimePrice'].dirty}
          placeholder="Enter a default price for one time consultations"
          onChange={onChange}
          required={true}
        />
        {form.fields['isPsychiatry'].value === 'true' ? (
          <>
            <MMDTextInput
              name={form.fields['firstTimePrice'].name}
              value={form.fields['firstTimePrice'].value}
              label={
                form.fields['doctorType'].value === 'psychology'
                  ? 'Default extended time consultation price for specialty'
                  : 'Default first time consultation price for specialty'
              }
              valid={form.fields['firstTimePrice'].valid}
              dirty={form.fields['firstTimePrice'].dirty}
              placeholder={
                form.fields['doctorType'].value === 'psychology'
                  ? 'Enter a default price for extended consultations'
                  : 'Enter a default price for first time consultations'
              }
              onChange={onChange}
              required={form.fields['isPsychiatry'].value === 'true'}
            />
            <MMDTextInput
              name={form.fields['firstTimeLength'].name}
              value={form.fields['firstTimeLength'].value}
              label={
                form.fields['doctorType'].value === 'psychology'
                  ? 'Length of extended consultation'
                  : 'Length of first consultation'
              }
              valid={form.fields['firstTimeLength'].valid}
              dirty={form.fields['firstTimeLength'].dirty}
              placeholder={
                form.fields['doctorType'].value === 'psychology'
                  ? 'Enter a length for the extended consultation'
                  : 'Enter a length for the first consultation'
              }
              onChange={onChange}
              required={form.fields['isPsychiatry'].value === 'true'}
            />
          </>
        ) : null}
        <MMDCheckbox
          name={form.fields['approved'].name}
          label="Approved"
          dirty={form.fields['approved'].dirty}
          value={form.fields['approved'].value as 'true' | 'false'}
          onChange={onChange}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={speciality ? 'Update' : 'Create'}
        />
      </div>
    );
  };

  if (!speciality && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-speciality-form-${params.id}`
      : 'create-speciality-form',
    fields: {
      name: putDefaultValueSafe(speciality, 'name'),
      credential: putDefaultValueSafe(speciality, 'credential'),
      timeSlotLength: putDefaultValueSafe(speciality, 'timeSlotLength'),
      monthlyPrice: putDefaultValueSafe(speciality, 'monthlyPrice'),
      oneTimePrice: putDefaultValueSafe(speciality, 'oneTimePrice'),
      firstTimePrice: Number(putDefaultValueSafe(speciality, 'firstTimePrice')),
      firstTimeLength: Number(
        putDefaultValueSafe(speciality, 'firstTimeLength'),
      ),
      doctorType: speciality?.doctorType ?? 'psychology',
      categories: speciality?.categories ?? [],
      isPsychiatry: putDefaultValueSafe(speciality, 'isPsychiatry'),
      approved: putDefaultValueSafe(speciality, 'approved'),
    },
    validator: specialityFormValidator,
    onSubmit: (values) => {
      const newSpecialityObj = {
        name: values['name'],
        credential: values['credential'],
        doctorType: values['doctorType'],
        timeSlotLength: getNumericFieldValue(values, 'timeSlotLength'),
        monthlyPrice: getNumericFieldValue(values, 'monthlyPrice'),
        oneTimePrice: getNumericFieldValue(values, 'oneTimePrice'),
        firstTimePrice: getNumericFieldValue(values, 'firstTimePrice'),
        firstTimeLength: getNumericFieldValue(values, 'firstTimeLength'),
        isPsychiatry: getBooleanFieldValue(values, 'isPsychiatry'),
        approved: getBooleanFieldValue(values, 'approved'),
        categories: values.categories,
      };

      return speciality?.id
        ? SpecialityActions.requestUpdateSpeciality({
            id: speciality.id,
            ...newSpecialityObj,
          })
        : SpecialityActions.requestCreateSpeciality(
            newSpecialityObj as Speciality,
          );
    },
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default SpecialityForm;
