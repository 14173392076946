import { useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { push as reduxPush } from 'redux-first-history';

import EnableMfa from './EnableMfa';
import MfaCodeForm from './MfaCodeForm';
import MMDModal from 'components/MMDModal';
import MMDButton from 'components/MMDButton';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { selectMfaState, Creators as MfaActions } from 'modules/mfa.module';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const MfaVerification: FC = () => {
  const {
    loading,
    mfaQrCode,
    isVerifyActive,
    error: mfaError,
    isVerifiedByGoogle,
  } = useSelector(selectMfaState);

  const [isVerifyFormVisible, setVerifyFormVisible] = useState(false);
  const [isVerifyByGoogle, setIsVerifyGoogle] = useState(false);

  const {
    push,
    verifyMfa,
    verifySmsMfa,
    clearMfaError,
    requestMfaCode,
    clearMfaVerify,
    requestEnableMfa,
  } = useBoundedActions({
    push: reduxPush,
    ...MfaActions,
  });

  const location = useLocation();

  const onVerifyWithGoogleAuthenticator = () => {
    setIsVerifyGoogle(true);
    if (isVerifiedByGoogle) {
      setVerifyFormVisible(true);
    } else {
      requestEnableMfa();
    }
  };

  const onVerifyWithSmsCode = () => {
    requestMfaCode();
    setIsVerifyGoogle(false);
    setVerifyFormVisible(true);
  };

  const goBack = () => {
    if (mfaError) {
      clearMfaError();
    }
    setVerifyFormVisible(false);
  };

  useEffect(() => {
    setVerifyFormVisible(false);
  }, [isVerifyActive]);

  const onCloseMfaModal = () => {
    clearMfaVerify();

    if (location.pathname === '/user-invitation') {
      toast.success('Action executed successfully');
      push('/');
    }
  };

  const renderMfaContent = () => {
    if (mfaQrCode) {
      return <EnableMfa onFinishEnable={() => setVerifyFormVisible(true)} />;
    }

    return isVerifyFormVisible ? (
      <div className="has-text-centered">
        <MfaCodeForm
          isVerifyBySms={!isVerifyByGoogle}
          onVerify={isVerifyByGoogle ? verifyMfa : verifySmsMfa}
        />

        <MMDButton text="Go back" onClick={goBack} className="mt-4" />
      </div>
    ) : (
      <>
        <MMDButton
          isDisabled={loading}
          text="Verify with Google Authenticator"
          onClick={onVerifyWithGoogleAuthenticator}
        />

        <MMDButton text="Verify with SMS Code" onClick={onVerifyWithSmsCode} />
      </>
    );
  };

  return (
    <MMDModal visible={isVerifyActive} onRequestClose={onCloseMfaModal}>
      <p className="has-text-centered has-text-weight-bold">MFA verification</p>

      <div className="flex is-justify-content-space-evenly mt-4">
        {renderMfaContent()}
      </div>
    </MMDModal>
  );
};

export default MfaVerification;
