import moment from 'moment/moment';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Timeslot } from 'typings';
import TimeslotButton from './TimeslotButton';
import { selectTimeslots } from 'modules/timeslots.module';

type Props = {
  currentDate: Date;
  onEditTimeslot: (timeslot: Timeslot) => void;
};

const TimeslotsList: FC<Props> = ({ currentDate, onEditTimeslot }) => {
  const timeslots = useSelector(selectTimeslots);

  const timeslotsByDay = useMemo(
    () =>
      timeslots
        .filter((timeslot) =>
          moment(timeslot.start.dateTime).isSame(currentDate, 'day'),
        )
        .sort(
          (firstDate, secondDate) =>
            moment(firstDate.start.dateTime).unix() -
            moment(secondDate.start.dateTime).unix(),
        ),
    [timeslots, currentDate],
  );

  if (!timeslotsByDay.length) {
    return (
      <div className="has-text-centered">
        <strong>No timeslots</strong>
      </div>
    );
  }

  return (
    <>
      {timeslotsByDay.map((timeslot) => (
        <TimeslotButton
          isEdit
          isTimeslot
          key={timeslot.id}
          timeslot={timeslot}
          onClick={onEditTimeslot}
        />
      ))}
    </>
  );
};

export default TimeslotsList;
