import { useLocation } from 'react-router-dom';

export const useFilterSearchParam = (
  queryName: string,
): [(value: any) => string, () => string] => {
  const location = useLocation();

  const prepareLocationUrl = (search: URLSearchParams) =>
    `${location.pathname}?${search.toString()}`;

  const add = (value: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(queryName, value);
    searchParams.delete('__offset');
    return prepareLocationUrl(searchParams);
  };

  const remove = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(queryName);
    searchParams.delete('__offset');
    return prepareLocationUrl(searchParams);
  };

  return [add, remove];
};
