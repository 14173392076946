import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { push as reduxPush } from 'redux-first-history';

import EmailForm from './EmailForm';
import MMDButton from 'components/MMDButton';
import { USER_NOT_FOUND_ERROR } from 'utils/onRoute';
import NewPasswordForm from './NewPasswordForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { Creators as MfaActions, selectMfaState } from 'modules/mfa.module';
import { Creators as AuthActions, selectAuthState } from 'modules/auth.module';

const ResetPassword = () => {
  const { isVerifySuccess } = useSelector(selectMfaState);
  const { error: authError } = useSelector(selectAuthState);

  const { push, clearMfaVerify, clearAuthError } = useBoundedActions({
    ...MfaActions,
    ...AuthActions,
    push: reduxPush,
  });

  useEffect(() => {
    clearMfaVerify();
  }, []);

  useEffect(() => {
    if (authError?.message === USER_NOT_FOUND_ERROR) {
      push('/');
      clearAuthError();
    }
  }, [authError?.message]);

  const onLoginClick = () => push('/');

  return (
    <div className="container p-2">
      {isVerifySuccess ? <NewPasswordForm /> : <EmailForm />}

      <MMDButton text="Login" isPrimary={false} onClick={onLoginClick} />
    </div>
  );
};

export default ResetPassword;
