import { PAGE_LEN } from './constants';

if (isNaN(PAGE_LEN)) {
  throw Error(
    'The environment variable REACT_APP_PAGE_LEN is either not set to a number or not set at all. Please set it :)',
  );
}

export function appendPageLimit(query: string = ''): string {
  if (query.includes('__limit')) {
    return query;
  }

  if (query.includes('?')) {
    return `${query}&__limit=${PAGE_LEN}`;
  }

  return `?__limit=${PAGE_LEN}`;
}
