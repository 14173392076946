import {
  createFormSaga,
  formActions,
  formReducer as reducer,
  formSelectors as selectors,
  FormState as State,
} from '@mrnkr/redux-saga-toolbox';

export type FormState = State;
export const Creators = formActions;
export const formReducer = reducer;
export const formSelectors = selectors;

const watcher = createFormSaga();

export const formSagas = [watcher];
