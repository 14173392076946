import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  EntityState,
  createEntityAdapter,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from '../config';
import { SagaIterator, SigninAttempt } from '../typings';
import {
  ArgsWithHeaders,
  LocationChangeActionPayload,
  Paginated,
} from '../utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

interface SigninAttemptPayload {
  id: string;
}

interface StatusPayload {
  status: string;
}

interface ActionTypes {
  REQUEST_UPDATE_SIGNIN_ATTEMPT: string;
  LOADING_SIGNIN_ATTEMPTS: string;
  COMMIT_SIGNIN_ATTEMPTS: string;
  COMMIT_SIGNIN_ATTEMPT: string;
  ERROR_SIGNIN_ATTEMPTS: string;
}

interface ActionCreators {
  requestUpdateSigninAttempt: (
    payload: Partial<SigninAttempt>,
  ) => MyAction<Partial<SigninAttempt>>;
  loadingSigninAttempts: () => Action;
  commitSigninAttempts: (
    payload: Paginated<SigninAttempt>,
  ) => MyAction<Paginated<SigninAttempt>>;
  commitSigninAttempt: (payload: SigninAttempt) => MyAction<SigninAttempt>;
  errorSigninAttempts: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface SigninAttemptsState<TError extends Error = Error>
  extends EntityState<SigninAttempt> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdateSigninAttempt: ['payload'],
  loadingSigninAttempts: [],
  commitSigninAttempts: ['payload'],
  commitSigninAttempt: ['payload'],
  errorSigninAttempts: ['error'],
});

const entityAdapter = createEntityAdapter<SigninAttempt>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});
const initialState = entityAdapter.getInitialState({
  loading: false,
});
export const SigninAttemptSelectors = entityAdapter.getSelectors();

function setLoading(state: SigninAttemptsState): SigninAttemptsState {
  return {
    ...state,
    loading: true,
  };
}

function commitSigninAttempt(
  state: SigninAttemptsState,
  action: MyAction<SigninAttempt>,
): SigninAttemptsState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function commitSigninAttempts(
  state: SigninAttemptsState,
  action: MyAction<Paginated<SigninAttempt>>,
): SigninAttemptsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: SigninAttemptsState,
  { error }: ErrorAction<TError>,
): SigninAttemptsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const singninAttemptReducer = createReducer(initialState, {
  [Types.LOADING_SIGNIN_ATTEMPTS]: setLoading,
  [Types.COMMIT_SIGNIN_ATTEMPT]: commitSigninAttempt,
  [Types.COMMIT_SIGNIN_ATTEMPTS]: commitSigninAttempts,
  [Types.ERROR_SIGNIN_ATTEMPTS]: setError,
});

async function downloadSigninAttempt({
  headers,
}: ArgsWithHeaders<LocationChangeActionPayload>): Promise<SigninAttempt> {
  const result = await fetch(`${API_URL}/signin-attempts?__sort=-updatedAt`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

async function requestUpdateSigninAttempt({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<SigninAttempt>>): Promise<void> {
  const result = await fetch(`${API_URL}/signin-attempts/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestSigninAttemptWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  SigninAttempt,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/signin-attempt/'),
  loadingAction: Creators.loadingSigninAttempts,
  commitAction: Creators.commitSigninAttempt,
  successAction: noOpAction,
  errorAction: Creators.errorSigninAttempts,
  action: downloadSigninAttempt,
  beforeAction: putAuthInfoInArgs,
});

const requestSigninAttemptsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<SigninAttempt>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/signin-attempts'),
  loadingAction: Creators.loadingSigninAttempts,
  commitAction: Creators.commitSigninAttempts,
  successAction: noOpAction,
  errorAction: Creators.errorSigninAttempts,
  action: downloadUsingLocationQuery<SigninAttempt>('signin-attempts'),
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateSigninAttemptWatcher = createSingleEventSaga<
  Partial<SigninAttempt>,
  Partial<SigninAttempt>,
  MyAction<Partial<SigninAttempt>>
>({
  takeEvery: Types.REQUEST_UPDATE_SIGNIN_ATTEMPT,
  loadingAction: Creators.loadingSigninAttempts,
  commitAction: Creators.commitSigninAttempt,
  successAction: noOpAction,
  errorAction: Creators.errorSigninAttempts,
  action: requestUpdateSigninAttempt,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<SigninAttempt>,
  ): SagaIterator {
    return args;
  },
});

export const signinAttemptSagas = [
  requestSigninAttemptWatcher,
  requestSigninAttemptsWatcher,
  requestUpdateSigninAttemptWatcher,
];

export function signinAttemptFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    content: true,
    userType: true,
  };

  return Promise.resolve(result);
}
