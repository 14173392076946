import { useSelector } from 'react-redux';
import { doc, setDoc } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

import { Rating } from 'typings';
import MMDModal from 'components/MMDModal';
import MMDAvatar from 'components/MMDAvatar';
import MMDButton from 'components/MMDButton';
import MMDLoader from 'components/MMDLoader';
import MMDDropdown from 'components/MMDDropdown';
import MMDTable from 'components/forms/MMDTable';
import { ModalState } from 'components/provider/types';
import { getExtension } from 'utils/getExtensionFromB64';
import MMDTextInput from 'components/forms/MMDTextInput';
import ProviderForm from 'components/provider/providerForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { APPROVED, REJECTED, USER_TYPE } from 'utils/constants';
import { ActionsProvider } from 'components/provider/ActionProvider';
import { ActionsProviderModal } from 'components/provider/ActionsProviderModal';
import {
  selectProviderById,
  selectProvidersLoading,
  Creators as ProvidersActions,
} from 'modules/providers.module';
import {
  selectSpecialities,
  selectSpecialitiesState,
} from 'modules/specialities.module';
import {
  selectAdminToolsState,
  Creators as AdminToolsActions,
} from 'modules/adminTools.module';
import {
  db,
  getFirebaseImage,
  uploadImageHelper,
  deleteProviderImage,
} from 'utils/Firebase';

const Provider = () => {
  const [providerImg, setProviderImg] = useState<string>();
  const [signatureImg, setSignatureImg] = useState<string>();
  const [providerIdImg, setProviderIdImg] = useState<string>();
  const [providerIdBack, setProviderIdBack] = useState<string>();
  const [documentImage, setDocumentImage] = useState<File>();
  const [documentBackImage, setDocumentBackImage] = useState<File>();
  const [motive, setMotive] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({ visible: false });

  const [showManualPaymentModal, setShowManualPaymentModal] = useState(false);
  const [amountToPay, setAmountToPay] = useState(null);
  const [manualPaymentNeedsConfirmation, confirmManualPayment] =
    useState(false);

  const params = useParams<{ id: string }>();

  const provider = useSelector((state) => selectProviderById(state, params.id));

  const providerTeacher = useSelector((state) =>
    selectProviderById(state, provider?.teacherId),
  );

  const loading = useSelector(selectProvidersLoading);

  const specialities = useSelector(selectSpecialities);

  const { loading: isSpecialitiesLoading } = useSelector(
    selectSpecialitiesState,
  );

  const adminTools = useSelector(selectAdminToolsState);

  const {
    requestProvider,
    payProvider,
    requestUpdateProvider,
    requestNotifyUpdateProvider,
    requestStudentUnlink,
  } = useBoundedActions({
    ...ProvidersActions,
    ...AdminToolsActions,
  });

  const getProviderImages = useCallback(async () => {
    if (!provider) {
      return;
    }

    const providerImg = await getFirebaseImage(`profile/${provider.id}.jpeg`);
    setProviderImg(providerImg);
    const signatureImg = await getFirebaseImage(
      provider.signature || `signatures/${provider.id}.png`,
    );
    setSignatureImg(signatureImg);
    const providerIdImg = await getFirebaseImage(
      provider.licenseFront || `licenses/${provider.id}.jpg`,
    );
    setProviderIdImg(providerIdImg);

    if (provider.licenseBack) {
      const providerIdBack = await getFirebaseImage(provider.licenseBack);
      setProviderIdBack(providerIdBack);
    }
  }, [provider]);

  useEffect(() => {
    if (provider) {
      getProviderImages();
    }

    if (provider?.teacherId && !providerTeacher) {
      requestProvider({ id: provider.teacherId });
    }
  }, [providerTeacher, provider, getProviderImages]);

  const updateProviderHandler = useCallback(
    (e, diff) => {
      e.preventDefault();
      requestUpdateProvider({ id: provider.id, ...diff });
    },
    [provider, requestUpdateProvider],
  );

  if (!provider) {
    return null;
  }

  return (
    <div className="container">
      <MMDLoader
        loading={
          loading || adminTools.notifyUpdateProvider.loading || uploadingImage
        }
      />
      <div className="columns is-vcentered">
        <div className="columns is-one-fifth">
          <div className="column">
            <MMDAvatar
              image={providerImg}
              label="Image"
              alt={'No image Available'}
            />
          </div>
        </div>
        {providerImg && (
          <div className="column is-one-fifth">
            {!provider.avatarApproved ? (
              <MMDButton
                className="mt-2"
                text="Approve Image"
                isSuccess
                onClick={(e) => {
                  updateProviderHandler(e, {
                    avatarApproved: APPROVED,
                  });
                }}
              />
            ) : (
              <MMDButton
                className="mt-2"
                text="Reject Image"
                isDanger
                onClick={(e) => {
                  updateProviderHandler(e, {
                    avatarApproved: REJECTED,
                  });
                }}
              />
            )}
            <MMDButton
              className="mt-2"
              text="Delete Image"
              isDanger
              onClick={(e) => {
                e.preventDefault();
                deleteProviderImage(`profile/${provider.id}.jpeg`);
                setProviderImg(null);
              }}
            />
          </div>
        )}
        {!provider.teacherId && (
          <div className="column">
            <img height="128" src={signatureImg} alt="Doctor signature" />
          </div>
        )}
        {!provider.teacherId && (
          <div className="column">
            <img
              height="128"
              src={providerIdImg}
              alt="Provider document front"
            />
            <input
              className="mt-2"
              name="front"
              type="file"
              multiple={false}
              onChange={(e) => setDocumentImage(e.target.files.item(0))}
            />
            <MMDButton
              className="mt-2"
              text="Upload new document front"
              isSuccess
              isDisabled={!documentImage}
              onClick={async () => {
                const path = `licenses/${provider.id}.front${getExtension(
                  documentImage.type,
                )}`;
                await setUploadingImage(true);
                await uploadImageHelper(path, documentImage);
                await setDoc(
                  doc(db, 'users', provider.id),
                  { licenseFront: path },
                  { merge: true },
                );

                await getProviderImages();
                await setUploadingImage(false);

                const uploaded = await getFirebaseImage(path);
                setProviderIdImg(uploaded);
                setDocumentImage(undefined);
              }}
            />

            {providerIdBack ? (
              <img
                className="mt-2"
                height="128"
                src={providerIdBack}
                alt="Provider document back"
              />
            ) : null}
            <input
              className="mt-2"
              name="back"
              multiple={false}
              type="file"
              onChange={(e) => setDocumentBackImage(e.target.files.item(0))}
            />
            <MMDButton
              className="mt-2"
              text="Upload new document back"
              isSuccess
              isDisabled={!documentBackImage}
              onClick={async () => {
                const path = `licenses/${provider.id}.back${getExtension(
                  documentBackImage.type,
                )}`;
                await setUploadingImage(true);
                await uploadImageHelper(path, documentBackImage);
                await setDoc(
                  doc(db, 'users', provider.id),
                  { licenseBack: path },
                  { merge: true },
                );
                await getProviderImages();
                await setUploadingImage(false);

                const uploaded = await getFirebaseImage(path);
                setProviderIdBack(uploaded);
                setDocumentBackImage(undefined);
              }}
            />
          </div>
        )}
        <div className="column">
          {!provider.teacherId && provider && provider.background && (
            <p>
              The background check came back with a{' '}
              <strong>
                {provider.background.status
                  ? provider.background.status
                  : 'No info'}{' '}
              </strong>{' '}
              result
            </p>
          )}
          {!provider.teacherId && (
            <p>
              At the moment the provider is <strong>{provider.status}</strong>{' '}
              by the admin.
            </p>
          )}
          {provider && (
            <ActionsProvider
              provider={provider}
              providerTeacher={providerTeacher}
              requestStudentUnlink={requestStudentUnlink}
              updateProviderHandler={updateProviderHandler}
              setModalState={setModalState}
            />
          )}
        </div>
        <div className="column">
          <MMDButton
            className="mt-2"
            text={`Notify ${provider?.teacherId ? 'Student' : 'Provider'}`}
            isSuccess
            onClick={(e) => {
              setModalState({
                visible: true,
                action: { type: 'notifiy', to: USER_TYPE.PROVIDER },
              });
            }}
          />

          <Link to={`/providers/${provider.email}/sessions`}>
            <MMDButton className="mt-2" text="Sessions" isSuccess />
          </Link>

          {!provider.teacherId && (
            <>
              <Link to={`/providers/${provider.id}/transactions`}>
                <MMDButton className="mt-2" text="Transactions" isSuccess />
              </Link>

              <i className="fas fa-search"></i>

              <MMDDropdown
                className="mb-2 mt-2"
                title="Bookings"
                items={[
                  {
                    text: 'Common',
                    href: `/providers/${provider.id}/bookings`,
                  },
                  {
                    text: 'Public',
                    href: `/providers/${provider.id}/bookings-public`,
                  },
                ]}
              />

              <MMDButton
                className="mt-2"
                text="Manual payment"
                isSuccess
                isDisabled={!provider.squareCustomerId}
                onClick={() => {
                  setShowManualPaymentModal(true);
                }}
              />

              <Link to={`/providers/${provider.id}/payment-transactions`}>
                <MMDButton
                  className="mt-2"
                  text="Payment Transactions"
                  isSuccess
                />
              </Link>

              <Link to={`/providers/${provider.id}/licensed-states`}>
                <MMDButton className="mt-2" text="Licensed states" isSuccess />
              </Link>

              <Link to={`/providers/${provider.id}/availability`}>
                <MMDButton className="mt-2" text="Availability" isSuccess />
              </Link>
            </>
          )}
        </div>
        {provider.background && !provider.teacherId && (
          <div className="column is-two-fifth">
            <h6 className="has-text-weight-bold">Provider Background:</h6>
            <ul>
              <li>
                {' '}
                - Status:{' '}
                {provider.background.status
                  ? provider.background.status
                  : 'No info.'}
              </li>
              <li>
                {' '}
                - CandidateId:{' '}
                {provider.background.candidateId
                  ? provider.background.candidateId
                  : 'No info.'}
              </li>
              <li>
                {' '}
                - Due at: {provider.background.dueAt?.toString() ?? 'No info.'}
              </li>
            </ul>
          </div>
        )}
      </div>
      {provider.profile && !provider.teacherId && (
        <div className="columns is-multiline is-vcentered">
          <div className="column is-full">
            <h5 className="has-text-weight-bold">Education: </h5>
            {provider.profile.education
              ? provider.profile.education
              : 'No info'}
          </div>
          <div className="column is-full">
            <h5 className="has-text-weight-bold">Awards: </h5>
            {provider.profile.awards ? provider.profile.awards : 'No info'}
          </div>
          <div className="column is-full">
            <h5 className="has-text-weight-bold">Experience: </h5>
            {provider.profile.experience
              ? provider.profile.experience
              : 'No info'}
          </div>
          <div className="column">
            <h5 className="has-text-weight-bold">Affiliations: </h5>
            {provider.profile &&
            provider.profile.affiliations &&
            provider.profile.affiliations.length
              ? provider.profile.affiliations.join(', ')
              : 'No info'}
          </div>
        </div>
      )}
      {!provider.teacherId &&
        provider.ratings !== undefined &&
        provider.ratings.length > 0 && (
          <div className="columns">
            <div className="column is-two-fifth">
              <h6 className="has-text-weight-bold">Feedback:</h6>
              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <td>Patient name</td>
                    <td>Date</td>
                    <td>Rating</td>
                    <td>Comments</td>
                  </tr>
                </thead>
                <tbody>
                  {provider.ratings.map((item: Rating) => (
                    <tr key={item.startDate}>
                      <td>{item.raterName}</td>
                      <td>
                        {item.startDate &&
                          item.startDate.length > 0 &&
                          item.startDate}
                      </td>
                      <td>{item.rating || 'Not rating'}</td>
                      <td>{item.comment || 'Not comment'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

      <div className="columns">
        <div className="column is-full">
          <div className="column">
            <h5 className="has-text-weight-bold">Timezone: </h5>
            {provider.timeZone ?? 'No info'}
          </div>

          <ProviderForm
            ready={!loading}
            provider={provider}
            specialities={specialities}
            isSpecialitiesLoading={isSpecialitiesLoading}
          />
        </div>
      </div>
      {!provider.teacherId && (
        <div className="columns">
          <div className="column is-full">
            <MMDTable
              titles={['Description', 'Plan type', 'Created at', 'Updated at']}
              label="Custom Price Requests"
              dataTable={provider.customPrices}
              isCustomPrice
            />
          </div>
        </div>
      )}

      <ActionsProviderModal
        provider={provider}
        modalState={modalState}
        loading={loading}
        setModalState={setModalState}
        setMotive={setMotive}
        updateProviderHandler={updateProviderHandler}
        requestNotifyUpdateProvider={requestNotifyUpdateProvider}
        motive={motive}
      />
      <MMDModal
        visible={showManualPaymentModal}
        onRequestClose={() => {
          setShowManualPaymentModal(false);
        }}
      >
        {!manualPaymentNeedsConfirmation && (
          <MMDTextInput
            label="Enter the amount of money to pay to the doctor"
            value={amountToPay}
            type="number"
            onInput={(evt) => {
              const value = evt.currentTarget.value.replace(/\+|-/gi, '');
              try {
                if (parseFloat(value)) {
                  setAmountToPay(value);
                }
                if ('' === evt.currentTarget.value) setAmountToPay(null);
              } catch (e) {
                setAmountToPay(null);
              }
            }}
          />
        )}
        {manualPaymentNeedsConfirmation && (
          <div>
            <h4>Are you sure you want to pay ${amountToPay} to the doctor?</h4>
          </div>
        )}
        <div className="columns">
          <div className="column is-2">
            <MMDButton
              text={!manualPaymentNeedsConfirmation ? 'Next' : 'Confirm'}
              isSuccess
              onClick={(e) => {
                if (!manualPaymentNeedsConfirmation) {
                  confirmManualPayment(true);
                } else {
                  setShowManualPaymentModal(false);
                  payProvider({
                    id: provider.id,
                    amount: amountToPay,
                    direct: true,
                  });
                  confirmManualPayment(false);
                  setAmountToPay(null);
                }
              }}
            />
          </div>
          <div className="column">
            <MMDButton
              text="Cancel"
              isDanger
              onClick={(e) => {
                if (!manualPaymentNeedsConfirmation) {
                  setShowManualPaymentModal(false);
                } else {
                  confirmManualPayment(false);
                }
                setAmountToPay(null);
              }}
            />
          </div>
        </div>
      </MMDModal>
    </div>
  );
};

export default Provider;
