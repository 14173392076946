import MMDForm from '../forms/MMDForm';
import MMDTextInput from '../forms/MMDTextInput';
import MMDButton from '../MMDButton';
import { Form } from '@mrnkr/redux-saga-toolbox';
import {
  Creators as NotificationsActions,
  notificationFormValidator,
} from '../../modules/notifications.module';
import { Notification } from '../../typings';
import { Heading } from 'react-bulma-components';
import { FC, FormEvent, useCallback } from 'react';

type PropTypes = {
  notification: Notification;
};

export const NotificationForm: FC<PropTypes> = ({ notification }) => {
  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['title'].name}
          value={form.fields['title'].value}
          label="Title"
          valid={form.fields['title'].valid}
          dirty={form.fields['title'].dirty}
          placeholder="notification Title"
          onChange={onChange}
          required={true}
        />
        <MMDTextInput
          name={form.fields['body'].name}
          label="Body"
          valid={form.fields['body'].valid}
          dirty={form.fields['body'].dirty}
          placeholder="Notification body"
          value={form.fields['body'].value}
          onChange={onChange}
          required={true}
        />
        <MMDButton className="mt-2" isSubmit isPrimary text="Update" />
      </div>
    ),
    [],
  );

  const formConfig = {
    formName: `update-notification-form`,
    fields: {
      id: notification.id,
      name: notification.name,
      title: notification.title,
      description: notification.description,
      body: notification.body,
      createdAt: notification.createdAt,
      updatedAt: notification.updatedAt,
    },
    validator: notificationFormValidator,
    onSubmit: (values) =>
      NotificationsActions.requestUpdateNotification({
        id: values['id'],
        name: values['name'],
        title: values['title'],
        description: values['description'],
        body: values['body'],
        updatedAt: values['updatedAt'],
        createdAt: values['createdAt'],
      }),
  };

  return (
    <>
      {/*// @ts-ignore*/}
      <Heading title size={6} renderAs="h4" className="mb-4 ml-2">
        Notification Form - <small>ID: {notification.id}</small>
      </Heading>
      <Heading subtitle size={6} renderAs="h5" className="mt-4 ml-2">
        Notification Description:
      </Heading>
      <Heading subtitle size={6} renderAs="h5" className="mt-2 ml-2">
        {notification.description}
      </Heading>
      <MMDForm {...({ renderFormControls, formConfig } as any)} />
    </>
  );
};

export default NotificationForm;
