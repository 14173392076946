import { Action } from 'redux';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { onRoute } from 'utils/onRoute';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { LocationChangeActionPayload, Paginated } from 'utils/typings';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';

export interface AdminLog {
  id: string;
  createdAt: string;
  endpoint: string;
  firstName: string;
  ipAddress: string;
  lastName: string;
  method: string;
  requestBody: object;
  requestParams: object;
  updatedAt: string;
  userAgent: string;
  userId: string;
}

interface ActionTypes {
  LOADING_ADMIN_LOGS: string;
  COMMIT_ADMIN_LOGS: string;
  ERROR_ADMIN_LOGS: string;
}

interface ActionCreators {
  loadingAdminLogs: () => Action;
  commitAdminLogs: (
    payload: Paginated<AdminLog>,
  ) => MyAction<Paginated<AdminLog>>;
  errorAdminLogs: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export interface AdminLogsState<TError extends Error = Error>
  extends EntityState<AdminLog> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  loadingAdminLogs: [],
  commitAdminLogs: ['payload'],
  errorAdminLogs: ['error'],
});

const entityAdapter = createEntityAdapter<AdminLog>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const adminLogsSelector = entityAdapter.getSelectors();

function setLoading(state: AdminLogsState): AdminLogsState {
  return {
    ...state,
    loading: true,
  };
}

function commitAdminLogs(
  state: AdminLogsState,
  action: MyAction<Paginated<AdminLog>>,
): AdminLogsState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: AdminLogsState,
  { error }: ErrorAction<TError>,
): AdminLogsState {
  return {
    ...state,
    error,
    loading: false,
  };
}

export const adminLogsReducer = createReducer(initialState, {
  [Types.LOADING_ADMIN_LOGS]: setLoading,
  [Types.COMMIT_ADMIN_LOGS]: commitAdminLogs,
  [Types.ERROR_ADMIN_LOGS]: setError,
});

const requestAdminLogsWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<AdminLog>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/admin-logs'),
  loadingAction: Creators.loadingAdminLogs,
  commitAction: Creators.commitAdminLogs,
  successAction: noOpAction,
  errorAction: Creators.errorAdminLogs,
  action: downloadUsingLocationQuery<AdminLog>('logs/admin-logs'),
  beforeAction: putAuthInfoInArgs,
});

export const adminLogsSagas = [requestAdminLogsWatcher];

export const selectAdminLogsState = (state: MyState) => state.adminLogs;

export const selectAdminLogs = createSelector(selectAdminLogsState, (state) =>
  adminLogsSelector.selectAll(state),
);
