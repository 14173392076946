import React, { Fragment } from 'react';
import profileAvatar from '../assets/profileAvatar.png';

export const MMDAvatar = ({
  image,
  label,
  alt,
}: {
  image?: any;
  label?: string;
  alt?: string;
}) => {
  const profileImage = image || profileAvatar;

  return (
    <Fragment>
      {label && <label className="label mt-2">{label}</label>}
      <figure className="image is-128x128">
        <img
          className="is-rounded"
          style={{ height: 128, width: 128, objectFit: 'cover' }}
          src={profileImage}
          alt={alt}
        />
      </figure>
    </Fragment>
  );
};

export default MMDAvatar;
