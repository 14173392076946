import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Organization } from 'typings';
import OrganizationRow from './OrganizationRow';
import EntitiesTable from 'components/entities-table';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectOrganizations,
  selectOrganizationsState,
  Creators as OrganizationsActions,
} from 'modules/organizations.module';

const Organizations: FC = () => {
  const { count, loading } = useSelector(selectOrganizationsState);

  const organizations = useSelector(selectOrganizations);

  const { requestRemoveOrganization } = useBoundedActions(OrganizationsActions);

  const getOnDeleteOrganization = (id: number) => () =>
    requestRemoveOrganization({ id });

  return (
    <EntitiesTable
      count={count}
      loading={loading}
      withoutApproveFilter
      rootPath="organizations"
      newEntityUrl="create-organization"
      elements={organizations.map((item: Organization) => (
        <OrganizationRow
          key={item.id}
          organization={item}
          targetPath="organizations"
          onDelete={getOnDeleteOrganization(item.id)}
        />
      ))}
    />
  );
};

export default Organizations;
