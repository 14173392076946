import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { QuestionnaireInList } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

type Props = {
  previewPath: string;
  questionnaire: QuestionnaireInList;
  requestDelete: () => void;
};

const QuestionnaireRow: FC<Props> = ({
  questionnaire,
  previewPath,
  requestDelete,
}) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>{questionnaire.name}</strong>
      </Media.Item>
    </Media>
    <Media.Item>Description: {questionnaire.description}</Media.Item>
    <Columns>
      <Columns.Column size={1} className="mr-3">
        <Link to={`/${previewPath}/${questionnaire.id}`}>
          <MMDSuperAdminButton className="mt-2" isPrimary text="Preview" />
        </Link>
      </Columns.Column>
      <Columns.Column size={1}>
        <MMDSuperAdminButton
          isPrimary
          text="Delete"
          className="mt-2"
          onClick={requestDelete}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default QuestionnaireRow;
