import React from 'react';
import Pagination from 'bulma-pagination-react';
import { PAGE_LEN } from '../utils/constants';

type Props = {
  itemCount: number;
  currentOffset: string;
  onChange: (nextOffset: number) => void;
};

const MMDPager: React.FC<Props> = ({ itemCount, currentOffset, onChange }) => {
  const offset = parseInt(currentOffset, 10);
  const currentPage = !isNaN(offset) ? offset / PAGE_LEN + 1 : 1;
  const pages = Math.ceil(itemCount / PAGE_LEN);

  const onPageChange = React.useCallback(
    (page: number) => {
      if (page <= pages && page > 0) {
        onChange((page - 1) * PAGE_LEN);
      }
    },
    [onChange, pages],
  );

  return (
    <div className="container p-2">
      <Pagination
        pages={pages}
        currentPage={currentPage}
        onChange={onPageChange}
      />
    </div>
  );
};

export default React.memo(MMDPager);
