import { ChangeEvent, FC } from 'react';

import MMDButton from './MMDButton';
import MMDTextInput from './forms/MMDTextInput';
import { Level } from 'react-bulma-components';

type Prop = {
  initialValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
};

const MMDSearch: FC<Prop> = ({ onChange, onSearch, initialValue }) => (
  <div className="is-flex is-align-items-center mr-4 ml-4">
    <Level.Item className="mb-0 mr-2">
      <MMDTextInput
        value={initialValue}
        label=""
        placeholder="search"
        onChange={onChange}
      />
    </Level.Item>
    <Level.Item>
      <MMDButton isSubmit isPrimary text="Search" onClick={onSearch} />
    </Level.Item>
  </div>
);

export default MMDSearch;
