import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';

import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import {
  UserFields,
  selectUserInvitationLoading,
} from 'modules/user-invitation.module';
import { FORM_FIELDS_NAMES, validatePassword } from './config';
import { PASSWORD_REG_EXP, PHONE_REG_EXP } from 'utils/constants';

type Props = {
  onSubmit: (formFields: Omit<UserFields, 'email'>) => void;
};

const RegisterUserForm: FC<Props> = ({ onSubmit }) => {
  const isInvitationRequestLoading = useSelector(selectUserInvitationLoading);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [FORM_FIELDS_NAMES.FIRST_NAME]: '',
      [FORM_FIELDS_NAMES.LAST_NAME]: '',
      [FORM_FIELDS_NAMES.JOB_TITLE]: '',
      [FORM_FIELDS_NAMES.PHONE]: '',
      [FORM_FIELDS_NAMES.PASSWORD]: '',
    },
  });

  const hasFormErrors = Object.keys(errors).length > 0;

  const registerWithMask = useHookFormMask(register);

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <MMDTextInput
        required
        label="First Name"
        valid={!errors[FORM_FIELDS_NAMES.FIRST_NAME]}
        {...register(FORM_FIELDS_NAMES.FIRST_NAME, {
          required: true,
        })}
      />

      <MMDTextInput
        required
        label="Last Name"
        valid={!errors[FORM_FIELDS_NAMES.LAST_NAME]}
        {...register(FORM_FIELDS_NAMES.LAST_NAME, {
          required: true,
        })}
      />

      <MMDTextInput
        label="Job Title"
        {...register(FORM_FIELDS_NAMES.JOB_TITLE)}
      />

      <MMDTextInput
        required
        label="Phone"
        valid={!errors[FORM_FIELDS_NAMES.PHONE]}
        {...registerWithMask(FORM_FIELDS_NAMES.PHONE, ['(###) ###-####'], {
          pattern: PHONE_REG_EXP,
          required: true,
        })}
      />

      <MMDTextInput
        required
        type="password"
        label="Password"
        valid={!errors[FORM_FIELDS_NAMES.PASSWORD]}
        error={validatePassword(watch(FORM_FIELDS_NAMES.PASSWORD))}
        {...register(FORM_FIELDS_NAMES.PASSWORD, {
          pattern: PASSWORD_REG_EXP,
          required: true,
        })}
      />

      <MMDButton
        isSubmit
        isPrimary
        text="Register"
        isDisabled={hasFormErrors || isInvitationRequestLoading}
      />
    </form>
  );
};

export default RegisterUserForm;
