import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { Immunization } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

type Props = {
  targetPath: string;
  onDelete: () => void;
  immunization: Immunization;
};

const ImmunizationRow: FC<Props> = ({ immunization, targetPath, onDelete }) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>{immunization.name}</strong>

        {immunization.description && (
          <p>Description: {immunization.description}</p>
        )}

        <p>Number of documents: {immunization.numberOfRequiredDocuments}</p>
      </Media.Item>
    </Media>

    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${immunization.id}`}>
          <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
        </Link>
      </Columns.Column>

      <Columns.Column size={1}>
        <MMDSuperAdminButton
          isPrimary
          text="Delete"
          className="mt-2"
          onClick={onDelete}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default ImmunizationRow;
