import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  User,
  selectUsers,
  selectUsersState,
  Creators as UsersActions,
} from 'modules/users.module';
import UsersRow from './users/UsersRow';
import MMDModal from 'components/MMDModal';
import MMDButton from 'components/MMDButton';
import EntitiesTable from 'components/entities-table';
import { useBoundedActions } from 'hooks/useBoundedActions';

const Users = () => {
  const users = useSelector(selectUsers);

  const { count, loading } = useSelector(selectUsersState);

  const { requestDeleteUser } = useBoundedActions(UsersActions);

  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] =
    useState(false);

  const [useIdToDelete, setUserIdToDelete] = useState(null);

  const onOpenConfirmModal = () => setIsConfirmDeleteModalVisible(true);

  const onCloseConfirmModal = () => setIsConfirmDeleteModalVisible(false);

  const onConfirmDeleteUser = () => {
    onCloseConfirmModal();
    requestDeleteUser({ id: useIdToDelete });
    setUserIdToDelete(null);
  };

  const onCancelUserDelete = () => {
    onCloseConfirmModal();
    setUserIdToDelete(null);
  };

  const getOnDeleteUser = (userId: string) => () => {
    setUserIdToDelete(userId);
    onOpenConfirmModal();
  };

  return (
    <>
      <MMDModal visible={isConfirmDeleteModalVisible}>
        <div className="has-text-centered">
          <big>Delete this user ?</big>
        </div>

        <div className="flex is-justify-content-space-evenly mt-4">
          <MMDButton
            text="Cancel"
            isPrimary={false}
            onClick={onCancelUserDelete}
          />

          <MMDButton text="Delete" isDanger onClick={onConfirmDeleteUser} />
        </div>
      </MMDModal>

      <EntitiesTable
        count={count}
        loading={loading}
        rootPath="users"
        withoutApproveFilter
        newEntityUrl="invite-user"
        elements={users
          .filter((item) => !!item)
          .map((item: User) => (
            <UsersRow
              key={item.id}
              user={item}
              targetPath="users"
              onDelete={getOnDeleteUser(item.id)}
            />
          ))}
      />
    </>
  );
};

export default Users;
