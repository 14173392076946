import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import MMDTextInput from 'components/forms/MMDTextInput';

const LinkBlock = forwardRef<
  HTMLInputElement,
  UseFormRegisterReturn & { readOnly: boolean; error?: string }
>((props, ref) => {
  return (
    <MMDTextInput
      required
      label="Link Block"
      placeholder="Enter link..."
      {...props}
      valid={!props.error}
      ref={ref}
    />
  );
});

LinkBlock.displayName = 'LinkBlock';

export default LinkBlock;
