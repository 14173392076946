import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Level } from 'react-bulma-components';
import { push as reduxPush } from 'redux-first-history';

import { useToggle } from 'hooks/useToggle';
import MMDLoader from 'components/MMDLoader';
import MMDButton from 'components/MMDButton';
import PatientConsentRow from './PatientConsentRow';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';
import {
  selectPatientConsents,
  selectPatientConsentState,
  Creators as PatientConsentActions,
} from 'modules/patient-consent.module';

const PatientConsent = () => {
  const isSuperAdmin = useIsSuperAdminCheck();

  const { loading } = useSelector(selectPatientConsentState);

  const patientConsents = useSelector(selectPatientConsents);

  const { push, requestUpdatePatientConsent } = useBoundedActions({
    push: reduxPush,
    ...PatientConsentActions,
  });

  const params = useParams<{ sendLive?: string }>();

  const [liveFilter, toggleLiveFilter] = useToggle(params.sendLive === 'true');

  const showOnlyNotLive = () => {
    const nextLiveFilter = !liveFilter;

    if (nextLiveFilter) {
      push(`patient-consent?sendLive=${nextLiveFilter}`);
    } else {
      push('patient-consent');
    }
    toggleLiveFilter();
  };

  const onCreateNewPatientConsent = () => push('/create-patient-consent');

  const getOnSendLivePatientConsent = (id: number) => () => {
    requestUpdatePatientConsent({ id, sendLive: true });
    setTimeout(() => push('/patient-consent'), 0);
  };

  return (
    <div className="container p-2">
      <MMDLoader loading={loading} />

      <Level>
        <Level.Side align="left">
          <Level.Item>
            <MMDCheckbox
              name="admin-approval"
              onChange={showOnlyNotLive}
              label="Show in live patient consent"
              value={liveFilter ? 'true' : 'false'}
            />
          </Level.Item>
        </Level.Side>

        {isSuperAdmin && (
          <Level.Side align="right">
            <Level.Item>
              <MMDButton
                isPrimary
                text="New"
                onClick={onCreateNewPatientConsent}
              />
            </Level.Item>
          </Level.Side>
        )}
      </Level>

      {patientConsents.map((item) => (
        <PatientConsentRow
          key={item.id}
          patientConsent={item}
          onSendLive={getOnSendLivePatientConsent(item.id)}
        />
      ))}

      {!patientConsents.length && (
        <h2 className="has-text-centered has-text-weight-bold mt-6 ">
          No items
        </h2>
      )}
    </div>
  );
};

export default PatientConsent;
