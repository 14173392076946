import { connect } from 'react-redux';
import { FC, useCallback } from 'react';
import { push } from 'redux-first-history';
import { Level } from 'react-bulma-components';
import { bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';

import { MyState } from 'store';
import { PromoCode } from 'typings';
import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import PromoCodeRow from './promo-codes/PromoCodeRow';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  promoCodesSelectors,
  Creators as PromoCodesActions,
} from 'modules/promo-code.module';

type Props = {
  promoCodes?: PromoCode[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof PromoCodesActions;

const PromoCodes: FC<Props> = ({
  push,
  count,
  loading,
  promoCodes,
  routeParams,
  requestRemovePromoCode,
}) => {
  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`promo-codes${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const goToCreatePromoCode = useCallback(() => {
    push('/create-promo-code');
  }, [push]);

  const requestDeletePromoCode = useCallback(
    (id: number) => {
      requestRemovePromoCode({ id });
    },
    [requestRemovePromoCode],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="right">
            <Level.Item>
              <MMDSuperAdminButton
                text="New"
                isPrimary
                onClick={goToCreatePromoCode}
              />
            </Level.Item>
          </Level.Side>
        </Level>
        {promoCodes.map((item: PromoCode) => (
          <PromoCodeRow
            key={item.id}
            promoCode={item}
            targetPath="promo-codes"
            onDeleteClick={requestDeletePromoCode}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  promoCodes: promoCodesSelectors.selectAll(state.promoCodes),
  loading: state.promoCodes.loading,
  count: state.promoCodes.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PromoCodesActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
