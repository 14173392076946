import {
  createSingleEventSaga,
  MyAction,
  ErrorAction,
  Dictionary,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions } from 'reduxsauce';

import { putAuthInfoInArgs } from '../auth.module';
import { API_URL } from '../../config';
import { ArgsWithHeaders } from '../../utils/typings';
import {
  goBackFactory,
  UNAUTHORIZED,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';
import { Child, SagaIterator } from '../../typings';

interface ActionTypes {
  REQUEST_UPDATE_CHILD: string;
  LOADING_CHILD: string;
  COMMIT_CHILD: string;
  ERROR_CHILD: string;
}

interface ActionCreators {
  requestUpdateChild: (payload: Child) => MyAction<Child>;
  loadingChild: () => Action;
  commitChild: (payload: Child) => MyAction<Child>;
  errorChild: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdateChild: ['payload'],
  loadingChild: [],
  commitChild: ['payload'],
  errorChild: ['error'],
});

async function updatePatient({
  headers,
  ...payload
}: ArgsWithHeaders<Child>): Promise<void> {
  const result = await fetch(
    `${API_URL}/patients/${payload.parentId}/children/${payload.id}`,
    {
      headers,
      method: 'PUT',
      body: JSON.stringify(payload),
    },
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const goBack = goBackFactory('/patients#childs');

const requestUpdateChildWatcher = createSingleEventSaga<
  Child,
  Child,
  MyAction<Child>
>({
  takeEvery: Types.REQUEST_UPDATE_CHILD,
  loadingAction: Creators.loadingChild,
  commitAction: Creators.commitChild,
  successAction: goBack.action,
  errorAction: Creators.errorChild,
  action: updatePatient,
  beforeAction: putAuthInfoInArgs,
  *afterAction(_, { headers, ...args }: ArgsWithHeaders<Child>): SagaIterator {
    return args;
  },
});

export const childSagas = [requestUpdateChildWatcher, goBack.watcher];

export function childrenFormValidator(
  values: Dictionary<string>,
): Promise<Dictionary<boolean>> {
  const result = {
    firstName: true,
    lastName: true,
    birthday: true,
    sex: true,
  };

  ['firstName', 'sex', 'lastName'].forEach((k) => {
    result[k] = !!values[k] && values[k].toString().length > 0;
  });

  return Promise.resolve(result);
}
