import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import ImmunizationForm from './ImmunizationForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { selectImmunizationById } from 'modules/immunizations.module';
import { Creators as ImmunizationsActions } from 'modules/immunizations.module';

const EditImmunization = () => {
  const { id } = useParams();

  const immunizationToEdit = useSelector((state) =>
    selectImmunizationById(state, id),
  );

  const { requestUpdateImmunization } = useBoundedActions(ImmunizationsActions);

  const onEditImmunization = (values) =>
    requestUpdateImmunization({ ...values, id });

  return (
    <div className="container p-2">
      <MMDLoader loading={!immunizationToEdit} />

      {immunizationToEdit && (
        <ImmunizationForm
          isEdit
          onSubmit={onEditImmunization}
          immunization={immunizationToEdit}
        />
      )}
    </div>
  );
};

export default EditImmunization;
