import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Columns, Media } from 'react-bulma-components';

import { Organization } from 'typings';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';

type Props = {
  targetPath: string;
  onDelete: () => void;
  organization: Organization;
};

const OrganizationRow: FC<Props> = ({ organization, targetPath, onDelete }) => (
  <Box>
    <Media>
      <Media.Item>
        <strong>{organization.name}</strong>

        {organization.type && <p>Type: {organization.type.name}</p>}
      </Media.Item>
    </Media>
    <Columns>
      <Columns.Column size={1}>
        <Link to={`/${targetPath}/${organization.id}`}>
          <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
        </Link>
      </Columns.Column>
      <Columns.Column size={1}>
        <MMDSuperAdminButton
          isPrimary
          text="Delete"
          className="mt-2"
          onClick={onDelete}
        />
      </Columns.Column>
    </Columns>
  </Box>
);

export default OrganizationRow;
