import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push as reduxPush } from 'redux-first-history';

import MMDPager from 'components/MMDPager';
import MMDLoader from 'components/MMDLoader';
import BookingRow from 'pages/bookings/BookingRow';
import { useBoundedActions } from 'hooks/useBoundedActions';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import { selectBookings, selectBookingsState } from 'modules/bookings.module';

export const Bookings: FC = () => {
  const location = useLocation();

  const routeParams = paramsToDictionary(location.search);

  const bookings = useSelector(selectBookings);

  const { count, loading } = useSelector(selectBookingsState);

  const { push } = useBoundedActions({
    push: reduxPush,
  });

  const gotoPage = useCallback(
    (nextOffset: number) =>
      push(`bookings${prepareQueryWithOffset(nextOffset, routeParams)}`),
    [push, routeParams],
  );

  const isNoBookings = !bookings?.length;

  return (
    <>
      <MMDLoader loading={loading} />

      <div className="container p-2">
        {isNoBookings && <span>No bookings</span>}

        {bookings.map((booking) => (
          <BookingRow key={booking.id} booking={booking} />
        ))}
      </div>

      {!isNoBookings && (
        <MMDPager
          itemCount={count}
          onChange={gotoPage}
          currentOffset={routeParams.__offset}
        />
      )}
    </>
  );
};

export default Bookings;
