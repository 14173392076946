import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Immunization } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDSelect from 'components/forms/MMDSelect';
import QuestionnaireSelect from './QuestionnaireSelect';
import MMDTextInput from 'components/forms/MMDTextInput';
import { DOCUMENT_NUMBERS_OPTIONS, ImmunizationFormValues } from './config';

type Props = {
  isEdit?: boolean;
  immunization?: Immunization;
  onSubmit: (values: ImmunizationFormValues) => void;
};

const ImmunizationForm: FC<Props> = ({ isEdit, immunization, onSubmit }) => {
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ImmunizationFormValues>({
    defaultValues: {
      name: immunization?.name ?? '',
      description: immunization?.description ?? '',
      numberOfRequiredDocuments:
        immunization?.numberOfRequiredDocuments?.toString() ??
        DOCUMENT_NUMBERS_OPTIONS[0].value,
      questionnaireId: immunization?.questionnaireId,
    },
  });

  const hasFormErrors = Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MMDTextInput
        required
        label="Name"
        valid={!errors.name}
        {...register('name', {
          required: true,
        })}
      />

      <MMDTextInput
        label="Description"
        valid={!errors.description}
        {...register('description')}
      />

      <MMDSelect
        required
        type="generic"
        name="numberOfRequiredDocuments"
        label="Documents number to confirm"
        value={watch('numberOfRequiredDocuments')}
        options={DOCUMENT_NUMBERS_OPTIONS}
        onExternalChange={(event) =>
          setValue('numberOfRequiredDocuments', event.target.value)
        }
      />

      <QuestionnaireSelect
        setValue={setValue}
        selectedQuestionnaireId={watch('questionnaireId')}
      />

      <MMDButton
        isSubmit
        isPrimary
        className="mt-3"
        isDisabled={hasFormErrors}
        text={isEdit ? 'Save changes' : 'Create'}
      />
    </form>
  );
};

export default ImmunizationForm;
