import {
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireBlock,
  QuestionnaireFormValue,
  QuestionnaireQuestionAnswerOption,
} from 'typings';

const getQuestionAnswerFromOptions = (
  options: QuestionnaireQuestionAnswerOption[],
): QuestionnaireAnswer => {
  const answer: QuestionnaireAnswer = { type: 'text' };

  if (options[0].type === 'radio') {
    answer['type'] = 'radio';
    options.forEach((option, index) => {
      answer[index + 1] = option.text;
    });
  }

  return answer;
};

export const mapQuestionnaireToPreview = (
  questionnaire: Questionnaire,
): QuestionnaireFormValue => {
  const blocks: QuestionnaireBlock[] = [];

  questionnaire.questions.forEach((question) => {
    blocks[question.order - 1] = {
      type: 'question',
      text: question.text,
      isRequired: question.isRequired,
      answer: getQuestionAnswerFromOptions(question.answerOptions),
    };
  });

  questionnaire.textBlocks.forEach((block) => {
    blocks[block.order - 1] = {
      type: 'text',
      text: block.content,
    };
  });

  questionnaire.linkBlocks.forEach((block) => {
    blocks[block.order - 1] = {
      type: 'link',
      text: block.url,
    };
  });

  return {
    name: questionnaire.name,
    description: questionnaire.description,
    blocks,
  };
};
