import MMDButton from 'components/MMDButton';
import { Dispatch, FC, SetStateAction } from 'react';
import { File } from 'typings';

export const Files: FC<{
  files: File[];
  onclick: (string: string) => Promise<any>;
  setLoadingFileFront: Dispatch<SetStateAction<boolean>>;
}> = ({ files, onclick, setLoadingFileFront }) => (
  <>
    {files.map((file) => (
      <MMDButton
        key={file.id}
        text={file.fileName}
        className="mt-2"
        isDanger
        onClick={async (e) => {
          setLoadingFileFront(true);
          const result = await onclick(
            `/bucket/${file.patientId}/${file.fileName}`,
          );
          setLoadingFileFront(false);
          window.open(result);
        }}
      />
    ))}
  </>
);
