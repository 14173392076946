import React, { FC, PropsWithChildren } from 'react';

type PropTypes = {
  onRequestClose?: () => void;
  visible: boolean;
  withoutClose?: boolean;
};

const MMDModal: FC<PropsWithChildren<PropTypes>> = ({
  children,
  onRequestClose = () => {},
  visible,
  withoutClose = false,
}) => (
  <div className={`modal ${visible ? 'is-active' : ''}`}>
    <div className="modal-background"></div>
    <div className="modal-content">
      <div className="box">{children}</div>
    </div>
    {!withoutClose && (
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onRequestClose}
      />
    )}
  </div>
);

export default MMDModal;
