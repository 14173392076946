import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import { Level } from 'react-bulma-components';
import { bindActionCreators, Dispatch } from 'redux';
import { Dictionary } from '@mrnkr/redux-saga-toolbox';
import { FC, FormEvent, useCallback, useState } from 'react';

import MMDLoader from 'components/MMDLoader';

import { MyState } from 'store';
import { Medication } from 'typings';
import MMDPager from 'components/MMDPager';
import MMDButton from 'components/MMDButton';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MedicationRow from './medications/MedicationRow';
import MMDTextInput from 'components/forms/MMDTextInput';
import { paramsToDictionary } from 'utils/paramsToDictionary';
import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { prepareQueryWithOffset } from 'utils/prepareUrlWithOffset';
import {
  medicationsSelectors,
  Creators as MedicationsActions,
} from 'modules/medication.module';

type Props = {
  medications?: Medication[];
  loading: boolean;
  count: number;
  routeParams: Dictionary<string>;
  push: typeof push;
} & typeof MedicationsActions;

const Medications: FC<Props> = ({
  push,
  count,
  loading,
  medications,
  routeParams,
  requestRemoveMedication,
}) => {
  const params = useParams<{ name__ire?: string; adminApproval?: string }>();

  const [approvalFilter, setApprovalFilter] = useState(
    params.adminApproval === 'true',
  );
  const [currentFilterValue, setCurrentFilterValue] = useState('');

  const onCurrentFilterChanged = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      setCurrentFilterValue(input.value);
    },
    [setCurrentFilterValue],
  );

  const applyFilter = useCallback(() => {
    push(`medications?name__ire=${currentFilterValue}`);
  }, [push, currentFilterValue]);

  const showOnlyNotApproved = useCallback(() => {
    const nextApprovalFilter = !approvalFilter;
    setApprovalFilter(nextApprovalFilter);
    push(`medications?adminApproval=${!nextApprovalFilter}`);
  }, [push, approvalFilter, setApprovalFilter]);

  const gotoPage = useCallback(
    (nextOffset: number) => {
      push(`medications${prepareQueryWithOffset(nextOffset, routeParams)}`);
    },
    [push, routeParams],
  );

  const goToCreateMedication = useCallback(() => {
    push('/create-medication');
  }, [push]);

  const requestDeleteMedication = useCallback(
    (id: number) => {
      requestRemoveMedication({ id });
    },
    [requestRemoveMedication],
  );

  return (
    <>
      <MMDLoader loading={loading} />
      <div className="container p-2">
        <Level>
          <Level.Side align="left">
            <Level.Item>
              <MMDTextInput
                name="filter-value"
                label="Filter value:"
                value={currentFilterValue}
                onChange={onCurrentFilterChanged}
              />
            </Level.Item>
            <Level.Item>
              <MMDButton isPrimary onClick={applyFilter} text="Apply filter" />
            </Level.Item>
            <Level.Item>
              <MMDCheckbox
                name="admin-approval"
                label="Show only medications that have not been approved"
                value={approvalFilter ? 'true' : 'false'}
                onChange={showOnlyNotApproved}
              />
            </Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>
              <MMDSuperAdminButton
                text="New"
                isPrimary
                onClick={goToCreateMedication}
              />
            </Level.Item>
          </Level.Side>
        </Level>
        {medications.map((item: Medication) => (
          <MedicationRow
            key={item.id}
            medication={item}
            targetPath={'medications'}
            onDeleteClick={requestDeleteMedication}
          />
        ))}
      </div>
      <MMDPager
        itemCount={count}
        currentOffset={routeParams.__offset}
        onChange={gotoPage}
      />
    </>
  );
};

const mapStateToProps = (state: MyState) => ({
  medications: medicationsSelectors.selectAll(state.medications),
  loading: state.medications.loading,
  count: state.medications.count,
  routeParams: paramsToDictionary(state.router.location.search),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...MedicationsActions,
      push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Medications);
