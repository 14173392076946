import { ComponentProps, FC } from 'react';

import MMDButton from 'components/MMDButton';
import { useIsSuperAdminCheck } from 'hooks/useIsSuperAdminCheck';

const MMDSuperAdminButton: FC<ComponentProps<typeof MMDButton>> = (props) => {
  const isSuperAdmin = useIsSuperAdminCheck();

  if (!isSuperAdmin) {
    return null;
  }

  return <MMDButton {...props} />;
};

export default MMDSuperAdminButton;
