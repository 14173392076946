import { ChangeEvent, FC, HTMLProps, useEffect, useState } from 'react';
import MMDDragAndDrop from './MMDDragAndDrop';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type PropTypes = {
  label: string;
  valid?: boolean;
  dirty?: boolean;
  value?: string;
  sectionsAux?: any;
  options?: any[];
  setSectionsName: any;
  onExternalChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & HTMLProps<HTMLSelectElement>;

const MMDCreateLabels: FC<PropTypes> = ({
  label,
  onChange,
  onExternalChange = undefined,
  valid = true,
  dirty = true,
  sectionsAux = [],
  setSectionsName,
  ...rest
}) => {
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState(
    sectionsAux[0] && typeof sectionsAux[0] !== 'string'
      ? sectionsAux
          .map((x) => JSON.stringify(x).toString())
          .map((select) =>
            select.split(',')[1].split(':')[1].split('}')[0].slice(1, -1),
          )
      : sectionsAux.map((x) => x),
  );
  const [selectedNames, setSelectedNames] = useState(selected);
  const handleChange = (value) => {
    setTitle(value);
  };

  const handleSelected = () => {
    if (!selectedNames.includes(title) && title !== '') {
      setSelectedNames([...selectedNames, title]);
      setSectionsName([...selectedNames, title]);
      setTitle('');
    }
  };

  const handleDelete = (value) => {
    setSelectedNames(selectedNames.filter((val) => val !== value));
    setSectionsName(selectedNames.filter((val) => val !== value));
    setSelected(selectedNames.filter((val) => val !== value));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      selectedNames,
      result.source.index,
      result.destination.index,
    );
    setSelectedNames(items);
    setSectionsName(items);
  };

  useEffect(() => {
    setSectionsName(selected);
  }, [selected]);

  return (
    <div className="field">
      <label className={`label`}>Sections</label>
      <input
        className={'input'}
        style={{ width: '30%' }}
        onChange={(e) => handleChange(e.target.value)}
        value={title}
        placeholder="insert title"
      />

      <button
        type={'button'}
        className={`button is-secondary `}
        style={{ borderRadius: '25px', marginLeft: '5px' }}
        onClick={() => handleSelected()}
      >
        Add Section
      </button>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              // @ts-ignore
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {provided.placeholder}

              {selectedNames.map((select, index) => (
                <Draggable key={select} draggableId={select} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MMDDragAndDrop
                        key={select}
                        column={select}
                        handleDelete={handleDelete}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default MMDCreateLabels;
