import { FC, FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, Dictionary } from '@mrnkr/redux-saga-toolbox';

import MMDForm from '../components/forms/MMDForm';
import MMDTextInput from '../components/forms/MMDTextInput';
import MMDButton from '../components/MMDButton';
import {
  Creators as ConditionActions,
  conditionFormValidator,
  selectConditionsById,
} from '../modules/conditions.module';
import { Condition } from 'typings';
import { useParams } from 'react-router-dom';

const PromoCodeForm: FC = () => {
  const params = useParams<{ id: string }>();

  const condition = useSelector((state) =>
    selectConditionsById(state, params.id),
  );

  const renderFormControls = useCallback(
    (form: Form, onChange: (e: FormEvent<HTMLInputElement>) => void) => (
      <div className="container p-2">
        <MMDTextInput
          name={form.fields['name'].name}
          value={form.fields['name'].value}
          label="Name"
          valid={form.fields['name'].valid}
          dirty={form.fields['name'].dirty}
          placeholder="Enter the condition's name here"
          onChange={onChange}
          required={true}
        />
        <MMDButton
          className="mt-2"
          isSubmit
          isPrimary
          text={condition ? 'Update' : 'Create'}
        />
      </div>
    ),
    [condition],
  );

  if (!condition && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-condition-form-${params.id}`
      : 'create-condition-form',
    fields: {
      name: condition && condition.name ? condition.name : '',
    },
    validator: conditionFormValidator,
    onSubmit: (values: Dictionary<string>) =>
      condition && condition.id
        ? ConditionActions.requestUpdateCondition({
            id: condition.id,
            name: values['name'],
          })
        : ConditionActions.requestCreateCondition({
            name: values['name'],
          } as Condition),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default PromoCodeForm;
