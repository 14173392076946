import map from 'lodash/map';
import uniq from 'lodash/uniq';
import moment from 'moment/moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectTimeslots,
  Creators as TimeslotActions,
} from 'modules/timeslots.module';
import {
  formatDate,
  formatLocalDate,
  shouldTriggerOnChange,
  checkValueForCurrentMonth,
} from './helpers';

type Props = {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
};

const TimeslotsCalendar: FC<Props> = ({ selectedDate, setSelectedDate }) => {
  const timeslots = useSelector(selectTimeslots);
  const { requestTimeslots, setCurrentDate } =
    useBoundedActions(TimeslotActions);

  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const markedDates = !timeslots?.length
    ? []
    : uniq(map(timeslots, (timeslot) => formatDate(timeslot.start.dateTime)));

  const getTitleClassName = ({ date }: { date: Date }) =>
    markedDates.includes(formatLocalDate(date))
      ? 'react-calendar__marked-date'
      : '';

  const onChangeTimeslots = (val) => {
    const newMonth = moment(val).month();
    const newYear = moment(val).year();
    if (selectedMonth !== newMonth || selectedYear !== newYear) {
      setSelectedMonth(newMonth);
      setCurrentDate(val);
      setSelectedYear(newYear);
      requestTimeslots({
        query: `?month=${formatDate(val)}`,
      });
    }

    setSelectedDate(val);
  };

  const onActiveStartDateChange = ({ activeStartDate, action, view }) => {
    if (shouldTriggerOnChange(action, view)) {
      onChangeTimeslots(checkValueForCurrentMonth(activeStartDate));
    }
  };

  return (
    <Calendar
      minDate={new Date()}
      value={selectedDate}
      calendarType="gregory"
      onChange={onChangeTimeslots}
      tileClassName={getTitleClassName}
      onActiveStartDateChange={onActiveStartDateChange}
    />
  );
};

export default TimeslotsCalendar;
